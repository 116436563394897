import { Icon, useColorMode } from '@chakra-ui/react';
import { RiDashboardFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { getColorThemeSelector } from '../../../utils/funcs';

export const DashboardLinkButton: React.FC = () => {
    const history = useHistory();
    const { chainId } = useWeb3WithDefault();
    const { THEME_CARD } = useCustomTheme();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <CustomButton
            variant="tertiary"
            w="34px"
            h="34px"
            borderRadius="3px"
            text={<Icon as={RiDashboardFill} boxSize="28px" px="3px" />}
            bgColor={colorTheme('secondary.50', THEME_CARD[chainId])}
            onClick={() => {
                history.push('/farm');
            }}
        ></CustomButton>
    );
};
