import { createModel } from '@rematch/core';
import BigNumber from 'bignumber.js';
import produce from 'immer';
import { RootModel } from '..';
import { blocksPerDay } from '../../../config/chains';
import { tokenSymbol2token } from '../../../config/tokens';
import { VEIZI_ADDRESS } from '../../../config/veizi/veiziContracts';
import { NftLockedResponse } from '../../../types/abis/veizi/VeiZi';
import { TokenSymbol } from '../../../types/mod';
import { decodeMethodResult, getVeiZiContract } from '../../../utils/contractHelpers';
import { amount2Decimal } from '../../../utils/tokenMath';
import { getChartTimeStamps, getTotalVeiZiCurve, getVeiZiGlobalData, getVeiZiNftCurve } from './funcs';
import { InitGlobalDataParams, InitManageDataParams, InitUserDataParams, VeiZiGlobalData, VeiZiManageData, VeiZiNft, VeiZiState, VeiZiUserData } from './types';

export const veiZi = createModel<RootModel>()({
    state: {
        globalData: {
            stakeiZiAmount: '0',
            rewardPerBlock: '0',
            totaliZiLocked: '0',
            totaliZiLockedDecimal: 0,
            totalVeiZi: '0',
            totalVeiZiDecimal: 0,
            seconds4Year: (4 * 365 + 1) * 24 * 3600,
            currentTimestamp: 0,
            curveTimestamps: [],
            totalVeiZiCurve: [],
            avgLockYear: 0,
        },
        userData: {
            nftList: [],
            totalLockedDecimal: 0,
            totalVeiZiDecimal: 0,
        },
        manageData: {
            manageNftId: '0',
            manageVeiZiCurve: [],
        }
    } as VeiZiState,
    reducers: {
        setVeiZiState: (state: VeiZiState, payload: VeiZiState) => {
            return {...state, ...payload};
        },
        setGlobalData: (state: VeiZiState, globalData: VeiZiGlobalData) => produce(state, draft => {
            draft.globalData = {...globalData};
        }),
        setUserData: (state: VeiZiState, userData: VeiZiUserData) => produce(state, draft => {
            draft.userData = {...userData};
        }),
        setManageData: (state: VeiZiState, manageData: VeiZiManageData) => produce(state, draft => {
            draft.manageData = {...manageData};
        }),
    },
    effects: (dispatch) => ({
        async initGlobalData(initGlobalDataParams: InitGlobalDataParams): Promise<void> {
            const {chainId, web3} = initGlobalDataParams;
            if (!chainId || !web3) {
                return;
            }
            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const globalData = await getVeiZiGlobalData(veiZiAddress, chainId, web3);
            const currentDate = new Date(globalData.currentTimestamp * 1000);
            const curveTimestamps = getChartTimeStamps(currentDate);
            globalData.curveTimestamps = curveTimestamps;
            globalData.avgLockYear = globalData.totalVeiZiDecimal / globalData.totaliZiLockedDecimal * 4 ?? 0;
            
            if (!veiZiAddress) {
                // not support chainId
                for (let i = 0; i < globalData.curveTimestamps.length; i ++) {
                    globalData.totalVeiZiCurve.push(0);
                }
            } else {
                globalData.totalVeiZiCurve = await getTotalVeiZiCurve(veiZiAddress, globalData.curveTimestamps, chainId, web3);
            }
            dispatch.veiZi.setGlobalData(globalData);
        },
        async initUserData(initUserDataParams: InitUserDataParams, rootState): Promise<void> {
            const {chainId, web3, account} = initUserDataParams;
            if (!chainId || !web3 || !account || !rootState.veiZi.globalData) {
                return;
            }
            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const veiZiContract = getVeiZiContract(veiZiAddress, web3);
            if (!veiZiContract) {
                const userData = {
                    nftList: [],
                    totalLockedDecimal: 0,
                    totalVeiZiDecimal: 0,
                } as VeiZiUserData;
                dispatch.veiZi.setUserData(userData);
                return;
            }

            const veiZiNum = await veiZiContract.methods.balanceOf(account).call().then((num: number) => num);
            const veiZiIdMulticall = [];
            for (let i = 0; i < veiZiNum; i ++) {
                veiZiIdMulticall.push(veiZiContract.methods.tokenOfOwnerByIndex(account, i).encodeABI());
            }
            
            const veiZiIdRawStringListResult: string[] = await veiZiContract.methods.multicall(veiZiIdMulticall).call().then((idList: string[]) => idList);
            const veiZiIdStringList: string[] = veiZiIdRawStringListResult.map((id: string)=>new BigNumber(id).toFixed(0));

            const stakedNftBN = new BigNumber(await veiZiContract.methods.stakedNft(account).call());
            const stakedNftId = stakedNftBN.toFixed(0);
            
            if (stakedNftId !== String('0')) {
                veiZiIdStringList.push(stakedNftId);
            }
            const nftLockedMulticallData = veiZiIdStringList.map((id:string)=>veiZiContract.methods.nftLocked(id).encodeABI());
            const nftLockedResponse = await veiZiContract.methods.multicall(nftLockedMulticallData).call();
            const nftLocked: NftLockedResponse[] = nftLockedResponse.map((data:string)=>{
                const t: NftLockedResponse = decodeMethodResult(veiZiContract, 'nftLocked', data);
                return t;
            });
            const nftList: VeiZiNft[] = [];


            const currentTimestamp = rootState.veiZi.globalData.currentTimestamp;
            const seconds4Year = rootState.veiZi.globalData.seconds4Year;
            const blockNum4Year = blocksPerDay(chainId) * 365;

            let totalLockedDecimal = 0;
            let totalVeiZiDecimal = 0;
            for (let i = 0; i < nftLocked.length; i ++) {
                const item = nftLocked[i];
                const nftId = veiZiIdStringList[i];
                const lockAmountStr = item.amount;
                const lockAmountDecimal: number = amount2Decimal(new BigNumber(lockAmountStr), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
                const timeRemain = Math.max(0, Number(item.end) - currentTimestamp);
                const veiZiDecimal = lockAmountDecimal * timeRemain / seconds4Year;

                let pendingRewardDecimal = 0;
                const isStaked = (nftId === stakedNftId);
                let apr = 0;
                if (isStaked) {
                    const pendingReward = await veiZiContract.methods.pendingRewardOfToken(nftId).call();
                    pendingRewardDecimal = amount2Decimal(new BigNumber(pendingReward), tokenSymbol2token(TokenSymbol.IZI, chainId)) ?? 0;
                    if (rootState.veiZi.globalData.stakeiZiAmount === '0') {
                        apr = 0;
                    } else {
                        const stakingStatus = await veiZiContract.methods.stakingStatus(nftId).call();
                        const aprTot = Number(new BigNumber(blockNum4Year).times(rootState.veiZi.globalData.rewardPerBlock).div(rootState.veiZi.globalData.stakeiZiAmount));
                        apr = aprTot * Number(new BigNumber(stakingStatus.lastVeiZi).div(item.amount));
                    }
                }

                const nft = {
                    lockAmountDecimal,
                    veiZiDecimal,
                    nftId,
                    isStaked,
                    endTimestamp: Number(item.end),
                    owner: account,
                    pendingRewardDecimal,
                    apr,
                } as VeiZiNft;
                nftList.push(nft);
                totalLockedDecimal += lockAmountDecimal;
                totalVeiZiDecimal += veiZiDecimal;
            }
            const userData = {
                nftList,
                totalLockedDecimal,
                totalVeiZiDecimal,
            } as VeiZiUserData;
            dispatch.veiZi.setUserData(userData);
        },
        async initManageData(initManageDataParams: InitManageDataParams, rootState): Promise<void> {
            const {chainId, web3, account, manageNftId} = initManageDataParams;
            if (!chainId || !web3 || !account || !rootState.veiZi.globalData || !rootState.veiZi.userData) {
                return;
            }
            const manageNft = rootState.veiZi.userData.nftList.find((e)=>{return e.nftId === manageNftId;});
            if (!manageNft) {
                const manageVeiZiCurve = [];
                for (let i = 0; i < rootState.veiZi.globalData.curveTimestamps.length; i ++) {
                    manageVeiZiCurve.push(0);
                }
                dispatch.veiZi.setManageData({manageNftId, manageVeiZiCurve} as VeiZiManageData);
            }
            const veiZiAddress = VEIZI_ADDRESS[chainId];
            const manageVeiZiCurve = await getVeiZiNftCurve(veiZiAddress, manageNftId, rootState.veiZi.globalData.curveTimestamps, chainId, web3);
            dispatch.veiZi.setManageData({manageNftId, manageVeiZiCurve} as VeiZiManageData);
        },
        async initAll(initUserDataParams: InitUserDataParams): Promise<void> {
            const {chainId, web3, account} = initUserDataParams;
            await dispatch.veiZi.initGlobalData({chainId, web3} as InitGlobalDataParams);
            await dispatch.veiZi.initUserData({chainId, web3, account} as InitUserDataParams);
        }
    }),
});