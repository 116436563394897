import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmDynamicRangeiZiContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    iZiSwapAddress: string;
    initialToggle?: boolean;

    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    contractVersion?: FarmDynamicRangeiZiContractVersion;
    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    priceRangeRatio?: number;

    // if priceRangeRatio is undefined, means
    // leftRangeRatio != rightRangeRatio
    // leftRangeRatio >= 1
    leftRangeRatio?: number;
    // rightRangeRatio >= 1
    rightRangeRatio?: number;
    isEnded?: boolean;

    useTimestamp?: boolean;
    useOriginLiquidity?: boolean;

    additionalKey?: string;
    remark?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [],
              [ChainId.Matic]: [],
              [ChainId.Mantle]: [
                  {
                      ...sortedToken(TokenSymbol.CTT, TokenSymbol.USDT, ChainId.Mantle),
                      miningContract: '0x3c61742c5b8B8c25e8D57b9f8115050463faf690',
                      iZiSwapAddress: '0xf7c5008ff737899d4f97992726d7572efeab87ff',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.AXL, TokenSymbol.USDC, ChainId.Mantle),
                      miningContract: '0xC0f4838625430A9c1d2D6D4ae9e834Af1dBA1D1c',
                      iZiSwapAddress: '0x5b39F3b6d83354d2a6adde26e39dEb0b0774937b',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.LEND, TokenSymbol.USDC, ChainId.Mantle),
                      miningContract: '0x414e38CF17a1CCcAc33599D2D88DAf6dbb680b1F',
                      iZiSwapAddress: '0xfc27767d5a406e732bc15b9bf8ad2907a1488a8f',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.MINU, TokenSymbol.MNT, ChainId.Mantle),
                      miningContract: '0x45b534B329d1410F37026e50014C37BD140B055d',
                      iZiSwapAddress: '0xC32b04620A04772dCeC882316224FFE0679cDFaf',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 10,
                      isEnded: true,
                      useTimestamp: true,
                  },
              ],
              [ChainId.BSC]: [
                  {
                      ...sortedToken(TokenSymbol.ARBI, TokenSymbol.BNB, ChainId.BSC),
                      miningContract: '0xa83d8df08Bd85C826162E71EFB85287Bf1045f4e',
                      iZiSwapAddress: '0x21b08b1a911f9058c92e02e9cc353aa1d34fe90d',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.CCC, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0x8830e9eb594d0bBABfe831B36d8fd2CE37A9c5eB',
                      iZiSwapAddress: '0x41c68fd66f8d9fe1f2c2fe8f42bf84ac4d073ff2',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },

                  {
                      ...sortedToken(TokenSymbol.HALO, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0xe35E73296726457a0a73b43fd0eF14e5348a8227',
                      iZiSwapAddress: '0x9e81504cecd8004594584569ae5d8ddad3f4f6e9',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.TLOS, ChainId.BSC),
                      miningContract: '0x3062a9aD5562E2fbbaf9c44621DC0D4e6Ce9529A',
                      iZiSwapAddress: '0x9e85e816e5C9c6973Dc0bc3F0fe58c9f63B3dF74',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.WOO, TokenSymbol.BNB, ChainId.BSC),
                      miningContract: '0xeC066c10B79c5f5663E3B224B731e5b390B29ADE',
                      iZiSwapAddress: '0x0c6c93F7DD4F38fb397c6CCCC4a6a74f069b6223',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.GRI, TokenSymbol.BNB, ChainId.BSC),
                      miningContract: '0xc9D1DCa243eACEB62B1e6E354015E53c10f2c59b',
                      iZiSwapAddress: '0x765E152ab2d0C5C41DE733092F5F281C0311695b',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 2,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.IZI, TokenSymbol.IUSD, ChainId.BSC),
                      miningContract: '0x29038f8ADFA0325Ae8dF92b3a1491e2eED281d23',
                      iZiSwapAddress: '0x50b4fd0858de0145740ba517dc34f682b9863405',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.BNB, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0xA39dD789D77a8D8AE17DdF1b3a8f569a31d6dd41',
                      iZiSwapAddress: '0xF964529721ECD0C9386d922a37CbBD2b67EA6E93',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 1.25,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.HYT, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0xe4C226cdFCBA27bbc283a3050feC65f76BBE8C08',
                      iZiSwapAddress: '0xB28B66cC7e327B2B3Cb49fBc461b6E755Ae56789',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: false,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: true,
                      feeCharged: 0,
                      priceRangeRatio: 2,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.MIT, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0x7d93470243cd1c0324276758C45d7d496175A1A9',
                      iZiSwapAddress: '0xC93020EeDeDF6903A7238Dd409f45B74D0ae2071',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: false,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: true,
                      feeCharged: 0,
                      priceRangeRatio: 2,
                      isEnded: true,
                  },
              ],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.ARBI, TokenSymbol.ETH, ChainId.Arbitrum),
                      miningContract: '0x8984901cEaff81b45396a13D8DcD4F153e62A429',
                      iZiSwapAddress: '0xcae394d44d6573a11db9e94a55a294a3af233e96',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.MTG, TokenSymbol.USDT, ChainId.Arbitrum),
                      miningContract: '0x7de737FB4aC1559a952B8DBa0CC6aCF771bcc4Da',
                      iZiSwapAddress: '0xf0718fe6f6f1884bf8b2004300ce11c3923d0428',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 10,
                      isEnded: false,
                      useTimestamp: true,
                  },
              ],
              [ChainId.ZkSyncEra]: [
                  {
                      ...sortedToken(TokenSymbol.ETH, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0xC6e980CC272767766592BcBb9763665983CDC4a0',
                      iZiSwapAddress: '0x43ff8a10b6678462265b00286796e88f03c8839a',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: true,
                      feeCharged: 0,
                      leftRangeRatio: 1.40056,
                      rightRangeRatio: 1.4,
                      isEnded: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.ZKSP, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0xD753A8a1155eFC50a52B7088D410f39f856225E2',
                      iZiSwapAddress: '0x48f65eaed62007e774e9893a491e546e8774b90d',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 4,
                      rightRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                      additionalKey: '02',
                  },
                  {
                      ...sortedToken(TokenSymbol.DEXTF, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0x7a209041eAD28cBd830f3e73289f7b89DE6C805C',
                      iZiSwapAddress: '0xca8d6a480e459d3b194d7f3e898c41003a3cf87c',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 4,
                      rightRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                      additionalKey: '02',
                  },
                  {
                      ...sortedToken(TokenSymbol.BEL, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0x9FB6Ca27D20E569E5c8FeC359C9d33D468d2803C',
                      iZiSwapAddress: '0xe84a3835CD9434A94f1632cF623bcb53eBAAb80c',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 2,
                      rightRangeRatio: 2,
                      isEnded: false,
                      useTimestamp: true,
                      additionalKey: '02',
                  },
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.zkUSD, ChainId.ZkSyncEra),
                      miningContract: '0xFcc21e03b25BC8cA918D497fb014fa3491503c0c',
                      iZiSwapAddress: '0x0460c2236809140dac7cb22d3e3a7eb9fd4aefbd',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 1.01010101010101,
                      rightRangeRatio: 1.01,
                      isEnded: true,
                      useTimestamp: true,
                      useOriginLiquidity: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.ETH, TokenSymbol.zkUSD, ChainId.ZkSyncEra),
                      miningContract: '0x2c38130dfF9097F9486Ee0A53f5261e9c6acd6ad',
                      iZiSwapAddress: '0x23de0ad8b08195b3bcefd58060837b4f87fc190e',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      leftRangeRatio: 1.3333333333333333,
                      rightRangeRatio: 1.3,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.KANA, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0xA7Fb8cd35409062a7D811535B7b0c2274335D5bD',
                      iZiSwapAddress: '0xa65b7ca8f35f1fd7e778fd435e276f6e0205e143',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },

                  {
                      ...sortedToken(TokenSymbol.GGG, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0xA7f0A6162567E2E9d77f81C8bc7a2E18F19f5d28',
                      iZiSwapAddress: '0xb6bfdfa8f2f2a4bef0ed03f39f77c48b0a2fb743',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.LSD, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x893feD28e2d1599a513498d6CF6D0Fb5dA5fbbd4',
                      iZiSwapAddress: '0xef0783943a07e3822f5094497767ff8888144b5d',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.BLADE, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0x8baCe5229771d2909924B055aCd2f109EB4cf8a8',
                      iZiSwapAddress: '0x6fdc8ba4428db4c72b17e6c59ba1ec6de5baeb16',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 2,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.DEXTF, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0x353a12B0D46618c513bF5313AB7DfFB01227C234',
                      iZiSwapAddress: '0xa9addc3bb68dd601cc9d9ba6b0e5e4651a7c476f',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.ZKSP, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x28702B2b58cCb5927b53aF91E5dC63270273d4C3',
                      iZiSwapAddress: '0x3a83b68de90a660e1780df9c84d57de3fa832e66',
                      feeTier: 1,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.RF, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x0C4E0F1Ff3B200db601b5A27adBaD288e804A35B',
                      iZiSwapAddress: '0x0f149b3f475d0acbc2ab849b50a0e14593450997',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.OT, TokenSymbol.USDC, ChainId.ZkSyncEra),
                      miningContract: '0xcB27b19c27e316f2deE2f07123716F36368C9e97',
                      iZiSwapAddress: '0x3fc25ee9c05eeddecd04f347b8ed702500993ec3',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.SPACE, TokenSymbol.ETH, ChainId.ZkSyncEra),
                      miningContract: '0x78e1F126a1BAE67bBC05A92CF2Bc171C69ee250a',
                      iZiSwapAddress: '0x682a471baa1e77d447b4a670925eb7c9ad752324',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      isEnded: true,
                      useTimestamp: true,
                  },
              ],
          }
        : {};
