import { BoxProps, useColorMode, HStack, VStack, Text, Image, Stack } from '@chakra-ui/react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy } from '../../../../../style';
import { RootState } from '../../../../../state/store';
import { formatNumber } from '../../../../../utils/tokenMath';
import { liquidity2TokenAmountDecimal } from '../../../../../utils/tickMath';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { usePositionManagerContract } from '../../../../../net/contractCall/positionManager';
import { getTokenOwned } from '../../../../../net/contractCall/uniV3Interface/getTokenOwned';
import { useEffect, useState, useMemo } from 'react';
import { CollectRequest, PositionsResponse } from '../../../../../types/abis/UniswapV3/UniswapPositionManager';
import { useSelector } from 'react-redux';
import { ModalHead } from '../../../components/ModalHead';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../state/store';
import { PoolEntryState } from '../../../../../state/models/farm/UniswapV3/fixRange/types';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { buildSendingParams } from '../../../../../utils/contractHelpers';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';

type PositionDetailModalProps = {
    isOpen: boolean | any;
    onClose: any;
    index: number;
    entry: PoolEntryState;
} & BoxProps;

export const PositionDetailModal: React.FC<PositionDetailModalProps> = (props) => {
    const { isOpen, onClose, entry, index, ...rest } = props;
    const { account, chainId, web3 } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { token } = useSelector((state: RootState) => state);

    const positionManagerContract = usePositionManagerContract(chainId, web3);
    const [tokenAGainDecimal, setTokenAGainDecimal] = useState(0);
    const [tokenBGainDecimal, setTokenBGainDecimal] = useState(0);

    const [tokenAGainAmount, setTokenAGainAmount] = useState('0');
    const [tokenBGainAmount, setTokenBGainAmount] = useState('0');

    const position = useMemo(() => {
        return [...(entry.positionList ?? []), ...(entry.stakedPositionList ?? [])][index];
    }, [entry, index]);

    console.log(position);
    console.log(entry);

    const [tokenADecimal, tokenBDecimal] = useMemo(() => {
        return liquidity2TokenAmountDecimal(
            position.liquidity,
            position.tickLower,
            position.tickUpper,
            entry.data.currentTick,
            {
                left: entry.meta.tokenA.decimal,
                right: entry.meta.tokenB.decimal,
            },
            entry.data.positionSqrtPriceX96
        );
    }, [position, entry]);

    useEffect(() => {
        getTokenOwned(positionManagerContract, Number(position.nftId)).then((r: PositionsResponse) => {
            setTokenAGainDecimal((parseFloat(r.tokensOwed0) / 10 ** entry.meta.tokenA.decimal) * (1 - (entry.meta.feeCharged ?? 0))); // after fee charge
            setTokenBGainDecimal((parseFloat(r.tokensOwed1) / 10 ** entry.meta.tokenB.decimal) * (1 - (entry.meta.feeCharged ?? 0)));
            setTokenAGainAmount(r.tokensOwed0);
            setTokenBGainAmount(r.tokensOwed1);
        });
    }, [position, positionManagerContract, entry, setTokenAGainDecimal, setTokenBGainDecimal]);

    const tokenAValue = tokenADecimal * getSafeTokenPrice(token, entry.meta.tokenA.symbol);
    const tokenBValue = tokenBDecimal * getSafeTokenPrice(token, entry.meta.tokenB.symbol);
    const tokenAGainValue = tokenAGainDecimal * getSafeTokenPrice(token, entry.meta.tokenA.symbol);
    const tokenBGainValue = tokenBGainDecimal * getSafeTokenPrice(token, entry.meta.tokenB.symbol);

    const WithdrawButton = (
        <CustomButton
            variant="orange"
            text="Withdraw"
            mb="2px"
            w={{ base: '100%', sm: '272px' }}
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const nftId = position?.nftId;
                if (nftId === undefined) {
                    return;
                }
                const multicallData = [];
                console.log(position.liquidity);
                if (Number(position.liquidity) !== 0) {
                    multicallData.push(
                        positionManagerContract?.methods
                            .decreaseLiquidity({
                                tokenId: nftId,
                                liquidity: String(position.liquidityBigNumber),
                                amount0Min: 0,
                                amount1Min: 0,
                                deadline: '2147483647', //2038 year
                            })
                            .encodeABI()
                    );
                    multicallData.push(
                        positionManagerContract?.methods
                            .collect({
                                tokenId: nftId,
                                recipient: account,
                                amount0Max: '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
                                amount1Max: '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF',
                            } as CollectRequest)
                            .encodeABI()
                    );
                }
                multicallData.push(positionManagerContract?.methods.burn(nftId).encodeABI());

                positionManagerContract?.methods
                    .multicall(multicallData)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        ) as any
                    )
                    .then((e: any) => {
                        console.log('eee: ', e);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }}
        />
    );

    const CollectButton = (
        <CustomButton
            variant="primary2"
            text="Collect"
            mb="2px"
            w={{ base: '100%', sm: '272px' }}
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const nftId = position?.nftId;
                if (nftId === undefined) {
                    return;
                }
                if (tokenAGainAmount === '0' && tokenBGainAmount === '0') {
                    return;
                }

                positionManagerContract?.methods
                    .collect({
                        tokenId: nftId,
                        recipient: account,
                        amount0Max: tokenAGainAmount,
                        amount1Max: tokenBGainAmount,
                    } as CollectRequest)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        )
                    )
                    .then((e: any) => {
                        console.log('eee: ', e);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }}
        />
    );

    const tokenIconBlock = (token: TokenInfoFormatted, amount: any, value: any, key?: any) => (
        <Stack
            w="100%"
            direction={{ base: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            key={key ? key : 'unset'}
        >
            <HStack>
                <Image borderRadius="9px" w="18px" h="18px" src={token.icon} />
                <Text className={i_text_copy_bold} fontSize="16px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                    {token.symbol}
                </Text>
            </HStack>
            <VStack alignItems="end" spacing="0px">
                <Text className={i_text_copy} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                    {amount}
                </Text>
                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.300', 'tertiary.300')}>
                    ~ {value} USD
                </Text>
            </VStack>
        </Stack>
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '73%', sm: '744px' }}
            h={{ base: 'unset', sm: '610px' }}
            title={t('NFT Status')}
            {...rest}
        >
            <ModalHead entry={entry} index={index} dispatch={dispatch.farmFixRange} />

            <Stack w="100%" direction={{ base: 'column', sm: 'row' }} spacing="16px" mt="30px">
                <Card variant="deep" w={{ base: '100%', sm: '328px' }} pb="20px">
                    <VStack
                        w="100%"
                        p={{ base: '30px', sm: '30px 46px 14px 46px' }}
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start" spacing="20px">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                Token in pools
                            </Text>
                            {tokenIconBlock(entry.meta.tokenA, formatNumber(tokenADecimal, 2, 4), formatNumber(tokenAValue, 2, 4), 1)}
                            {tokenIconBlock(entry.meta.tokenB, formatNumber(tokenBDecimal, 2, 4), formatNumber(tokenBValue, 2, 4), 2)}
                        </VStack>
                        {!position.isStaked && WithdrawButton}
                    </VStack>
                </Card>

                {(entry.meta.feeCharged ?? 0) < 1 && (
                    <Card variant="deep" w={{ base: '100%', sm: '328px' }} pb="20px">
                        <VStack
                            w="100%"
                            p={{ base: '30px', sm: '20px 46px 14px 46px' }}
                            spacing="30px"
                            alignItems="center"
                            bg={colorTheme('undefined', 'tertiary.800')}
                        >
                            <VStack w="100%" alignItems="start" spacing="20px">
                                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                    {t('Fee Gains')}
                                </Text>
                                {tokenIconBlock(
                                    entry.meta.tokenA,
                                    formatNumber(tokenAGainDecimal, 2, 2, true),
                                    formatNumber(tokenAGainValue, 2, 2, true),
                                    1
                                )}
                                {tokenIconBlock(
                                    entry.meta.tokenB,
                                    formatNumber(tokenBGainDecimal, 2, 2, true),
                                    formatNumber(tokenBGainValue, 2, 2, true),
                                    2
                                )}
                            </VStack>
                            {!position.isStaked && CollectButton}
                        </VStack>
                    </Card>
                )}
            </Stack>
        </Modal>
    );
};
