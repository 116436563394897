import { ChainId, TokenSymbol } from '../types/mod';
import chains from './chains';
import { tokenSymbol2token } from './tokens';

export const DISABLE_CONSOLE = false;

export const TOKEN_CONFIG = {
    AUTO_REFRESH_TOKEN_PRICE_INTERVAL: 60 * 1000,
    INITIAL_FETCH_TOKEN_LIST: [
        TokenSymbol.USDC,
        TokenSymbol.ETH,
        TokenSymbol.WETH,
        TokenSymbol.USDT,
        TokenSymbol.IZI,
        TokenSymbol.BUSD,
        TokenSymbol.WBNB,
        TokenSymbol.IUSD,
        TokenSymbol.WBNB,
        TokenSymbol.BNB,
    ],
};

export const DEFAULT_CHAIN_ID = ChainId.BSC;
export const DEFAULT_CHAIN_ID_TEST = ChainId.BSCTestnet;

export const FARM_CONFIG = {
    AUTO_REFRESH_FARM_DATA_INTERVAL: 6 * 1000,
    REWARD_DISPLAY: 'day', // day or block/second
    APR_FEE_GAINS: {
        [ChainId.EthereumMainnet]: 2,
        [ChainId.Matic]: 6,
        [ChainId.Optimism]: 0,
        [ChainId.BSC]: 0,
        [ChainId.Gatechain]: 0,
        [ChainId.Arbitrum]: 3,
        [ChainId.Rinkeby]: 0,
        [ChainId.Aurora]: 0,
        [ChainId.ETC]: 0,
    } as Record<ChainId, number>,
    ALL_FACTOR: 0.99999,
};

export const UNISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
};

export const IZISWAP_MINT_CONFIG = {
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.98,
    DEADLINE_OFFSET_MINUTES: 5,
    AUTO_REFRESH_POOL_STATE_INTERVAL: 15 * 1000,
};

export const IZUMI_SWAP_CONFIG = {
    AUTO_REFRESH_LIQUIDITY_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL: 6 * 1000,
    AUTO_REFRESH_SWAP_CALC_INTERVAL: 15 * 1000,

    ADD_LIQ_DEFAULT_PRICE_OFFSET_PERCENT: 2,

    SWAP_DEFAULT_SLIPPAGE_PERCENT: 0.5,
    TAP_SWAP_DESIRED_MIN_AMOUNT_FACTOR: 0.995,
    SWAP_DEFAULT_MAXIMUM_DELAY: 10,
    DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR: 0.985,

    DESIRED_AMOUNT_TO_MIN_AMOUNT_FEE_TOKEN_FACTOR: 0.85,

    SWAP_PRICE_FEE_SWITCH: false, // if true, displayed swap price (current price) will count in fee cost
};

export const ACCOUNT_CONFIG = {
    AUTO_REFRESH_TOKEN_BALANCE_INTERVAL: 15 * 1000,
};

export const CHAIN_CONFIG = {
    AUTO_REFRESH_GAS_PRICE_INTERVAL: 10 * 1000,
    AUTO_REFRESH_BLOCK_NUMBER_INTERVAL: 5 * 1000,
};

export const PRO_CONFIG = {
    KLINE_AUTO_REFRESH_INTERVAL: 15 * 1000,
    ORDERBOOK_AUTO_REFRESH_PRICE_INTERVAL: 3 * 1000,
    POOL_STATE_REFRESH_INTERVAL: 15 * 1000,
};

export const PRO_DEFAULT_MARKET = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const markets = {
        //mainnet
        [ChainId.BSC]: {
            tokenX: tokenSymbol2token(TokenSymbol.WBNB, ChainId.BSC),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSC),
            pool: '0xf964529721ecd0c9386d922a37cbbd2b67ea6e93',
            fee: 2000,
            market: 'WBNB_USDT_2000',
        },
        [ChainId.Arbitrum]: {
            tokenX: tokenSymbol2token(TokenSymbol.WETH, ChainId.Arbitrum),
            tokenY: tokenSymbol2token(TokenSymbol.USDC, ChainId.Arbitrum),
            pool: '0x6336e3f52d196b4f63ee512455237c934b3355eb',
            fee: 2000,
            market: 'WETH_USDC_2000',
        },
        [ChainId.ZkSyncEra]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDC, ChainId.ZkSyncEra),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.ZkSyncEra),
            pool: '0x43ff8a10b6678462265b00286796e88f03c8839a', //2023.07.26
            fee: 2000,
            market: 'USDC_WETH_2000',
        },
        [ChainId.Linea]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDC, ChainId.Linea),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.Linea),
            pool: '0x564e52bbdf3adf10272f3f33b00d65b2ee48afff', //2023.10.09
            fee: 3000,
            market: 'USDC_WETH_3000',
        },
        [ChainId.Mantle]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDT, ChainId.Mantle),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.Mantle),
            pool: '0xbe18aad013699c1cdd903cb3e6d596ef99c37650', //2023.07.26
            fee: 3000,
            market: 'USDT_WETH_3000',
        },
        [ChainId.Base]: {
            tokenX: tokenSymbol2token(TokenSymbol.USDBC, ChainId.Base),
            tokenY: tokenSymbol2token(TokenSymbol.WETH, ChainId.Base),
            pool: '0xdb5d62f06eecef0da7506e0700c2f03c57016de5', //2023.10.09
            fee: 3000,
            market: 'USDbC_WETH_3000',
        },

        // testnet
        [ChainId.BSCTestnet]: {
            tokenX: tokenSymbol2token(TokenSymbol.WBNB, ChainId.BSCTestnet),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSCTestnet),
            pool: '0x16baebb51e257e9b9b3fe692b7122e80ed26df3b',
            fee: 2000,
            market: 'WBNB_USDT_2000',
        },
        [ChainId.LineaTest]: {
            tokenX: tokenSymbol2token(TokenSymbol.IZI, ChainId.LineaTest),
            tokenY: tokenSymbol2token(TokenSymbol.USDC, ChainId.LineaTest),
            pool: '0x972C8B328eED91cd2548fEB306fA1dfC636BFafC',
            fee: 2000,
            market: 'iZi_USDC_2000',
        },
        [ChainId.MantleTest]: {
            tokenX: tokenSymbol2token(TokenSymbol.WMNT, ChainId.MantleTest),
            tokenY: tokenSymbol2token(TokenSymbol.USDT, ChainId.MantleTest),
            pool: '0x2722c280c3a99902f7c9970fb2076e5266d98207',
            fee: 2000,
            market: 'WMNT_USDT_2000',
        },
    } as any;
    memo[chain.id] = markets[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const AUTO_LOGIN = true;

export const A_LONG_FUTURE_TIME = new Date('2026-01-01').getTime() / 1000;

export const FAKE_PRICE = {
    use: false,
    price: {
        USDC: 1,
        iUSD: 1,
        USDT: 1,
        BUSD: 1,
    },
} as any;

export const HOT_TOKENS = {
    [ChainId.BSC]: ['BNB', 'iZi', 'CYBER', 'ETH', 'USDT'],
    [ChainId.ZkSyncEra]: ['iZi', 'USDC', 'USDT', 'ETH'],
    [ChainId.Mantle]: ['iZi', 'MNT', 'USDT', 'ETH'],
    [ChainId.Scroll]: ['iZi', 'USDT', 'USDC', 'ETH'],
    [ChainId.BSCTestnet]: ['iZi', 'BNB'],
} as any;

export const SEARCH_TOKENS_PRO = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        //mainnet
        [ChainId.BSC]: ['USDT', 'BUSD', 'iUSD', 'WBNB'],
        [ChainId.Aurora]: ['USDT', 'USDC'],
        [ChainId.Icplaza]: ['USDT'],
        [ChainId.ConfluxESpace]: ['USDT'],
        [ChainId.Meter]: ['USDT.eth', 'WMTR'],
        [ChainId.ZkSyncEra]: ['USDC', 'WETH'],
        [ChainId.Mantle]: ['USDT', 'WETH'],
        [ChainId.Linea]: ['USDC', 'iUSD', 'BUSD', 'WETH'],
        [ChainId.Base]: ['USDbC', 'WETH'],
        //testnet
        [ChainId.BSCTestnet]: ['USDT', 'BUSD', 'WBNB'],
        [ChainId.ScrollTestL2]: ['USDT'],
        [ChainId.MantleTest]: ['USDT'],
        [ChainId.LineaTest]: ['USDC', 'USDT', 'WETH'],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const CUSTOM_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0x76E4EFB2c78b08c3fdE3eDF98c6503dd9Df9C5E8', //ANT
            '0xa0D289c752FE23043f29BD81e30D8345180bD9BB', //WrappedANT,
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_TOKEN_BLACKLIST = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const tokens = {
        [ChainId.BSC]: [
            '0xe85afccdafbe7f2b096f268e31cce3da8da2990a', // aBNBc
        ],
    } as any;
    memo[chain.id] = tokens[chain.id] ?? [];
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_FROM_TOKEN = chains.all.reduce<Record<number, string | string[]>>((memo, chain) => {
    const tokens = {
        //mainnet
        //testnet
        [ChainId.LineaTest]: ['USDC', '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068'],
        [ChainId.StagingFastActiveBellatrix]: 'USDC',
    } as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const TRADE_DEFAULT_TO_TOKEN = chains.all.reduce<Record<number, string | string[]>>((memo, chain) => {
    const tokens = {
        //mainnet
        [ChainId.BSC]: 'USDT', //iZi
        [ChainId.ConfluxESpace]: 'USDT',
        [ChainId.Arbitrum]: 'USDC',
        [ChainId.Matic]: 'USDC',
        [ChainId.ZkSyncEra]: 'USDC',
        [ChainId.Telos]: 'USDT',
        [ChainId.Meter]: 'MTRG',
        [ChainId.Icplaza]: 'USDT',
        [ChainId.Ontology]: 'WONT',
        [ChainId.Ultron]: 'uUSDT',
        [ChainId.Mantle]: 'WETH',
        [ChainId.Loot]: 'DAI',
        [ChainId.Optimism]: 'USDT',
        [ChainId.Linea]: 'USDC',
        [ChainId.Kroma]: 'USDT',
        [ChainId.Manta]: 'USDC',
        [ChainId.OpBNB]: 'USDT',
        [ChainId.Base]: 'USDbC',
        [ChainId.Scroll]: 'USDC',
        //testnet
        [ChainId.MantleTest]: 'USDT',
        [ChainId.ScrollTestL2]: 'USDT',
        [ChainId.LineaTest]: ['USDT', '0x1990BC6dfe2ef605Bfc08f5A23564dB75642Ad73'],
        [ChainId.GasZeroGoerliL2]: 'USDT',
        [ChainId.ZetaTest]: 'USDT',
        [ChainId.X1Test]: 'USDT',
    } as any;
    memo[chain.id] = tokens[chain.id] ?? undefined;
    return memo;
}, {}) as any;

export const POOLS_APR_RANGE = {
    [ChainId.ZkSyncEra]: [
        {
            pool_address: '0x43ff8a10b6678462265b00286796e88f03c8839a',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.2%-[-20%,+20%]
        {
            pool_address: '0x2158ec5366effca1216af93ee0d77762e2ebd466',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.04%-[-20%,+20%]
        {
            pool_address: '0xe930f025edac0fe0dc8fe343d18a355d0b68aff6',
            leftRange: -2,
            rightRange: 2,
        }, // BUSD-USDC-0.04%-[-2%,+2%]
    ],
    [ChainId.Scroll]: [
        {
            pool_address: '0x8F8ed95B3B3eD2979d1ee528f38cA3e481a94dd9',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.3%-[-20%,+20%]
    ],
    [ChainId.Linea]: [
        {
            pool_address: '0x564e52Bbdf3aDF10272F3f33b00D65b2EE48AFfF',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.3%-[-20%,+20%]
        {
            pool_address: '0x5615a7b1619980f7D6B5E7f69f3dc093DFe0C95C',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.05%-[-20%,+20%]
    ],
    [ChainId.Base]: [
        {
            pool_address: '0xdb5D62f06EEcEf0Da7506e0700c2f03c57016De5',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.3%-[-20%,+20%]
    ],
    [ChainId.Mantle]: [
        {
            pool_address: '0xBE18AAd013699C1CDd903cb3e6d596ef99C37650',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.3%-[-20%,+20%]
        {
            pool_address: '0x58E201316aA3dC7f1227A5C5c14490836926636e',
            leftRange: -20,
            rightRange: 20,
        }, // WETH-USDC-0.05%-[-20%,+20%]
        {
            pool_address: '0xCddf50c3DfAc95939167eff4c05D38781f549Ea9',
            leftRange: -50,
            rightRange: 50,
        }, // WMNT-USDT-0.3%-[-50%,+50%]
        {
            pool_address: '0x1dA0925773E15359C8b87272146E86444eb4faed',
            leftRange: -50,
            rightRange: 50,
        }, // WMNT-USDT-0.05%-[-50%,+50%]
    ],
} as any;

export const SHOW_REFRESH_PAGE = ['/trade/swap', '/trade/liquidity'];

export const FEE_CHARGE_PERCENT = 20;

export const LIMIT_ORDER_DISABLE_CHAIN = [] as ChainId[]; //ChainId.Arbitrum, ChainId.Matic, ChainId.EthereumMainnet, ChainId.ZkSyncEra];

export const SHOW_LEGACY_WARNING_CHAIN = [ChainId.ZkSyncEra, ChainId.BSC, ChainId.Arbitrum, ChainId.Matic];

export const AIRDROP_CHAIN = {
    [ChainId.StagingFastActiveBellatrix]: {
        token: TokenSymbol.sFUEL,
        amount: 0.000025,
    },
} as any;

export const SHOW_LEGACY_MODAL_CHAIN = [ChainId.ZkSyncEra];

export const WARNING_INFO = {} as any;
