import { getChain, getTxLink } from "../../../config/chains";
import { ToastLink } from "../../../iZUMi-UI-toolkit/src/components/Toast/Toast";
import { ChainId } from "../../../types/mod";
import { buildSendingParams } from "../../../utils/contractHelpers";
import { loginWithCyber } from "../../../utils/cyberWallet";

export interface SendTransactionParams {
    chainId: ChainId
    account: string
    gasPrice: number
    value?: string
}

export enum TransactionTypes {
    approve = 'approve',
    swap = 'swap',
    addLiquidity = 'addLiquidity',
    removeLiquidity = 'removeLiquidity',
}

const CyberChainList: { [key: string]: string } = {
    "42161": "arbiturm",
    "8453": "base",
    "59144": "linea",
    "59140": "lineaTestnet",
}

export async function sendTransaction(type: TransactionTypes,  calling:any,  params: SendTransactionParams, isAaAccount?: Boolean, targetAddress?: any, onGoingCallback?: Function) {
  if (isAaAccount) {
    const chainName = CyberChainList[params.chainId.toString()];
    const cyber = await loginWithCyber()
    const encodeData = calling.encodeABI()
    const cyberWallet = (cyber?.cyberWallet as any)[chainName]

    return cyberWallet.sendTransaction({
        to: targetAddress,
        value: params.value ? params.value : "0",
        data: encodeData
    });
  } else {
      const toastLink = {} as ToastLink;
      const chain = getChain(params.chainId);
      let option = {}

      if (type == TransactionTypes.swap) {
          option = {from: params.account, value: params.value }
      } else if (type == TransactionTypes.approve) {
          option = { from: params.account, maxFeePerGas: params.gasPrice }
      } else if (type == TransactionTypes.addLiquidity || type == TransactionTypes.removeLiquidity) {
          option = {from: params.account, maxFeePerGas: params.gasPrice, value: params.value}
      }

      return calling.send(buildSendingParams(params.chainId, option, params.gasPrice)).on(
          'transactionHash',
          (hash: string) => {
              if (chain) {
                  toastLink.title = 'View on ' + chain.name;
                  toastLink.link = getTxLink(hash, chain);
              }
              if (typeof onGoingCallback != 'undefined') {
                  onGoingCallback(toastLink);
              }
          }
      );
  }
}