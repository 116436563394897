import { createModel } from "@rematch/core";
import BigNumber from "bignumber.js";
import { RootModel } from "../..";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { ChainId } from "../../../../types/mod";
import { decodeMethodResult } from "../../../../utils/contractHelpers";
import { amount2Decimal } from "../../../../utils/tokenMath";
import { getBaseQuoteOrder } from "../proOrderFormState/funcs";
import { FetchLimitOrderParams, ProLimitOrder, ProOrderList } from "./types";
import { Contract } from 'web3-eth-contract';
import { LimOrderResponse, PoolMetasResponse } from "../../../../types/abis/iZiSwap/LimitOrderWithSwapManager";
import { toFeeNumber } from "../../../../utils/funcs";
import { point2PriceDecimal } from "../../trade/utils/priceMath";
import moment from "moment";


interface LimitOrder {
    orderId: string;
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;

    feeTier: FeeTier;
    poolAddress: string;

    isSellTokenX: boolean;

    priceDecimalXByY: number;

    initSellingAmount: BigNumber;
    sellingRemain: BigNumber;
    earn: BigNumber;

    pending: BigNumber;

    status: string;

    createTime: string;
}


function order2Pro(o: LimitOrder, chainId: ChainId): ProLimitOrder {
    const {baseToken, quoteToken} = getBaseQuoteOrder(o.tokenX, o.tokenY, chainId)
    const pairs = baseToken.symbol + '/' + quoteToken.symbol + '/' + String(o.feeTier) + '%'
    let direction = 'Buy'
    if (baseToken.symbol === o.tokenX.symbol && o.isSellTokenX || baseToken.symbol === o.tokenY.symbol && !o.isSellTokenX) {
        direction = 'Sell'
    }
    const sellToken = o.isSellTokenX ? {...o.tokenX} : {...o.tokenY}
    const acquireToken = o.isSellTokenX ? {...o.tokenY} : {...o.tokenX}
    const priceDecimalBaseByQuote = (baseToken.symbol === o.tokenX.symbol) ? o.priceDecimalXByY : 1 / o.priceDecimalXByY

    let fillPercent = 0
    let baseAmountDecimal = 0
    const pendingAmountDecimal = amount2Decimal(o.pending, acquireToken)

    if (sellToken.symbol === baseToken.symbol) {
        baseAmountDecimal = amount2Decimal(o.initSellingAmount, baseToken) ?? 0
        const sellingRemainDecimal = amount2Decimal(o.sellingRemain, baseToken) ?? 0
        //console.log('base amount decimal: ', baseAmountDecimal)
        //console.log('price decimal X by Y : ', o.priceDecimalXByY)
        //console.log('pending amount decimal: ', pendingAmountDecimal)
        //console.log('selling remain decimal: ', sellingRemainDecimal)
        //console.log('filled amount decimal: ', baseAmountDecimal - sellingRemainDecimal + ((pendingAmountDecimal??0) * o.priceDecimalXByY))
        fillPercent = Math.max(Math.min(((baseAmountDecimal - sellingRemainDecimal + ((pendingAmountDecimal??0) * o.priceDecimalXByY) ) / baseAmountDecimal * 100) ?? 0, 100), 0)
    } else {
        const earnDecimal = amount2Decimal(o.earn, baseToken) ?? 0
        const sellingRemainDecimal = amount2Decimal(o.sellingRemain, quoteToken) ?? 0
        const unoccuredEarnDecimal = sellingRemainDecimal / priceDecimalBaseByQuote
        baseAmountDecimal = earnDecimal + unoccuredEarnDecimal
        fillPercent = Math.min(((earnDecimal + (pendingAmountDecimal??0))  / baseAmountDecimal * 100) ?? 0, 100)
    }



    const status = o.status
    const createTime = o.createTime
    return {
        orderIdx: o.orderId,
        pairs,
        direction,
        baseAmountDecimal,
        priceDecimalBaseByQuote,
        fillPercent,
        pendingAmountDecimal,
        sellToken,
        acquireToken,
        status,
        createTime
    } as ProLimitOrder
}

export const proOrderList = createModel<RootModel>()({
    state: {
        activeList: [] as ProLimitOrder[],
    } as ProOrderList,
    reducers: {
        setOrderList: (state: ProOrderList, payload: {activeList: LimitOrder[], chainId: ChainId}) => {
            const activeList = payload.activeList.map((e:LimitOrder)=>{ return order2Pro(e,payload.chainId)})
            return {activeList} as ProOrderList
        },
    },

    effects: (dispatch) => ({
        async fetchLimitOrder(fetchLimitOrderParams: FetchLimitOrderParams): Promise<LimitOrder[]> {
            const { chainId, web3, orderManagerContract, account, tokenList } = fetchLimitOrderParams;
            if (tokenList.length === 0 ) {
                return [];
            }
            if (!chainId || !web3 || !account || !orderManagerContract) { return []; }
            const startTime = new Date();

            // 1. get active limit order id
            const limitOrderIdMulticallData = [];
            limitOrderIdMulticallData.push(orderManagerContract.methods.getActiveOrders(account).encodeABI());

            const limitOrderIdListResult: string[] = await orderManagerContract.methods.multicall(limitOrderIdMulticallData).call();
            const { activeIdx: activeOrderIds, activeLimitOrder: activeOrders } = decodeMethodResult(orderManagerContract as unknown as Contract, 'getActiveOrders', limitOrderIdListResult[0]);

            const allOrders = [...activeOrders] as LimOrderResponse[];
            if (allOrders.length <= 0) { 
                dispatch.proOrderList.setOrderList({activeList: [], chainId})
                return [] ;
            }

            const orderTotal = allOrders.length;

            // 2. get limit order detail
            const poolMetaMulticallData = allOrders.map(order => orderManagerContract.methods.poolMetas(order.poolId).encodeABI());
            const poolAddrMulticallData = allOrders.map(order => orderManagerContract.methods.poolAddrs(order.poolId).encodeABI());
            const poolResult: string[] = await orderManagerContract.methods.multicall([...poolMetaMulticallData, ...poolAddrMulticallData]).call();
            const poolMetaList = poolResult.slice(0, orderTotal).map(p => decodeMethodResult(orderManagerContract as unknown as Contract, 'poolMetas', p)) as PoolMetasResponse[];
            const poolAddressList = poolResult.slice(orderTotal, orderTotal * 2).map(p => decodeMethodResult(orderManagerContract as unknown as Contract, 'poolAddrs', p)) as string[];

            // 3. fake call get latest pending earn
            const collectOrderMulticallData = activeOrderIds.map((orderId: string) => orderManagerContract.methods.collect(account, orderId.toString()).encodeABI());

            const collectOrderResult: string[] = await orderManagerContract.methods.multicall(
                collectOrderMulticallData
            ).call({ from: account });
            // 4. compose order list
            const limitOrderList = await Promise.all(allOrders.map(async (order, i) => {
                const poolMeta = poolMetaList[i];
                let tokenX = { ...tokenList.find((e) => e.address === poolMeta.tokenX) } as TokenInfoFormatted;
                let tokenY = { ...tokenList.find((e) => e.address === poolMeta.tokenY) } as TokenInfoFormatted;
                if (! tokenX.symbol) {
                    tokenX =  await dispatch.customTokens.fetchAndAddToken({tokenAddr: poolMeta.tokenX, chainId, web3});
                }
                if (! tokenY.symbol) {
                    tokenY = await dispatch.customTokens.fetchAndAddToken({tokenAddr: poolMeta.tokenY, chainId, web3});
                }

                let pending = new BigNumber(collectOrderResult[i]);
                
                const initSellingAmount = new BigNumber(order.initSellingAmount);
                const sellingRemain = new BigNumber(order.sellingRemain)
                const priceDecimalXByY = point2PriceDecimal(tokenX, tokenY, Number(order.pt))
                const earn = new BigNumber(order.earn)

                const limitOrder: LimitOrder = {
                    orderId: activeOrderIds[i].toString(),
                    tokenX: tokenX,
                    tokenY: tokenY,
                    feeTier: toFeeNumber(Number(poolMeta.fee)),
                    isSellTokenX: order.sellXEarnY,
                    priceDecimalXByY,
                    poolAddress: poolAddressList[i],
                    initSellingAmount,
                    sellingRemain,
                    earn,
                    pending,
                    status: 'unknown',
                    createTime: moment(Number(order.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')
                };
                return limitOrder;
            }));

            const activeOrderList = limitOrderList.filter(order=>order.tokenX.symbol && order.tokenY.symbol)

            dispatch.proOrderList.setOrderList({activeList: activeOrderList, chainId})

            console.log(`fetchLimitOrder end, ${(new Date()).getTime() - startTime.getTime()} ms`);
            return activeOrderList
        },
    })
});
