import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/portal/';

export const PORTAL_PER_CHAIN = {
    [ChainId.ZkSyncEra]: [
        {
            titleImgSrc: baseURL + 'orderly.png',
            title: 'Orderly',
            content:
                'Orderly is an omnichain CLOB infrastructure. It’s the ultimate trading lego for seamless integration by any builder on any blockchain.',
            link: 'https://orderly.network',
        },
        {
            titleImgSrc: baseURL + 'gecko.png',
            title: 'Gecko Terminal',
            content: "iZUMi's close partner. A real-time DEX Tracker and the other half-blood of CoinGecko.",
            link: 'https://www.geckoterminal.com/zksync/iziswap-v2-zksync/pools',
        },
    ],
    [ChainId.Linea]: [
        {
            titleImgSrc: baseURL + 'airswap.svg',
            title: 'AirSwap',
            content:
                "iZUMi's close partner. AirSwap is an open-source community that powers the original peer-to-peer DEX - efficient swaps without slippage or counter-party risk.",
            link: 'https://www.airswap.io/#/',
        },
        {
            titleImgSrc: baseURL + 'gecko.png',
            title: 'Gecko Terminal',
            content: "iZUMi's close partner. A real-time DEX Tracker and the other half-blood of CoinGecko.",
            link: 'https://www.geckoterminal.com/linea/iziswap-linea/pools',
        },
    ],
    [ChainId.Mantle]: [
        {
            titleImgSrc: baseURL + 'gecko.png',
            title: 'Gecko Terminal',
            content: "iZUMi's close partner. A real-time DEX Tracker and the other half-blood of CoinGecko.",
            link: 'https://www.geckoterminal.com/mantle/iziswap-mantle/pools',
        },
    ],
    [ChainId.StagingFastActiveBellatrix]: [
        {
            titleImgSrc: baseURL + 'skale.png',
            title: 'SKALE Bridge',
            content:
                'The SKALE Network makes the SKALE Metaport Bridge UI available to help users transfer cryptographic tokens between the Ethereum blockchain and any SKALE blockchain. ',
            link: 'https://testnet.bridge.skale.network',
        },
    ],
} as any;

export const PORTAL = [
    // {
    //     titleImgSrc: baseURL + 'iZi.png',
    //     title: 'Main Site',
    //     content:
    //         "iZUMi.finance's main website, where all the supported public chains (mainnet), such as BSC, Arbitrum, Polygon, and Lp farming activities of iZiSwap are gathered.",
    //     link: 'https://izumi.finance',
    // },
    // {
    //     titleImgSrc: baseURL + 'veiZi.svg',
    //     title: 'veiZi',
    //     content: 'Lock iZi for veiZi to get voting power and staking rewards on Ethereum. Become an owner of iZUMi',
    //     link: 'https://izumi.finance/veiZi/locker',
    // },
    {
        titleImgSrc: baseURL + 'iZi-zksync.svg',
        title: 'iZiSwap@zkSync Era',
        content: 'A website specifically designed for iZiSwap on zkSync Era network. Now the mainnet is alive. ',
        link: 'https://zksync.izumi.finance',
    },
    {
        titleImgSrc: baseURL + 'iZi-scroll.svg',
        title: 'iZiSwap@Scroll',
        content: 'A website specifically designed for iZiSwap on Scroll network. It is currently in the testing phase. ',
        link: 'https://scroll.izumi.finance',
    },
    {
        titleImgSrc: baseURL + 'iZi-mantle.svg',
        title: 'iZiSwap@Mantle',
        content: 'A website specifically designed for iZiSwap on Mantle network. Now the mainnet is alive. ',
        link: 'https://mantle.izumi.finance',
    },
    {
        titleImgSrc: baseURL + 'iZi-linea.svg',
        title: 'iZiSwap@Linea',
        content: 'A website specifically designed for iZiSwap on Linea network. Now the mainnet is alive. ',
        link: 'https://linea.izumi.finance',
    },
    {
        titleImgSrc: baseURL + 'iZi-base.svg',
        title: 'iZiSwap@Base',
        content: 'A website specifically designed for iZiSwap on Base network. Now the mainnet is alive. ',
        link: 'https://base.izumi.finance',
    },
    // {
    //     titleImgSrc: baseURL + 'iZi.png',
    //     title: 'Portfolio',
    //     content: 'A tool provided by iZUMi for monitoring LP situations on different chains for concentrated AMM protocols.',
    //     link: 'https://portfolio.izumi.finance',
    // },
    // {
    //     titleImgSrc: baseURL + 'iZi.png',
    //     title: 'Test Env',
    //     content: "iZUMi's testing environment that allows you to experience it freely without worrying about security risks.",
    //     link: 'https://alpha.izumi.finance',
    // },
];
