import { Box, HStack, Image, Tag, VStack, Icon, IconButton, Menu, MenuButton, useDisclosure, useColorMode } from '@chakra-ui/react';
import JumpButton from '../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import Card from '../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Heading, Text } from '../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { AiOutlineEllipsis, AiOutlineHistory } from 'react-icons/ai';
import { useEffect } from 'react';
import { formatNumber } from '../../utils/tokenMath';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../state/store';
import { useSelector } from 'react-redux';
import truncateWalletAddress from '../../utils/truncateWalletAddress';
import Identicon from '../Identicon';
import NetworkSelect from '../Select/NetworkSelect/NetworkSelect';
import WalletModal from '../Modal/WalletModal/WalletModal';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { getColorThemeSelector } from '../../utils/funcs';
import useInterval from 'ahooks/lib/useInterval';
import { ACCOUNT_CONFIG } from '../../config/bizConfig';
import { AccountTokenParams } from '../../state/models/account/account';
import { useTranslation } from 'react-i18next';
import useCustomTheme from '../../hooks/useCustomTheme';

const AccountCard: React.FC = () => {
    const { chainId, web3, account, chainName } = useWeb3WithDefault();
    const { THEME_CARD, THEME_SELECT } = useCustomTheme();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { account: accountModel } = useSelector((state: RootState) => state);

    useEffect(() => {
        if (account && web3 && chainId) {
            console.log('account changed: ', chainId, account);
            dispatch.account.changeAccount({ address: account, chainId });
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, [account, chainId, web3, dispatch.account]);

    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.refreshTokenBalance({
                account,
                web3,
                chainId,
            } as AccountTokenParams);
        }
    }, ACCOUNT_CONFIG.AUTO_REFRESH_TOKEN_BALANCE_INTERVAL);

    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <VStack
            as={Card}
            w={{ base: '250px', sm: '266px' }}
            p="44px 0 16px"
            position="relative"
            bg={colorTheme('#fff', THEME_SELECT[chainId])}
        >
            <Tag
                color={colorTheme('#20B352', 'white')}
                bg={colorTheme('#EAFEF1', '#314A44')}
                p="4px 32px"
                position="absolute"
                top="0"
                transform="translateY(-50%)"
            >
                <Text variant="caption">{t('Connected')}</Text>
            </Tag>
            <Menu>
                <MenuButton
                    as={IconButton}
                    position="absolute"
                    top="2px"
                    right="12px"
                    aria-label="more"
                    isRound={true}
                    variant="ghost"
                    icon={<Icon as={AiOutlineEllipsis} boxSize={6} />}
                />
                <IconButton
                    position="absolute"
                    top="2px"
                    right="12px"
                    aria-label="more"
                    isRound={true}
                    variant="ghost"
                    onClick={onOpen}
                    icon={<Icon as={AiOutlineEllipsis} boxSize={6} />}
                />
            </Menu>
            <HStack pb="28px!important">
                <Box w="40px" h="40px">
                    <Identicon />
                </Box>
                <VStack>
                    <Heading level="4" color={colorTheme('tertiary.800', 'tertiary.50')}>
                        {t('Address')}
                    </Heading>
                    <Text variant="caption" color="tertiary.300">
                        {truncateWalletAddress(account || '', 8)}
                    </Text>
                </VStack>
            </HStack>

            <HStack pb="7px!important">
                <Image
                    src={process.env.PUBLIC_URL + colorTheme('/assets/wallet/balanceIcon.png', '/assets/wallet/balanceIconDark.png')}
                    w="6px"
                    h="16px"
                />
                <Heading level="3" color={colorTheme('tertiary.800', 'tertiary.50')}>
                    {formatNumber(accountModel.ethBalance ?? 0, 2, 2)}
                </Heading>
            </HStack>
            <Text variant="caption" color="tertiary.300" mb="16px!important">
                ≈{formatNumber(accountModel.ethBalanceUSD ?? 0, 2, 2)} USD
            </Text>
            <HStack>
                <NetworkSelect />
                <JumpButton
                    type="icon"
                    target="/order-history"
                    w="39px"
                    h="39px"
                    icon={AiOutlineHistory}
                    bg={colorTheme('secondary.50', THEME_CARD[chainId])}
                />
            </HStack>
            <WalletModal
                account={account!}
                chainId={chainId!}
                isOpen={isOpen}
                onClose={onClose}
                onCopyAddress={onCopyAddress}
                scanName={chainName}
            />
        </VStack>
    );
};

export default AccountCard;
