import { Box, BoxProps, Divider, Flex, HStack, useColorMode, VStack, Text, ChakraProps } from '@chakra-ui/react';
import { default as React, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import useIsMobile from '../../../hooks/useIsMobile';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { RootState } from '../../../state/store';
import { i_text_d } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import * as styles from './style';
import { LayoutStateEnum } from '../../../state/models/pageLayout/pageLayout';
import useLayoutType from '../../../hooks/useLayoutType';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import useCustomTheme from '../../../hooks/useCustomTheme';

type Value = string | number;

type DividerStyle = {
    w: string;
    top: string;
};

type Props = {
    list: { label: string; value: Value; component: ReactElement }[];
    endList?: { label: string; value: Value; component: ReactElement | undefined }[];
    value: Value;
    handleClick: OnClickFunction;
    variant?: 'simple' | 'none';
    dividerStyle?: DividerStyle;
    notShowDivider?: boolean;
    tabsProps?: ChakraProps;
} & BoxProps;

const Tabs: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { THEME, THEME_CARD, THEME_SELECT } = useCustomTheme();
    const { list, endList, value, handleClick, variant, dividerStyle, notShowDivider, tabsProps, ...rest } = props;
    const { tradeMode } = useSelector((state: RootState) => state);
    const isMobile = useIsMobile();
    const isSimpleMode = tradeMode.isSimpleMode;
    const layoutType = useLayoutType();
    const isHeader = layoutType === LayoutStateEnum.header;
    const { chainId } = useWeb3WithDefault();

    return isMobile ? (
        <VStack borderRadius="4px" alignItems="flex-start" spacing="0" w={isMobile ? '100%' : 'unset'} {...rest}>
            <HStack position={{ base: 'relative', sm: 'inherit' }} h={'66px'} cursor="pointer" w="100%">
                {list.map((item, key) => (
                    <CustomButton
                        key={key}
                        onClick={() => handleClick(item.value)}
                        variant={value === item.value ? 'purple' : 'outlinePurple'}
                        text={item.value as string}
                        borderRadius="3px"
                        h="30px"
                        w="100px"
                        mr="10px !important"
                        ml="0px !important"
                        fontSize="12px"
                    />
                ))}
            </HStack>

            <Box w="100%">{list.find((item) => item.value === value)?.component}</Box>
        </VStack>
    ) : (
        <VStack borderRadius="4px" alignItems="flex-start" spacing="0" {...rest}>
            <HStack
                position={{ base: 'relative', sm: 'inherit' }}
                h={isSimpleMode ? '66px' : '56px'}
                spacing="0"
                cursor="pointer"
                w="100%"
                bg={variant ? 'inherit' : colorTheme('#ffffff', '#140E28')}
                px={isSimpleMode ? { base: '0px', sm: '25px', xxl: '44px' } : '0px'}
                {...tabsProps}
            >
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="0px">
                        {list.map((item, key) => (
                            <Flex
                                className={variant ? i_text_d : styles.tabsBtnStyle}
                                fontSize={{ base: '16px', sm: '14px', xxl: '16px' }}
                                key={key}
                                bg={
                                    variant
                                        ? 'inherit'
                                        : value !== item.value
                                        ? colorTheme('#EBEAF9', THEME_SELECT[chainId])
                                        : colorTheme('#fff', THEME_CARD[chainId])
                                }
                                w={isSimpleMode ? { base: '130px', sm: '95px', xxl: '130px' } : '185px'}
                                textAlign="center"
                                h={isSimpleMode ? '66px' : '56px'}
                                alignItems="center"
                                justify="center"
                                onClick={() => handleClick(item.value)}
                                borderRadius="3px 3px 0 0"
                                direction="column"
                                color={variant ? colorTheme('#52466A', '#DDDAE1') : ''}
                                _hover={
                                    isSimpleMode
                                        ? {
                                              color: THEME[chainId],
                                              fontWeight: '700 !important',
                                              zIndex: 2,
                                          }
                                        : {}
                                }
                                boxShadow={
                                    isSimpleMode
                                        ? ''
                                        : colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')
                                }
                            >
                                {variant ? (
                                    <Text
                                        color={value === item.value ? THEME[chainId] : ''}
                                        fontWeight={value === item.value ? '700' : '500'}
                                    >
                                        {item.label}
                                    </Text>
                                ) : (
                                    <Text
                                        className={i_text_d}
                                        fontSize="14px"
                                        color={colorTheme('tertiary.700', 'tertiary.50')}
                                        fontWeight={value === item.value ? '700 !important' : '500 !important'}
                                    >
                                        {item.label}
                                    </Text>
                                )}

                                {variant && (
                                    <Divider
                                        mt={{ base: '11px', sm: '5px', xxl: '11px' }}
                                        w={{ base: '114px', sm: '76px', xxl: '114px' }}
                                        borderWidth={{ base: '3px', sm: '2px', xxl: '3px' }}
                                        borderColor={value === item.value ? THEME[chainId] : colorTheme('#EBE6F000', '#52466A00')}
                                        zIndex={value === item.value ? 1 : 0}
                                        borderRadius="28px"
                                    ></Divider>
                                )}
                            </Flex>
                        ))}
                    </HStack>

                    {endList && (
                        <HStack pb="12px">
                            {endList &&
                                endList.map(
                                    (item, key) =>
                                        item &&
                                        item.component && (
                                            <Flex
                                                className={variant ? i_text_d : styles.tabsBtnStyle}
                                                key={key}
                                                bg={
                                                    variant
                                                        ? 'inherit'
                                                        : value !== item.value
                                                        ? colorTheme('#EBEAF9', '#24193C')
                                                        : colorTheme('#fff', '#292343')
                                                }
                                                w={isSimpleMode ? 'auto' : '185px'}
                                                textAlign="center"
                                                h={isSimpleMode ? '66px' : '56px'}
                                                alignItems="center"
                                                justify="center"
                                                onClick={() => handleClick(item.value)}
                                                borderRadius="3px 3px 0 0"
                                                direction="column"
                                                color={variant ? colorTheme('#52466A', '#DDDAE1') : ''}
                                                _hover={
                                                    isSimpleMode
                                                        ? {
                                                              color: THEME[chainId],
                                                              fontWeight: '700 !important',
                                                              zIndex: 2,
                                                          }
                                                        : {}
                                                }
                                                boxShadow={
                                                    isSimpleMode
                                                        ? ''
                                                        : colorTheme(
                                                              '0px 0px 27px 5px rgba(218, 206, 230, 0.25)',
                                                              '0px 0px 27px 5px rgba(29, 6, 51, 0.25)'
                                                          )
                                                }
                                            >
                                                {variant ? (
                                                    <Text
                                                        color={value === item.value ? THEME[chainId] : ''}
                                                        fontWeight={value === item.value ? '700' : '500'}
                                                    >
                                                        {item.component}
                                                    </Text>
                                                ) : (
                                                    <Text
                                                        className={i_text_d}
                                                        fontSize="14px"
                                                        color={colorTheme('tertiary.700', 'tertiary.50')}
                                                        fontWeight={value === item.value ? '700 !important' : '500 !important'}
                                                    >
                                                        {item.label}
                                                    </Text>
                                                )}
                                            </Flex>
                                        )
                                )}
                        </HStack>
                    )}
                </HStack>
                {variant && !notShowDivider && (
                    <Divider
                        position="absolute"
                        w={dividerStyle?.w || { base: '793px', sm: '528px', xxl: '793px' }}
                        top={
                            dividerStyle?.top ||
                            (layoutType == LayoutStateEnum.sidebar
                                ? { base: '155px', sm: '124px', xxl: '155px' }
                                : { base: '115px', sm: '84px', xxl: '115px' })
                        }
                        borderWidth="1px"
                    ></Divider>
                )}

                <Flex bg={colorTheme('#fbfbfb', '#140E28')} flexGrow={2} h="100%"></Flex>
            </HStack>

            <Box
                w="100%"
                boxShadow={variant ? 'inherit' : isSimpleMode ? colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined') : ''}
                bg={
                    variant
                        ? 'inherit'
                        : isSimpleMode
                        ? colorTheme(
                              'linear-gradient(180deg, #EAEAF9 15.93%, rgba(246, 245, 253, 0.72) 45.7%)',
                              'linear-gradient(179.48deg, #40325C 3.65%, #2C1E42 42.65%, #221739 92.15%)'
                          )
                        : colorTheme('linear-gradient( #ffffff 15%, #fbfbfb 50%)', '#16100A')
                }
            >
                {list.find((item) => item.value === value)?.component}
            </Box>
        </VStack>
    );
};

export default Tabs;
