import React, { useEffect, useState } from 'react';
import { BoxProps } from '@chakra-ui/react';
import { TokenEntity } from '../../state/models/hooks/useTokenEntity';
import { i_text_copy_bold } from '../../style';
import CustomButton from '../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../config/chains';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import useCustomTheme from '../../hooks/useCustomTheme';

type ApproveTokenButtonProps = {
    tokenEntity: TokenEntity;
    approveAmount?: string;
} & BoxProps;

export const ApproveTokenButton: React.FC<ApproveTokenButtonProps> = (props) => {
    const { tokenEntity, approveAmount, ...rest } = props;
    const toast = useCustomToast();
    const { t } = useTranslation();
    const { chainId, isAaAccount } = useWeb3WithDefault();
    const { THEME } = useCustomTheme();

    const approved = !approveAmount ? tokenEntity.isApproved() : tokenEntity.isEnoughApproved(approveAmount);
    return (
        <CustomButton
            variant="orange"
            hidden={approved}
            fontClass={i_text_copy_bold}
            text={t('Approve') + ' ' + tokenEntity.token?.symbol}
            w="100%"
            h="51px"
            bg={THEME[chainId]}
            _hover={{ opacity: 0.75 }}
            _focus={{ opacity: 0.75 }}
            onClick={() => {
                const chain = getChain(tokenEntity.token.chainId);
                const toastLink = {} as ToastLink;
                tokenEntity
                    .handleApprove(
                        {
                            onGoingCallback(toastLink: any) {
                                toast('info', t('Approve') + ' ' + tokenEntity.token?.symbol, undefined, toastLink);
                            },
                        },
                        isAaAccount
                    )
                    // .on('transactionHash', (hash: string) => {
                    //     if (chain) {
                    //         toastLink.title = 'View on ' + chain.name;
                    //         toastLink.link = getTxLink(hash, chain);
                    //     }
                    //     toast('info', t('Approve') + ' ' + tokenEntity.token?.symbol, undefined, toastLink);
                    // })
                    .then((e: any) => {
                        if (chain) {
                            const transactionHash = e.transactionHash ? e.transactionHash : e;
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(transactionHash, chain);
                        }
                        tokenEntity.handleApproveSuccess();
                        toast('success', t('Approve') + ' ' + tokenEntity.token?.symbol + ' successfully', undefined, toastLink);
                    })
                    .catch((e: any) => {
                        console.info('error   :', e.message);
                    });
            }}
            {...rest}
        />
    );
};

export default ApproveTokenButton;
