import React from 'react';
import { Flex } from '@chakra-ui/react';
import NavButton from '../NavButton/NavButton';
import NavAccordion from '../NavAccordion/NavAccordion';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { config } from '../../../config/sidebar/config';

interface Props {
    setShowNav?: (e: boolean) => void;
    showNav?: boolean;
}

const Navbar: React.FC<Props> = (props) => {
    const location = useLocation();
    const currentPage = location.pathname.substring(1);
    const showNav = props.showNav;
    const setShowNav = props.setShowNav;

    const showArray = config.pages.filter((page) => page.inHeader === true);
    const showMoreArray = config.pages
        .filter((page) => page.inHeader === false)
        .map((page) => ({
            name: page.name,
            address: page.address,
        }));
    const morePage = {
        name: 'More',
        img: 'More.svg',
        children: showMoreArray,
    } as PageConfig;

    return (
        <Flex
            direction="column"
            w="100%"
            cursor="pointer"
            overflowY="auto"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none !important',
                },
            }}
        >
            {showArray.map((page) => {
                const commonAttr = {
                    key: page.name,
                    img: page.img,
                    showNav: showNav,
                };
                return (
                    <NavButton
                        {...commonAttr}
                        key={page.name}
                        page={page}
                        isSelected={currentPage.toLowerCase().includes(page.name.toLowerCase())}
                    />
                );
            })}
            <NavAccordion showNav={showNav} page={morePage} setShowNav={setShowNav} img={'More.svg'} />
        </Flex>
    );
};

export default observer(Navbar);
