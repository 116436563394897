import React from 'react';
import { Box, Link, Image, useColorMode, HStack } from '@chakra-ui/react';
import MediaButton from '../../../iZUMi-UI-toolkit/src/components/Sidebar/MediaButton/MediaButton';
import TelegramIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/TelegramIcon/TelegramIcon';
import TwitterIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/TwitterIcon/TwitterIcon';
import MediumIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/MediumIcon/MediumIcon';
import DiscordIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/DiscordIcon/DiscordIcon';
import { links } from '../../../config/links';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useHover } from '../../../hooks/useHover';

const MediaList: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [DEXScreenerRef, isDEXScreenerHovering] = useHover<HTMLDivElement>();
    const [defillamaRef, isDefillamaHovering] = useHover<HTMLDivElement>();

    return (
        <HStack direction="row" p="0" mb="17px" alignSelf="center">
            <Box>
                <MediaButton Icon={TelegramIcon} link={links.telegram} type="list" />
            </Box>
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={TwitterIcon} link={links.twitter} />
            </Box>
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={MediumIcon} link={links.medium} />
            </Box>
            <Box mr={{ base: '15px', sm: '8px' }}>
                <MediaButton Icon={DiscordIcon} link={links.discord} />
            </Box>

            <Box flex="1" pt="2px" ref={DEXScreenerRef}>
                <Link target="_blank" href={links.DEXScreener} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={
                            isDEXScreenerHovering
                                ? '/assets/mediaList/HoverDEXScreener.svg'
                                : colorTheme('/assets/mediaList/DEXScreener.svg', '/assets/mediaList/DEXScreener.svg')
                        }
                        fallbackSrc={colorTheme('/assets/mediaList/DEXScreener.svg', '/assets/mediaList/DEXScreener.svg')}
                    ></Image>
                </Link>
            </Box>
            <Box flex="1" pt="2px" ref={defillamaRef}>
                <Link target="_blank" href={links.DefiLlamaLink} rel="noreferrer">
                    <Image
                        boxSize="24px"
                        src={
                            isDefillamaHovering
                                ? '/assets/mediaList/Hoverdefillama.svg'
                                : colorTheme('/assets/mediaList/defillama.svg', '/assets/mediaList/defillama.svg')
                        }
                        fallbackSrc={colorTheme('/assets/mediaList/defillama.svg', '/assets/mediaList/defillama.svg')}
                    ></Image>
                </Link>
            </Box>
        </HStack>
    );
};

export default MediaList;
