import React, { useCallback, useEffect, useMemo } from 'react';
import { Accordion, Center, Heading, useColorMode } from '@chakra-ui/react';
import PoolListEntry from './PoolListEntry/PoolListEntry';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useSelector } from 'react-redux';
import {
    InitPoolListDataParams,
    InitPoolListMetaParams,
    InitLiquidityParams,
    RefreshPoolListDataAndPositionParams,
} from '../../../../../state/models/farm/iZiSwap/fixRange/types';
import { FARM_CONFIG } from '../../../../../config/bizConfig';
import { miningPoolConfigList } from '../../../../../config/farm/iZiSwap/fixRange/miningPoolMetaConst';
import { RootDispatch, RootState } from '../../../../../state/store';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import useInterval from 'ahooks/lib/useInterval';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { useLiquidityManagerContract } from '../../../../../utils/contractFactory';
import BigNumber from 'bignumber.js';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { AdaptationMode } from '../../../../../components/layout/PageLayout';
import useCustomTheme from '../../../../../hooks/useCustomTheme';

export interface FarmRefreshHandle {
    refreshPoolListData?(): void;
    refreshPosition?(): void;
    refreshPoolListDataAndPosition?(): void;
    type?: AdaptationMode;
}

const PoolLiquidityList: React.FC = () => {
    const { chainId, web3, account } = useWeb3WithDefault();
    const { THEME_CARD } = useCustomTheme();
    const liquidityManagerContract = useLiquidityManagerContract(chainId, web3);
    const pools = miningPoolConfigList[chainId];

    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { farmFixRangeiZi: farm } = useSelector((state: RootState) => state);
    const isMobile = useIsMobile();

    const farmFiltered = useMemo(() => {
        const control = farm.farmControl;
        let ff = [...farm.poolEntryList];
        if (account && control.stakedOnly) {
            ff = ff.filter((pool) => pool.stakedLiquidityList && pool.stakedLiquidityList.length > 0);
        }

        if (control.type === 'live') {
            ff = ff.filter((pool) => !pool.data.isEnded && !pool.meta.isEnded);
        } else {
            ff = ff.filter((pool) => pool.meta.isEnded || pool.data.isEnded);
        }

        if (control.sortBy === 'APR') {
            ff = ff.sort((a, b) => {
                const av = a.data.apr.length > 1 ? a.data.apr[1] : a.data.apr[0];
                const bv = b.data.apr.length > 1 ? b.data.apr[1] : b.data.apr[0];
                return bv - av;
            });
        } else if (control.sortBy === 'Liquidity') {
            ff = ff.sort((a, b) => {
                return Number(new BigNumber(b.data.totalVLiquidity).minus(a.data.totalVLiquidity));
            });
        }

        if (control.searchKey) {
            ff = ff.filter((pool) => {
                return pool.meta.tokenA.address.includes(control.searchKey) || pool.meta.tokenB.address.includes(control.searchKey);
            });
        }

        return ff;
    }, [farm, account]);

    const refreshPosition = useCallback(
        () =>
            account &&
            dispatch.farmFixRangeiZi
                .initLiquidity({
                    chainId,
                    web3,
                    liquidityManagerContract,
                    account,
                } as InitLiquidityParams)
                .catch((e) => {
                    console.log('initPosition', e);
                }),
        [chainId, account, web3, liquidityManagerContract, dispatch]
    );

    const refreshPoolListData = useCallback(
        () =>
            dispatch.farmFixRangeiZi
                .initPoolListData({
                    chainId,
                    web3,
                    liquidityManagerContract,
                } as InitPoolListDataParams)
                .catch((e) => console.log('initPoolListData', e)),
        [chainId, account, web3, liquidityManagerContract, dispatch]
    );

    const refreshPoolListDataAndPosition = useCallback(
        () =>
            dispatch.farmFixRangeiZi
                .refreshPoolListDataAndPosition({
                    chainId,
                    web3,
                    liquidityManagerContract,
                    account,
                } as RefreshPoolListDataAndPositionParams)
                .catch((e) => console.log('refreshPoolListDataAndPosition', e)),
        [chainId, account, web3, liquidityManagerContract]
    );

    useEffect(() => {
        console.info('trigger initPoolList', chainId);
        dispatch.farmFixRangeiZi
            .initPoolList({
                chainId: chainId,
                web3,
                metaList: pools,
                liquidityManagerContract,
            } as InitPoolListMetaParams & InitPoolListDataParams)
            .catch((e) => console.log('initPoolList', e));
    }, [chainId, web3, liquidityManagerContract]);

    useEffect(() => {
        console.info('initPosition', chainId, account);
        refreshPosition();
    }, [chainId, account]);

    useEffect(() => {
        console.info('trigger cleanPosition', account);
        dispatch.farmFixRangeiZi.cleanPositionIfExist(account).then(() => {
            if (!account) {
                return;
            }
            console.log('account change, trigger refreshFarmData', chainId, account);
            refreshPoolListData();
            refreshPosition();
        });
    }, [account, refreshPoolListData, refreshPosition, chainId]);

    console.log('account: ', account);

    useInterval(() => {
        if (farm.poolEntryList.length === 0) {
            return;
        }
        console.log('trigger refreshFarmData', chainId, account);
        refreshPoolListData();
        refreshPosition();
    }, FARM_CONFIG.AUTO_REFRESH_FARM_DATA_INTERVAL);

    return (
        <Accordion allowMultiple={true} allowToggle={true} mb="60px !important">
            {farmFiltered.length > 0 ? (
                farmFiltered.map((poolEntry, i) => (
                    <PoolListEntry
                        key={i}
                        mb={{ base: '30px', sm: '15px' }}
                        entry={poolEntry}
                        refreshPosition={refreshPosition}
                        refreshPoolListDataAndPosition={refreshPoolListDataAndPosition}
                        type="mobile"
                        bg={THEME_CARD[chainId]}
                    />
                ))
            ) : (
                <Center>
                    <Heading
                        size={isMobile ? 'sm' : 'lg'}
                        w={isMobile ? '75%' : 'unset'}
                        color={colorTheme('tertiary.100', 'tertiary.600')}
                        mt="50px !important"
                        textAlign={isMobile ? 'center' : 'unset'}
                    >
                        {t('No ' + (farm.farmControl.type === 'live' ? 'live' : 'ended') + ' mining pools in current network yet.')}
                    </Heading>
                </Center>
            )}
        </Accordion>
    );
};

export default PoolLiquidityList;
