import { HStack, Stack, VStack, IconButton, Icon, useColorMode, Tooltip, BoxProps } from '@chakra-ui/react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { CustomNumberInput } from '../../../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../../utils/tokenMath';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { RootDispatch, RootState } from '../../../../../state/store';
import { findPoolEntryByPoolKey, getPriceDecimalFromX96, getPriceFromX96 } from '../../../../../state/models/farm/UniswapV3/fixRange/funcs';
import React, { useEffect, useState } from 'react';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { AiOutlineSync, AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import { isNumeric } from '../../../../../utils/valid';
import { usePositionManagerContract } from '../../../../../net/contractCall/positionManager';
import { UniswapPositionManagerContract } from '../../../../../types/abis/UniswapV3/UniswapPositionManager';
import { InitPositionParams, PoolEntryState } from '../../../../../state/models/farm/UniswapV3/fixRange/types';
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../../../../config/miscellaneous/uniswapContracts';
import { getColorThemeSelector, identity, parseJsonRpcError } from '../../../../../utils/funcs';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { i_text_copy_bold, i_text_copy } from '../../../../../style';
import { parallelCollect } from '../../../../../net/contractCall/parallel';
import CurrentPriceBlock from '../../../../components/CurrentPriceBlock';
import { TokenIconSymbol } from '../../../../components/TokenIconSymbol';
import { getFieldValidateInfo, validateMintForm } from '../../../../../state/models/farm/UniswapV3/fixRange/addLiquidity/validationHelper';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import BigNumber from 'bignumber.js';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import ApproveTokenButton from '../../../../components/ApproveTokenButton';
import { TokenIcons } from '../../../../Trade/components/TokenIcons';
import { FeeRate } from '../../../../Trade/components/FeeRate';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../../config/chains';

const IconWithSymbol = TokenIconSymbol;

type AddLiquidityModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    entry: PoolEntryState;
} & BoxProps;

const AddLiquidityModal: React.FC<AddLiquidityModalProps> = (props) => {
    const { isOpen, onClose, entry, ...rest } = props;

    const {
        farmFixRange: { poolEntryList },
        farmFixRangeUniLiquidity: { mintForm },
    } = useSelector((state: RootState) => state);
    const { t } = useTranslation();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const { account, chainId, web3 } = useWeb3WithDefault();
    const positionManagerContract = usePositionManagerContract(chainId, web3);

    const toast = useCustomToast();

    const positionManagerContractAddress = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId];

    const token0Entity = useTokenEntity(mintForm.token0, positionManagerContractAddress);
    const token1Entity = useTokenEntity(mintForm.token1, positionManagerContractAddress);

    const [estimateUSD, setEstimateUSD] = useState(0);

    useEffect(() => {
        if (!mintForm.token0 || !mintForm.token1 || !mintForm.token0.symbol || !mintForm.token1.symbol) {
            return;
        }
        parallelCollect(
            dispatch.token.fetchTokenPriceIfMissing(mintForm.token0),
            dispatch.token.fetchTokenPriceIfMissing(mintForm.token1)
        ).then((priceResult: number[]) => {
            let estimateValue = 0;
            estimateValue += (isNumeric(mintForm.amount0DecimalDesired) ? mintForm.amount0DecimalDesired : 0) * priceResult[0];
            estimateValue += (isNumeric(mintForm.amount1DecimalDesired) ? mintForm.amount1DecimalDesired : 0) * priceResult[1];
            setEstimateUSD(estimateValue);
        });
    }, [mintForm.amount0DecimalDesired, mintForm.amount1DecimalDesired, mintForm.token0, mintForm.token1, dispatch]);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { gasPrice } = useGasPrice();

    const { width } = useWindowDimensions();
    const changeLineWidth = 960;

    if (!mintForm.positionPoolKey || poolEntryList.length === 0) {
        return null;
    }

    const poolEntry = findPoolEntryByPoolKey(poolEntryList, mintForm.positionPoolKey);

    // Trick: price are computed from poolEntry, which is alway in a certain order
    // if the token order in poolEntry is not the same as in mintForm, the price
    // should be toggled.
    // console.log(poolEntry);
    let priceDecimal = parseFloat(
        getPriceDecimalFromX96(poolEntry.data.positionSqrtPriceX96, {
            left: poolEntry.meta.tokenA,
            right: poolEntry.meta.tokenB,
        })
    );

    let price = parseFloat(getPriceFromX96(poolEntry.data.positionSqrtPriceX96));
    //let price = tick2Price(poolEntry.data.currentTick);

    price = mintForm.token0.address === poolEntry.meta.tokenA.address ? price : 1 / price;
    priceDecimal = mintForm.token0.address === poolEntry.meta.tokenA.address ? priceDecimal : 1 / priceDecimal;

    const needToken0 = price < mintForm.tickUpperPrice;
    const needToken1 = price > mintForm.tickLowerPrice;

    const validateResult = validateMintForm(mintForm, [token0Entity.tokenBalance(), token1Entity.tokenBalance()]);

    const isChangeLine = changeLineWidth > width ? true : false;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '73%', lg: '744px' }}
            h="unset"
            title={t('Add liquidity')}
            {...rest}
            overflowX="hidden"
        >
            <VStack w="100%" spacing="10px" alignItems="start">
                <Card variant="deep" w="100%" h={isChangeLine ? 'unset' : '108px'} mt="-10px">
                    <Stack
                        direction={isChangeLine ? 'column' : 'row'}
                        w="100%"
                        spacing="10px"
                        p={isChangeLine ? '14px 20px 14px 25px' : '14px 20px 14px 40px'}
                        justifyContent="space-between"
                    >
                        <HStack>
                            <TokenIcons tokenA={mintForm.token0} tokenB={mintForm.token1} initialToggle={false} />
                            <FeeRate
                                tokenA={mintForm.token0}
                                tokenB={mintForm.token1}
                                feeTier={entry.meta.feeTier as FeeTier}
                                initialToggle={false}
                            />
                        </HStack>
                        <CurrentPriceBlock
                            tokenA={mintForm.token0}
                            tokenB={mintForm.token1}
                            price={priceDecimal}
                            handleToggle={() => dispatch.farmFixRangeUniLiquidity.toggleTokenOrder()}
                            w={isChangeLine ? '100%' : '241px'}
                            mt={isChangeLine ? '40px !important' : 'unset'}
                        />
                    </Stack>
                </Card>
                <Stack direction="row" h="35px" w="150px" alignItems="center">
                    <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                        {t('Volume')}
                    </Text>
                    <Tooltip label={'The tokens will be added into the Uniswap V3 pool to earn fees.'}>
                        <IconButton
                            size="sm"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </Stack>

                <Stack
                    direction={isChangeLine ? 'column' : 'row'}
                    bg={colorTheme('#FDFDFD', '#423854')}
                    pt="16px"
                    w="100%"
                    boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined')}
                    borderRadius="6px"
                    px={isChangeLine ? '20px' : '30px'}
                    pb="16px"
                >
                    <VStack
                        bg={colorTheme('#fff', '#34294A')}
                        boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                        borderRadius="6px"
                        border={colorTheme('undefined', '1px solid #8664CE')}
                        pb={isChangeLine ? '16px' : '10px'}
                        w={isChangeLine ? '100%' : '270px'}
                    >
                        <Stack
                            direction={isChangeLine ? 'column' : 'row'}
                            h={isChangeLine ? 'unset' : '31px'}
                            px="17px"
                            justifyContent="space-between"
                            w="100%"
                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            alignItems="center"
                        >
                            <IconWithSymbol token={mintForm.token0} mt={isChangeLine ? '10px' : 'unset'} />

                            <Text
                                color="tertiary.300"
                                variant="caption"
                                w={isChangeLine ? '100%' : 'unset'}
                                pb={isChangeLine ? '10px' : 'unset'}
                                textAlign={isChangeLine ? 'center' : 'unset'}
                            >
                                {formatNumber(token0Entity.tokenBalance())}
                            </Text>
                        </Stack>
                        <VStack w={isChangeLine ? '100%' : 'unset'}>
                            {needToken0 ? (
                                <>
                                    <CustomNumberInput
                                        inputValue={mintForm.amount0DecimalDesired && Number(mintForm.amount0DecimalDesired).toFixed(4)}
                                        onBlur={(value) => {
                                            isNumeric(value) &&
                                                dispatch.farmFixRangeUniLiquidity.setMintFormAmountDesired({
                                                    isDesired0: true,
                                                    desiredAmountDecimal: value,
                                                    price,
                                                });
                                        }}
                                        errorInfo={getFieldValidateInfo(validateResult, 'amount0Desired')}
                                    />
                                    <HStack w={isChangeLine ? '100%' : 'unset'} px={isChangeLine ? '10px' : 'unset'}>
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="tertiary"
                                            text="Clear"
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeUniLiquidity.setMintFormAmountDesired({
                                                    isDesired0: true,
                                                    desiredAmountDecimal: 0,
                                                    price,
                                                })
                                            }
                                        />
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="primary2"
                                            text="All"
                                            ml={isChangeLine ? '10px' : 'unset'}
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeUniLiquidity.setMintFormAmountDesired({
                                                    isDesired0: true,
                                                    desiredAmountDecimal: token0Entity.tokenBalance(),
                                                    price,
                                                })
                                            }
                                        />
                                    </HStack>
                                </>
                            ) : (
                                <Text color="tertiary.300" variant="caption">
                                    {t('No Need')}
                                </Text>
                            )}
                        </VStack>
                    </VStack>

                    <IconButton
                        size="sm"
                        transform="rotate(90deg)"
                        aria-label=""
                        variant="ghost"
                        alignSelf="center"
                        width="15px"
                        height="15px"
                        icon={<Icon as={MdAdd} boxSize="20px" />}
                    />
                    <VStack
                        bg={colorTheme('#fff', '#34294A')}
                        boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'undefined')}
                        borderRadius="6px"
                        border={colorTheme('undefined', '1px solid #8664CE')}
                        pb="10px"
                        w={isChangeLine ? '100%' : '270px'}
                    >
                        <Stack
                            direction={isChangeLine ? 'column' : 'row'}
                            h={isChangeLine ? 'unset' : '31px'}
                            px="17px"
                            justifyContent="space-between"
                            w="100%"
                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            alignItems="center"
                        >
                            <IconWithSymbol token={mintForm.token1} mt={isChangeLine ? '10px' : 'unset'} />

                            <Text
                                color="tertiary.300"
                                variant="caption"
                                w={isChangeLine ? '100%' : 'unset'}
                                pb={isChangeLine ? '10px' : 'unset'}
                                textAlign={isChangeLine ? 'center' : 'unset'}
                            >
                                {formatNumber(token1Entity.tokenBalance())}
                            </Text>
                        </Stack>
                        <VStack w={isChangeLine ? '100%' : 'unset'}>
                            {needToken1 ? (
                                <>
                                    <CustomNumberInput
                                        inputValue={mintForm.amount1DecimalDesired && Number(mintForm.amount1DecimalDesired).toFixed(4)}
                                        onBlur={(value) => {
                                            isNumeric(value) &&
                                                dispatch.farmFixRangeUniLiquidity.setMintFormAmountDesired({
                                                    isDesired0: false,
                                                    desiredAmountDecimal: value,
                                                    price,
                                                });
                                        }}
                                        errorInfo={getFieldValidateInfo(validateResult, 'amount1Desired')}
                                    />
                                    <HStack w={isChangeLine ? '100%' : 'unset'} px={isChangeLine ? '10px' : 'unset'}>
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="tertiary"
                                            text="Clear"
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeUniLiquidity.setMintFormAmountDesired({
                                                    isDesired0: false,
                                                    desiredAmountDecimal: 0,
                                                    price,
                                                })
                                            }
                                        />
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="primary2"
                                            text="All"
                                            ml={isChangeLine ? '10px' : 'unset'}
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeUniLiquidity.setMintFormAmountDesired({
                                                    isDesired0: false,
                                                    desiredAmountDecimal: token1Entity.tokenBalance(),
                                                    price,
                                                })
                                            }
                                        />
                                    </HStack>
                                </>
                            ) : (
                                <Text color="tertiary.300" variant="caption">
                                    {t('No Need')}
                                </Text>
                            )}
                        </VStack>
                    </VStack>
                </Stack>
                <Text variant="caption" color="tertiary.300" transform="scale(0.83)" alignSelf="center" textAlign="center">
                    {t('Estimate Value')} : &nbsp; &nbsp; ${formatNumber(estimateUSD)} USD
                </Text>
                <Stack direction="row" h="35px" w="150px" alignItems="center">
                    <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                        {t('Price Range')}
                    </Text>
                    <IconButton
                        size="sm"
                        aria-label=""
                        variant="ghost"
                        isRound={true}
                        onClick={() => dispatch.farmFixRangeUniLiquidity.toggleTokenOrder()}
                        icon={<Icon as={AiOutlineSync} />}
                    />
                </Stack>

                <Stack
                    direction={isChangeLine ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    bg={colorTheme('#fff', '#34294A')}
                    boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'undefined')}
                    borderRadius="6px"
                    borderColor="tertiary.100"
                    w="100%"
                    py="16px"
                    px="20px"
                >
                    <VStack w="340px" justifyContent="space-between" spacing="15px" pt="15px !important">
                        <HStack>
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.200')}>
                                1
                            </Text>
                            <IconWithSymbol token={mintForm.token0} />
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.200')}>
                                = &nbsp; ?
                            </Text>

                            <IconWithSymbol token={mintForm.token1} />
                        </HStack>
                        <HStack>
                            <CustomButton
                                w="88px"
                                h="24px"
                                text={t('Default')}
                                variant="tertiary"
                                fontClass={i_text_copy_bold}
                                onClick={() => dispatch.farmFixRangeUniLiquidity.setMintFormDefault(priceDecimal)}
                            />
                        </HStack>
                    </VStack>

                    <VStack w="200px" alignItems="center">
                        <Card variant="deep">
                            <CustomNumberInput
                                onDec={() =>
                                    dispatch.farmFixRangeUniLiquidity.addMintFormTick({
                                        stepPositive: false,
                                        isUpper: false,
                                        price,
                                    })
                                }
                                onInc={() =>
                                    dispatch.farmFixRangeUniLiquidity.addMintFormTick({
                                        stepPositive: true,
                                        isUpper: false,
                                        price,
                                    })
                                }
                                inputValue={mintForm.tickLowerPriceDecimal?.toPrecision(8)}
                                onBlur={(value) => {
                                    isNumeric(value) &&
                                        dispatch.farmFixRangeUniLiquidity.setMintFormTickPrice({
                                            tickPriceDecimal: value,
                                            tickPrice: 0,
                                            isUpper: false,
                                            price,
                                        });
                                }}
                                errorInfo={getFieldValidateInfo(validateResult, 'tickLower')}
                            />
                        </Card>
                        <Text color={colorTheme('tertiary.600', 'tertiary.300')} variant="caption-bold">
                            {t('Min Price')}
                        </Text>
                    </VStack>

                    <VStack w="200px" alignItems="center">
                        <Card variant="deep">
                            <CustomNumberInput
                                onDec={() =>
                                    dispatch.farmFixRangeUniLiquidity.addMintFormTick({
                                        stepPositive: false,
                                        isUpper: true,
                                        price,
                                    })
                                }
                                onInc={() =>
                                    dispatch.farmFixRangeUniLiquidity.addMintFormTick({
                                        stepPositive: true,
                                        isUpper: true,
                                        price,
                                    })
                                }
                                inputValue={mintForm.tickUpperPriceDecimal?.toPrecision(8)}
                                onBlur={(value) => {
                                    isNumeric(value) &&
                                        dispatch.farmFixRangeUniLiquidity.setMintFormTickPrice({
                                            tickPriceDecimal: value,
                                            tickPrice: 0,
                                            isUpper: true,
                                            price,
                                        });
                                }}
                                errorInfo={getFieldValidateInfo(validateResult, 'tickUpper')}
                            />
                        </Card>
                        <Text color={colorTheme('tertiary.600', 'tertiary.300')} variant="caption-bold">
                            {t('Max Price')}
                        </Text>
                    </VStack>
                </Stack>

                <HStack w="100%">
                    <CustomButton
                        hidden={![token0Entity.isApproved(), token1Entity.isApproved()].every(identity)}
                        disabled={validateResult !== undefined}
                        variant="purple"
                        text={t('Add Liquidity')}
                        className={i_text_copy}
                        fontSize="14px"
                        w="300px"
                        h="51px"
                        mt="30px !important"
                        mx="auto"
                        onClick={() =>
                            dispatch.farmFixRangeUniLiquidity
                                .estimateMintGasLimit({
                                    account: account!,
                                    mintForm,
                                    positionManagerContract: positionManagerContract as unknown as UniswapPositionManagerContract,
                                    chainId,
                                    gasPrice,
                                })
                                .then((gas: number) => {
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                                    dispatch.farmFixRangeUniLiquidity
                                        .mintLiquidityFromUniswap({
                                            account: account!,
                                            mintForm,
                                            positionManagerContract: positionManagerContract as unknown as UniswapPositionManagerContract,
                                            chainId,
                                            gas: gasLimit,
                                            gasPrice,
                                        })
                                        .on('transactionHash', (e: any) => {
                                            if (chain) {
                                                toastLink.title = 'View on ' + chain.name;
                                                toastLink.link = getTxLink(e, chain);
                                            }
                                            toast('info', 'Mint Uniswap NFT: ' + e, undefined, toastLink);
                                        })
                                        .then((e: any) => {
                                            console.log(e);
                                            toast('success', 'Mint Uniswap NFT successfully', undefined, toastLink);
                                            // refresh if success
                                            dispatch.farmFixRange.initPosition({
                                                chainId: chainId,
                                                web3,
                                                positionManagerContract,
                                                account,
                                            } as InitPositionParams);
                                        })
                                        .catch((e: any) => {
                                            toast('error', e.message);
                                        });
                                })
                                .catch((e: any) => {
                                    toast('error', parseJsonRpcError(e));
                                })
                        }
                    />

                    <ApproveTokenButton tokenEntity={token0Entity} mt="10px !important" w="200px" mx="auto !important" />

                    <ApproveTokenButton tokenEntity={token1Entity} mt="10px !important" w="200px" mx="auto !important" />
                </HStack>
            </VStack>
        </Modal>
    );
};

export default AddLiquidityModal;
