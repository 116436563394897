import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol } from '../types/mod';
import { getChain } from './chains';

export interface TokenInfo {
    chains: number[];
    name: TokenSymbol;
    icon: string;
    contracts: Partial<Record<ChainId, { address: string; decimal: number; wrapTokenAddress?: string }>>;
}

type Tokens = Record<TokenSymbol, TokenInfo>;

const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/';

const tokens: Tokens = {
    [TokenSymbol.USDC]: {
        chains: [],
        name: TokenSymbol.USDC,
        icon: baseURL + 'usdc.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
                decimal: 6,
            },
            [ChainId.Optimism]: {
                address: '0x7f5c764cbc14f9669b88837ca1490cca17c31607',
                decimal: 6,
            },
            [ChainId.BSC]: {
                address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
                decimal: 18,
            },
            [ChainId.Aurora]: {
                address: '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
                decimal: 6,
            },
            [ChainId.Linea]: {
                address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
                decimal: 6,
            },
            [ChainId.BSCTestnet]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 6,
            },
            [ChainId.OpBNBTestnet]: {
                address: '0xb0453a1bAf6056393897e60dfd851C61A825ef67',
                decimal: 18,
            },
            [ChainId.Matic]: {
                address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
                decimal: 6,
            },
            [ChainId.Arbitrum]: {
                address: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
                decimal: 6,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
                decimal: 6,
            },
            [ChainId.LineaTest]: {
                address: '0xf56dc6695cf1f5c364edebc7dc7077ac9b586068',
                decimal: 6,
            },
            [ChainId.OpsideTestRollux]: {
                address: '0x6a7436775c0d0B70cfF4c5365404ec37c9d9aF4b',
                decimal: 18,
            },
            [ChainId.Mantle]: {
                address: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
                decimal: 6,
            },
            [ChainId.Manta]: {
                address: '0xb73603C5d87fA094B7314C74ACE2e64D165016fb',
                decimal: 6,
            },
            [ChainId.GasZeroGoerliL2]: {
                address: '0xb0453a1bAf6056393897e60dfd851C61A825ef67',
                decimal: 18,
            },
            [ChainId.Scroll]: {
                address: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
                decimal: 6,
            },
        },
    },

    [TokenSymbol.ETH]: {
        chains: [],
        name: TokenSymbol.ETH,
        icon: baseURL + 'eth.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
                decimal: 18,
            }, // WETH on Ethereum
            [ChainId.Optimism]: {
                address: '0x4200000000000000000000000000000000000006',
                decimal: 18,
            }, // WETH on Optimism
            [ChainId.BSCTestnet]: {
                address: '0xc9DC6ed52Bf07E4048185d7B66ED7e7c5084C8F2',
                decimal: 18,
            }, // Custom WETH on BSCTestNet
            [ChainId.Arbitrum]: {
                address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                decimal: 18,
            }, // WETH on Arbitrum
            [ChainId.Goerli]: {
                address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
                decimal: 18,
            },
            // WETH on Aurora
            [ChainId.Aurora]: {
                address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
                decimal: 18,
            },
            [ChainId.ScrollTestL2]: {
                address: '0xfa6a407c4C49Ea1D46569c1A4Bcf71C3437bE54c',
                decimal: 18,
            },
            [ChainId.Scroll]: {
                address: '0x5300000000000000000000000000000000000004',
                decimal: 18,
            },
            [ChainId.LineaTest]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
            [ChainId.Linea]: {
                address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
                decimal: 18,
            },
            [ChainId.Mantle]: {
                address: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
                decimal: 18,
            }, //WETH on Mantle
            [ChainId.Base]: {
                address: '0x4200000000000000000000000000000000000006',
                decimal: 18,
            },
            [ChainId.MantaTest]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            }, //WETH on MantaTest
            [ChainId.KromaSepoliaTest]: {
                address: '0x4200000000000000000000000000000000000001',
                decimal: 18,
            },
            [ChainId.Kroma]: {
                address: '0x4200000000000000000000000000000000000001',
                decimal: 18,
            },
            [ChainId.Manta]: {
                address: '0x0Dc808adcE2099A9F62AA87D9670745AbA741746',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WETH]: {
        chains: [],
        name: TokenSymbol.WETH,
        icon: baseURL + 'WETH.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0xc9DC6ed52Bf07E4048185d7B66ED7e7c5084C8F2',
                decimal: 18,
            },
            [ChainId.Goerli]: {
                address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
                decimal: 18,
            },
            [ChainId.Aurora]: {
                address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
                decimal: 18,
            },
            [ChainId.Mantle]: {
                address: '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
                decimal: 18,
            },
            [ChainId.LineaTest]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
            [ChainId.Linea]: {
                address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
                decimal: 18,
            },
            [ChainId.Base]: {
                address: '0x4200000000000000000000000000000000000006',
                decimal: 18,
            },
            [ChainId.ScrollTestL2]: {
                address: '0xfa6a407c4C49Ea1D46569c1A4Bcf71C3437bE54c',
                decimal: 18,
            },
            [ChainId.Scroll]: {
                address: '0x5300000000000000000000000000000000000004',
                decimal: 18,
            },
            [ChainId.Optimism]: {
                address: '0x4200000000000000000000000000000000000006',
                decimal: 18,
            },
            [ChainId.KromaSepoliaTest]: {
                address: '0x4200000000000000000000000000000000000001',
                decimal: 18,
            },
            [ChainId.Kroma]: {
                address: '0x4200000000000000000000000000000000000001',
                decimal: 18,
            },
            [ChainId.Manta]: {
                address: '0x0Dc808adcE2099A9F62AA87D9670745AbA741746',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BNB]: {
        chains: [],
        name: TokenSymbol.BNB,
        icon: baseURL + 'bnb.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                decimal: 18,
            }, // WBNB on BSC
            [ChainId.BSCTestnet]: {
                address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
                decimal: 18,
            }, // WBNB on BSCTestNet
            [ChainId.OpBNBTestnet]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            }, // WBNB on op bnb testnet
            [ChainId.OpBNB]: {
                address: '0x4200000000000000000000000000000000000006',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.CRO]: {
        chains: [],
        name: TokenSymbol.CRO,
        icon: baseURL + 'cro.png',
        contracts: {
            [ChainId.Cronos]: {
                address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
                decimal: 18,
            }, // WCRO on Cronos
        },
    },
    [TokenSymbol.BNBX]: {
        chains: [],
        name: TokenSymbol.BNBX,
        icon: baseURL + 'bnbx.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.SD]: {
        chains: [],
        name: TokenSymbol.SD,
        icon: baseURL + 'sd.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WBNB]: {
        chains: [],
        name: TokenSymbol.WBNB,
        icon: baseURL + 'bnb.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
                decimal: 18,
            }, // WBNB on BSC
            [ChainId.BSCTestnet]: {
                address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
                decimal: 18,
            }, // WBNB on BSCTestNet
            [ChainId.OpBNBTestnet]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            }, // WBNB on op bnb testnet
        },
    },

    [TokenSymbol.WMATIC]: {
        chains: [],
        name: TokenSymbol.WMATIC,
        icon: baseURL + 'matic.png',
        contracts: {},
    },

    [TokenSymbol.ETC]: {
        chains: [ChainId.ETC],
        name: TokenSymbol.ETC,
        icon: baseURL + 'etc.png',
        contracts: {
            [ChainId.ETC]: {
                address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
                decimal: 18,
            }, // WETC
        },
    },

    [TokenSymbol.WETC]: {
        chains: [],
        name: TokenSymbol.WETC,
        icon: baseURL + 'etc.png',
        contracts: {
            [ChainId.ETC]: {
                address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.USDBC]: {
        chains: [],
        name: TokenSymbol.USDBC,
        icon: baseURL + 'usdbc.png',
        contracts: {
            [ChainId.Base]: {
                address: '0xd9aaec86b65d86f6a7b5b1b0c42ffa531710b6ca',
                decimal: 6,
            },
        },
    },

    [TokenSymbol.USDT]: {
        chains: [],
        name: TokenSymbol.USDT,
        icon: baseURL + 'usdt.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
                decimal: 6,
            },
            [ChainId.Linea]: {
                address: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
                decimal: 6,
            },
            [ChainId.Arbitrum]: {
                address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
                decimal: 6,
            },
            [ChainId.Matic]: {
                address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
                decimal: 6,
            },
            [ChainId.BSCTestnet]: {
                address: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
                decimal: 6,
            },
            [ChainId.OpBNBTestnet]: {
                address: '0x6a7436775c0d0B70cfF4c5365404ec37c9d9aF4b',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x55d398326f99059fF775485246999027B3197955',
                decimal: 18,
            },
            [ChainId.MantleTest]: {
                address: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
                decimal: 18,
            },
            [ChainId.Mantle]: {
                address: '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
                decimal: 6,
            },
            // [ChainId.Ontology]: {
            //     address: '0x72b0f5612802d473a13716db71a0348bcf631d98',
            //     decimal: 6,
            // },
            [ChainId.OpsideTestRollux]: {
                address: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
                decimal: 18,
            },
            [ChainId.Manta]: {
                address: '0xf417F5A458eC102B90352F697D6e2Ac3A3d2851f',
                decimal: 6,
            },
            [ChainId.GasZeroGoerliL2]: {
                address: '0x2A2E08ea99E63482BDad8Bf69c7b69752F07736F',
                decimal: 18,
            },
            [ChainId.Scroll]: {
                address: '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df',
                decimal: 6,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
                decimal: 6,
            },
            [ChainId.X1Test]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BUSD]: {
        chains: [],
        name: TokenSymbol.BUSD,
        icon: baseURL + 'busd.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0xd88972676f5D0997c8150A3d2C4634CbaaDD3396',
                decimal: 18,
            },
            [ChainId.AuroraTestnet]: {
                address: '0x2A2E08ea99E63482BDad8Bf69c7b69752F07736F',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.IUSD]: {
        chains: [],
        name: TokenSymbol.IUSD,
        icon: baseURL + 'iusd.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.Matic]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0x60FE1bE62fa2082b0897eA87DF8D2CfD45185D30',
                decimal: 18,
            },
            [ChainId.Linea]: {
                address: '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
                decimal: 18,
            },
            [ChainId.Base]: {
                address: '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.APEX]: {
        chains: [],
        name: TokenSymbol.APEX,
        icon: baseURL + 'apex.svg',
        contracts: {
            [ChainId.Arbitrum]: {
                address: '0x61A1ff55C5216b636a294A07D77C6F4Df10d3B56',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ESAPEX]: {
        chains: [],
        name: TokenSymbol.ESAPEX,
        icon: baseURL + 'esAPEX.svg',
        contracts: {
            [ChainId.Arbitrum]: {
                address: '0xB86851c7F7C1b904A034F07Dd819382f8afD7893',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.DAI]: {
        chains: [],
        name: TokenSymbol.DAI,
        icon: baseURL + 'dai.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x6b175474e89094c44da98b954eedeac495271d0f',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BIT]: {
        chains: [],
        name: TokenSymbol.BIT,
        icon: baseURL + 'bit.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MNT]: {
        chains: [],
        name: TokenSymbol.MNT,
        icon: baseURL + 'mnt.png',
        contracts: {
            [ChainId.Mantle]: {
                address: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
                decimal: 18,
            },
            [ChainId.MantleTest]: {
                address: '0x6e1723460D190B4A092a2c13BA42BcC57d71870b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WMNT]: {
        chains: [],
        name: TokenSymbol.WMNT,
        icon: baseURL + 'mnt.png',
        contracts: {
            [ChainId.Mantle]: {
                address: '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8',
                decimal: 18,
            },
            [ChainId.MantleTest]: {
                address: '0x6e1723460D190B4A092a2c13BA42BcC57d71870b',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.LEND]: {
        chains: [],
        name: TokenSymbol.LEND,
        icon: baseURL + 'lend.png',
        contracts: {
            [ChainId.Mantle]: {
                address: '0x25356aeca4210ef7553140edb9b8026089e49396',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.LING]: {
        chains: [],
        name: TokenSymbol.LING,
        icon: baseURL + 'ling.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x68c0E798b749183c97239114BB76fd1e5e3DB98C',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.IZI]: {
        chains: [],
        name: TokenSymbol.IZI,
        icon: baseURL + 'izumi.svg',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0x9ad37205d608B8b219e6a2573f922094CEc5c200',
                decimal: 18,
            },
            [ChainId.Matic]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.BSCTestnet]: {
                address: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
                decimal: 18,
            },
            [ChainId.OpBNBTestnet]: {
                address: '0x2A2E08ea99E63482BDad8Bf69c7b69752F07736F',
                decimal: 18,
            },
            [ChainId.Goerli]: {
                address: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08',
                decimal: 18,
            },
            [ChainId.ETC]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.MantleTest]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 18,
            },
            [ChainId.Mantle]: {
                address: '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
                decimal: 18,
            },
            [ChainId.ZkSyncAlphaTest]: {
                address: '0xA5900cce51c45Ab9730039943B3863C822342034',
                decimal: 18,
            },
            [ChainId.ZkSyncEra]: {
                address: '0x16a9494e257703797d747540f01683952547ee5b',
                decimal: 18,
            },
            [ChainId.LineaTest]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 18,
            },
            [ChainId.OpsideTestRollux]: {
                address: '0x876508837C162aCedcc5dd7721015E83cbb4e339',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.DEVT]: {
        chains: [],
        name: TokenSymbol.DEVT,
        icon: baseURL + 'devt.png',
        contracts: {
            [ChainId.EthereumMainnet]: {
                address: '0xB5c578947de0fd71303F71F2C3d41767438bD0de',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.DDAO]: {
        chains: [],
        name: TokenSymbol.DDAO,
        icon: baseURL + 'ddao.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x90f3edc7d5298918f7bb51694134b07356f7d0c7',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ACY]: {
        chains: [],
        name: TokenSymbol.ACY,
        icon: baseURL + 'acy.svg',
        contracts: {
            [ChainId.Matic]: {
                address: '0x8b1f836491903743fE51ACd13f2CC8Ab95b270f6',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.YIN]: {
        chains: [],
        name: TokenSymbol.YIN,
        icon: baseURL + 'yin.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
                decimal: 18,
            },
            [ChainId.EthereumMainnet]: {
                address: '0x794baab6b878467f93ef17e2f2851ce04e3e34c8',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MATIC]: {
        chains: [],
        name: TokenSymbol.MATIC,
        icon: baseURL + 'matic.png',
        contracts: {
            [ChainId.Matic]: {
                address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.SLD]: {
        chains: [],
        name: TokenSymbol.SLD,
        icon: baseURL + 'sld.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x1ef6A7e2c966fb7C5403EFEFdE38338b1a95a084',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.KSW]: {
        chains: [],
        name: TokenSymbol.KSW,
        icon: baseURL + 'KSW.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.REVO]: {
        chains: [],
        name: TokenSymbol.REVO,
        icon: baseURL + 'REVO.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xfC279e6ff1FB1C7F5848d31561cAb27d34a2856b',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.LAND]: {
        chains: [],
        name: TokenSymbol.LAND,
        icon: baseURL + 'LAND.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x9A981FAcCD3B9b3B9453BFF266296fc0f2De21a2',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.FROYO]: {
        chains: [],
        name: TokenSymbol.FROYO,
        icon: baseURL + 'froyo.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.NAS]: {
        chains: [],
        name: TokenSymbol.NAS,
        icon: baseURL + 'nas.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x2E1F3D4cB35980F6339C006e64AC3a555dB6676d',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MNW]: {
        chains: [],
        name: TokenSymbol.MNW,
        icon: baseURL + 'MNW.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x8e596702b7e84907836776fddc56067b64147c8f',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.TAP]: {
        chains: [],
        name: TokenSymbol.TAP,
        icon: baseURL + 'tap.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x35bedbf9291b22218a0da863170dcc9329ef2563',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MELT]: {
        chains: [],
        name: TokenSymbol.MELT,
        icon: baseURL + 'melt.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x7eb35225995b097c84eF10501dD6E93A49bdFd63',
                decimal: 8,
            },
        },
    },

    [TokenSymbol.DMT]: {
        chains: [],
        name: TokenSymbol.DMT,
        icon: baseURL + 'DMT.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x09CdC7E87ABd416246F1015B08B4463Abdda00B4',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BURGER]: {
        chains: [],
        name: TokenSymbol.BURGER,
        icon: baseURL + 'burger.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.GE]: {
        chains: [],
        name: TokenSymbol.GE,
        icon: baseURL + 'ge.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x708F90170722f8F8775bf64d7f435A2BADBd89FD',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.RIV]: {
        chains: [],
        name: TokenSymbol.RIV,
        icon: baseURL + 'RIV.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xCa5e32d44F1744001b5600Dc2F5F5e0BbB6E9D3e',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.COCA]: {
        chains: [],
        name: TokenSymbol.COCA,
        icon: baseURL + 'coca.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x44516Eb3D488175128D257748610426a866937D8',
                decimal: 9,
            },
        },
    },

    [TokenSymbol.FEVR]: {
        chains: [],
        name: TokenSymbol.FEVR,
        icon: baseURL + 'fevr.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.aBNBc]: {
        chains: [],
        name: TokenSymbol.aBNBc,
        icon: baseURL + 'abnbc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ankrBNB]: {
        chains: [],
        name: TokenSymbol.ankrBNB,
        icon: baseURL + 'abnbc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x52f24a5e03aee338da5fd9df68d2b6fae1178827',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ANKR]: {
        chains: [],
        name: TokenSymbol.ANKR,
        icon: baseURL + 'ankr.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.GRI]: {
        chains: [],
        name: TokenSymbol.GRI,
        icon: baseURL + 'gri.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xD767713e787Cb351e4daFb777DD93d54f5381D29',
                decimal: 8,
            },
        },
    },

    [TokenSymbol.ZBC]: {
        chains: [],
        name: TokenSymbol.ZBC,
        icon: baseURL + 'zbc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x37a56cdcD83Dce2868f721De58cB3830C44C6303',
                decimal: 9,
            },
        },
    },

    [TokenSymbol.ERA]: {
        chains: [],
        name: TokenSymbol.ERA,
        icon: baseURL + 'era.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.GOT]: {
        chains: [],
        name: TokenSymbol.GOT,
        icon: baseURL + 'got.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xDA71E3eC0d579FED5dbABa31eEaD3cEB9e77A928',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WOO]: {
        name: TokenSymbol.WOO,
        icon: baseURL + 'woo.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.HYT]: {
        name: TokenSymbol.HYT,
        icon: baseURL + 'HYT.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0xE28C51f4fa57dC44af3691c6f73D1ca578b586Be',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MIT]: {
        name: TokenSymbol.MIT,
        icon: baseURL + 'mit.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0xe6906717f129427eebade5406de68cadd57aa0c0',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.slUSDT]: {
        name: TokenSymbol.slUSDT,
        icon: baseURL + 'slusdt.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0x65cd2e7d7bacdac3aa9dae68fb5d548dfe1fefb5',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x65cd2e7d7bacdac3aa9dae68fb5d548dfe1fefb5',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.slUSDC]: {
        name: TokenSymbol.slUSDC,
        icon: baseURL + 'slusdc.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0x55a26773a8ba7fa9a8242b43e14a69488e6d2b05',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x55a26773a8ba7fa9a8242b43e14a69488e6d2b05',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.slBTC]: {
        name: TokenSymbol.slBTC,
        icon: baseURL + 'slbtc.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0xe04b30f233e3bc96da2401ee146a94f1c7c54917',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0xe04b30f233e3bc96da2401ee146a94f1c7c54917',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.slETH]: {
        name: TokenSymbol.slETH,
        icon: baseURL + 'sleth.png',
        chains: [],
        contracts: {
            [ChainId.BSC]: {
                address: '0xdf6964c7ccfabaace7c3f918b795fa4a894589c8',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0xdf6964c7ccfabaace7c3f918b795fa4a894589c8',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WONT]: {
        chains: [],
        name: TokenSymbol.WONT,
        icon: baseURL + 'wont.png',
        contracts: {
            [ChainId.Ontology]: {
                address: '0x219cc8e994ea6b35cdcffb5d44e229325d5ad02a',
                decimal: 9,
            },
        },
    },

    [TokenSymbol.ONG]: {
        chains: [],
        name: TokenSymbol.ONG,
        icon: baseURL + 'ong.png',
        contracts: {
            [ChainId.OntologyTestnet]: {
                address: '0xe8cf015f797877a9a23e80447429c0b0f04e114b',
                decimal: 18,
            },
            [ChainId.Ontology]: {
                address: '0xd8bc24cfd45452ef2c8bc7618e32330b61f2691b',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WONG]: {
        chains: [],
        name: TokenSymbol.WONG,
        icon: baseURL + 'ong.png',
        contracts: {
            [ChainId.OntologyTestnet]: {
                address: '0xe8cf015f797877a9a23e80447429c0b0f04e114b',
                decimal: 18,
            },
            [ChainId.Ontology]: {
                address: '0xd8bc24cfd45452ef2c8bc7618e32330b61f2691b',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ICT]: {
        chains: [],
        name: TokenSymbol.ICT,
        icon: baseURL + 'ict.png',
        contracts: {
            [ChainId.Icplaza]: {
                address: '0xc59d478873d11CCc68F9c63571E821a253c5B605', //WICT
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WICT]: {
        chains: [],
        name: TokenSymbol.WICT,
        icon: baseURL + 'ict.png',
        contracts: {
            [ChainId.Icplaza]: {
                address: '0xc59d478873d11CCc68F9c63571E821a253c5B605',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.CFX]: {
        chains: [],
        name: TokenSymbol.CFX,
        icon: baseURL + 'cfx.png',
        contracts: {
            [ChainId.ConfluxESpace]: {
                address: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WCFX]: {
        chains: [],
        name: TokenSymbol.WCFX,
        icon: baseURL + 'cfx.png',
        contracts: {
            [ChainId.ConfluxESpace]: {
                address: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MTR]: {
        chains: [],
        name: TokenSymbol.MTR,
        icon: baseURL + 'mtr.png',
        contracts: {
            [ChainId.Meter]: {
                address: '0x160361ce13ec33C993b5cCA8f62B6864943eb083', //WMTR
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WMTR]: {
        chains: [],
        name: TokenSymbol.WMTR,
        icon: baseURL + 'mtr.png',
        contracts: {
            [ChainId.Meter]: {
                address: '0x160361ce13ec33C993b5cCA8f62B6864943eb083',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MTRG]: {
        chains: [],
        name: TokenSymbol.MTRG,
        icon: baseURL + 'mtrg.png',
        contracts: {
            [ChainId.Meter]: {
                address: '0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.TLOS]: {
        chains: [],
        name: TokenSymbol.TLOS,
        icon: baseURL + 'tlos.png',
        contracts: {
            [ChainId.Telos]: {
                address: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
                decimal: 18,
            },
            [ChainId.BSC]: {
                address: '0xb6C53431608E626AC81a9776ac3e999c5556717c',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WTLOS]: {
        chains: [],
        name: TokenSymbol.WTLOS,
        icon: baseURL + 'tlos.png',
        contracts: {
            [ChainId.Telos]: {
                address: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.HALO]: {
        chains: [],
        name: TokenSymbol.HALO,
        icon: baseURL + 'halo.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xb6b8ccd230bb4235c7b87986274e7ab550b72261',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ULX]: {
        chains: [],
        name: TokenSymbol.ULX,
        icon: baseURL + 'ulx.png',
        contracts: {
            [ChainId.Ultron]: {
                address: '0xb1183357745D3fD7d291E42a2c4B478cdB5710c6',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WULX]: {
        chains: [],
        name: TokenSymbol.WULX,
        icon: baseURL + 'halo.png',
        contracts: {
            [ChainId.Ultron]: {
                address: '0xb1183357745D3fD7d291E42a2c4B478cdB5710c6',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.DEXTF]: {
        chains: [],
        name: TokenSymbol.DEXTF,
        icon: baseURL + 'dextf.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x9929bCAC4417A21d7e6FC86F6Dae1Cc7f27A2e41',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ZKSP]: {
        chains: [],
        name: TokenSymbol.ZKSP,
        icon: baseURL + 'zksp.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x7eCf006f7c45149B457e9116392279CC8A630F14',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.RF]: {
        chains: [],
        name: TokenSymbol.RF,
        icon: baseURL + 'rf.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x5f7cbcb391d33988dad74d6fd683aadda1123e4d',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.OT]: {
        chains: [],
        name: TokenSymbol.OT,
        icon: baseURL + 'ot.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xd0ea21ba66b67be636de1ec4bd9696eb8c61e9aa',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.SPACE]: {
        chains: [],
        name: TokenSymbol.SPACE,
        icon: baseURL + 'space.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x47260090cE5e83454d5f05A0AbbB2C953835f777',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.XSPACE]: {
        chains: [],
        name: TokenSymbol.XSPACE,
        icon: baseURL + 'space.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x6aF43486Cb84bE0e3EDdCef93d3C43Ef0C5F63b1',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.CCC]: {
        chains: [],
        name: TokenSymbol.CCC,
        icon: baseURL + 'ccc.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0x383268691eE31c68489B19b8F2D7003ec504D534',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BLADE]: {
        chains: [],
        name: TokenSymbol.BLADE,
        icon: baseURL + 'blade.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x591CAdFaF2F5dBBD09580C8b74A4770A9255bF28',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MTG]: {
        chains: [],
        name: TokenSymbol.MTG,
        icon: baseURL + 'mtg.png',
        contracts: {
            [ChainId.Arbitrum]: {
                address: '0x748b5be12ac1ce2ef73035189f943591c1822b7d',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.LSD]: {
        chains: [],
        name: TokenSymbol.LSD,
        icon: baseURL + 'lsd.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x458A2E32eAbc7626187E6b75f29D7030a5202bD4',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.GGG]: {
        chains: [],
        name: TokenSymbol.GGG,
        icon: baseURL + 'ggg.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x7E2A6456Bb3eCEcbdb38bC76Ad8dF9e448B15835',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.KANA]: {
        chains: [],
        name: TokenSymbol.KANA,
        icon: baseURL + 'kana.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0x26aC1D9945f65392B8E4E6b895969b5c01A7B414',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.IDE]: {
        chains: [],
        name: TokenSymbol.IDE,
        icon: baseURL + 'ide.png',
        contracts: {
            [ChainId.OpsideTestRollux]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WIDE]: {
        chains: [],
        name: TokenSymbol.WIDE,
        icon: baseURL + 'ide.png',
        contracts: {
            [ChainId.OpsideTestRollux]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.ARBI]: {
        chains: [],
        name: TokenSymbol.ARBI,
        icon: baseURL + 'arbi.png',
        contracts: {
            [ChainId.BSC]: {
                address: '0xa7bD657C5838472dDF85FF0797A2e6fce8fd4833',
                decimal: 18,
            },
            [ChainId.Arbitrum]: {
                address: '0x07DD5BEAffb65B8fF2e575d500BDf324a05295DC',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.zkUSD]: {
        chains: [],
        name: TokenSymbol.zkUSD,
        icon: baseURL + 'zkusd.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xfC7E56298657B002b3e656400E746b7212912757',
                decimal: 6,
            },
        },
    },

    [TokenSymbol.PIKO]: {
        chains: [],
        name: TokenSymbol.PIKO,
        icon: baseURL + 'piko.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xf8C6dA1bbdc31Ea5F968AcE76E931685cA7F9962',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.esPIKO]: {
        chains: [],
        name: TokenSymbol.esPIKO,
        icon: baseURL + 'espiko.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xCaa5ba70830f8557fd6516Cf632b08AA4B6A528F',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.BEL]: {
        chains: [],
        name: TokenSymbol.BEL,
        icon: baseURL + 'bel.png',
        contracts: {
            [ChainId.ZkSyncEra]: {
                address: '0xB83CFB285fc8D936E8647FA9b1cC641dBAae92D9',
                decimal: 18,
            },
            [ChainId.Mantle]: {
                address: '0x3390108E913824B8eaD638444cc52B9aBdF63798',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.AXL]: {
        chains: [],
        name: TokenSymbol.AXL,
        icon: baseURL + 'axl.png',
        contracts: {
            [ChainId.Mantle]: {
                address: '0x23ee2343B892b1BB63503a4FAbc840E0e2C6810f',
                decimal: 6,
            },
        },
    },
    [TokenSymbol.CTT]: {
        chains: [],
        name: TokenSymbol.CTT,
        icon: baseURL + 'ctt.png',
        contracts: {
            [ChainId.Mantle]: {
                address: '0x6a3b0eb5b57c9a4f5772fc900dae427e65f8c1a5',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.AGLD]: {
        chains: [],
        name: TokenSymbol.AGLD,
        icon: baseURL + 'agld.png',
        contracts: {
            [ChainId.Loot]: {
                address: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WAGLD]: {
        chains: [],
        name: TokenSymbol.WAGLD,
        icon: baseURL + 'agld.png',
        contracts: {
            [ChainId.Loot]: {
                address: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.sFUEL]: {
        chains: [],
        name: TokenSymbol.sFUEL,
        icon: baseURL + 'sfuel.png',
        contracts: {
            [ChainId.StagingFastActiveBellatrix]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.wsFUEL]: {
        chains: [],
        name: TokenSymbol.wsFUEL,
        icon: baseURL + 'sfuel.png',
        contracts: {
            [ChainId.StagingFastActiveBellatrix]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.MINU]: {
        chains: [],
        name: TokenSymbol.MINU,
        icon: baseURL + 'minu.png',
        contracts: {
            [ChainId.Mantle]: {
                address: '0x51cfe5b1e764dc253f4c8c1f19a081ff4c3517ed',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.AZETA]: {
        chains: [ChainId.ZetaTest],
        name: TokenSymbol.AZETA,
        icon: baseURL + 'zeta.png',
        contracts: {
            [ChainId.ZetaTest]: {
                address: '0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WZETA]: {
        chains: [ChainId.ZetaTest],
        name: TokenSymbol.WZETA,
        icon: baseURL + 'zeta.png',
        contracts: {
            [ChainId.ZetaTest]: {
                address: '0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.GAS0]: {
        chains: [],
        name: TokenSymbol.GAS0,
        icon: baseURL + 'gaszero.png',
        contracts: {
            [ChainId.GasZeroGoerliL2]: {
                address: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.WGAS0]: {
        chains: [],
        name: TokenSymbol.WGAS0,
        icon: baseURL + 'gaszero.png',
        contracts: {
            [ChainId.GasZeroGoerliL2]: {
                address: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08',
                decimal: 18,
            },
        },
    },

    [TokenSymbol.OKB]: {
        chains: [],
        name: TokenSymbol.OKB,
        icon: baseURL + 'okb.png',
        contracts: {
            [ChainId.X1Test]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
        },
    },
    [TokenSymbol.WOKB]: {
        chains: [],
        name: TokenSymbol.WOKB,
        icon: baseURL + 'okb.png',
        contracts: {
            [ChainId.X1Test]: {
                address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
                decimal: 18,
            },
        },
    },
};

export default tokens;

export const tokenSymbol2token = (tokenSymbol: string, chainId: ChainId): TokenInfoFormatted => {
    const token = tokens[tokenSymbol as TokenSymbol];
    return {
        name: token.name,
        symbol: tokenSymbol as string,
        icon: token.icon,
        chainId: chainId,
        address: token.contracts[chainId]?.address ?? '',
        decimal: token.contracts[chainId]?.decimal ?? 18,
        wrapTokenAddress: token.contracts[chainId]?.wrapTokenAddress,
        custom: false,
    };
};

export const tokenAddr2Token = (tokenAddr: string, chainId: ChainId): TokenInfoFormatted => {
    const token = Object.values(tokens).find((e) => e.contracts[chainId]?.address.toUpperCase() === tokenAddr.toUpperCase());
    // console.log(' ------------------ token: ', tokenAddr, ' name: ', token?.name);
    return {
        name: token?.name ?? '',
        symbol: token?.name ?? '',
        icon: token?.icon ?? '',
        chainId,
        address: tokenAddr,
        decimal: token?.contracts[chainId]?.decimal ?? 18,
        wrapTokenAddress: token?.contracts[chainId]?.wrapTokenAddress,
        custom: false,
    };
};

export const isGasToken = (token: TokenInfoFormatted, chainId: ChainId): boolean => {
    if (!token || !chainId) {
        return false;
    }
    const chain = getChain(chainId);
    const chainTokenAddress = chain?.token.address ?? '';
    if (
        token.chainId === chainId &&
        token.symbol === chain?.token.symbol &&
        token.address.toLowerCase() === chainTokenAddress.toLowerCase()
    ) {
        return true;
    }
    return false;
};

export const isGasOrWrappedGasToken = (token: TokenInfoFormatted, chainId: ChainId): boolean => {
    if (!token || !chainId) {
        return false;
    }
    const chain = getChain(chainId);
    const chainTokenAddress = chain?.token.address ?? '';
    if (token.chainId === chainId && token.address.toLowerCase() === chainTokenAddress.toLowerCase()) {
        return true;
    }
    return false;
};

export const isWrappedGasToken = (token: TokenInfoFormatted, chainId: ChainId): boolean => {
    if (!token || !chainId) {
        return false;
    }
    const chain = getChain(chainId);
    const chainTokenAddress = chain?.token.address ?? '';
    if (
        token.chainId === chainId &&
        token.symbol !== chain?.token.symbol &&
        token.address.toLowerCase() === chainTokenAddress.toLowerCase()
    ) {
        return true;
    }
    return false;
};

export const getGasToken = (chainId: ChainId): TokenInfoFormatted => {
    const chain = getChain(chainId);
    return tokenSymbol2token(chain?.token.symbol ?? 'BNB', chainId);
};

export const getWrappedGasTokenIfExists = (chainId: ChainId): TokenInfoFormatted => {
    const chain = getChain(chainId);
    if (!chain?.token.symbol) {
        return undefined as unknown as TokenInfoFormatted;
    }
    const token = tokenSymbol2token(chain?.token.symbol as string, chainId);
    const wrappedSymbol = chain?.wrappedTokenSymbol ?? token.symbol;
    return { ...token, symbol: wrappedSymbol as TokenSymbol };
};
