import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'Swap',
            img: 'Swap.svg',
            address: '/trade/swap',
            marker: 'new',
            children: [],
            inTabBar: true,
            inHeader: true,
        },
        {
            name: 'Pools',
            img: 'Pools.svg',
            address: '/trade/pools',
            children: [],
            inTabBar: true,
            inHeader: true,
        },
        {
            name: 'Liquidity',
            img: 'Liquidity.svg',
            address: '/trade/liquidity',
            children: [],
            inTabBar: true,
            inHeader: true,
        },
        {
            name: 'Farm',
            img: 'Farm.svg',
            address: '/farm/iZi/dynamic',
            children: [],
            inTabBar: true,
            inHeader: true,
        },
        {
            name: 'Pro',
            img: 'Pro.svg',
            address: '/trade/pro',
            inTabBar: false,
            inHeader: false,
            children: [],
        },

        {
            name: 'Portal',
            img: 'Portal.svg',
            address: '/portal',
            children: [],
            inTabBar: false,
            inHeader: false,
        },
        {
            name: 'Bridge',
            img: 'Bridge.svg',
            address: '/bridge',
            children: [],
            inTabBar: false,
            inHeader: false,
        },
        {
            name: 'Analytics',
            address: links.analyticLink,
            img: 'Analytics.svg',
            children: [],
            inTabBar: false,
            inHeader: false,
        },
    ],
} as { pages: PageConfig[] };
