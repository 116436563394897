import { HStack, Image, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { i_h3, i_text_copy } from '../../../style';
import placeholder from '../../../assets/placeholder.png';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useTranslation } from 'react-i18next';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CssFilterConverter from 'css-filter-converter';

type PartnerCardProps = {
    titleImgSrc?: string;
    title?: string;
    content?: string;
    link?: string;
};
export const PartnerCard: React.FC<PartnerCardProps> = (props) => {
    const { titleImgSrc, title, content, link } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { THEME, THEME_CARD } = useCustomTheme();
    const { t } = useTranslation();
    const [cardFlow, setCardFlow] = useState(false);

    return (
        <Card
            className={cardFlow ? 'cardFlow' : 'cardLeave'}
            h="247px"
            bg={colorTheme('ffffff', THEME_CARD[chainId])}
            p="21px"
            cursor="pointer"
            onClick={() => {
                window.open(link);
            }}
            onMouseOver={() => {
                setCardFlow(true);
            }}
            onMouseLeave={() => {
                setCardFlow(false);
            }}
        >
            <Stack h="100%" spacing="25px">
                <HStack spacing="15px">
                    <Image
                        boxSize="53px"
                        borderRadius="10px"
                        src={titleImgSrc ? titleImgSrc : placeholder}
                        fallbackSrc={titleImgSrc}
                    ></Image>
                    <Text className={i_h3} isTruncated={true}>
                        {title && t(title)}
                    </Text>
                </HStack>
                <Text className={i_text_copy} noOfLines={5}>
                    {content && t(content)}
                </Text>
                <Image
                    mt="auto !important"
                    ml="auto !important"
                    boxSize="15px"
                    src="/assets/portal/ArrowRight.svg"
                    fallbackSrc="/assets/portal/ArrowRight.svg"
                    filter={CssFilterConverter.hexToFilter(THEME[chainId]).color!}
                ></Image>
            </Stack>
        </Card>
    );
};
