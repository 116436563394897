import React from 'react';
import { Text, Flex, BoxProps, useColorMode, HStack } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { getColorThemeSelector } from '../../utils/funcs';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../state/store';
import useIsMobile from '../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';

type BarProps = {
    text: string;
    link?: string;
    linkText?: string;
    icon?: string;
    onClick?: () => void;
    type: string;
    index: number;
} & BoxProps;

const AnnouncementBar: React.FC<BarProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { announceBar } = useSelector((state: RootState) => state);
    const isMobile = useIsMobile();

    const { index } = props;

    return (
        <Flex
            direction="row"
            align="center"
            justify="center"
            px="30px"
            py="0"
            w="100%"
            h={announceBar.showBar[index] ? '30px' : '0'}
            m="0 !important"
            bg={colorTheme('#ECE4F9', '#3D2C63')}
            justifyContent="space-between"
            transitionProperty="all"
            transitionDuration="0.3s"
            transitionTimingFunction="ease"
            transitionDelay="0.2s"
        >
            {announceBar.showBar[index] && (
                <HStack
                    justifyContent="space-between"
                    w={isMobile ? '100%' : '100%'}
                    overflowX="scroll"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                >
                    <Text
                        fontFamily="Avenir"
                        fontWeight="bold"
                        textAlign="center"
                        p="5px"
                        fontSize={{ base: '12px', xxl: '14px' }}
                        color={colorTheme('secondary.700', 'secondary.200')}
                        whiteSpace="nowrap"
                    >
                        {t(props.text, { nsSeparator: false })}
                    </Text>
                    <CloseIcon
                        boxSize={3}
                        cursor="pointer"
                        onClick={() => {
                            dispatch.announceBar.setShowBar(false, index);
                        }}
                    />
                </HStack>
            )}
        </Flex>
    );
};

export default AnnouncementBar;
