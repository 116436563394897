import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { BoxProps, VStack } from '@chakra-ui/react';
import { ModalHead } from '../../../components/ModalHead';
import { useSelector } from 'react-redux';
import { RootState, RootDispatch } from '../../../../../state/store';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { PoolEntryState } from '../../../../../state/models/farm/UniswapV3/fixRange/types';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import useFixRangeEntity, { FixRangeStakeParams } from '../../../../../state/models/hooks/farm/useFixRangeEntity';
import { MiningCallbacks } from '../../../../../state/models/hooks/farm/common/callbacks';
import { VeNFTBoostBlock } from '../../../components/VeNFTBoostBlock';
import { getChain, getTxLink } from '../../../../../config/chains';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';

type StakeModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    entry: PoolEntryState;
    index: number;
} & BoxProps;

export const StakeModalVeiZi: React.FC<StakeModalProps> = (props) => {
    const { isOpen, onClose, entry, index, onSuccess, ...rest } = props;
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const {
        veiZiFarmFixRangeStake: { mintForm },
    } = useSelector((state: RootState) => state);

    const extraveiZi = Math.max(mintForm.veiZiForFullBoostDecimal - entry.userData.veiZiDecimal, 0);

    const fixRangeEntity = useFixRangeEntity(entry);
    const { chainId } = useWeb3WithDefault();

    const toast = useCustomToast();
    const { gasPrice } = useGasPrice();

    const StakeButton = (
        <CustomButton
            variant="purple"
            text="Stake"
            mb="2px"
            w="272px"
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                fixRangeEntity.stake(
                    {
                        nftId: mintForm.nftId,
                        niZi: '0',
                    } as FixRangeStakeParams,
                    {
                        onTransactionHash: (hash: string) => {
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(hash, chain);
                            }
                            toast('info', 'Staking ...', undefined, toastLink);
                        },
                        then: (r: any) => {
                            toast('success', 'Stake successfully', undefined, toastLink);
                            console.log(r);
                            onSuccess?.();
                            onClose();
                        },
                        catch: (e: any) => {
                            console.log(e);
                        },
                    } as MiningCallbacks,
                    gasPrice
                );
            }}
        />
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} w="744px" minH="350px" title="Stake with Boost" {...rest}>
            <ModalHead entry={entry} index={index} dispatch={dispatch.farmFixRange} />

            <VeNFTBoostBlock
                entry={{
                    veiZiDecimal: entry.userData.veiZiDecimal,
                    nftId: entry.userData.veiZiNftId,
                }}
                aprRaw={mintForm.apr}
                aprBoost={mintForm.aprBoostVeiZi}
                extraveiZi={extraveiZi}
            />

            <VStack alignItems="center" mt="20px">
                {StakeButton}
            </VStack>
        </Modal>
    );
};
