import React from 'react';
import { i_text_d, i_text_copy } from '../../style';
import { RepeatIcon } from '@chakra-ui/icons';
import { BoxProps, useColorMode, HStack, VStack, Text, Image } from '@chakra-ui/react';
import Card from '../../iZUMi-UI-toolkit/src/components/Card/Card';
import { getColorThemeSelector } from '../../utils/funcs';
import { formatNumber } from '../../utils/tokenMath';
import { TokenInfoFormatted } from '../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';
import { AdaptationMode } from '../../components/layout/PageLayout';

type BlockProps = {
    maxPrecision?: number;
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    price: number | string;
    toggle?: boolean;
    handleToggle: () => void;
    type?: AdaptationMode;
} & BoxProps;

const CurrentPriceBlock: React.FC<BlockProps> = (props) => {
    const { maxPrecision, tokenA, tokenB, price, toggle, handleToggle, type, ...rest } = props;
    const maxNumberPrecision = maxPrecision === undefined ? 3 : maxPrecision;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return type === 'mobile' ? (
        <HStack w="100%" alignItems="center" {...rest}>
            <HStack alignItems="center" spacing="12px">
                <Text className={i_text_d} fontSize="12px !important" color="#A398BD">
                    {t('Current Price')}
                </Text>
                <Image boxSize="10px" src={process.env.PUBLIC_URL + '/assets/farm/transform.svg'} onClick={handleToggle}></Image>
            </HStack>
            <Text className={i_text_copy} color={colorTheme('#563478', '#AB8BCB')}>
                1 {toggle ? tokenB.symbol : tokenA.symbol}{' '}
            </Text>
            <Text className={i_text_copy} color={colorTheme('#563478', '#AB8BCB')}>
                = &nbsp;
                {toggle
                    ? formatNumber(1 / Number(price), 2, 1 / Number(price) < 1 ? 6 : maxNumberPrecision, true)
                    : formatNumber(Number(price), 2, Number(price) > 1 ? maxNumberPrecision : 6, true)}{' '}
                {toggle ? tokenA.symbol : tokenB.symbol}
            </Text>
        </HStack>
    ) : (
        <Card w="100%" h="80px" p="10px" bg={colorTheme('#F5F5F5', 'tertiary.700')} {...rest}>
            <HStack w="100%" justifyContent="space-between" alignItems="center">
                <VStack spacing="2px" alignItems="start">
                    <Text className={i_text_copy} fontSize="12px !important">
                        1 {toggle ? tokenB.symbol : tokenA.symbol}
                    </Text>
                    <Text
                        className={i_text_d}
                        fontWeight="bold"
                        fontSize="14px !important"
                        color={colorTheme('tertiary.700', 'tertiary.50')}
                    >
                        ={' '}
                        {toggle
                            ? formatNumber(1 / Number(price), 2, 1 / Number(price) < 1 ? 6 : maxNumberPrecision)
                            : formatNumber(Number(price), 2, Number(price) > 1 ? maxNumberPrecision : 6)}{' '}
                        {toggle ? tokenA.symbol : tokenB.symbol}
                    </Text>
                    <HStack alignItems="center" spacing="30px">
                        <Text className={i_text_d} color={colorTheme('tertiary.600', 'tertiary.400')} fontSize="12px !important">
                            {t('Current Price')}
                        </Text>
                        <RepeatIcon onClick={handleToggle} cursor="pointer" />
                    </HStack>
                </VStack>
            </HStack>
        </Card>
    );
};

export default CurrentPriceBlock;
