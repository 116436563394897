import {
    VStack,
    HStack,
    Text,
    Flex,
    Image,
    AccordionItem,
    Accordion,
    AccordionPanel,
    Icon,
    useColorMode,
    Circle,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { FiExternalLink } from 'react-icons/fi';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector, viewTrans } from '../../../utils/funcs';
import { FeeRate } from '../../Trade/components/FeeRate';
import { TokenIcons } from '../../Trade/components/TokenIcons';
import { Pagination } from './Pagination';
import { formatNumber } from '../../../utils/tokenMath';
import moment from 'moment';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { TransListSetting } from '../OrderHistory';
import { Loading } from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';

export interface HistoryListProps {
    data: [];
    netWorkloading: boolean;
    totalPage: number;
    setting: TransListSetting;
    setCurrentPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
}
const SwapList: React.FC<HistoryListProps> = (pros) => {
    const { data, netWorkloading, totalPage, setting, setCurrentPage, setPageSize } = pros;
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const isXl = useBreakpointValue({ base: false, sm: true, xl: false, '2xl': false });
    const { THEME_CARD } = useCustomTheme();
    const { chainId } = useWeb3WithDefault();

    const { loading, tokenList } = useTokenListFormatted();
    let resultList: any = [];
    function getList(list: []) {
        const map = new Map();
        list &&
            list.forEach((item: any, index: number, arr: any) => {
                if (!map.has(item.txHash)) {
                    map.set(
                        item.txHash,
                        arr.filter((a: any) => a.txHash === item.txHash)
                    );
                }
            });

        return Array.from(map).map((item) => [...item[1]]);
    }
    function getIcon(symbol: string) {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        } as unknown as any;
        return tempToken.icon ?? '/assets/tokens/default.svg';
    }

    if (data) {
        resultList = getList(data);
        resultList &&
            resultList.map((item: any) => {
                item.map((entry: any) => {
                    if (!entry.sellXEarnY) {
                        //exchange x y token
                        const tempTokenX = entry.tokenY;
                        const tempTokenY = entry.tokenX;
                        entry.newTokenX = tempTokenX;
                        entry.newTokenY = tempTokenY;

                        const tempTokenX_amount = entry.amountY;
                        const tempTokenY_amount = entry.amountX;
                        entry.newAmountX = tempTokenX_amount;
                        entry.newAmountY = tempTokenY_amount;

                        const tempTokenX_address = entry.tokeY_address;
                        const tempTokenY_address = entry.tokenX_address;

                        entry.newTokenX_address = tempTokenX_address;
                        entry.newTokenY_address = tempTokenY_address;
                    } else {
                        entry.newTokenX = entry.tokenX;
                        entry.newTokenY = entry.tokenY;

                        entry.newAmountX = entry.amountX;
                        entry.newAmountY = entry.amountY;

                        entry.newTokenX_address = entry.tokenX_address;
                        entry.newTokenY_address = entry.tokeY_address;
                    }
                    return entry;
                });
                if (item.length > 1 && item[0].newTokenX === item[1].newTokenY) {
                    [item[0], item[1]] = [item[1], item[0]];
                }
                return item;
            });
    }

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const tabs = [
        [t('Pool/fees'), '250px'],
        [t('Transaction'), '350px'],
        // ['Type', '227px'],
        [t('Avg. Price'), isXl ? '100px' : '160px'],
        [t('Order Date'), isXl ? '204px' : '180px'],
        ['', '108px'],
    ];
    return isMobile ? (
        <Card
            variant="deep2"
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            pt="12px"
            pb="16px"
            mt={{ base: '10px', sm: '23px' }}
            mb={{ base: '80px', sm: '0px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
        >
            <VStack spacing="11px" alignItems="left">
                {(netWorkloading || loading) && (
                    <Flex alignItems="center" justifyContent="center" pt="40px">
                        <Loading />
                    </Flex>
                )}
                {!netWorkloading &&
                    !loading &&
                    resultList &&
                    resultList.map((item: any, i: number) => {
                        const isMultiple = item.length === 1 ? false : true;

                        return (
                            <Accordion key={i} allowToggle={true}>
                                <AccordionItem border="0">
                                    <Flex direction="column">
                                        <Stack direction={{ base: 'column', sm: 'row' }}>
                                            <HStack w="250px" mt="2px !important" cursor="pointer" spacing="15px" px="32px">
                                                <Text>{i + 1}</Text>
                                                <TokenIcons
                                                    tokenA={
                                                        {
                                                            chainId: item[0].chainId,
                                                            name: item[0].newTokenX,
                                                            symbol: item[0].newTokenX,
                                                            icon: getIcon(item[0].newTokenX),
                                                            address: item[0].newTokenX_address,
                                                            decimal: item[0].newTokenX,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: item[item.length - 1].chainId,
                                                            name: item[item.length - 1].newTokenY,
                                                            symbol: item[item.length - 1].newTokenY,
                                                            icon: getIcon(item[item.length - 1].newTokenY),
                                                            address: item[item.length - 1].newTokenY_address,
                                                            decimal: item[item.length - 1].newTokenY,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    initialToggle={false}
                                                    // chainId={item.chainId}
                                                    // showChainIcon={true}
                                                />
                                                <FeeRate
                                                    tokenA={
                                                        {
                                                            chainId: item[0].chainId,
                                                            name: item[0].newTokenX,
                                                            symbol: item[0].newTokenX,
                                                            icon: getIcon(item[0].newTokenX),
                                                            address: item[0].newTokenX_address,
                                                            decimal: item[0].newTokenX,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: item[item.length - 1].chainId,
                                                            name: item[item.length - 1].newTokenY,
                                                            symbol: item[item.length - 1].newTokenY,
                                                            icon: getIcon(item[item.length - 1].newTokenY),
                                                            address: item[item.length - 1].newTokenY_address,
                                                            decimal: item[item.length - 1].newTokenY,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    feeTier={(item[0].fee / 10000).toFixed(2) as any}
                                                    initialToggle={false}
                                                    direction="row"
                                                    variant="purple"
                                                    multipleFee={isMultiple}
                                                />
                                            </HStack>
                                            <Stack
                                                bg={colorTheme(
                                                    'linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)',
                                                    'inherit'
                                                )}
                                                px="32px"
                                                py="10px"
                                            >
                                                <HStack w={{ base: 'unset', sm: '350px' }}>
                                                    <Flex>
                                                        <Text
                                                            key={i}
                                                            w="80px"
                                                            className={i_text_copy}
                                                            color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                            mr="10px"
                                                        >
                                                            {tabs[0][0]}
                                                        </Text>
                                                        <HStack>
                                                            <Image
                                                                w="12px"
                                                                h="32px"
                                                                mx="10px"
                                                                src={process.env.PUBLIC_URL + '/assets/orderHistory/swapArrowMobile.svg'}
                                                            ></Image>

                                                            <Stack ml="2px !important">
                                                                <HStack>
                                                                    <Image boxSize="15px" mr="6px" src={getIcon(item[0].newTokenX)}></Image>

                                                                    <Text
                                                                        w={{ base: 'unset', sm: '116px' }}
                                                                        className={i_text_copy}
                                                                        fontSize="13px"
                                                                    >
                                                                        {formatNumber(item[0].newAmountX, 2, 5, true)}
                                                                        {' ' + item[0].newTokenX}
                                                                    </Text>
                                                                </HStack>

                                                                <HStack>
                                                                    <Image
                                                                        boxSize="15px"
                                                                        mr="6px"
                                                                        src={getIcon(item[item.length - 1].newTokenY)}
                                                                    ></Image>
                                                                    <Text className={i_text_copy} fontSize="13px">
                                                                        {formatNumber(item[item.length - 1].newAmountY, 2, 5, true)}
                                                                        {' ' + item[item.length - 1].newTokenY}
                                                                    </Text>
                                                                </HStack>
                                                            </Stack>
                                                        </HStack>
                                                    </Flex>
                                                </HStack>
                                                <HStack w={{ base: 'unset', sm: '140px' }} pt={{ base: '10px', sm: '0px' }}>
                                                    {isMobile && (
                                                        <Text
                                                            key={i}
                                                            w="80px"
                                                            className={i_text_copy}
                                                            color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                            mr="10px"
                                                        >
                                                            {tabs[1][0]}
                                                        </Text>
                                                    )}
                                                    <Text className={i_text_copy}>
                                                        {formatNumber(item[item.length - 1].newAmountY / item[0].newAmountX, 2, 4, true)}
                                                    </Text>
                                                </HStack>
                                                <HStack w={{ base: 'unset', sm: '180px' }} pt={{ base: '10px', sm: '0px' }}>
                                                    {isMobile && (
                                                        <Text
                                                            key={i}
                                                            w="80px"
                                                            className={i_text_copy}
                                                            color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                            mr="10px"
                                                        >
                                                            {tabs[2][0]}
                                                        </Text>
                                                    )}
                                                    <Text className={i_text_copy}>
                                                        {moment(Number(item[0].timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                                    </Text>
                                                </HStack>
                                                <HStack
                                                    cursor="pointer"
                                                    pt={{ base: '10px', sm: '0px' }}
                                                    onClick={() => {
                                                        viewTrans(item[0].chainId, item[0].txHash);
                                                    }}
                                                >
                                                    <Text
                                                        color={colorTheme('#0166FF', '#B08EFF')}
                                                        className={i_text_copy}
                                                        fontSize="13px"
                                                        w="90px"
                                                    >
                                                        {truncateWalletAddress(item[0].txHash, 5, 2)}
                                                    </Text>
                                                    <Icon
                                                        as={FiExternalLink}
                                                        boxSize="16px"
                                                        onClick={() => {}}
                                                        color={colorTheme('#0166FF', '#B08EFF')}
                                                    />
                                                </HStack>
                                            </Stack>
                                        </Stack>
                                        <AccordionPanel px="0px">
                                            <Flex
                                                w="100%"
                                                h={{ base: 'unset', sm: '117px' }}
                                                direction="column"
                                                bgColor={colorTheme('#F5F6FF', 'rgba(24, 16, 45, 0.62);')}
                                                py="7px"
                                                justifyContent="center"
                                            >
                                                {item.length > 0 &&
                                                    item.map((entry: any, index: number) => {
                                                        const isLastOne = index === 1;

                                                        return (
                                                            <Flex
                                                                key={index}
                                                                w="100%"
                                                                h={{ base: 'unset', sm: '30px' }}
                                                                direction={{ base: 'column', sm: 'row' }}
                                                                my="5px"
                                                                px={{ base: '10px', sm: '35px' }}
                                                                className={i_text_copy}
                                                                alignItems="center"
                                                            >
                                                                <Flex w="24px" direction="column" ml="100px" mr="17px">
                                                                    {!isMobile && (
                                                                        <Circle
                                                                            w="5px"
                                                                            h="5px"
                                                                            bgColor="#0166FF"
                                                                            // mt={!isLastOne ? '17px' : '0px'}
                                                                        ></Circle>
                                                                    )}
                                                                    {/* {!isLastOne && (
                                                                        <Divider
                                                                            w="42px"
                                                                            borderColor="#0166FFB0"
                                                                            ml="-18.5px"
                                                                            mt="20px"
                                                                            transform="rotate(90deg)"
                                                                        />
                                                                    )} */}
                                                                </Flex>

                                                                <Text
                                                                    w={{ base: 'unset', sm: '114px' }}
                                                                    className={i_text_copy}
                                                                    fontSize="13px"
                                                                    fontWeight="600 !important"
                                                                >
                                                                    {entry.newTokenX}
                                                                    {'/'}
                                                                    {entry.newTokenY}
                                                                </Text>

                                                                <Flex w={{ base: 'unset', sm: '15%' }} mt={{ base: '10px', sm: '0px' }}>
                                                                    <Text color="#9B8FAE" ml={{ base: '0px', sm: '33px' }}>
                                                                        {t('Fee')}
                                                                    </Text>
                                                                    <Text ml="11px">
                                                                        {(entry.fee / 10000).toFixed(2) as any}
                                                                        {'%'}
                                                                    </Text>
                                                                </Flex>
                                                                {/* <FeeRate
                                                                        tokenA={
                                                                            item.tokenA
                                                                        }
                                                                        tokenB={
                                                                            item.tokenB
                                                                        }
                                                                        feeTier={
                                                                            item.fee
                                                                        }
                                                                        initialToggle={
                                                                            false
                                                                        }
                                                                        direction="row"
                                                                    /> */}
                                                                <Flex w={{ base: '100%', sm: '60%' }} mt={{ base: '10px', sm: '0px' }}>
                                                                    <Image boxSize="11px" ml="12px" src={getIcon(entry.newTokenX)}></Image>
                                                                    <Text w={{ base: 'unset', sm: '115px' }} ml="6px">
                                                                        {formatNumber(entry.newAmountX, 2, 5, true)}
                                                                        {' ' + entry.newTokenX}
                                                                    </Text>
                                                                    <Image
                                                                        boxSize="15px"
                                                                        mx="13px"
                                                                        src={process.env.PUBLIC_URL + '/assets/orderHistory/Arrow.svg'}
                                                                    ></Image>
                                                                    <Image boxSize="11px" src={getIcon(entry.newTokenY)}></Image>
                                                                    <Text ml="6px">
                                                                        {formatNumber(entry.newAmountY, 2, 5, true)}
                                                                        {' ' + entry.newTokenY}
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        );
                                                    })}
                                            </Flex>
                                        </AccordionPanel>
                                    </Flex>
                                </AccordionItem>
                            </Accordion>
                        );
                    })}

                <Pagination
                    totalPage={totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                    alignItems="center"
                />
            </VStack>
        </Card>
    ) : (
        <Card
            variant="deep2"
            w={{ base: '100%', xxl: '1100px', xlp1: '1150px' }}
            minW={{ base: '100%', sm: '810px' }}
            alignItems="left"
            p={{ base: '10px', sm: '30px 20px 30px 20px', xl: '30px 40px 30px 40px' }}
            mt={{ base: '10px', sm: '23px' }}
            mb={{ base: '80px', sm: '0px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
            bg={THEME_CARD[chainId]}
        >
            <VStack spacing="11px" alignItems="left">
                {!isMobile && (
                    <HStack mb="10px">
                        {tabs.map((e: any, i: any) => {
                            return (
                                <Text key={i} w={e[1]} className={i_text_copy} color={colorTheme('#9B8FAE', 'tertiary.100')}>
                                    {e[0]}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                {(netWorkloading || loading) && (
                    <Flex alignItems="center" justifyContent="center" pt="40px">
                        <Loading />
                    </Flex>
                )}
                {!netWorkloading &&
                    !loading &&
                    resultList &&
                    resultList.map((item: any, i: number) => {
                        const isMultiple = item.length === 1 ? false : true;

                        return (
                            <Accordion key={i} allowToggle={true}>
                                <AccordionItem border="0">
                                    <Flex direction="column">
                                        <Stack direction={{ base: 'column', sm: 'row' }}>
                                            <HStack w={isXl ? '200px' : '250px'} mt="2px !important" cursor="pointer">
                                                <TokenIcons
                                                    tokenA={
                                                        {
                                                            chainId: item[0].chainId,
                                                            name: item[0].newTokenX,
                                                            symbol: item[0].newTokenX,
                                                            icon: getIcon(item[0].newTokenX),
                                                            address: item[0].newTokenX_address,
                                                            decimal: item[0].newTokenX,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: item[item.length - 1].chainId,
                                                            name: item[item.length - 1].newTokenY,
                                                            symbol: item[item.length - 1].newTokenY,
                                                            icon: getIcon(item[item.length - 1].newTokenY),
                                                            address: item[item.length - 1].newTokenY_address,
                                                            decimal: item[item.length - 1].newTokenY,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    initialToggle={false}
                                                    // chainId={item.chainId}
                                                    // showChainIcon={true}
                                                    type={isXl ? 'small' : ''}
                                                />
                                                <FeeRate
                                                    tokenA={
                                                        {
                                                            chainId: item[0].chainId,
                                                            name: item[0].newTokenX,
                                                            symbol: item[0].newTokenX,
                                                            icon: getIcon(item[0].newTokenX),
                                                            address: item[0].newTokenX_address,
                                                            decimal: item[0].newTokenX,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: item[item.length - 1].chainId,
                                                            name: item[item.length - 1].newTokenY,
                                                            symbol: item[item.length - 1].newTokenY,
                                                            icon: getIcon(item[item.length - 1].newTokenY),
                                                            address: item[item.length - 1].newTokenY_address,
                                                            decimal: item[item.length - 1].newTokenY,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    feeTier={(item[0].fee / 10000).toFixed(2) as any}
                                                    initialToggle={false}
                                                    direction="row"
                                                    variant="purple"
                                                    multipleFee={isMultiple}
                                                    type={isXl ? 'small' : ''}
                                                    ml={{ base: '0px !important', xl: '8px !important' }}
                                                />
                                            </HStack>

                                            <HStack w={{ base: 'unset', sm: '350px' }} pt={{ base: '10px', sm: '0px' }} whiteSpace="nowrap">
                                                <Flex>
                                                    {isMobile && (
                                                        <Text
                                                            key={i}
                                                            className={i_text_copy}
                                                            color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                            mr="10px"
                                                        >
                                                            {tabs[0][0]}
                                                        </Text>
                                                    )}
                                                    <Image boxSize="15px" mr="6px" src={getIcon(item[0].newTokenX)}></Image>

                                                    <Text
                                                        w={{ base: 'unset', sm: 'unset', xl: '116px' }}
                                                        className={i_text_copy}
                                                        fontSize="13px"
                                                    >
                                                        {formatNumber(item[0].newAmountX, 2, 5, true)}
                                                        {' ' + item[0].newTokenX}
                                                    </Text>
                                                    <Image
                                                        boxSize="15px"
                                                        mx={{ base: '13px', sm: '5px', xl: '13px' }}
                                                        src={process.env.PUBLIC_URL + '/assets/orderHistory/Arrow.svg'}
                                                    ></Image>

                                                    <Image boxSize="15px" mr="6px" src={getIcon(item[item.length - 1].newTokenY)}></Image>
                                                    <Text className={i_text_copy} fontSize="13px">
                                                        {formatNumber(item[item.length - 1].newAmountY, 2, 5, true)}
                                                        {' ' + item[item.length - 1].newTokenY}
                                                    </Text>
                                                </Flex>
                                            </HStack>
                                            <HStack w={{ base: 'unset', sm: '100px', xl: '140px' }} pt={{ base: '10px', sm: '0px' }}>
                                                {isMobile && (
                                                    <Text
                                                        key={i}
                                                        className={i_text_copy}
                                                        color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                        mr="10px"
                                                    >
                                                        {tabs[1][0]}
                                                    </Text>
                                                )}
                                                <Text className={i_text_copy}>
                                                    {formatNumber(item[item.length - 1].newAmountY / item[0].newAmountX, 2, 4, true)}
                                                </Text>
                                            </HStack>
                                            <HStack w={{ base: 'unset', sm: '180px' }} pt={{ base: '10px', sm: '0px' }}>
                                                {isMobile && (
                                                    <Text
                                                        key={i}
                                                        className={i_text_copy}
                                                        color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                        mr="10px"
                                                    >
                                                        {tabs[2][0]}
                                                    </Text>
                                                )}
                                                <Text className={i_text_copy}>
                                                    {moment(Number(item[0].timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                                </Text>
                                            </HStack>
                                            <HStack
                                                cursor="pointer"
                                                pt={{ base: '10px', sm: '0px' }}
                                                onClick={() => {
                                                    viewTrans(item[0].chainId, item[0].txHash);
                                                }}
                                            >
                                                <Text
                                                    color={colorTheme('#0166FF', '#B08EFF')}
                                                    className={i_text_copy}
                                                    fontSize="13px"
                                                    w="75px"
                                                >
                                                    {truncateWalletAddress(item[0].txHash, 5, 2)}
                                                </Text>
                                                <Icon
                                                    as={FiExternalLink}
                                                    boxSize="16px"
                                                    onClick={() => {}}
                                                    color={colorTheme('#0166FF', '#B08EFF')}
                                                />
                                            </HStack>
                                        </Stack>
                                        <AccordionPanel px="0px">
                                            <Flex
                                                w="100%"
                                                h={{ base: 'unset', sm: '117px' }}
                                                direction="column"
                                                bgColor={colorTheme('#F5F6FF', 'rgba(24, 16, 45, 0.62);')}
                                                py="7px"
                                                justifyContent="center"
                                            >
                                                {item.length > 0 &&
                                                    item.map((entry: any, index: number) => {
                                                        const isLastOne = index === 1;

                                                        return (
                                                            <Flex
                                                                key={index}
                                                                w="100%"
                                                                h={{ base: 'unset', sm: '30px' }}
                                                                direction={{ base: 'column', sm: 'row' }}
                                                                my="5px"
                                                                px={{ base: '10px', sm: '35px' }}
                                                                className={i_text_copy}
                                                                alignItems="center"
                                                            >
                                                                <Flex w="24px" direction="column" ml="100px" mr="17px">
                                                                    {!isMobile && (
                                                                        <Circle
                                                                            w="5px"
                                                                            h="5px"
                                                                            bgColor="#0166FF"
                                                                            // mt={!isLastOne ? '17px' : '0px'}
                                                                        ></Circle>
                                                                    )}
                                                                    {/* {!isLastOne && (
                                                                        <Divider
                                                                            w="42px"
                                                                            borderColor="#0166FFB0"
                                                                            ml="-18.5px"
                                                                            mt="20px"
                                                                            transform="rotate(90deg)"
                                                                        />
                                                                    )} */}
                                                                </Flex>

                                                                <Text
                                                                    w={{ base: 'unset', sm: '114px' }}
                                                                    className={i_text_copy}
                                                                    fontSize="13px"
                                                                    fontWeight="600 !important"
                                                                >
                                                                    {entry.newTokenX}
                                                                    {'/'}
                                                                    {entry.newTokenY}
                                                                </Text>

                                                                <Flex w={{ base: 'unset', sm: '15%' }} mt={{ base: '10px', sm: '0px' }}>
                                                                    <Text color="#9B8FAE" ml={{ base: '0px', sm: '33px' }}>
                                                                        {t('Fee')}
                                                                    </Text>
                                                                    <Text ml="11px">
                                                                        {(entry.fee / 10000).toFixed(2) as any}
                                                                        {'%'}
                                                                    </Text>
                                                                </Flex>
                                                                {/* <FeeRate
                                                                        tokenA={
                                                                            item.tokenA
                                                                        }
                                                                        tokenB={
                                                                            item.tokenB
                                                                        }
                                                                        feeTier={
                                                                            item.fee
                                                                        }
                                                                        initialToggle={
                                                                            false
                                                                        }
                                                                        direction="row"
                                                                    /> */}
                                                                <Flex w={{ base: '100%', sm: '60%' }} mt={{ base: '10px', sm: '0px' }}>
                                                                    <Image boxSize="11px" ml="12px" src={getIcon(entry.newTokenX)}></Image>
                                                                    <Text w={{ base: 'unset', sm: '115px' }} ml="6px">
                                                                        {formatNumber(entry.newAmountX, 2, 5, true)}
                                                                        {' ' + entry.newTokenX}
                                                                    </Text>
                                                                    <Image
                                                                        boxSize="15px"
                                                                        mx="13px"
                                                                        src={process.env.PUBLIC_URL + '/assets/orderHistory/Arrow.svg'}
                                                                    ></Image>
                                                                    <Image boxSize="11px" src={getIcon(entry.newTokenY)}></Image>
                                                                    <Text ml="6px">
                                                                        {formatNumber(entry.newAmountY, 2, 5, true)}
                                                                        {' ' + entry.newTokenY}
                                                                    </Text>
                                                                </Flex>
                                                            </Flex>
                                                        );
                                                    })}
                                            </Flex>
                                        </AccordionPanel>
                                    </Flex>
                                </AccordionItem>
                            </Accordion>
                        );
                    })}

                <Pagination
                    totalPage={totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Card>
    );
};
export default SwapList;
