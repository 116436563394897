import { isNumeric } from '../../../../../../utils/valid';

export interface LiquidityValidAble {
    tickLower: number;
    tickUpper: number;
    amount0Desired: number;
    amount1Desired: number;
    amount0DecimalDesired?: number;
    amount1DecimalDesired?: number;
}

export const validateMintForm = (form: LiquidityValidAble, tokenBalance: number[]): string[] | undefined => {
    if (!isNumeric(form.amount0Desired) || form.amount0Desired < 0) {
        return ['amount0Desired', 'please enter correct volume !'];
    }
    if (form.amount0DecimalDesired && (form.amount0DecimalDesired > tokenBalance[0])) {
        return ['amount0Desired', 'Insufficient balance !'];
    }

    if (!isNumeric(form.amount1Desired) || form.amount1Desired < 0) {
        return ['amount1Desired', 'please enter correct volume !'];
    }
    if (form.amount1DecimalDesired && (form.amount1DecimalDesired > tokenBalance[1])) {
        return ['amount1Desired', 'Insufficient balance !'];
    }

    if (form.amount0Desired === 0 && form.amount1Desired === 0) {
        return ['amount0Desired', 'desired volume are zero !'];
    }

    if (!isNumeric(form.tickLower) || form.tickLower < -887272) {
        return ['tickLower', 'please enter correct min price !'];
    }
    if (!isNumeric(form.tickUpper) || form.tickUpper > 887272) {
        return ['tickUpper', 'please enter correct max price !'];
    }

    if (form.tickLower > form.tickUpper) {
        return ['tickLower', 'min price lower than max !'];
    }
    return undefined;
};

export const getFieldValidateInfo = (validateResult: string[] | undefined, filedName: string): string | undefined => {
    if (validateResult === undefined || validateResult[0] !== filedName) {
        return undefined;
    }
    return validateResult[1];
};
