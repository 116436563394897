import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Image,
    Text,
    useColorMode,
    HStack,
    BoxProps,
    Box,
    MenuButtonProps,
    IconProps,
    Stack,
    Center,
} from '@chakra-ui/react';
import ArrowIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/ArrowIcon/ArrowIcon';
import React, { useMemo, useState } from 'react';
import chains from '../../../config/chains';
import { switchNetwork } from '../../../utils/metamaskWallet';
import { i_text_copy_bold, i_text_copy } from '../../../style';
import { getCurrentConnector, useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../state/store';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useLocation } from 'react-router-dom';
import placeholder from '../../../assets/placeholder.png';
import { ConnectorNames } from '../../../utils/connectors';
import useAuth from '../../../hooks/useAuth';
import { useToast } from '../../../providers/ToastProvider';
import { useWeb3React } from '@web3-react/core';
import { AIRDROP_CHAIN } from '../../../config/bizConfig';
import { AirdropLoadingModal } from '../../../views/components/Loading/AirdropLoadingModal';
import useCustomTheme from '../../../hooks/useCustomTheme';

type Props = {
    isHome?: boolean;
    showArrow?: boolean;
    buttonStyle?: MenuButtonProps;
    rightIconStyle?: IconProps;
} & BoxProps;

const NetworkSelect: React.FC<Props> = ({ isHome, showArrow = true, buttonStyle, rightIconStyle, ...rest }) => {
    const { chainId, account, chainIcon, chainName, isAaAccount } = useWeb3WithDefault();
    const { THEME_CARD, THEME_SELECT } = useCustomTheme();
    const { library } = useWeb3React();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { login } = useAuth();
    const { show } = useToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg1 = colorTheme('#EBEAEC', THEME_SELECT[chainId]);
    const bg2 = colorTheme('#F5F5F5', THEME_CARD[chainId]);

    const location = useLocation();
    const [isShowMore, setIsShowMore] = useState(false);

    const chainsFilter = useMemo(() => {
        if (location.pathname.startsWith('/farm/uni')) {
            return chains.farmUni;
        }
        if (location.pathname.startsWith('/farm/iZi')) {
            return chains.farmiZi;
        }
        if (location.pathname.startsWith('/veiZi')) {
            return chains.veiZi;
        }
        if (location.pathname.startsWith('/trade')) {
            return chains.trade;
        }
        if (location.pathname.startsWith('/iPoints')) {
            return chains.iPoints;
        }
        if (location.pathname.startsWith('/bridge')) {
            return chains.bridge;
        }
        return chains.all;
    }, [location]);
    const showLessCount = 5;
    const hasScrollbar = useMemo(() => {
        return chainsFilter.length > showLessCount ? true : false;
    }, [chainsFilter.length]);

    return (
        <Box {...rest}>
            <Menu>
                {isHome ? (
                    <MenuButton
                        h="40px"
                        as={Button}
                        size="sm"
                        bg="transparent"
                        borderRadius="12px"
                        _hover={{ background: 'unset' }}
                        _active={{ background: 'unset' }}
                        leftIcon={
                            <Image src={process.env.PUBLIC_URL + chainIcon} boxSize="20px" fallbackSrc={placeholder} borderRadius="4px" />
                        }
                    >
                        <HStack>
                            <Text fontSize="13px !important" textAlign="left" className={i_text_copy}>
                                {chainName}
                            </Text>
                            {showArrow && <ArrowIcon dir="right" color="#078CFD" />}
                        </HStack>
                    </MenuButton>
                ) : (
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon boxSize="24px" m="9px" background={bg1} borderRadius="5px" {...rightIconStyle} />}
                        leftIcon={
                            <Image
                                src={process.env.PUBLIC_URL + chainIcon}
                                boxSize="24px"
                                ml="17px"
                                mr="7px"
                                fallbackSrc={placeholder}
                                borderRadius="4px"
                            />
                        }
                        style={{
                            height: '40px',
                            //width: '178px',
                            padding: '0',
                            background: { bg2 } as unknown as string,
                            borderRadius: '6px',
                        }}
                        background={bg2}
                        {...buttonStyle}
                    >
                        <Text ml="-10px" className={i_text_copy_bold}>
                            {chainName}
                        </Text>
                    </MenuButton>
                )}

                <MenuList
                    minW={{ base: '190px', xxl: '243px' }}
                    w={{ base: '190px', xxl: '243px' }}
                    px="2px"
                    borderRadius="6px"
                    bg={colorTheme('#fff', bg2)}
                >
                    <Stack
                        maxH={isShowMore ? { base: '290px', sm: '546px' } : '260px'}
                        transitionProperty="max-height"
                        transitionDuration="0.5s"
                        transitionTimingFunction="ease"
                        overflow="auto"
                        spacing="0px"
                        px="10px"
                    >
                        {chainsFilter.map((chain) => (
                            <MenuItem
                                as={Button}
                                variant="light"
                                text={chain.name}
                                size="md"
                                h="52px"
                                key={chain.id}
                                isActive={chain.id === chainId}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    fontSize: '12px',
                                    fontFamily: 'Montserrat',
                                }}
                                _active={{ bg: 'inherit', fontFamily: 'Montserrat-Bold !important' }}
                                _hover={{ bg: THEME_SELECT[chainId] }}
                                onClick={() => {
                                    if (account && !isAaAccount) {
                                        switchNetwork(chain.id, library).then((res) => {
                                            const currentConnector = getCurrentConnector();
                                            if (currentConnector === ConnectorNames.Injected) {
                                                login(ConnectorNames.Injected);
                                            }
                                            if (res) {
                                                show('Error : ', res.toString());
                                            }
                                        });
                                    } else {
                                        dispatch.account.setOfflineChainId(chain.id);
                                    }
                                }}
                                leftIcon={
                                    <Image
                                        src={process.env.PUBLIC_URL + chain.icon}
                                        boxSize="28px"
                                        mr="5px"
                                        fallbackSrc={placeholder}
                                        borderRadius="4px"
                                    />
                                }
                            >
                                {chain.name}
                                {chain.id === chainId && (
                                    <Image
                                        w="13px"
                                        h="10px"
                                        src="/assets/networkSelect/select.svg"
                                        fallbackSrc='"/assets/networkSelect/select.svg"'
                                        ml="auto"
                                    ></Image>
                                )}
                            </MenuItem>
                        ))}
                    </Stack>
                    {hasScrollbar && (
                        <Center
                            w="100%"
                            h="35px"
                            minH={'35px'}
                            bg={colorTheme('#fff', bg2)}
                            position="sticky"
                            bottom={'0px'}
                            zIndex="1"
                            pt={isShowMore ? '8px' : '0px'}
                            _hover={{ opacity: 0.5 }}
                            cursor="pointer"
                            onClick={() => {
                                if (isShowMore) {
                                    setIsShowMore(false);
                                } else {
                                    setIsShowMore(true);
                                }
                            }}
                        >
                            <Image
                                w={{ base: '10px', xxl: '12px' }}
                                h={{ base: '14px', xxl: '16px' }}
                                src={
                                    isShowMore
                                        ? colorTheme('/assets/networkSelect/ArrowUp.svg', '/assets/networkSelect/darkArrowUp.svg')
                                        : colorTheme('/assets/networkSelect/ArrowDown.svg', '/assets/networkSelect/darkArrowDown.svg')
                                }
                                fallbackSrc={
                                    isShowMore
                                        ? colorTheme('/assets/networkSelect/ArrowUp.svg', '/assets/networkSelect/darkArrowUp.svg')
                                        : colorTheme('/assets/networkSelect/ArrowDown.svg', '/assets/networkSelect/darkArrowDown.svg')
                                }
                            ></Image>
                        </Center>
                    )}
                </MenuList>
            </Menu>

            {AIRDROP_CHAIN[chainId] && !isHome && <AirdropLoadingModal></AirdropLoadingModal>}
        </Box>
    );
};

export default NetworkSelect;
