import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BarPrice, BarPrices, CandlestickData, HistogramData, IChartApi, ISeriesApi, MouseEventParams, Time } from 'lightweight-charts';
import {
    Divider,
    HStack,
    Stack,
    Image,
    useColorMode,
    BoxProps,
    useBreakpointValue,
    Text,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Portal,
    Center,
    useDisclosure,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { noto_t3, noto_t3_bold } from '../../../../style';
import ChartInfo from './components/ChartInfo';
import CandleChart, { VisibleEnum } from './components/CandleChart';
import { getColorThemeSelector } from '../../../../utils/funcs';
import useWindowDimensions from '../../../../hooks/useWindowDimension';
import { useInterval, useKeyPress } from 'ahooks';
import {
    getIziSwapKLinesRecord,
    iZiSwapKLinesRecordEnum,
    RequestIziSwapKLinesRecord,
} from '../../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import { formatNumber } from '../../../../utils/tokenMath';
import { calculateSMA, calculateVolumeSMA } from '../../../../utils/pro/sma';
import HistogramInfo from './components/HistogramChart/HistogramInfo';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../state/store';
import _ from 'lodash';
import { PRO_CONFIG } from '../../../../config/bizConfig';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import moment from 'moment';
import useIsMobile from '../../../../hooks/useIsMobile';
import { Loading, LoadingEnum } from '../../../components/Loading';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';

type KlineChartProps = {
    themeColor: any;
} & BoxProps;

export const KlineChart: React.FC<KlineChartProps> = (props) => {
    const { themeColor, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();

    const { colorMode } = useColorMode();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { width, height } = useWindowDimensions();
    const isLg = useBreakpointValue({ base: false, lg: true, xl: false });
    const isMobile = useIsMobile();
    const { pro } = useSelector((state: RootState) => state);
    const { isOpen, onToggle, onClose } = useDisclosure();

    const [chartFullScreen, setChartFullScreen] = useState(false);
    const chartRef = useRef<IChartApi>(null);
    const histogramChartRef = useRef<IChartApi>(null);
    //first chart
    const candlesSeries = useRef<ISeriesApi<'Candlestick'>>(null);
    const histogramSeries = useRef<ISeriesApi<'Histogram'>>(null);
    const MA10Ref = useRef<ISeriesApi<'Line'>>(null);
    const MA30Ref = useRef<ISeriesApi<'Line'>>(null);
    const MA60Ref = useRef<ISeriesApi<'Line'>>(null);
    const histogramMA10Ref = useRef<ISeriesApi<'Line'>>(null);
    //second chart
    const secondCandlesSeries = useRef<ISeriesApi<'Candlestick'>>(null);
    const secondHistogramSeries = useRef<ISeriesApi<'Histogram'>>(null);
    const secondMA10Ref = useRef<ISeriesApi<'Line'>>(null);
    const secondMA30Ref = useRef<ISeriesApi<'Line'>>(null);
    const secondMA60Ref = useRef<ISeriesApi<'Line'>>(null);
    const secondHistogramMA10Ref = useRef<ISeriesApi<'Line'>>(null);

    const [lastBarPrice, setLastBarPrice] = useState({ open: 0, close: 0, high: 0, low: 0 } as BarPrices);
    const [barPrice, setBarPrice] = useState(lastBarPrice);
    const [lastMA, setLastMA] = useState(['']);
    const [MA, setMA] = useState(lastMA);
    const [lastVolume, setLastVolume] = useState('');
    const [volume, setVolume] = useState(lastVolume);
    const [diffChartWidth, setDiffChartWidth] = useState(0);

    const [selectedInterval, setSelectedInterval] = useState('');
    const [candlestickData, setCandlestickData] = useState([] as CandlestickData[]);
    const [histogramData, setHistogramData] = useState([] as HistogramData[]);
    const [freshData, setFreshData] = useState([] as any[]);
    const [freshIndex, setFreshIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    // const [leftSideRange, setLeftSideRange] = useState(0);
    const [delay, setDelay] = useState(false);
    const is2XL = useBreakpointValue({ base: false, xxl: false, '2xl': true })!;
    const isXl = useBreakpointValue({ base: false, xl: true, xxl: false, '2xl': false });
    const isXXl = useBreakpointValue({ base: false, xl: true, xxl: true, '2xl': false });
    const chartWidth = chartFullScreen
        ? width
        : isMobile
        ? width - 44
        : is2XL
        ? width - 910
        : isXXl
        ? width - 632
        : isXl
        ? width - 663
        : isLg
        ? width - 440
        : 642;
    const chartHeight = height > 920 ? 351 : height > 865 ? height - 560 : 300;
    const [isMouseInChart, setIsMouseInChart] = useState(false);

    const [totalCandlestickData, setTotalCandlestickData] = useState(candlestickData);
    const [totalHistogramData, setTotalHistogramData] = useState(histogramData);

    useEffect(() => {
        setTotalCandlestickData(candlestickData);
        setTotalHistogramData(histogramData);
    }, [candlestickData, histogramData]);
    useEffect(() => {
        if (pro.intervals.length > 0) {
            // initial interval set 15m
            if (pro.intervals.includes(iZiSwapKLinesRecordEnum.MINUTE_15)) {
                setSelectedInterval(iZiSwapKLinesRecordEnum.MINUTE_15);
            } else {
                setSelectedInterval(pro.intervals[0]);
            }
        } else {
            setSelectedInterval('');
        }
    }, [pro.intervals.length]);

    const handleCrosshairMove = useCallback(
        _.throttle((param: MouseEventParams) => {
            //set bar price
            if (param.time) {
                setIsMouseInChart(true);
                if (candlesSeries.current !== null && histogramSeries.current != null) {
                    const price = param.seriesPrices.get(candlesSeries.current) as BarPrices;
                    const histogramPrice = param.seriesPrices.get(histogramSeries.current) as BarPrice;
                    if (price !== undefined) {
                        setBarPrice({ open: price.open, close: price.close, high: price.high, low: price.low });
                        if (histogramPrice !== undefined) {
                            setVolume(formatNumber(histogramPrice, 2, 2, true));
                        }
                    }
                }
                //second chart
                if (secondCandlesSeries.current !== null && secondHistogramSeries.current != null) {
                    const price = param.seriesPrices.get(secondCandlesSeries.current) as BarPrices;
                    const histogramPrice = param.seriesPrices.get(secondHistogramSeries.current) as BarPrice;
                    if (price !== undefined) {
                        setBarPrice({ open: price.open, close: price.close, high: price.high, low: price.low });
                        if (histogramPrice !== undefined) {
                            setVolume(formatNumber(histogramPrice, 2, 2, true));
                        }
                    }
                }
            } else {
                setIsMouseInChart(false);
            }

            //set ma value
            if (param.time) {
                if (MA10Ref.current && MA30Ref.current && MA60Ref.current && histogramMA10Ref.current) {
                    const ma10Price = param.seriesPrices.get(MA10Ref.current) as BarPrice | undefined;
                    const ma30Price = param.seriesPrices.get(MA30Ref.current) as BarPrice | undefined;
                    const ma60Price = param.seriesPrices.get(MA60Ref.current) as BarPrice | undefined;
                    const histogramMA10Price = param.seriesPrices.get(histogramMA10Ref.current) as BarPrice | undefined;
                    if (ma10Price !== undefined && ma30Price !== undefined && ma60Price !== undefined && histogramMA10Price !== undefined) {
                        if (
                            ma10Price !== undefined &&
                            ma30Price !== undefined &&
                            ma60Price !== undefined &&
                            histogramMA10Price !== undefined
                        ) {
                            setMA([
                                formatNumber(ma10Price, 2, 2, true),
                                formatNumber(ma30Price, 2, 2, true),
                                formatNumber(ma60Price, 2, 2, true),
                                formatNumber(histogramMA10Price, 2, 2, true),
                            ]);
                        }
                    }
                }
                if (secondMA10Ref.current && secondMA30Ref.current && secondMA60Ref.current && secondHistogramMA10Ref.current) {
                    const ma10Price = param.seriesPrices.get(secondMA10Ref.current) as BarPrice | undefined;
                    const ma30Price = param.seriesPrices.get(secondMA30Ref.current) as BarPrice | undefined;
                    const ma60Price = param.seriesPrices.get(secondMA60Ref.current) as BarPrice | undefined;
                    const histogramMA10Price = param.seriesPrices.get(secondHistogramMA10Ref.current) as BarPrice | undefined;
                    if (ma10Price !== undefined && ma30Price !== undefined && ma60Price !== undefined && histogramMA10Price !== undefined) {
                        if (
                            ma10Price !== undefined &&
                            ma30Price !== undefined &&
                            ma60Price !== undefined &&
                            histogramMA10Price !== undefined
                        ) {
                            setMA([
                                formatNumber(ma10Price, 2, 2, true),
                                formatNumber(ma30Price, 2, 2, true),
                                formatNumber(ma60Price, 2, 2, true),
                                formatNumber(histogramMA10Price, 2, 2, true),
                            ]);
                        }
                    }
                }
            }
        }, 16),
        [candlestickData, histogramData]
    );

    useKeyPress('esc', () => {
        setChartFullScreen(false);
    });

    //TO DO : When sliding to the left boundary, get more historical values。
    //function: Judge whether to reach the left boundary
    // const isOutOfLeftRange = () => {
    //     const leftVisibleRange = chartRef.current && chartRef.current.timeScale().getVisibleRange()?.from;
    //     if (leftVisibleRange) {
    //         if (leftVisibleRange > leftSideRange) {
    //             return false;
    //         } else {
    //             return true;
    //         }
    //     } else {
    //         return true;
    //     }
    // };
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current && chartRef.current.timeScale().subscribeVisibleTimeRangeChange(syncHandler);
            histogramChartRef.current && histogramChartRef.current.timeScale().subscribeVisibleTimeRangeChange(syncHistogramHandler);
            histogramChartRef.current &&
                histogramChartRef.current.timeScale().subscribeSizeChange(() => {
                    if (histogramChartRef.current && chartRef.current) {
                        setDiffChartWidth(chartRef.current.priceScale().width() - histogramChartRef.current.priceScale().width());
                    }
                });
        }
    }, [chartRef.current]);

    function syncHandler() {
        const barSpacing1 = chartRef.current?.timeScale().options().barSpacing;
        const getVisibleRange1 = chartRef.current?.timeScale().getVisibleLogicalRange();

        const scrollPosition1 = chartRef.current && chartRef.current.timeScale().scrollPosition();
        if (scrollPosition1 && histogramChartRef.current && getVisibleRange1) {
            histogramChartRef.current.timeScale().applyOptions({ rightOffset: scrollPosition1, barSpacing: barSpacing1 });
            histogramChartRef.current.timeScale().setVisibleLogicalRange(getVisibleRange1);
        }
    }
    function syncHistogramHandler() {
        const histogramBarSpacing = histogramChartRef.current?.timeScale().options().barSpacing;
        const getHistogramVisibleRange = histogramChartRef.current?.timeScale().getVisibleLogicalRange();
        const scrollHistogramPosition = histogramChartRef.current && histogramChartRef.current.timeScale().scrollPosition();
        if (chartRef.current && scrollHistogramPosition && getHistogramVisibleRange) {
            chartRef.current.timeScale().applyOptions({ rightOffset: scrollHistogramPosition, barSpacing: histogramBarSpacing });
            chartRef.current.timeScale().setVisibleLogicalRange(getHistogramVisibleRange);
        }
    }
    const [tempCandlestickData, setTempCandlestickData] = useState([] as CandlestickData[]); // keep newest data

    useEffect(() => {
        if (!selectedInterval || pro.intervals.length === 0) {
            return;
        }
        setLoading(true);
        setCandlestickData([]);
        setHistogramData([]);
        const buildParam = (): RequestIziSwapKLinesRecord => {
            const minInterval =
                selectedInterval === '1m'
                    ? iZiSwapKLinesRecordEnum.MINUTE_1
                    : selectedInterval === '5m'
                    ? iZiSwapKLinesRecordEnum.MINUTE_5
                    : selectedInterval === '15m'
                    ? iZiSwapKLinesRecordEnum.MINUTE_15
                    : selectedInterval === '1h'
                    ? iZiSwapKLinesRecordEnum.HOUR_1
                    : selectedInterval === '4h'
                    ? iZiSwapKLinesRecordEnum.HOUR_4
                    : selectedInterval === 'D'
                    ? iZiSwapKLinesRecordEnum.DAY
                    : selectedInterval === 'W'
                    ? iZiSwapKLinesRecordEnum.WEEK
                    : iZiSwapKLinesRecordEnum.MONTH;
            const page_size = 1000;

            const startTime =
                minInterval === iZiSwapKLinesRecordEnum.MINUTE_1
                    ? moment().add(-page_size, 'minute').format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.MINUTE_5
                    ? moment()
                          .add(-page_size * 5, 'minute')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.MINUTE_15
                    ? moment()
                          .add(-page_size * 15, 'minute')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.HOUR_1
                    ? moment()
                          .add(-page_size * 1, 'hour')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.HOUR_4
                    ? moment()
                          .add(-page_size * 4, 'hour')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.DAY
                    ? moment()
                          .add(-page_size * 1, 'day')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.WEEK
                    ? moment()
                          .add(-page_size * 1, 'week')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.MONTH
                    ? moment().add(-page_size, 'month').format('YYYY-MM-DD HH:mm:ss')
                    : moment().add(-page_size, 'month').format('YYYY-MM-DD HH:mm:ss');

            return {
                identity: pro.pool,
                interval: minInterval as iZiSwapKLinesRecordEnum,
                page_size: page_size,
                // order_by: '-time',
                time_start: startTime,
                time_end: moment().format('YYYY-MM-DD HH:mm:ss'),
            } as RequestIziSwapKLinesRecord;
        };

        getIziSwapKLinesRecord(buildParam()).then((r) => {
            setLoading(false);
            if (!r.data.is_success) {
                return;
            }
            const originData = r.data.data.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));

            if (originData.length === 1) {
                const data = originData.map((item) => {
                    return pro.isReverseToken
                        ? {
                              timestamp: item.timestamp as Time,
                              open: 1 / Number(item.open),
                              high: 1 / Number(item.low),
                              low: 1 / Number(item.high),
                              close: 1 / Number(item.close),
                              volume: Number(item.volume),
                          }
                        : {
                              timestamp: item.timestamp as Time,
                              open: Number(item.open),
                              high: Number(item.high),
                              low: Number(item.low),
                              close: Number(item.close),
                              volume: Number(item.volume),
                          };
                });

                const result: any = [];
                data.forEach((item, index) => {
                    result.push({
                        time: item.timestamp as Time,
                        open: Number(item.open),
                        high: Number(item.high),
                        low: Number(item.low),
                        close: Number(item.close),
                        volume: Number(item.volume),
                    });
                });
                setTempCandlestickData(result);

                const histogramResult = [] as HistogramData[];
                data.forEach((item, index) => {
                    histogramResult.push({
                        time: Number(item.timestamp) as Time,
                        value: Number(item.volume),
                        color: colorTheme('#8FCA96', '#455D53'),
                    });
                });

                setCandlestickData(result);
                setHistogramData(histogramResult);
                return;
            }

            if (originData.length < 2) {
                // Because of the volume chart, at least two data are required ，and the first data will not be displayed
                setCandlestickData([]);
                setHistogramData([]);
                setBarPrice({ open: 0 as BarPrice, close: 0 as BarPrice, high: 0 as BarPrice, low: 0 as BarPrice });
                setVolume('n/a');
                setMA(['n/a', 'n/a', 'n/a', 'n/a']);
                setLastMA(['n/a', 'n/a', 'n/a', 'n/a']);
                return;
            }

            // setLeftSideRange(originData[1].timestamp);

            const data = originData.map((item) => {
                return pro.isReverseToken
                    ? {
                          timestamp: item.timestamp as Time,
                          open: 1 / Number(item.open),
                          high: 1 / Number(item.low),
                          low: 1 / Number(item.high),
                          close: 1 / Number(item.close),
                          volume: Number(item.volume),
                      }
                    : {
                          timestamp: item.timestamp as Time,
                          open: Number(item.open),
                          high: Number(item.high),
                          low: Number(item.low),
                          close: Number(item.close),
                          volume: Number(item.volume),
                      };
            });

            const result: any = [];
            data.forEach((item, index) => {
                if (index > 0) {
                    result.push({
                        time: item.timestamp as Time,
                        open: Number(item.open),
                        high: Number(item.high),
                        low: Number(item.low),
                        close: Number(item.close),
                        volume: Number(item.volume),
                    });
                }
            });
            setTempCandlestickData(result);

            const histogramResult = [] as HistogramData[];
            data.forEach((item, index) => {
                if (index > 0) {
                    histogramResult.push({
                        time: Number(item.timestamp) as Time,
                        value: Number(item.volume),
                        color:
                            Number(data[index].close) - Number(data[index - 1].close) > 0
                                ? colorTheme('#8FCA96', '#455D53')
                                : colorTheme('#DB6465', '#7B3944'),
                    });
                }
            });

            setCandlestickData(result);
            setHistogramData(histogramResult);
            setBarPrice({
                open: result[result.length - 1].open,
                close: result[result.length - 1].close,
                high: result[result.length - 1].high,
                low: result[result.length - 1].low,
            } as BarPrices);
            setLastBarPrice({
                open: result[result.length - 1].open,
                close: result[result.length - 1].close,
                high: result[result.length - 1].high,
                low: result[result.length - 1].low,
            } as BarPrices);
            setVolume(formatNumber(result[result.length - 1].volume, 2, 2, true));
            setLastVolume(formatNumber(result[result.length - 1].volume, 2, 2, true));

            const MA10 = calculateSMA(result, 10);
            const MA30 = calculateSMA(result, 30);
            const MA60 = calculateSMA(result, 60);
            const lastMA10 = MA10[MA10.length - 1];
            const lastMA30 = MA30[MA30.length - 1];
            const lastMA60 = MA60[MA60.length - 1];

            const volumeMA10 = calculateVolumeSMA(histogramResult, 10);
            const lastVolumeMA10 = volumeMA10[volumeMA10.length - 1];
            if (lastMA10 && lastMA30 && lastMA60 && lastVolumeMA10) {
                setLastMA([
                    formatNumber(lastMA10.value, 2, 2, true),
                    formatNumber(lastMA30.value, 2, 2, true),
                    formatNumber(lastMA60.value, 2, 2, true),
                    formatNumber(lastVolumeMA10.value, 2, 2, true),
                ]);
                setMA([
                    formatNumber(lastMA10.value, 2, 2, true),
                    formatNumber(lastMA30.value, 2, 2, true),
                    formatNumber(lastMA60.value, 2, 2, true),
                    formatNumber(lastVolumeMA10.value, 2, 2, true),
                ]);
            } else {
                setMA(['n/a', 'n/a', 'n/a', 'n/a']);
                setLastMA(['n/a', 'n/a', 'n/a', 'n/a']);
            }
            dispatch.pro.setPoolChange(false);
        });
    }, [colorMode, pro.isReverseToken, pro.pool, selectedInterval]);

    useEffect(() => {
        setFreshData([]);
        if (!selectedInterval || candlestickData.length === 0 || pro.intervals.length === 0) {
            return;
        }
        const buildParam = (): RequestIziSwapKLinesRecord => {
            const minInterval =
                selectedInterval === '1m'
                    ? iZiSwapKLinesRecordEnum.MINUTE_1
                    : selectedInterval === '5m'
                    ? iZiSwapKLinesRecordEnum.MINUTE_5
                    : selectedInterval === '15m'
                    ? iZiSwapKLinesRecordEnum.MINUTE_15
                    : selectedInterval === '1h'
                    ? iZiSwapKLinesRecordEnum.HOUR_1
                    : selectedInterval === '4h'
                    ? iZiSwapKLinesRecordEnum.HOUR_4
                    : selectedInterval === 'D'
                    ? iZiSwapKLinesRecordEnum.DAY
                    : selectedInterval === 'W'
                    ? iZiSwapKLinesRecordEnum.WEEK
                    : iZiSwapKLinesRecordEnum.MONTH;
            const page_size = 1000;
            const startTime =
                minInterval === iZiSwapKLinesRecordEnum.MINUTE_1
                    ? moment().add(-page_size, 'minute').format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.MINUTE_5
                    ? moment()
                          .add(-page_size * 5, 'minute')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.MINUTE_15
                    ? moment()
                          .add(-page_size * 15, 'minute')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.HOUR_1
                    ? moment()
                          .add(-page_size * 1, 'hour')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.HOUR_4
                    ? moment()
                          .add(-page_size * 4, 'hour')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.DAY
                    ? moment()
                          .add(-page_size * 1, 'day')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.WEEK
                    ? moment()
                          .add(-page_size * 1, 'week')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : minInterval === iZiSwapKLinesRecordEnum.MONTH
                    ? moment().add(-page_size, 'month').format('YYYY-MM-DD HH:mm:ss')
                    : moment().add(-page_size, 'month').format('YYYY-MM-DD HH:mm:ss');

            return {
                chain_id: chainId,
                identity: pro.pool,
                interval: minInterval as iZiSwapKLinesRecordEnum,
                page_size: page_size,
                // order_by: '-time',
                time_start: startTime,
                time_end: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
        };
        getIziSwapKLinesRecord(buildParam()).then((r) => {
            const originData = r.data.data.sort((a, b) => Number(a.timestamp) - Number(b.timestamp));

            const data = originData.map((item) => {
                return pro.isReverseToken
                    ? {
                          time: item.timestamp as Time,
                          open: 1 / Number(item.open),
                          high: 1 / Number(item.low),
                          low: 1 / Number(item.high),
                          close: 1 / Number(item.close),
                          volume: Number(item.volume),
                      }
                    : {
                          time: item.timestamp as Time,
                          open: Number(item.open),
                          high: Number(item.high),
                          low: Number(item.low),
                          close: Number(item.close),
                          volume: Number(item.volume),
                      };
            });

            if (data.length > 0 && tempCandlestickData.length > 0) {
                const lastDataIndex = data.findIndex((item) => item.time === tempCandlestickData[tempCandlestickData.length - 1].time);
                if (lastDataIndex !== tempCandlestickData.length - 1 && lastDataIndex !== -1) {
                    // new data come in
                    const new_array = [];
                    for (let i = lastDataIndex + 1; i < data.length; i++) {
                        new_array.push(data[i]);
                    }
                    setFreshIndex(lastDataIndex);
                    setFreshData(new_array);
                }
            }
            setTempCandlestickData(data);
        });
    }, [pro.isReverseToken, pro.pool, selectedInterval, delay, pro.isPoolChange]);
    useEffect(() => {
        if (freshData.length === 0) {
            return;
        }

        if (
            !freshData[0] ||
            !totalCandlestickData[totalCandlestickData.length - 1] ||
            !tempCandlestickData[freshIndex] ||
            freshData[0].time < totalCandlestickData[totalCandlestickData.length - 1].time
        ) {
            setFreshData([]);
            return;
        }
        setTotalCandlestickData(totalCandlestickData.concat(freshData));
        const tempTotalCandlestick = totalCandlestickData.concat(freshData);

        if (freshIndex > tempCandlestickData.length - 1) {
            return;
        }
        const temp = freshData.map((item) => {
            return {
                time: Number(item.time) as Time,
                value: Number(item.volume),
                color:
                    Number(tempCandlestickData[freshIndex].close) - Number(tempCandlestickData[freshIndex - 1].close) > 0
                        ? colorTheme('#8FCA96', '#455D53')
                        : colorTheme('#DB6465', '#7B3944'),
            };
        });
        setTotalHistogramData(totalHistogramData.concat(temp));

        const tempTotalHistogram = totalHistogramData.concat(temp);

        freshData.map((item, index) => {
            candlesSeries.current?.update({
                time: Number(item.time) as Time,
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
            });
            histogramSeries.current?.update({
                time: Number(item.time) as Time,
                value: Number(item.volume),
                color:
                    Number(tempCandlestickData[freshIndex].close) - Number(tempCandlestickData[freshIndex - 1].close) > 0
                        ? colorTheme('#8FCA96', '#455D53')
                        : colorTheme('#DB6465', '#7B3944'),
            });
            secondCandlesSeries.current?.update({
                time: Number(item.time) as Time,
                open: item.open,
                high: item.high,
                low: item.low,
                close: item.close,
            });
            secondHistogramSeries.current?.update({
                time: Number(item.time) as Time,
                value: Number(item.volume),
                color:
                    Number(tempCandlestickData[freshIndex].close) - Number(tempCandlestickData[freshIndex - 1].close) > 0
                        ? colorTheme('#8FCA96', '#455D53')
                        : colorTheme('#DB6465', '#7B3944'),
            });
            const sma10 = calculateSMA(tempTotalCandlestick, 10);
            const lastMA10 = sma10.find((i) => i.time === item.time);
            const sma30 = calculateSMA(tempTotalCandlestick, 30);
            const lastMA30 = sma30.find((i) => i.time === item.time);
            const sma60 = calculateSMA(tempTotalCandlestick, 60);
            const lastMA60 = sma60.find((i) => i.time === item.time);

            if (lastMA10) {
                MA10Ref.current?.update({ time: Number(lastMA10.time) as Time, value: lastMA10.value });
                secondMA10Ref.current?.update({ time: Number(lastMA10.time) as Time, value: lastMA10.value });
            }
            if (lastMA30) {
                MA30Ref.current?.update({ time: Number(lastMA30.time) as Time, value: lastMA30.value });
                secondMA30Ref.current?.update({ time: Number(lastMA30.time) as Time, value: lastMA30.value });
            }
            if (lastMA60) {
                MA60Ref.current?.update({ time: Number(lastMA60.time) as Time, value: lastMA60.value });
                secondMA60Ref.current?.update({ time: Number(lastMA60.time) as Time, value: lastMA60.value });
            }
            const volumeSma10 = calculateVolumeSMA(tempTotalHistogram, 10);
            const lastVolumeMA10 = volumeSma10.find((i) => i.time === item.time);
            if (lastVolumeMA10) {
                histogramMA10Ref.current?.update({ time: Number(lastVolumeMA10.time) as Time, value: lastVolumeMA10.value });
                secondHistogramMA10Ref.current?.update({ time: Number(lastVolumeMA10.time) as Time, value: lastVolumeMA10.value });
            }
        });

        setBarPrice({
            open: freshData[freshData.length - 1].open,
            close: freshData[freshData.length - 1].close,
            high: freshData[freshData.length - 1].high,
            low: freshData[freshData.length - 1].low,
        } as BarPrices);
        setLastBarPrice({
            open: freshData[freshData.length - 1].open,
            close: freshData[freshData.length - 1].close,
            high: freshData[freshData.length - 1].high,
            low: freshData[freshData.length - 1].low,
        } as BarPrices);
        setVolume(formatNumber(freshData[freshData.length - 1].volume, 2, 2, true));
        setLastVolume(formatNumber(freshData[freshData.length - 1].volume, 2, 2, true));
        const MA10 = calculateSMA(tempTotalCandlestick, 10);
        const MA30 = calculateSMA(tempTotalCandlestick, 30);
        const MA60 = calculateSMA(tempTotalCandlestick, 60);
        const lastMA10 = MA10[MA10.length - 1];
        const lastMA30 = MA30[MA30.length - 1];
        const lastMA60 = MA60[MA60.length - 1];
        const volumeMA10 = calculateVolumeSMA(tempTotalHistogram, 10);
        const lastVolumeMA10 = volumeMA10[volumeMA10.length - 1];
        if (lastMA10 && lastMA30 && lastMA60 && lastVolumeMA10) {
            setLastMA([
                formatNumber(lastMA10.value, 2, 2, true),
                formatNumber(lastMA30.value, 2, 2, true),
                formatNumber(lastMA60.value, 2, 2, true),
                formatNumber(lastVolumeMA10.value, 2, 2, true),
            ]);
            setMA([
                formatNumber(lastMA10.value, 2, 2, true),
                formatNumber(lastMA30.value, 2, 2, true),
                formatNumber(lastMA60.value, 2, 2, true),
                formatNumber(lastVolumeMA10.value, 2, 2, true),
            ]);
        } else {
            setMA(['n/a', 'n/a', 'n/a', 'n/a']);
            setLastMA(['n/a', 'n/a', 'n/a', 'n/a']);
        }

        setFreshData([]);
    }, [freshData.length, totalCandlestickData, totalHistogramData]);

    useEffect(() => {
        if (!isMouseInChart) {
            setBarPrice(lastBarPrice);
            setVolume(lastVolume);
            setMA(lastMA);
        }
    }, [isMouseInChart, lastMA, lastVolume]);

    useInterval(() => {
        setDelay(!delay);
    }, PRO_CONFIG.KLINE_AUTO_REFRESH_INTERVAL);

    const scaleButton = (text: string) => (
        <CustomButton
            className={noto_t3}
            h="20px"
            px="5px"
            bg={
                text === selectedInterval
                    ? colorTheme(
                          'linear-gradient(109.4deg, #EDDEFF 3.26%, rgba(221, 212, 232, 0.42) 107.7%)',
                          'linear-gradient(180deg, #645985 0%, #6F589E 100%)'
                      )
                    : ''
            }
            text={text}
            _hover={{ bg: text !== selectedInterval && colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#251B39') }}
            onClick={() => {
                setSelectedInterval(text);
            }}
        ></CustomButton>
    );

    const fullScreenStyle = {
        position: 'absolute',
        w: { width },
        h: { height },
        top: '0px',
        left: '0px',
        zIndex: 10,
    } as any;

    return (
        <Stack bg={themeColor} spacing="0px" pl={{ base: '0px', sm: '21px' }} borderRadius="6px" {...rest}>
            <HStack
                w="100%"
                h={chartFullScreen ? (isLg ? '70px' : '60px') : '33px'}
                opacity={chartFullScreen ? 1 : '0.8'}
                pr="21px"
                py="7px"
                bg={chartFullScreen ? themeColor : ''}
                style={
                    chartFullScreen
                        ? {
                              position: 'absolute',
                              top: '0px',
                              left: '50px',

                              zIndex: 13,
                          }
                        : undefined
                }
            >
                {isMobile ? (
                    <HStack spacing="0px">
                        <Text className={noto_t3} color="#B3B3B3">
                            Time
                        </Text>

                        <Menu variant="unstyled">
                            <MenuButton
                                w="55px"
                                className={noto_t3_bold}
                                color="#7F4AFE"
                                transition="all 0.2s"
                                borderRadius="md"
                                _focus={{}}
                                _active={{}}
                                _hover={{}}
                            >
                                {selectedInterval} <ChevronDownIcon />
                            </MenuButton>
                            <Portal>
                                <MenuList minW="50px" zIndex={9999} position="relative">
                                    {pro.intervals.length > 0 &&
                                        pro.intervals.map((item: string, index: number) => {
                                            return (
                                                <MenuItem
                                                    className={noto_t3}
                                                    key={index}
                                                    w="50px"
                                                    color="#B3B3B3"
                                                    _focus={{
                                                        bg: 'linear-gradient(109.4deg, #EDDEFF 3.26%, rgba(221, 212, 232, 0.42) 107.7%)',
                                                    }}
                                                    onClick={() => {
                                                        setSelectedInterval(item);
                                                    }}
                                                >
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                </MenuList>
                            </Portal>
                        </Menu>

                        <Popover isOpen={isOpen} onClose={onClose}>
                            <PopoverTrigger>
                                <Center boxSize="14px" bg={colorTheme('#EEF0F3', '#3A2F53')} borderRadius="2px" onClick={onToggle}>
                                    <Image
                                        boxSize="10px"
                                        src={
                                            isOpen
                                                ? '/assets/pro/chart/openStatus.svg'
                                                : colorTheme('/assets/pro/chart/status.svg', '/assets/pro/chart/darkStatus.svg')
                                        }
                                        fallbackSrc={
                                            isOpen
                                                ? '/assets/pro/chart/openStatus.svg'
                                                : colorTheme('/assets/pro/chart/status.svg', '/assets/pro/chart/darkStatus.svg')
                                        }
                                    ></Image>
                                </Center>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent w="171px" p="10px" opacity={1} bg="#ffffff">
                                    <Stack className={noto_t3}>
                                        <HStack>
                                            <HStack>
                                                <Text color="#767676">O</Text>
                                                <Text color="#47C87A">{formatNumber(barPrice.open, 2, 2, true)}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text color="#767676">H</Text>
                                                <Text color="#47C87A">{formatNumber(barPrice.high, 2, 2, true)}</Text>
                                            </HStack>
                                        </HStack>
                                        <HStack>
                                            <HStack>
                                                <Text color="#767676">L</Text>
                                                <Text color="#47C87A">{formatNumber(barPrice.low, 2, 2, true)}</Text>
                                            </HStack>
                                            <HStack>
                                                <Text color="#767676">C</Text>
                                                <Text color="#47C87A">{formatNumber(barPrice.close, 2, 2, true)}</Text>
                                            </HStack>
                                        </HStack>
                                        <Text w="80px" color="#767676">
                                            MA(10,30,60)
                                        </Text>
                                        {MA.length >= 3 && (
                                            <Stack>
                                                <Text w="110px" color="#D17A13">
                                                    MA10:
                                                    <Text as="span" ml="5px">
                                                        {MA[0]}
                                                    </Text>
                                                </Text>
                                                <Text w="110px" color="#3EAEFF">
                                                    MA30:
                                                    <Text as="span" ml="5px">
                                                        {MA[1]}
                                                    </Text>
                                                </Text>
                                                <Text w="110px" color="#EC35D8">
                                                    MA60:
                                                    <Text as="span" ml="5px">
                                                        {MA[2]}
                                                    </Text>
                                                </Text>
                                            </Stack>
                                        )}
                                    </Stack>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    </HStack>
                ) : (
                    pro.intervals.length > 0 &&
                    pro.intervals.map((item: string, index: number) => {
                        return <Stack key={index}>{scaleButton(item)}</Stack>;
                    })
                )}
                {!isMobile && (
                    <Stack
                        p="3px"
                        ml="auto !important"
                        borderRadius="2px"
                        cursor="pointer"
                        _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                        onClick={() => {
                            setChartFullScreen(!chartFullScreen);
                        }}
                        style={chartFullScreen ? { position: 'absolute', right: '150px' } : undefined}
                    >
                        <Image
                            boxSize={chartFullScreen ? '20px' : '10px'}
                            src={chartFullScreen ? '/assets/pro/closeFullScreen.svg' : '/assets/pro/fullScreen.svg'}
                            fallbackSrc={chartFullScreen ? '/assets/pro/closeFullScreen.svg' : '/assets/pro/fullScreen.svg'}
                        ></Image>
                    </Stack>
                )}
            </HStack>
            <Divider mb="8px !important" style={chartFullScreen ? { position: 'absolute', top: '60px', zIndex: 13 } : undefined}></Divider>
            <Stack spacing="0px" cursor="crosshair" style={chartFullScreen ? fullScreenStyle : undefined}>
                <Stack>
                    {!isMobile && (
                        <ChartInfo
                            open={formatNumber(barPrice.open, 2, 2, true)}
                            high={formatNumber(barPrice.high, 2, 2, true)}
                            low={formatNumber(barPrice.low, 2, 2, true)}
                            close={formatNumber(barPrice.close, 2, 2, true)}
                            MA10={MA[0]}
                            MA30={MA[1]}
                            MA60={MA[2]}
                            chartFullScreen={chartFullScreen}
                        ></ChartInfo>
                    )}
                </Stack>
                <Stack
                    w={chartWidth}
                    style={chartFullScreen ? { top: '60px', position: 'absolute', backgroundColor: themeColor } : undefined}
                    spacing="0px"
                    overflow="hidden"
                >
                    {loading ? (
                        <Loading
                            w={chartWidth}
                            h={chartFullScreen ? height - 260 : chartHeight}
                            variant={LoadingEnum.purple}
                            justifyContent="center"
                            imgProps={{ boxSize: '16px' }}
                            textProps={{ fontSize: '14px' }}
                        ></Loading>
                    ) : (
                        <CandleChart
                            chartWidth={chartWidth}
                            height={chartFullScreen ? height - 260 : chartHeight}
                            handleCrosshairMove={handleCrosshairMove}
                            chartRef={chartRef}
                            series={candlesSeries}
                            MA10Ref={MA10Ref}
                            MA30Ref={MA30Ref}
                            MA60Ref={MA60Ref}
                            data={candlestickData}
                            histogramData={histogramData}
                            selectedInterval={selectedInterval}
                            chartFullScreen={chartFullScreen}
                            themeColor={themeColor}
                            histogramSeries={histogramSeries}
                            histogramMA10Ref={histogramMA10Ref}
                            diffChartWidth={diffChartWidth}
                            visible={VisibleEnum.Candlestick}
                        ></CandleChart>
                    )}
                    <Divider
                        border="0.6px solid #D9D9E7"
                        mb="5px !important"
                        style={chartFullScreen ? { bottom: '195px', position: 'absolute' } : undefined}
                        zIndex="11"
                    ></Divider>

                    <HistogramInfo volume={volume} MA10={MA[3]} chartFullScreen={chartFullScreen}></HistogramInfo>

                    {loading ? (
                        <Loading
                            w={chartWidth}
                            h={chartFullScreen ? 200 : 100}
                            variant={LoadingEnum.purple}
                            justifyContent="center"
                            imgProps={{ boxSize: '16px' }}
                            textProps={{ fontSize: '14px' }}
                        ></Loading>
                    ) : (
                        <CandleChart
                            chartWidth={chartWidth}
                            height={chartFullScreen ? 200 : 100}
                            handleCrosshairMove={handleCrosshairMove}
                            chartRef={histogramChartRef}
                            series={secondCandlesSeries}
                            MA10Ref={secondMA10Ref}
                            MA30Ref={secondMA30Ref}
                            MA60Ref={secondMA60Ref}
                            data={candlestickData}
                            histogramData={histogramData}
                            selectedInterval={selectedInterval}
                            chartFullScreen={chartFullScreen}
                            themeColor={themeColor}
                            histogramSeries={secondHistogramSeries}
                            histogramMA10Ref={secondHistogramMA10Ref}
                            diffChartWidth={diffChartWidth}
                            visible={VisibleEnum.Histogram}
                        ></CandleChart>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
};
