import { useSelector } from 'react-redux';
import { Box, HStack, useColorMode } from '@chakra-ui/react';

import { links } from '../../../config/links';
import { RootState } from '../../../state/store';
import { formatNumber } from '../../../utils/tokenMath';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import WalletButton from '../../../components/Buttons/WalletButton/WalletButton';
import HomeJumpButton from '../../../components/Buttons/HomeJumpButton/HomeJumpButton';
import BuyiZiButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/BuyiZiButton/BuyiZiButton';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';
import IziLogo from '../../../assets/logo/IziLogo';
import { useHistory } from 'react-router-dom';
import useCustomTheme from '../../../hooks/useCustomTheme';
import CssFilterConverter from 'css-filter-converter';

export const MobileHeader: React.FC = () => {
    const { token } = useSelector((state: RootState) => state);
    const { chainId } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const history = useHistory();
    const { THEME, THEME_BG, THEME_BUTTON_FONT } = useCustomTheme();

    return (
        <>
            <HStack w="100%" h="67px" direction="row" px="17px" bg={THEME_BG[chainId]}>
                <IziLogo
                    style={{ width: '30px', height: '30px' }}
                    onClick={() => {
                        history.push('/home');
                    }}
                ></IziLogo>

                <HStack ml="auto !important" spacing="10px">
                    <BuyiZiButton
                        expand={true}
                        tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                        onClick={() => {
                            window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                        }}
                        imageProps={{ filter: CssFilterConverter.hexToFilter(THEME_BUTTON_FONT[chainId]).color! }}
                        bg={THEME[chainId]}
                        textProps={{ color: THEME_BUTTON_FONT[chainId] }}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                    />
                    <WalletButton />
                </HStack>
            </HStack>
            <Box
                w="100%"
                h="1px"
                bg={colorTheme(
                    '#F5EAFB',
                    'linear-gradient(270deg, rgba(60, 38, 81, 0.57) 3.17%, #453351 47.6%, rgba(65, 33, 85, 0.57) 101.54%)'
                )}
            ></Box>
        </>
    );
};
