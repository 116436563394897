import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { BoxProps, useColorMode, HStack, VStack, Text, Image, Stack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy } from '../../../../../style';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { RootState } from '../../../../../state/store';
import { formatNumber } from '../../../../../utils/tokenMath';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { getFeeEarnedFromiZiLiquidity } from '../../../../../net/contractCall/uniV3Interface/getTokenOwned';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ModalHead } from './ModalHead';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../state/store';
import { LiquidityEntry, PoolEntryState } from '../../../../../state/models/farm/iZiSwap/fixRange/types';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useLiquidityManagerContract } from '../../../../../utils/contractFactory';
import { BigNumber } from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../../config/chains';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { buildSendingParams } from '../../../../../utils/contractHelpers';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';

type PositionDetailModalProps = {
    isOpen: boolean | any;
    onClose: any;
    entry: PoolEntryState;
    liquidity: LiquidityEntry;
} & BoxProps;

export const PositionDetailModal: React.FC<PositionDetailModalProps> = (props) => {
    const { isOpen, onClose, entry, liquidity: position, ...rest } = props;

    const { account, chainId, web3 } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { token } = useSelector((state: RootState) => state);

    const liquidityManagerContract = useLiquidityManagerContract(chainId, web3);
    const [tokenAGainDecimal, setTokenAGainDecimal] = useState(0);
    const [tokenBGainDecimal, setTokenBGainDecimal] = useState(0);

    const [tokenAGainAmount, setTokenAGainAmount] = useState('0');
    const [tokenBGainAmount, setTokenBGainAmount] = useState('0');

    const tokenADecimal = position.amountADecimal;
    const tokenBDecimal = position.amountBDecimal;

    useEffect(() => {
        getFeeEarnedFromiZiLiquidity(liquidityManagerContract, position.nftId).then((r: { amountX: string; amountY: string }) => {
            console.log('tokenA: ', entry.meta.tokenA.symbol, ' ', r.amountX);
            console.log('tokenB: ', entry.meta.tokenB.symbol, ' ', r.amountY);
            setTokenAGainDecimal((parseFloat(r.amountX) / 10 ** entry.meta.tokenA.decimal) * (1 - (entry.meta.feeCharged ?? 0)));
            setTokenBGainDecimal((parseFloat(r.amountY) / 10 ** entry.meta.tokenB.decimal) * (1 - (entry.meta.feeCharged ?? 0)));
            setTokenAGainAmount(r.amountX);
            setTokenBGainAmount(r.amountY);
        });
    }, [position, liquidityManagerContract, entry, setTokenAGainDecimal, setTokenBGainDecimal]);

    const tokenAValue = tokenADecimal * getSafeTokenPrice(token, entry.meta.tokenA.symbol);
    const tokenBValue = tokenBDecimal * getSafeTokenPrice(token, entry.meta.tokenB.symbol);
    const tokenAGainValue = tokenAGainDecimal * getSafeTokenPrice(token, entry.meta.tokenA.symbol);
    const tokenBGainValue = tokenBGainDecimal * getSafeTokenPrice(token, entry.meta.tokenB.symbol);
    const toast = useCustomToast();
    const WithdrawButton = (
        <CustomButton
            variant="orange"
            text={t('Withdraw')}
            mb="2px"
            w={{ base: '100%', sm: '272px' }}
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const nftId = position?.nftId;
                if (!nftId || !account || !liquidityManagerContract) {
                    return;
                }
                const multicallData = [] as string[];
                console.log(position.liquidity);
                if (new BigNumber(position.liquidity).gt(0)) {
                    multicallData.push(
                        liquidityManagerContract.methods.decLiquidity(nftId, position.liquidity, '0', '0', '0xffffffff').encodeABI()
                    );
                }
                multicallData.push(
                    liquidityManagerContract.methods
                        .collect(account, nftId, '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF', '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF')
                        .encodeABI()
                );
                multicallData.push(liquidityManagerContract.methods.burn(nftId).encodeABI());
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                liquidityManagerContract.methods
                    .multicall(multicallData)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        ) as any
                    )
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Ongoing', undefined, toastLink);
                    })
                    .then((e: any) => {
                        toast('success', 'Withdraw successfully', undefined, toastLink);
                        console.log('eee: ', e);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }}
        />
    );

    const CollectButton = (
        <CustomButton
            variant="primary2"
            text={t('Collect')}
            mb="2px"
            w="272px"
            h="50px"
            marginLeft="30px"
            onClick={() => {
                const nftId = position?.nftId;
                if (!nftId || !liquidityManagerContract || !account) {
                    return;
                }
                if (tokenAGainAmount === '0' && tokenBGainAmount === '0') {
                    return;
                }
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                liquidityManagerContract.methods
                    .collect(account, nftId, tokenAGainAmount, tokenBGainAmount)
                    .send(
                        buildSendingParams(
                            chainId,
                            {
                                from: account,
                                maxFeePerGas: gasPrice,
                            },
                            gasPrice
                        ) as any
                    )
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Ongoing', undefined, toastLink);
                    })
                    .then((e: any) => {
                        toast('success', 'Collect successfully', undefined, toastLink);
                        console.log('eee: ', e);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }}
        />
    );

    const tokenIconBlock = (token: TokenInfoFormatted, amount: any, value: any, key?: any) => (
        <Stack w="100%" justifyContent="space-between" key={key ? key : 'unset'} direction={{ base: 'column', sm: 'row' }}>
            <HStack>
                <Image borderRadius="9px" w="18px" h="18px" src={token.icon} />
                <Text className={i_text_copy_bold} fontSize="16px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                    {token.symbol}
                </Text>
            </HStack>
            <VStack alignItems={{ base: 'start', sm: 'end' }} spacing="0px">
                <Text className={i_text_copy} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                    {amount}
                </Text>
                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.300', 'tertiary.300')}>
                    ~ {value} USD
                </Text>
            </VStack>
        </Stack>
    );

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '73%', sm: '744px' }}
            h={{ base: 'unset', sm: '610px' }}
            title={t('NFT Status')}
            {...rest}
        >
            <ModalHead entry={entry} liquidity={position} dispatch={dispatch.farmFixRangeiZi} />

            <Stack w="100%" spacing="16px" mt="30px" direction={{ base: 'column', sm: 'row' }}>
                <Card variant="deep" w={{ base: '100%', sm: '328px' }} pb="20px">
                    <VStack
                        w="100%"
                        p={{ base: '30px', sm: '30px 46px 14px 46px' }}
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start" spacing="20px">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                {t('Token in pools')}
                            </Text>
                            {tokenIconBlock(entry.meta.tokenA, formatNumber(tokenADecimal, 2, 4), formatNumber(tokenAValue, 2, 4), 1)}
                            {tokenIconBlock(entry.meta.tokenB, formatNumber(tokenBDecimal, 2, 4), formatNumber(tokenBValue, 2, 4), 2)}
                        </VStack>
                        {!position.isStaked && WithdrawButton}
                    </VStack>
                </Card>

                {(entry.meta.feeCharged ?? 0) < 1 && (
                    <Card variant="deep" w="328px" pb="20px">
                        <VStack
                            w="100%"
                            p="20px 46px 14px 46px"
                            spacing="30px"
                            alignItems="center"
                            bg={colorTheme('undefined', 'tertiary.800')}
                        >
                            <VStack w="100%" alignItems="start" spacing="20px">
                                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                    {t('Fee Gains')}
                                </Text>
                                {tokenIconBlock(
                                    entry.meta.tokenA,
                                    formatNumber(tokenAGainDecimal > 1e-7 ? tokenAGainDecimal : 0, 2, 4),
                                    formatNumber(tokenAGainValue > 1e-7 ? tokenAGainValue : 0, 2, 4),
                                    1
                                )}
                                {tokenIconBlock(
                                    entry.meta.tokenB,
                                    formatNumber(tokenBGainDecimal > 1e-7 ? tokenBGainDecimal : 0, 2, 4),
                                    formatNumber(tokenBGainValue > 1e-7 ? tokenBGainValue : 0, 2, 4),
                                    2
                                )}
                            </VStack>
                            {!position.isStaked && CollectButton}
                        </VStack>
                    </Card>
                )}
            </Stack>
        </Modal>
    );
};
