import { Box, Image } from '@chakra-ui/react';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import placeholder from '../../../assets/placeholder.png';
import './style.css';
export interface TokenIconsProps {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    initialToggle?: boolean;
    chainIcon?: string;
    type?: string;
}

export const TokenIcons: React.FC<TokenIconsProps> = (props) => {
    const { tokenA, tokenB, initialToggle, chainIcon, type } = props;
    return type === 'small' ? (
        <Box className="imageRender" key="CoinIcons" position="relative" w="40px" h="40px" flexShrink={0}>
            <Image
                position="absolute"
                top={{ base: '7px', xxl: '0' }}
                left={{ base: '7px', xxl: '0' }}
                borderRadius="5px"
                h={{ base: '16px', xxl: '25px' }}
                w={{ base: '16px', xxl: '25px' }}
                flexShrink={0}
                src={(initialToggle ? tokenB : tokenA)?.icon}
                fallbackSrc={placeholder}
            />

            <Image
                position="absolute"
                bottom={{ base: '7px', xxl: '0' }}
                right={{ base: '7px', xxl: '0' }}
                borderRadius="5px"
                h={{ base: '20px', xxl: '30px' }}
                w={{ base: '20px', xxl: '30px' }}
                flexShrink={0}
                src={(initialToggle ? tokenA : tokenB)?.icon}
                fallbackSrc={placeholder}
            />
            {chainIcon && (
                <Image
                    position="absolute"
                    top={{ base: '0px', xxl: '-3px' }}
                    right={{ base: '0px', xxl: '-3px' }}
                    borderRadius="6px"
                    h="13px"
                    w="13px"
                    flexShrink={0}
                    src={chainIcon}
                    fallbackSrc={placeholder}
                />
            )}
        </Box>
    ) : (
        <Box className="imageRender" key="CoinIcons" position="relative" w="32px" h="32px" flexShrink={0}>
            <Image
                position="absolute"
                top="0"
                left="0"
                borderRadius="5px"
                h="20px"
                w="20px"
                flexShrink={0}
                src={(initialToggle ? tokenB : tokenA)?.icon}
                fallbackSrc={placeholder}
            />

            <Image
                position="absolute"
                bottom="0"
                right="0"
                borderRadius="5px"
                h="24px"
                w="24px"
                flexShrink={0}
                src={(initialToggle ? tokenA : tokenB)?.icon}
                fallbackSrc={placeholder}
            />
            {chainIcon && (
                <Image
                    position="absolute"
                    top="-3px"
                    right="-3px"
                    borderRadius="6px"
                    h="13px"
                    w="13px"
                    flexShrink={0}
                    src={chainIcon}
                    fallbackSrc={placeholder}
                />
            )}
        </Box>
    );
};
