import { Contract } from 'web3-eth-contract';
import { ChainId } from '../../../../types/mod';
import { buildSendingParams } from '../../../../utils/contractHelpers';

export const withdraw = (
    chainId: ChainId,
    contract: Contract,
    account: string,
    tokenId: number,
    gas: string,
    gasPrice: string | number
): any => {
    return contract.methods.withdraw(tokenId, false).send(
        buildSendingParams(
            chainId,
            {
                from: account,
                gas,
                maxFeePerGas: gasPrice,
            },
            gasPrice
        )
    );
};

export const estimateWithdrawGas = (contract: Contract, account: string, tokenId: number): any => {
    return contract.methods.withdraw(tokenId, false).estimateGas({ from: account });
};
