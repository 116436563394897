import { Contract } from 'web3-eth-contract'
import Web3 from "web3";
import { TokenInfoFormatted } from "../../../../hooks/useTokenListFormatted";
import { ChainId } from "../../../../types/mod";
import { BasePathQueryPlugin, BasePreQueryPlugin } from "./BaseDexPlugin";
import { getPathQueryPlugins, getPreQueryPlugins, SwapTag } from "./config";
import { CallingProperty, DagNode, Path, PathQueryCalling, PathQueryResult, PreQueryResult, SwapDirection } from "./utils";
import BigNumber from 'bignumber.js';

function checkNodeCanVisit(dagNode: DagNode, visited: number): boolean {
    if (!dagNode.preIdx) {
        return true
    }
    const maxIdx = Math.max(...dagNode.preIdx)
    return maxIdx < visited
}

function checkFinish(dagNodes: DagNode[][], visited: number[]): boolean {
    for (let i = 0; i < dagNodes.length; i ++) {
        if (visited[i] < dagNodes[i].length) {
            return false
        }
    }
    return true
}

async function multiQuery(multicall: Contract, calling: string[], targetAddress: string[]): Promise<{successes: boolean[], results: string[]}> {
    const result = await multicall.methods.multicall(targetAddress, calling).call()
    return {
        successes: result.successes,
        results: result.results
    }
}


export const doPreQuery = async (
    swapTags: SwapTag[], 
    preQueryResult: PreQueryResult[],
    chainId: ChainId,
    web3: Web3,
    multicall: Contract,
    tokenIn: TokenInfoFormatted,
    tokenOut: TokenInfoFormatted
): Promise<PreQueryResult[]> => {
    
    const preQueryPlugins = getPreQueryPlugins(swapTags, preQueryResult, chainId, web3)

    const dagNodes: DagNode[][] = []

    const visited: number[] = []
    const currentNum: number[] = []

    for (const plugin of preQueryPlugins) {
        const nodes = plugin?.getPreQueryDag(tokenIn, tokenOut) ?? []
        dagNodes.push(nodes)
        visited.push(0)
        currentNum.push(0)
    }

    while (!checkFinish(dagNodes, visited)) {
        for (let i = 0; i < dagNodes.length; i ++) {
            let num = 0
            while (num + visited[i] < dagNodes[i].length) {
                if (!checkNodeCanVisit(dagNodes[i][num + visited[i]], visited[i])) {
                    break
                } else {
                    num ++
                }
            }
            currentNum[i] = num
        }

        const currentNodes = [] as DagNode[]

        for (let i = 0; i < dagNodes.length; i ++) {
            for (let j = 0; j < currentNum[i]; j ++) {
                const currentNode = dagNodes[i][visited[i] + j]
                if (!currentNode.calling || !currentNode.targetAddress) {
                    const query = currentNode.getCallingAndTargetAddress!()
                    if (query) {
                        currentNode.calling = query.calling
                        currentNode.targetAddress = query.targetAddress
                        currentNodes.push(currentNode)
                    }
                } else {
                    currentNodes.push(currentNode)
                }
            }
        }

        const targetAddress = currentNodes.map((n)=>n.targetAddress) as string[]
        const calling = currentNodes.map((n)=>n.calling) as string[]
        const {successes, results} = await multiQuery(multicall, calling, targetAddress)


        for (let i = 0; i < successes.length; i ++) {
            if (!successes[i]) {
                continue
            }
            currentNodes[i].parseCallingResponse(results[i])
        }

        for (let i = 0; i < visited.length; i ++) {
            visited[i] += currentNum[i]
        }
    }
    
    const newPreQueryResults = preQueryPlugins.map((plugin: BasePreQueryPlugin)=>plugin?.getQueryResult())

    return newPreQueryResults
}

function checkNewPathQueryBetter(
    oldPathQuery: PathQueryResult, 
    newPathQuery: PathQueryResult,
    direction: SwapDirection
): boolean {
    if (!newPathQuery) {
        return false
    }
    if (!oldPathQuery) {
        return true
    }
    let scale = 1
    if (newPathQuery.swapTag === SwapTag.iZiSwap && oldPathQuery.swapTag !== SwapTag.iZiSwap) {
        scale = 1.015
    }
    if (newPathQuery.swapTag !== SwapTag.iZiSwap && oldPathQuery.swapTag === SwapTag.iZiSwap) {
        scale = 0.985
    }
    const newAmount = new BigNumber(newPathQuery.amount)
    const oldAmount = oldPathQuery.amount
    const better = (direction === SwapDirection.ExactIn) ? newAmount.times(scale).gt(oldAmount) : newAmount.div(scale).lt(oldAmount)
    return better
}

async function _doPathQuery(
    multicall: Contract, 
    callings: PathQueryCalling[],
    callingPluginIdx: number[],
    callingPath: Path[],
    pathQueryPlugins: BasePathQueryPlugin[],
    swapTags: SwapTag[],
    direction: SwapDirection,
    amount: string,
    batchSize: number
): Promise<PathQueryResult> {

    let finalPathQueryResult = undefined as unknown as PathQueryResult
    for (let i = 0; i < callings.length; i += batchSize) {
        const end = Math.min(i + batchSize, callings.length)
        const len = end - i
        const batchCallings = callings.slice(i, end)
        const data = batchCallings.map((e)=>e.calling)
        const contracts = batchCallings.map((e)=>e.targetAddress)
        const {successes, results} = await multiQuery(multicall, data, contracts)
        for (let j = 0; j < len; j ++) {
            if (!successes[j]) {
                continue
            }
            const idx = i + j
            const plugin = pathQueryPlugins[callingPluginIdx[idx]]
            const path = callingPath[idx]
            const pathQueryResult = plugin.parseCallingResponse(path, direction, amount, results[j])

            if (!pathQueryResult.noSufficientLiquidity) {
                pathQueryResult.swapTag = swapTags[callingPluginIdx[idx]]
                if (checkNewPathQueryBetter(finalPathQueryResult, pathQueryResult, direction)) {
                    finalPathQueryResult = {...pathQueryResult}
                    finalPathQueryResult.path = path
                }
            }
        }
    }
    return finalPathQueryResult
}

export const doPathQuery = async (
    swapTags: SwapTag[], 
    preQueryResult: PreQueryResult[],
    config: {[swapTag: string]: any},
    chainId: ChainId,
    web3: Web3,
    multicall: Contract,
    tokenIn: TokenInfoFormatted,
    tokenOut: TokenInfoFormatted,
    direction: SwapDirection,
    amount: string,
    longBatchSize: number = 20,
    shortBatchSize: number = 20
): Promise<PathQueryResult> => {

    const pathQueryPlugins = getPathQueryPlugins(swapTags, preQueryResult, config, chainId, web3)
    let finalPathQueryResult = undefined as unknown as PathQueryResult

    const longCallingPluginIdx = [] as number[]
    const shortCallingPluginIdx = [] as number[]

    const longCalling = [] as PathQueryCalling[]
    const shortCalling = [] as PathQueryCalling[]

    const longCallingPath = [] as Path[]
    const shortCallingPath = [] as Path[]

    for (let i = 0; i < swapTags.length; i ++) {
        const plugin = pathQueryPlugins[i]
        const pathQueryList = plugin.getPathQuery(tokenIn, tokenOut, direction, amount)
        for (const pathQuery of pathQueryList) {
            if (pathQuery.path.tokenChain[0].symbol !== tokenIn.symbol) {
                continue
            }
            if (pathQuery.path.tokenChain[pathQuery.path.tokenChain.length - 1].symbol !== tokenOut.symbol) {
                continue
            }
            if (!pathQuery.pathQueryResult) {
                const pathQueryCalling = pathQuery.pathQueryCalling
                if (pathQueryCalling?.callingProperty === CallingProperty.Short) {
                    shortCalling.push(pathQueryCalling)
                    shortCallingPluginIdx.push(i)
                    shortCallingPath.push(pathQuery.path)
                } else if (pathQueryCalling?.callingProperty === CallingProperty.Long) {
                    longCalling.push(pathQueryCalling)
                    longCallingPluginIdx.push(i)
                    longCallingPath.push(pathQuery.path)
                }
            } else if (!pathQuery.pathQueryResult.noSufficientLiquidity) {
                pathQuery.pathQueryResult.swapTag = swapTags[i]
                if (checkNewPathQueryBetter(finalPathQueryResult, pathQuery.pathQueryResult, direction)) {
                    finalPathQueryResult = {...pathQuery.pathQueryResult}
                    finalPathQueryResult.path = pathQuery.path
                }
            }
        }
    }
    
    const shortPathQueryResult = await _doPathQuery(
        multicall, shortCalling, shortCallingPluginIdx, 
        shortCallingPath, pathQueryPlugins, swapTags, direction, amount, shortBatchSize
    )

    const longPathQueryResult = await _doPathQuery(
        multicall, longCalling, longCallingPluginIdx, 
        longCallingPath, pathQueryPlugins, swapTags, direction, amount, longBatchSize
    )

    if (checkNewPathQueryBetter(finalPathQueryResult, shortPathQueryResult, direction)) {
        finalPathQueryResult = {...shortPathQueryResult}
        finalPathQueryResult.path = shortPathQueryResult.path
    }
    if (checkNewPathQueryBetter(finalPathQueryResult, longPathQueryResult, direction)) {
        finalPathQueryResult = {...longPathQueryResult}
        finalPathQueryResult.path = longPathQueryResult.path
    }

    if (finalPathQueryResult) {
        for (let i = 0; i < pathQueryPlugins.length; i ++) {
            if (swapTags[i] === finalPathQueryResult.swapTag) {
                finalPathQueryResult.swapPathQueryPlugin = pathQueryPlugins[i]
            }
        }
    }

    return finalPathQueryResult
}