import { BoxProps, HStack, Image, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { links } from '../../../../config/links';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_h5, i_text_copy } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
type LegacyWarningProps = {
    content?: string;
    target?: string;
    buttonText?: string;
} & BoxProps;
const LegacyWarning: React.FC<LegacyWarningProps> = (props) => {
    const { content, target, buttonText, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return (
        <Stack
            w="100%"
            direction={{ base: 'column', xxl: 'row' }}
            bg={colorTheme('#FFE7E7', '#582835')}
            px={{ base: '18px', xl: '22px' }}
            py="15px"
            justifyContent="space-between"
            borderRadius="7px"
            mt="7px"
            spacing={{ base: '8px', xxl: '20px' }}
            {...rest}
        >
            <HStack spacing="16px">
                <Image
                    w="26px"
                    h="23px"
                    src={colorTheme('/assets/liquidity/warning.svg', '/assets/liquidity/darkWarning.svg')}
                    fallbackSrc="/assets/liquidity/warning.svg"
                ></Image>
                <Stack className={i_text_copy} color={colorTheme('#FF2626', '#FFA8A8')} spacing="2px">
                    <Text>
                        {content
                            ? content
                            : t(
                                  'The old contract has been upgraded with optimizations for gas fees and limit order functionality. The old contract will be deprecated. If your previously added liquidity is not displayed, please withdraw it from the old contract and re-add it here. Withdrawal link: https://legacy.izumi.finance/trade/liquidity',
                                  { nsSeparator: false }
                              )}
                    </Text>
                </Stack>
            </HStack>
            <HStack spacing="13px" ml="auto !important" pl="30px">
                <CustomButton
                    w={{ base: '100px', sm: '120px' }}
                    h="31px"
                    className={i_h5}
                    bg="rgba(255, 47, 47, 0.51)"
                    color="#FFFFFF"
                    text={buttonText ? buttonText : t('Start Now')}
                    borderRadius="100px"
                    _hover={{ opacity: '0.75' }}
                    onClick={() => {
                        window.open(target ? target : links.legacyLink);
                    }}
                ></CustomButton>
            </HStack>
        </Stack>
    );
};
export default LegacyWarning;
