import { SimpleGrid, Stack } from '@chakra-ui/react';
import { PORTAL, PORTAL_PER_CHAIN } from '../../config/portal/config';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { PartnerCard } from './components/PartnerCard';

export const PortalBlock: React.FC = () => {
    const { chainId } = useWeb3WithDefault();
    return (
        <Stack w="100%">
            <SimpleGrid columns={{ base: 2, lg: 3, xxl: 4 }} spacing="18px" my="25px">
                {PORTAL &&
                    PORTAL.map((item: any, index: number) => {
                        return (
                            <PartnerCard
                                key={index}
                                titleImgSrc={item.titleImgSrc}
                                title={item.title}
                                content={item.content}
                                link={item.link}
                            ></PartnerCard>
                        );
                    })}
                {PORTAL_PER_CHAIN[chainId] &&
                    PORTAL_PER_CHAIN[chainId].map((item: any, index: number) => {
                        return (
                            <PartnerCard
                                key={index}
                                titleImgSrc={item.titleImgSrc}
                                title={item.title}
                                content={item.content}
                                link={item.link}
                            ></PartnerCard>
                        );
                    })}
            </SimpleGrid>
        </Stack>
    );
};
