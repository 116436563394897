import BigNumber from 'bignumber.js';
import { TransactionReceipt } from 'ethereum-abi-types-generator/dist/converters/typescript/contexts/web3-contract-context';
import { ethers } from 'ethers';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isGasToken } from '../../../config/tokens';
import { useGasPrice } from '../../../hooks/useGasPrice';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { getErc20TokenContract, getWrapTokenContract } from '../../../utils/contractFactory';
import { buildSendingParams } from '../../../utils/contractHelpers';
import { RootDispatch, RootState } from '../../store';
import { FetchTokenAllowanceParams } from '../account/account';
import { getTokenAndSpenderKey } from '../account/funcs';
import { getSafeTokenPrice } from '../token/funcs';
import { sendTransaction, SendTransactionParams, TransactionTypes } from '../wallet/sendTransaction';

export interface TokenEntity {
    //handleApprove: () => Promise<TransactionReceipt>;
    handleApprove: (params?: ApproveParams, isAaAccount?: boolean) => any;
    handleDepositApprove: () => any;
    handleApproveSuccess: () => Promise<boolean | string>;
    handleDepositApproveSuccess: () => Promise<boolean>;
    handleRefreshTokenBalance: () => Promise<number>;
    isApproved: () => boolean;
    isEnoughApproved: (amount: string) => boolean;
    isDepositApproved: () => boolean;
    tokenPrice: () => number;
    tokenBalance: () => number;
    token: TokenInfoFormatted;
}

export interface ApproveParams {  
    onGoingCallback?: (toastLink?: any) => void
}

const useTokenEntity = (token: TokenInfoFormatted, spender: string | undefined, depositSpender?: string | undefined, checkAllowance?:boolean|undefined): TokenEntity => {
    const { chainId, web3, account } = useWeb3WithDefault();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { account: accountModel, token: tokenModel } = useSelector((state: RootState) => state);
    const { gasPrice } = useGasPrice();

    useEffect(() => {
        if (!account || !token || token.symbol === undefined) {
            return;
        }
        const param = {
            token,
            account,
            web3,
            chainId,
            spender,
            skipCache: true,
        } as FetchTokenAllowanceParams;
        dispatch.token.fetchTokenPriceIfMissing(token);
        dispatch.account.fetchTokenBalanceIfMissing(param);
        if (spender) {
            if (!checkAllowance) {
                dispatch.account.fetchTokenApprovedIfMissing(param);
            } else {
                dispatch.account.updateTokenAllowance(param)
            }
        }

        if (depositSpender) {
            const depositSpenderParam = {...param, spender: depositSpender}
            dispatch.account.fetchTokenDepositApprovedIfMissing(depositSpenderParam)
        }

    }, [token, account, chainId, spender, depositSpender, web3, dispatch.token, dispatch.account, checkAllowance]);

    const handleApprove = useCallback(
        //(): Promise<TransactionReceipt> => {
        async (params?: ApproveParams, isAaAccount?: boolean): Promise<any> => {
            if (!account || !ethers.utils.isAddress(account) || !spender) {
                return new Promise<TransactionReceipt>((_, reject) => reject('missing account'));
            }
            const tokenContract = getErc20TokenContract(
                token,
                chainId,
                web3
            ) as any;

            const calling = tokenContract.methods.approve(spender, ethers.constants.MaxUint256.toString())
            const sendTransactionParams: SendTransactionParams = {
                chainId,
                account,
                gasPrice: gasPrice 
            }

            return sendTransaction(TransactionTypes.approve, calling, sendTransactionParams, isAaAccount, token.address, params?.onGoingCallback)
        },
        [account, spender, token, chainId, web3, gasPrice]
    );

    const handleDepositApprove = useCallback(
        //(): Promise<TransactionReceipt> => {
        (): any => {
            if (!account || !ethers.utils.isAddress(account) || !depositSpender) {
                return new Promise<TransactionReceipt>((_, reject) => reject('missing account'));
            }
            if (!token.wrapTokenAddress) {
                return new Promise<TransactionReceipt>((_, reject) => reject('missing wrap token'))
            }
            const tokenContract = getWrapTokenContract(
                web3,
                token.wrapTokenAddress
            ) as any;
            return tokenContract.methods.depositApprove(depositSpender, ethers.constants.MaxUint256.toString()).send(buildSendingParams(chainId, { from: account, maxFeePerGas: gasPrice }, gasPrice));
        },
        [account, depositSpender, token, chainId, web3, gasPrice]
    );

    const handleApproveSuccess = useCallback(
        (): Promise<boolean | string> => {
            if (!checkAllowance) {
                return dispatch.account.fetchTokenApprovedIfMissing({
                    token,
                    account,
                    web3,
                    chainId,
                    spender,
                    skipCheck: true,
                } as FetchTokenAllowanceParams)
            } else {
                return dispatch.account.updateTokenAllowance({
                    token,
                    account,
                    web3,
                    chainId,
                    spender,
                    skipCheck: true,
                } as FetchTokenAllowanceParams)
            }
        },
        [dispatch.account, token, account, web3, chainId, spender, checkAllowance]
    );

    const handleDepositApproveSuccess = useCallback(
        (): Promise<boolean> => {
            return dispatch.account.fetchTokenDepositApprovedIfMissing({
                token,
                account,
                web3,
                chainId,
                spender: depositSpender,
            } as FetchTokenAllowanceParams)
        },
        [dispatch.account, token, account, web3, chainId, depositSpender]
    )

    const handleRefreshTokenBalance = useCallback(
        (): Promise<number> =>
            dispatch.account.fetchTokenBalanceIfMissing({
                token,
                account: account as string,
                web3,
                chainId,
                skipCache: true
            }),
        [dispatch.account, token, account, web3, chainId]
    );

    return {
        handleApprove,
        handleDepositApprove,
        handleApproveSuccess,
        handleDepositApproveSuccess,
        handleRefreshTokenBalance,
        // TODO loading status
        isApproved: () => {
            if (isGasToken(token,chainId)) {return true;}
            if (!spender) { return true; }
            return accountModel.tokenSpenderApproved.has(
                getTokenAndSpenderKey(token?.symbol, spender!)
            );
        },
        isEnoughApproved: (amount: string) => {
            if (isGasToken(token, chainId)) {
                return true
            }
            if (!spender) { return true; }
            const approveAmount: string = accountModel.tokenSpenderAllowance.get(
                getTokenAndSpenderKey(token?.symbol, spender!)
            )?? '0'
            return new BigNumber(approveAmount).gte(amount)
        },
        isDepositApproved: () => {
            if (!token.wrapTokenAddress || !depositSpender) { return false; }
            return accountModel.tokenSpenderDepositApproved.has(
                getTokenAndSpenderKey(token.symbol, depositSpender)
            );
        },
        tokenBalance: () => accountModel.tokenBalance[token?.symbol]?.[0] ?? 0,
        tokenPrice: () => getSafeTokenPrice(tokenModel, token?.symbol ?? '') ?? 0,
        token,
    };
};

export default useTokenEntity;
