import { useMemo } from 'react';
import { LiquidityManagerContract } from '../types/abis/iZiSwap/LiquidityManager';
import { LimitOrderManagerContract } from '../types/abis/iZiSwap/LimitOrderManager';
import { QuoterContract } from '../types/abis/iZiSwap/Quoter';
import { SwapContract } from '../types/abis/iZiSwap/Swap';
import {
    FarmDynamicRangeContractVersion,
    FarmDynamicRangeiZiContractVersion,
    FarmFixRangeContractVersion,
    FarmOneSideContractVersion,
    FarmOneSideiZiContractVersion,
} from '../types/mod';
import {
    getLiquidityManagerContract,
    getLimitOrderManagerContract,
    getQuoterContract,
    getSwapContract,
    getPrizeContract,
} from '../utils/contractFactory';
import {
    getMiningDynamicRangeBoostContract,
    getMiningFixRangeBoostContract,
    getMiningOneSideBoostContract,
    getVeiZiContract,
    getMiningFixRangeiZiV1Contract,
    getMiningDynamicRangeiZiBoostContract,
    getMiningOneSideiZiBoostContract,
    getMulticallContract,
} from '../utils/contractHelpers';
import { useWeb3WithDefault } from './useWeb3WithDefault';
import { PrizeContract } from '../types/abis/prize/Prize';
import { QUOTER_TYPE } from '../config/trade/tradeContracts';
import { Contract } from 'web3-eth-contract';
import { MULTICALL_ADDRESS } from '../config/multicall/multicallContracts';

export const useMiningFixRangeContract = (miningAddress: string, contractVersion: FarmFixRangeContractVersion): any => {
    const { web3 } = useWeb3WithDefault();

    const contract = useMemo(() => {
        return getMiningFixRangeBoostContract(miningAddress, web3, contractVersion);
    }, [contractVersion, miningAddress, web3]);

    return { contract };
};

export const useMiningOneSideBoostContractWithVersion = (miningAddress: string, version: FarmOneSideContractVersion): any => {
    const { web3 } = useWeb3WithDefault();
    const contract = useMemo(() => {
        // todo: choose proper oneside contract by version
        return getMiningOneSideBoostContract(miningAddress, web3, version);
    }, [miningAddress, web3, version]);
    return { contract };
};

export const useMiningOneSideiZiBoostContractWithVersion = (miningAddress: string, version: FarmOneSideiZiContractVersion): any => {
    const { web3 } = useWeb3WithDefault();
    const contract = useMemo(() => {
        // todo: choose proper oneside contract by version
        return getMiningOneSideiZiBoostContract(miningAddress, web3, version);
    }, [miningAddress, web3, version]);
    return { contract };
};

export const useMiningDynamicRangeContractWithVersion = (miningAddress: string, version: FarmDynamicRangeContractVersion) => {
    const { web3 } = useWeb3WithDefault();
    const contract = useMemo(() => {
        // todo: choose proper oneside contract by version
        return getMiningDynamicRangeBoostContract(miningAddress, web3, version);
    }, [miningAddress, web3, version]);
    return { contract };
};

export const useMiningDynamicRangeiZiContractWithVersion = (miningAddress: string, version: FarmDynamicRangeiZiContractVersion) => {
    const { web3 } = useWeb3WithDefault();
    const contract = useMemo(() => {
        // todo: choose proper oneside contract by version
        return getMiningDynamicRangeiZiBoostContract(miningAddress, web3, version);
    }, [miningAddress, web3, version]);
    return { contract };
};

export const useVeiZiContractWithVersion = (veiZiAddress: string, version: string) => {
    const { web3 } = useWeb3WithDefault();
    const contract = useMemo(() => {
        return getVeiZiContract(veiZiAddress, web3);
    }, [veiZiAddress, web3, version]);
    return { contract };
};

// izumi swap contract
export const useLiquidityManagerContract = (): LiquidityManagerContract | undefined => {
    const { chainId, web3 } = useWeb3WithDefault();
    return useMemo(() => getLiquidityManagerContract(chainId, web3), [web3, chainId]);
};

export const useMulticallContract = (): Contract | undefined => {
    const { chainId, web3 } = useWeb3WithDefault();
    const address = MULTICALL_ADDRESS[chainId];
    return useMemo(() => getMulticallContract(address, web3), [web3, chainId]);
};

export const useLimitOrderManagerContract = (): LimitOrderManagerContract | undefined => {
    const { chainId, web3 } = useWeb3WithDefault();
    return useMemo(() => getLimitOrderManagerContract(chainId, web3), [web3, chainId]);
};

export const useIzumiSwapContract = (): SwapContract | undefined => {
    const { chainId, web3 } = useWeb3WithDefault();
    return useMemo(() => getSwapContract(chainId, web3), [web3, chainId]);
};

export const useIzumiQuoterContract = (quoterType: QUOTER_TYPE): QuoterContract | undefined => {
    const { chainId, web3 } = useWeb3WithDefault();
    return useMemo(() => getQuoterContract(chainId, web3, quoterType == QUOTER_TYPE.limit), [web3, chainId, quoterType]);
};

export const usePrizeContract = (): PrizeContract | undefined => {
    const { chainId, web3 } = useWeb3WithDefault();
    return useMemo(() => getPrizeContract(chainId, web3), [web3, chainId]);
};
