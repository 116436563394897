import {
    Box,
    BoxProps,
    HStack,
    Image,
    useColorMode,
    VStack,
    Divider,
    Center,
    Stack,
    Switch,
    Tooltip,
    Icon,
    IconButton,
} from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { default as React, useEffect, useState } from 'react';
import { Text } from '../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ToastLink, useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { CustomNumberInput } from '../../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { i_h4, i_text_copy, i_text_copy_bold } from '../../../../style';
import { defaultPrecision, getColorThemeSelector, identity } from '../../../../utils/funcs';
import { AmountInput } from '../../components/AmountInput';
import { TokenSelectButton } from '../../components/TokenSelectButton';
import { TokenBalanceBlock } from '../../components/TokenBalanceBlock';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../state/store';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { LIQUIDITY_MANAGER_ADDRESS } from '../../../../config/trade/tradeContracts';
import NotConnectModal from '../../components/NotConnectModal';
import { isNumeric } from '../../../../utils/valid';
import { useLiquidityManagerContract } from '../../../../hooks/useContracts';
import { formatNumber } from '../../../../utils/tokenMath';
import { getSortedToken, getSwapTokenAddress } from '../../../../state/models/common/positionPoolHelper';
import { izumiFeeToTickSpacingMapping } from '../../../../utils/tickMath';
import { getFieldValidateInfo, validateMintForm } from '../../../../state/models/trade/liquidity/validationHelper';
import { Chart } from '../../components/Chart/Chart';
import useTokenEntity, { TokenEntity } from '../../../../state/models/hooks/useTokenEntity';
import { validateCreatePoolParam } from '../../../../state/models/trade/liquidity/funcs';
import FeesTiersSelect from '../../components/FeesTiersSelect';
import placeholder from '../../../../assets/placeholder.png';
import { mintNeedTokenA } from '../components/funcs';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { useTranslation } from 'react-i18next';
import { ErrorInfo } from '../../../../iZUMi-UI-toolkit/src/components/ErrorInfo/ErrorInfo';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../hooks/useTokenListFormatted';
import { PreQueryParams } from '../../../../state/models/trade/aggregator/model';
import { getMulticallContract } from '../../../../utils/contractHelpers';
import { MULTICALL_ADDRESS } from '../../../../config/multicall/multicallContracts';
import { TapModeStatus } from '../../../../state/models/trade/tap/types';
import { TapConfirmModal } from '../components/TapConfirmModal';
import { TAP_PROXY_ADDRESS } from '../../../../state/models/trade/tap/config';
import { createPool, fetchPoolState, getiZiSwapPoolKey } from '../../../../state/models/trade/pools/controllers';
import { PoolState } from '../../../../state/models/trade/pools/types';
import { InitiZiSwapMintFormParams, SetMintFormCurrentPriceParams } from '../../../../state/models/trade/liquidity/models/mintModel';
import { point2PriceDecimal } from '../../../../state/models/trade/utils/priceMath';
import { getChain, getTxLink } from '../../../../config/chains';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useInterval } from 'ahooks';
import { CUSTOM_TOKEN_BLACKLIST, IZISWAP_MINT_CONFIG } from '../../../../config/bizConfig';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { getGasToken } from '../../../../config/tokens';
import { TradeLoadingModal, TradeLoadingType } from '../../../../components/Loading/TradeLoadingModal';
import { useTradeLoading } from '../../../../hooks/useTradeLoading';
import { useToast } from '../../../../providers/ToastProvider';
import ButtonArea from '../../../../iZUMi-UI-toolkit/src/components/ButtonArea/ButtonArea';
import { WarningInfo } from '../../../../components/WarningInfo/WarningInfo';
import useCustomTheme from '../../../../hooks/useCustomTheme';

type Props = BoxProps;

const AddLiquidityForm: React.FC<Props> = (props) => {
    const { ...rest } = props;
    const { t } = useTranslation();
    const toast = useCustomToast();
    const { chainId, web3, account, isAaAccount } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { THEME, THEME_CARD, THEME_SELECT, THEME_BUTTON_FONT } = useCustomTheme();
    const isMobile = useIsMobile();
    const { iZiSwapMintOrIncForm: mintForm } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const liquidityManagerContract = useLiquidityManagerContract();

    const { loading, tokenList } = useTokenListFormatted();
    const { tradeState, setInitTradeState, setTradeStateIfNotSubmitted, setTradeStateIfNotSuccess } = useTradeLoading();

    const liquidityManagerAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];

    const { gasPrice } = useGasPrice();
    const { show } = useToast();

    const token0Entity = useTokenEntity(mintForm.token0, liquidityManagerAddress, undefined, true);
    const token1Entity = useTokenEntity(mintForm.token1, liquidityManagerAddress, undefined, true);

    const amount0Desired = mintForm?.amount0Desired?.toFixed(0) ?? '0';
    const amount1Desired = mintForm?.amount1Desired?.toFixed(0) ?? '0';

    const token0AllApproved = token0Entity.isEnoughApproved(amount0Desired);
    const token1AllApproved = token1Entity.isEnoughApproved(amount1Desired);

    const [poolState, setPoolState] = useState<PoolState>(undefined as unknown as PoolState);
    const poolExist = !!poolState;

    const tokenFeeFilled = mintForm.token0 && mintForm.token1 && mintForm.fee ? true : false;

    const [showTapConfirmModal, setShowTapConfirmModal] = useState(false);

    const [initPoolPriceDecimal, setInitPoolPriceDecimal] = useState<number>(0);

    const [isInputFromFocus, setIsInputFromFocus] = useState(false);
    const [isInputToFocus, setIsInputToFocus] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [toastLink, setToastLink] = useState({} as ToastLink);

    const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3);

    const tapProxyAddress = TAP_PROXY_ADDRESS[chainId];

    const searchParams = useSearchParams();

    useEffect(() => {
        const chainIdFromUrl = searchParams.get('chainId');

        if (chainIdFromUrl && Number(chainIdFromUrl) !== Number(chainId)) {
            show('Info', 'Please switch to the the corresponding chain.');
        }
    }, [account, chainId]);

    // add custom token if token is missing
    useEffect(() => {
        if (!loading) {
            const chainIdFromUrl = searchParams.get('chainId');

            if (chainIdFromUrl && Number(chainIdFromUrl) !== Number(chainId)) {
                return;
            }

            const token0Addr = searchParams.get('token0');

            if (token0Addr && token0Addr !== 'GasToken') {
                const token0Formatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === token0Addr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === token0Addr.toUpperCase()
                    ),
                };
                if (
                    !token0Formatted.symbol &&
                    !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === token0Addr.toLowerCase())
                ) {
                    dispatch.customTokens.fetchAndAddToken({
                        tokenAddr: token0Addr,
                        chainId,
                        web3,
                    });
                }
            }

            const token1Addr = searchParams.get('token1');

            if (token1Addr && token1Addr !== 'GasToken') {
                const token1Formatted = {
                    ...tokenList.find(
                        (e) =>
                            e.address.toUpperCase() === token1Addr.toUpperCase() ||
                            e.wrapTokenAddress?.toUpperCase() === token1Addr.toUpperCase()
                    ),
                };
                if (
                    !token1Formatted.symbol &&
                    !CUSTOM_TOKEN_BLACKLIST[chainId].find((e: string) => e.toLowerCase() === token1Addr.toLowerCase())
                ) {
                    dispatch.customTokens.fetchAndAddToken({
                        tokenAddr: token1Addr,
                        chainId,
                        web3,
                    });
                }
            }
        }
    }, [searchParams, tokenList, loading, dispatch, chainId, web3]);

    // Auto fill token if has params in URL
    useEffect(() => {
        const chainIdFromUrl = searchParams.get('chainId');

        if (chainIdFromUrl && Number(chainIdFromUrl) !== Number(chainId)) {
            dispatch.iZiSwapMintOrIncForm.clearForm();
            setPoolState(undefined as unknown as PoolState);
            return;
        }

        if (!chainId || loading) {
            return;
        }

        const token0Addr = searchParams.get('token0');
        const token1Addr = searchParams.get('token1');
        const fee = Number(searchParams.get('fee')) / 10000;

        let token0Formatted = undefined;
        let token1Formatted = undefined;

        if (token0Addr) {
            if (token0Addr !== 'GasToken') {
                token0Formatted = {
                    ...tokenList.find(
                        (e) =>
                            (e.address.toUpperCase() === token0Addr.toUpperCase() ||
                                e.wrapTokenAddress?.toUpperCase() === token0Addr.toUpperCase()) &&
                            e.chainId === chainId
                    ),
                };
            } else {
                token0Formatted = getGasToken(chainId);
            }
        }

        if (token1Addr) {
            if (token1Addr !== 'GasToken') {
                token1Formatted = {
                    ...tokenList.find(
                        (e) =>
                            (e.address.toUpperCase() === token1Addr.toUpperCase() ||
                                e.wrapTokenAddress?.toUpperCase() === token1Addr.toUpperCase()) &&
                            e.chainId === chainId
                    ),
                };
            } else {
                token1Formatted = getGasToken(chainId);
            }
        }

        if (!loading && tokenList.length) {
            dispatch.iZiSwapMintOrIncForm.setMintFormToken({
                isUpper: false,
                chainId,
                tokenInfo: (token0Formatted ? token0Formatted : {}) as unknown as TokenInfoFormatted,
            });
        }

        if (!loading && tokenList.length) {
            dispatch.iZiSwapMintOrIncForm.setMintFormToken({
                isUpper: true,
                chainId,
                tokenInfo: (token1Formatted ? token1Formatted : {}) as unknown as TokenInfoFormatted,
            });
        }

        if (!loading && tokenList.length) {
            dispatch.iZiSwapMintOrIncForm.setMintFormFee(fee as unknown as FeeTier);
        }
    }, [searchParams, chainId, dispatch.iZiSwapMintOrIncForm, loading, tokenList]);

    const fetchPoolInfo = (token0: TokenInfoFormatted, token1: TokenInfoFormatted, fee: FeeTier, isTapMode: boolean) => {
        if (!token0.symbol || !token1.symbol || !fee || !chainId) {
            setPoolState(undefined as unknown as PoolState);
            return;
        }
        const [tokenLower, tokenUpper] = getSortedToken(token0, token1);
        const token0AddrPara = searchParams.get('token0');
        const token1AddrPara = searchParams.get('token1');
        const feePara = Number(searchParams.get('fee')) / 10000;
        const rangePara = isNumeric(searchParams.get('range')) ? Number(searchParams.get('range')) : 100; // at least 100%

        const paramMatch =
            ((token0.address.toUpperCase() === token0AddrPara?.toUpperCase() &&
                token1.address.toUpperCase() === token1AddrPara?.toUpperCase()) ||
                (token1.address.toUpperCase() === token0AddrPara?.toUpperCase() &&
                    token0.address.toUpperCase() === token1AddrPara?.toUpperCase())) &&
            feePara === fee;

        fetchPoolState({
            web3,
            chainId,
            baseContract: liquidityManagerContract,
            tokenA: tokenLower,
            tokenB: tokenUpper,
            fee,
        }).then((r: PoolState) => {
            setPoolState(r);
            if (r && r.currentPoint) {
                const currentPoint = Number(r.currentPoint);
                if (!isTapMode) {
                    dispatch.iZiSwapMintOrIncForm.initMintForm({
                        chainId,
                        token0,
                        token1,
                        currentPoint,
                        fee,
                        spacingMapping: izumiFeeToTickSpacingMapping,
                        isTapMode,
                        customOffset: paramMatch ? rangePara : undefined,
                    } as InitiZiSwapMintFormParams);
                } else {
                    dispatch.swapPreQueryModel
                        .preQuery({
                            chainId,
                            web3,
                            tokenIn: token0,
                            tokenOut: token1,
                            multicall: multicallContract,
                        } as PreQueryParams)
                        .then(() => {
                            dispatch.iZiSwapMintOrIncForm.initMintForm({
                                chainId,
                                token0,
                                token1,
                                currentPoint,
                                fee,
                                spacingMapping: izumiFeeToTickSpacingMapping,
                                isTapMode,
                                customOffset: paramMatch ? rangePara : undefined,
                            } as InitiZiSwapMintFormParams);
                        })
                        .catch((e: any) => {
                            console.info(e.message);
                        });
                }
            }
        });
    };

    const refreshPoolInfo = (token0: TokenInfoFormatted, token1: TokenInfoFormatted, fee: FeeTier) => {
        if (!token0.symbol || !token1.symbol || !fee || !chainId) {
            setPoolState(undefined as unknown as PoolState);
            return;
        }
        const [tokenLower, tokenUpper] = getSortedToken(token0, token1);

        fetchPoolState({
            web3,
            chainId,
            baseContract: liquidityManagerContract,
            tokenA: tokenLower,
            tokenB: tokenUpper,
            fee,
        }).then((r: PoolState) => {
            setPoolState(r);
            if (r && r.currentPoint) {
                const currentPoint = Number(r.currentPoint);
                dispatch.iZiSwapMintOrIncForm.setCurrentPointUnTap({
                    chainId,
                    token0,
                    token1,
                    currentPoint,
                    fee,
                } as SetMintFormCurrentPriceParams);
            }
        });
    };

    useInterval(() => {
        if (!mintForm.isTapMode) {
            refreshPoolInfo(mintForm.token0, mintForm.token1, mintForm.fee);
        }
    }, IZISWAP_MINT_CONFIG.AUTO_REFRESH_POOL_STATE_INTERVAL);

    useEffect(() => {
        fetchPoolInfo(mintForm.token0, mintForm.token1, mintForm.fee, mintForm.isTapMode);
    }, [mintForm.isTapMode, liquidityManagerContract, web3, chainId, mintForm.fee, mintForm.token0, mintForm.token1]);

    const validateResult = validateMintForm(mintForm, [token0Entity.tokenBalance(), token1Entity.tokenBalance()]);
    const isShowError = tokenFeeFilled && poolExist && validateResult && validateResult?.length > 0;
    const errorInfo0 = getFieldValidateInfo(validateResult, 'amount0Desired');
    const errorInfo1 = getFieldValidateInfo(validateResult, 'amount1Desired');

    const initPoolValidateResult = validateCreatePoolParam(mintForm.token0, mintForm.token1, mintForm.fee, initPoolPriceDecimal);

    if (!account) {
        return (
            <>
                <Box height="50px" />
                <NotConnectModal />
            </>
        );
    }

    let needToken0 = true;
    let needToken1 = true;
    let price0By1Decimal = 0;
    if (poolState && mintForm.token0.symbol && mintForm.token1.symbol) {
        const currentPoint = Number(poolState.currentPoint);
        const currentPoolKey = getiZiSwapPoolKey(mintForm.token0, mintForm.token1, mintForm.fee);
        if (currentPoolKey === poolState.poolKey) {
            needToken0 = mintNeedTokenA(
                getSwapTokenAddress(mintForm.token0),
                getSwapTokenAddress(mintForm.token1),
                mintForm.leftPoint,
                mintForm.rightPoint,
                currentPoint
            );
            needToken1 = mintNeedTokenA(
                getSwapTokenAddress(mintForm.token1),
                getSwapTokenAddress(mintForm.token0),
                mintForm.leftPoint,
                mintForm.rightPoint,
                currentPoint
            );
            price0By1Decimal = point2PriceDecimal(mintForm.token0, mintForm.token1, currentPoint);
        }
    }
    const token0Price = token0Entity.tokenPrice();
    const token1Price = token1Entity.tokenPrice();
    const tokenRatio = token0Price / token1Price;
    const priceDiffMultiple = 2;
    let isPriceDiffExceedsPercent = false;
    if (token0Price === 0 || token1Price === 0 || price0By1Decimal === 0) {
        isPriceDiffExceedsPercent = false;
    } else {
        isPriceDiffExceedsPercent = price0By1Decimal > tokenRatio * priceDiffMultiple || price0By1Decimal < tokenRatio / priceDiffMultiple;
    }

    //console.log('render.......');

    return (
        <>
            <VStack
                w={{ base: '100%', sm: '704px' }}
                alignSelf="center"
                mt="-20px !important"
                mb={{ base: '60px !important', sm: 'unset' }}
                {...rest}
            >
                <WarningInfo mb="8px" mt="12px"></WarningInfo>
                <Card
                    w="100%"
                    h="91px"
                    variant="deep"
                    pt="18px"
                    pl={{ base: '18px', sm: '35px' }}
                    pr={{ base: '18px', sm: 'unset' }}
                    pb="26px"
                    display="flex"
                    justifyContent="center"
                    bg={THEME_CARD[chainId]}
                >
                    <HStack spacing="0" pt="6px">
                        <TokenSelectButton
                            size={isMobile ? 'sm' : 'xs'}
                            w={{ base: '140px', sm: '291px' }}
                            h="58px"
                            token={mintForm.token0}
                            handleSelect={(tokenInfo) => {
                                if (!tokenInfo.wrapTokenAddress || !mintForm.isTapMode) {
                                    dispatch.iZiSwapMintOrIncForm.setMintFormToken({
                                        isUpper: false,
                                        chainId,
                                        tokenInfo,
                                    });
                                    fetchPoolInfo(tokenInfo, mintForm.token1, mintForm.fee, mintForm.isTapMode);
                                }
                            }}
                            pl={{ base: '10px', sm: '44px' }}
                            bg={THEME_SELECT[chainId]}
                        />
                        <Image
                            w="14px"
                            mx={{ base: '9px !important', sm: '0px !important' }}
                            ml={{ base: '9px ', sm: '20px !important' }}
                            src={process.env.PUBLIC_URL + '/assets/swap/transicon_h.svg'}
                            fallbackSrc={placeholder}
                            disabled={mintForm.isTapMode}
                            onClick={() => {
                                dispatch.iZiSwapMintOrIncForm.toggleTokenOrder();
                            }}
                            cursor="pointer"
                        />
                        <TokenSelectButton
                            size={isMobile ? 'sm' : 'xs'}
                            w={{ base: '140px', sm: '291px' }}
                            h="58px"
                            ml={{ base: '9px', sm: '20px !important' }}
                            token={mintForm.token1}
                            handleSelect={(tokenInfo) => {
                                if (!tokenInfo.wrapTokenAddress || !mintForm.isTapMode) {
                                    dispatch.iZiSwapMintOrIncForm.setMintFormToken({
                                        isUpper: true,
                                        chainId,
                                        tokenInfo,
                                    });
                                    fetchPoolInfo(mintForm.token0, tokenInfo, mintForm.fee, mintForm.isTapMode);
                                }
                                //fetchPoolInfo();
                            }}
                            pl={{ base: '10px', sm: '44px' }}
                        />
                    </HStack>
                </Card>

                <Card
                    w="100%"
                    variant="deep"
                    mt={{ base: '15px !important', sm: '8px !important' }}
                    pt="18px"
                    pb="16px"
                    pl="35px"
                    pr="25px"
                    display="flex"
                    justifyContent="space-between"
                    bg={THEME_CARD[chainId]}
                >
                    <Stack
                        alignItems={{ base: 'start', sm: 'center' }}
                        w="100%"
                        direction={{ base: 'column', sm: 'row' }}
                        spacing={{ base: '8px', sm: '12px' }}
                    >
                        <HStack spacing="4px">
                            <Text variant="caption-bold" color={colorTheme('tertiary.600', 'tertiary.200')} whiteSpace="nowrap">
                                {t('Fees Tiers')}
                            </Text>
                            <Tooltip label={t('fees charged in each swap.')}>{<InfoOutlineIcon />}</Tooltip>
                            <Text variant="caption-bold" color={colorTheme('tertiary.600', 'tertiary.200')}>
                                :
                            </Text>
                        </HStack>
                        <FeesTiersSelect
                            handleSelect={(item) => {
                                dispatch.iZiSwapMintOrIncForm.setMintFormFee(item);
                                fetchPoolInfo(mintForm.token0, mintForm.token1, item, mintForm.isTapMode);
                            }}
                            currentFee={mintForm.fee}
                            w={{ base: 'unset', sm: '91px' }}
                            h="35px"
                        />
                    </Stack>
                </Card>

                {!tokenFeeFilled && (
                    <Card w="100%" h="60px" spacing="16px" mt="30px !important" bg={THEME_CARD[chainId]}>
                        <Center w="100%" h="100%">
                            <Text variant="caption" color="tertiary.400" fontSize="14px">
                                {t('Please choose tokens and fees tier')} ...
                            </Text>
                        </Center>
                    </Card>
                )}
                {tokenFeeFilled && !poolExist && (
                    <Card w="100%" h="260px" spacing="16px" mt="30px !important" bg={THEME_CARD[chainId]}>
                        <VStack w="100%" h="256px" bg={THEME_CARD[chainId]} alignItems="left" p="22px 35px 10px 30px">
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.300')}>
                                {t('Initialize the pool')} :
                            </Text>
                            <Text variant="caption" mt="30px !important" color={colorTheme('tertiary.500', 'tertiary.400')}>
                                {t('This pool is not initialized before.')} <br />
                                {t(
                                    'To initialize, select a starting price for the pool and the enter your liquidity price range and deposit amount.'
                                )}
                            </Text>

                            <Text
                                variant="caption-bold"
                                color={colorTheme('secondary.700', 'secondary.200')}
                                className={i_text_copy_bold}
                                mt="20px !important"
                            >
                                {t('Gas fees will be higher than usual!')}
                            </Text>

                            <HStack mt="30px !important" alignItems="center">
                                <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.300')}>
                                    {t('Starting price')} :
                                </Text>

                                <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.300')} ml="30px !important">
                                    1 {mintForm.token0.symbol} =
                                </Text>
                                <CustomNumberInput
                                    w="150px"
                                    inputBg={colorTheme('#F8F7FF', '#211834')}
                                    inputValue={initPoolPriceDecimal.toPrecision(8)}
                                    onBlur={(value) => {
                                        isNumeric(value) && setInitPoolPriceDecimal(Number(value));
                                    }}
                                />
                                <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.300')}>
                                    {mintForm.token1.symbol}
                                </Text>
                            </HStack>
                        </VStack>
                    </Card>
                )}

                {tokenFeeFilled && poolExist && (
                    <Stack
                        w="100%"
                        h={{ base: 'unset', sm: '430px' }}
                        direction={{ base: 'column', sm: 'row' }}
                        spacing="16px"
                        mt="30px !important"
                    >
                        <VStack w={{ base: '100%', sm: '293px' }} h="100%" position="relative">
                            <HStack mt="-10px !important" mb="10px !important" p="0 !important" spacing="30px">
                                <Text variant="caption-bold" alignSelf="center">
                                    {t('Volume')}
                                </Text>
                                <Card
                                    w="100%"
                                    variant="deep"
                                    py="2px"
                                    px="10px"
                                    display="flex"
                                    //justifyContent="space-between"
                                    bg={THEME_CARD[chainId]}
                                >
                                    <HStack spacing="10px" justifyItems="center">
                                        <Text variant="caption-bold" color="#938CA4">
                                            {t('Tap')}
                                        </Text>

                                        <HStack>
                                            <Switch
                                                isChecked={mintForm.isTapMode ? true : false}
                                                disabled={!tapProxyAddress}
                                                onChange={(e) => {
                                                    if (
                                                        !e.target.checked ||
                                                        (!mintForm.token0.wrapTokenAddress && !mintForm.token1.wrapTokenAddress)
                                                    ) {
                                                        dispatch.iZiSwapMintOrIncForm.setMintFormTapMode(e.target.checked);
                                                        fetchPoolInfo(mintForm.token0, mintForm.token1, mintForm.fee, e.target.checked);
                                                    }
                                                }}
                                                size="md"
                                            />
                                            <Tooltip
                                                label={
                                                    'If switch on, some tokens A may swap into token B first and then add as a Liquidity together.'
                                                }
                                            >
                                                <IconButton
                                                    size="sm"
                                                    aria-label=""
                                                    variant="ghost"
                                                    isRound={true}
                                                    icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </HStack>
                                </Card>
                            </HStack>

                            <Card
                                variant="tabCard"
                                h="172px"
                                w="100%"
                                p="10px"
                                border={
                                    isInputFromFocus
                                        ? '2px solid #A880FF'
                                        : errorInfo0
                                        ? '2px solid #E05757'
                                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                                }
                                bg={THEME_CARD[chainId]}
                            >
                                <TokenBalanceBlock token={mintForm.token0} balance={token0Entity.tokenBalance()} p="10px" />
                                <Divider m="0 !important" />

                                <Box w="100%" px="10px">
                                    {needToken0 ? (
                                        <AmountInput
                                            handleSetValue={(value: number) => {
                                                isNumeric(value) &&
                                                    dispatch.iZiSwapMintOrIncForm.setMintFormAmountDesired({
                                                        isDesired0: true,
                                                        desiredAmountDecimal: value,
                                                        currentPoint: Number(poolState.currentPoint),
                                                    });
                                            }}
                                            token={mintForm.token0}
                                            price={token0Entity.tokenPrice()}
                                            balance={token0Entity.tokenBalance()}
                                            inputValue={mintForm.amount0DecimalDesired && Number(mintForm.amount0DecimalDesired).toFixed(4)}
                                            fontClass={i_h4}
                                            setIsInputFocus={setIsInputFromFocus}
                                            w="100%"
                                        />
                                    ) : (
                                        <Center w="100%" h="50px">
                                            <Text color="tertiary.300" variant="caption">
                                                {t('no need')}
                                            </Text>
                                        </Center>
                                    )}
                                </Box>
                            </Card>

                            <Card
                                h="170px"
                                w="100%"
                                variant="deep"
                                mt="30px !important"
                                p="10px"
                                border={
                                    isInputToFocus
                                        ? '2px solid #A880FF'
                                        : errorInfo1
                                        ? '2px solid #E05757'
                                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                                }
                                bg={THEME_CARD[chainId]}
                            >
                                <TokenBalanceBlock token={mintForm.token1} balance={token1Entity.tokenBalance()} p="10px" />
                                <Divider m="0 !important" />

                                <Box w="100%" px="10px">
                                    {needToken1 ? (
                                        <AmountInput
                                            handleSetValue={(value: number) => {
                                                isNumeric(value) &&
                                                    dispatch.iZiSwapMintOrIncForm.setMintFormAmountDesired({
                                                        isDesired0: false,
                                                        desiredAmountDecimal: value,
                                                        currentPoint: Number(poolState.currentPoint),
                                                    });
                                            }}
                                            token={mintForm.token1}
                                            price={token1Entity.tokenPrice()}
                                            balance={token1Entity.tokenBalance()}
                                            inputValue={mintForm.amount1DecimalDesired && Number(mintForm.amount1DecimalDesired).toFixed(4)}
                                            fontClass={i_h4}
                                            setIsInputFocus={setIsInputToFocus}
                                            w="100%"
                                        />
                                    ) : (
                                        <Center w="100%" h="50px">
                                            <Text color="tertiary.300" variant="caption">
                                                {t('no need')}
                                            </Text>
                                        </Center>
                                    )}
                                </Box>
                            </Card>

                            <Box
                                position="absolute"
                                borderRadius="50%"
                                border={colorTheme('6px solid #F7F6F9', '6px solid #150E29')}
                                w="54px"
                                h="54px"
                                top="220px"
                                left="50%"
                                transform="translateX(-50%) translateY(-50%)"
                                backgroundImage={colorTheme(
                                    process.env.PUBLIC_URL + '/assets/addLiquidity/add.svg',
                                    process.env.PUBLIC_URL + '/assets/addLiquidity/darkAdd.svg'
                                )}
                                backgroundSize="auto"
                                backgroundRepeat="no-repeat"
                                backgroundPosition="center"
                            />
                        </VStack>

                        <VStack w={{ base: '100%', sm: '394px' }} h="100%">
                            <Text variant="caption-bold" alignSelf="center" mb="20px !important">
                                {t('Price Range')}
                            </Text>

                            <Card h="372px" w="100%" variant="deep" bg={THEME_CARD[chainId]}>
                                <VStack w="100%" h="256px" alignItems="center" pt="14px">
                                    <HStack alignSelf="center">
                                        <Text
                                            variant="caption"
                                            className={i_text_copy_bold}
                                            color={colorTheme('tertiary.400', 'tertiary.300')}
                                        >
                                            {t('Current Price')} :
                                        </Text>
                                        <Text variant="caption" color={colorTheme('tertiary.700', 'tertiary.50')} className={i_text_copy}>
                                            1 {mintForm.token0.symbol} = {formatNumber(price0By1Decimal, 2, 2, true)}{' '}
                                            {mintForm.token1.symbol}
                                        </Text>
                                    </HStack>
                                    <Chart
                                        dim={{
                                            width: 345,
                                            height: 220,
                                        }}
                                        zoom={0}
                                        currentPrice={price0By1Decimal}
                                        minPrice={mintForm.lowerPrice0By1Decimal ?? 1}
                                        maxPrice={mintForm.upperPrice0By1Decimal ?? 1}
                                    />
                                </VStack>
                                <VStack pt="10px">
                                    <HStack w="80%" justifyContent="space-between">
                                        <VStack w="149px">
                                            <Text variant="caption" color={colorTheme('tertiary.400', 'tertiary.300')}>
                                                {t('Min Price')} :
                                            </Text>
                                            <Card variant="deep">
                                                <CustomNumberInput
                                                    inputValue={mintForm.lowerPrice0By1Decimal?.toPrecision(
                                                        defaultPrecision(mintForm.lowerPrice0By1Decimal)
                                                    )}
                                                    size="md"
                                                    onDec={() =>
                                                        dispatch.iZiSwapMintOrIncForm.addMintFormTick({
                                                            stepPositive: false,
                                                            isUpper: false,
                                                            currentPoint: Number(poolState.currentPoint),
                                                        })
                                                    }
                                                    onInc={() =>
                                                        dispatch.iZiSwapMintOrIncForm.addMintFormTick({
                                                            stepPositive: true,
                                                            isUpper: false,
                                                            currentPoint: Number(poolState.currentPoint),
                                                        })
                                                    }
                                                    onBlur={(value) => {
                                                        isNumeric(value) &&
                                                            dispatch.iZiSwapMintOrIncForm.setMintFormTickPrice({
                                                                price0By1Decimal: value,
                                                                isUpper: false,
                                                                currentPoint: Number(poolState.currentPoint),
                                                            });
                                                    }}
                                                    errorInfo={getFieldValidateInfo(validateResult, 'leftPoint')}
                                                    fontClass={i_text_copy}
                                                    bg={THEME_SELECT[chainId]}
                                                />
                                            </Card>
                                        </VStack>
                                        <VStack w="149px">
                                            <Text variant="caption" color={colorTheme('tertiary.400', 'tertiary.300')}>
                                                {t('Max Price')} :
                                            </Text>
                                            <Card variant="deep">
                                                <CustomNumberInput
                                                    inputValue={mintForm.upperPrice0By1Decimal?.toPrecision(
                                                        defaultPrecision(mintForm.upperPrice0By1Decimal)
                                                    )}
                                                    size="md"
                                                    onDec={() =>
                                                        dispatch.iZiSwapMintOrIncForm.addMintFormTick({
                                                            stepPositive: false,
                                                            isUpper: true,
                                                            currentPoint: Number(poolState.currentPoint),
                                                        })
                                                    }
                                                    onInc={() =>
                                                        dispatch.iZiSwapMintOrIncForm.addMintFormTick({
                                                            stepPositive: true,
                                                            isUpper: true,
                                                            currentPoint: Number(poolState.currentPoint),
                                                        })
                                                    }
                                                    onBlur={(value) => {
                                                        isNumeric(value) &&
                                                            dispatch.iZiSwapMintOrIncForm.setMintFormTickPrice({
                                                                price0By1Decimal: value,
                                                                isUpper: true,
                                                                currentPoint: Number(poolState.currentPoint),
                                                            });
                                                    }}
                                                    errorInfo={getFieldValidateInfo(validateResult, 'rightPoint')}
                                                    fontClass={i_text_copy}
                                                    bg={THEME_SELECT[chainId]}
                                                />
                                            </Card>
                                        </VStack>
                                    </HStack>
                                    <HStack w="100%" pt="3px" px="12px" justifyContent="space-between">
                                        {['10', '20', '50', '100', 'full'].map((item, index) => {
                                            return (
                                                <CustomButton
                                                    key={index}
                                                    disabled={!poolExist}
                                                    variant="primary2"
                                                    fontClass={i_text_copy_bold}
                                                    fontSize="12px"
                                                    w={{ base: '20%', sm: '65px' }}
                                                    h="22px"
                                                    onClick={() => {
                                                        if (item === 'full') {
                                                            dispatch.iZiSwapMintOrIncForm.setMintFormTickPriceTwoSide({
                                                                lowerPrice0By1Decimal: price0By1Decimal / 100000,
                                                                upperPrice0By1Decimal: price0By1Decimal * 100000,
                                                                currentPoint: Number(poolState.currentPoint),
                                                            });
                                                        } else {
                                                            dispatch.iZiSwapMintOrIncForm.setMintFormTickPriceTwoSide({
                                                                lowerPrice0By1Decimal: price0By1Decimal / ((100 + Number(item)) / 100),
                                                                upperPrice0By1Decimal: price0By1Decimal * ((100 + Number(item)) / 100),
                                                                currentPoint: Number(poolState.currentPoint),
                                                            });
                                                        }
                                                    }}
                                                    text={item === 'full' ? '∞' : '±' + item + '%'}
                                                />
                                            );
                                        })}
                                    </HStack>
                                </VStack>
                            </Card>
                        </VStack>
                    </Stack>
                )}
                {isShowError && <ErrorInfo content={validateResult && validateResult[1]} mt="13px !important"></ErrorInfo>}
                {isPriceDiffExceedsPercent && (
                    <HStack w="100%" minH="24px" bg="#E15959" borderRadius="5px" px={{ base: '19px', sm: '25px' }} py="5px">
                        <Text variant="caption" lineHeight="14px" letterSpacing="0.02em" color={'#FFFFFF'}>
                            {t(
                                'A significant price deviation has been detected in this pool. Please confirm that the price is acceptable.'
                            )}
                        </Text>
                    </HStack>
                )}
                <Stack w={{ base: '100%', sm: 'unset' }} spacing="20px">
                    <CustomButton
                        hidden={!poolExist || !mintForm.isTapMode}
                        disabled={
                            validateResult !== undefined || !(mintForm.amount0DecimalDesired > 0 || mintForm.amount1DecimalDesired > 0)
                        }
                        mt="20px !important"
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Tap And Mint')}
                        w={{ base: '100%', sm: '300px', xxl: '469px' }}
                        h={{ base: '50px', xxl: '62px' }}
                        fontSize="14px"
                        onClick={() => {
                            setShowTapConfirmModal(true);
                        }}
                    />
                    <CustomButton
                        hidden={![token0AllApproved, token1AllApproved].every(identity) || !poolExist || mintForm.isTapMode}
                        disabled={
                            validateResult !== undefined || (mintForm.amount0DecimalDesired === 0 && mintForm.amount1DecimalDesired === 0)
                        }
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Create')}
                        w={{ base: '100%', sm: '300px', xxl: '469px' }}
                        h={{ base: '50px', xxl: '62px' }}
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            setShowLoadingModal(true);
                            setInitTradeState();
                            dispatch.iZiSwapMintOrIncForm
                                .mintLiquidity({
                                    web3,
                                    account,
                                    chainId,
                                    liquidityManagerContract: liquidityManagerContract,
                                    gasPrice,
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        setTradeStateIfNotSubmitted();
                                        toastLink && setToastLink(toastLink);
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                    isAaAccount,
                                })
                                .then((e: any) => {
                                    if (mintForm.amount0Desired.gt(0)) {
                                        token0Entity.handleApproveSuccess();
                                    }
                                    if (mintForm.amount1Desired.gt(0)) {
                                        token1Entity.handleApproveSuccess();
                                    }
                                    console.log(e);
                                    const transactionHash = e.transactionHash ? e.transactionHash : e;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    setTradeStateIfNotSuccess();
                                    setToastLink(toastLink);
                                    toast('success', 'add liquidity successfully', undefined, toastLink);
                                })
                                .catch((e: any) => {
                                    console.log(e.message);
                                    setShowLoadingModal(false);
                                    toast('info', e.message);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={!(tokenFeeFilled && !poolExist)}
                        disabled={initPoolValidateResult !== undefined}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Create Pool')}
                        w={{ base: '100%', sm: '300px', xxl: '469px' }}
                        h={{ base: '50px', xxl: '62px' }}
                        fontSize="14px"
                        onClick={() => {
                            setInitTradeState();
                            createPool({
                                account,
                                chainId,
                                liquidityManagerContract,
                                tokenA: mintForm.token0,
                                tokenB: mintForm.token1,
                                initPriceDecimalAByB: initPoolPriceDecimal,
                                fee: mintForm.fee,
                                gasPrice,
                                onGoingCallback: (toastLink?: ToastLink) => {
                                    setTradeStateIfNotSubmitted();
                                    toastLink && setToastLink(toastLink);
                                    toast('info', 'Ongoing ...', undefined, toastLink);
                                },
                            }).then((e: any) => {
                                const chain = getChain(chainId);
                                const toastLink = {} as ToastLink;
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(e.transactionHash, chain);
                                }
                                setTradeStateIfNotSuccess();
                                setToastLink(toastLink);
                                toast('success', 'create pool successfully', undefined, toastLink);
                                fetchPoolInfo(mintForm.token0, mintForm.token1, mintForm.fee, mintForm.isTapMode);
                            });
                        }}
                    />
                    <CustomButton
                        hidden={
                            amount0Desired === '0' ||
                            token0Entity.isEnoughApproved(amount0Desired) ||
                            !account ||
                            !mintForm.token0.symbol ||
                            mintForm.amount0DecimalDesired == 0 ||
                            (tokenFeeFilled && !poolExist) ||
                            mintForm.isTapMode
                        }
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve') + ' ' + mintForm.token0.symbol}
                        w={{ base: '100%', sm: '300px', xxl: '469px' }}
                        h={{ base: '50px', xxl: '62px' }}
                        fontSize="14px"
                        onClick={() => {
                            setInitTradeState();
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            token0Entity
                                .handleApprove(
                                    {
                                        onGoingCallback(toastLink: any) {
                                            setTradeStateIfNotSubmitted();
                                            toast('info', 'Ongoing', undefined, toastLink);
                                        },
                                    },
                                    isAaAccount
                                )
                                // .on('transactionHash', (hash: string) => {
                                //     if (chain) {
                                //         toastLink.title = 'View on ' + chain.name;
                                //         toastLink.link = getTxLink(hash, chain);
                                //     }
                                //     setTradeStateIfNotSubmitted();
                                //     setToastLink(toastLink);
                                //     toast('info', 'Ongoing', undefined, toastLink);
                                // })
                                .then((e: any) => {
                                    if (chain) {
                                        const transactionHash = e.transactionHash ? e.transactionHash : e;
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    setTradeStateIfNotSuccess();
                                    setToastLink(toastLink);
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    token0Entity.handleApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={
                            amount1Desired === '0' ||
                            token1Entity.isEnoughApproved(amount1Desired) ||
                            !account ||
                            !mintForm.token1.symbol ||
                            (tokenFeeFilled && !poolExist) ||
                            mintForm.amount1DecimalDesired == 0 ||
                            mintForm.isTapMode
                        }
                        mt="24px !important"
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve') + ' ' + mintForm.token1.symbol}
                        w={{ base: '100%', sm: '300px', xxl: '469px' }}
                        h={{ base: '50px', xxl: '62px' }}
                        fontSize="14px"
                        onClick={() => {
                            setInitTradeState();
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;

                            token1Entity
                                .handleApprove(
                                    {
                                        onGoingCallback(toastLink: any) {
                                            setTradeStateIfNotSubmitted();
                                            toast('info', 'Ongoing', undefined, toastLink);
                                        },
                                    },
                                    isAaAccount
                                )
                                // .on('transactionHash', (hash: string) => {
                                //     if (chain) {
                                //         toastLink.title = 'View on ' + chain.name;
                                //         toastLink.link = getTxLink(hash, chain);
                                //     }
                                //     setTradeStateIfNotSubmitted();
                                //     setToastLink(toastLink);
                                //     toast('info', 'Ongoing', undefined, toastLink);
                                // })
                                .then((e: any) => {
                                    if (chain) {
                                        const transactionHash = e.transactionHash ? e.transactionHash : e;
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    setTradeStateIfNotSuccess();
                                    setToastLink(toastLink);
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    token1Entity.handleApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                });
                        }}
                    />
                </Stack>
            </VStack>

            {showTapConfirmModal && (
                <TapConfirmModal
                    isOpen={showTapConfirmModal}
                    onClose={() => {
                        dispatch.iZiSwapMintOrIncForm.setTapModeStatus(TapModeStatus.Modified);
                        setShowTapConfirmModal(false);
                    }}
                    currentPoint={Number(poolState.currentPoint)}
                    onSuccess={() => {}}
                />
            )}
            <TradeLoadingModal
                isOpen={showLoadingModal}
                onClose={() => {
                    setShowLoadingModal(false);
                    setInitTradeState();
                }}
                type={TradeLoadingType.add}
                content={
                    'Add Liquidity \r\n' +
                    (mintForm.amount0DecimalDesired && Number(mintForm.amount0DecimalDesired).toFixed(4)) +
                    mintForm.token0.symbol +
                    ' + ' +
                    (mintForm.amount1DecimalDesired && Number(mintForm.amount1DecimalDesired).toFixed(4)) +
                    mintForm.token1.symbol
                }
                tokenX={mintForm.token0}
                tokenY={mintForm.token1}
                tokenXAmount={String(mintForm.amount0DecimalDesired && Number(mintForm.amount0DecimalDesired).toFixed(5))}
                tokenYAmount={String(mintForm.amount1DecimalDesired && Number(mintForm.amount1DecimalDesired).toFixed(5))}
                toastLink={toastLink}
                tradeState={tradeState}
            ></TradeLoadingModal>
        </>
    );
};

export default AddLiquidityForm;
