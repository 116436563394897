import { Contract } from 'web3-eth-contract';
import { ChainId } from '../../../../types/mod';
import { buildSendingParams } from '../../../../utils/contractHelpers';

export const collectRewards = (chainId: ChainId, contract: Contract, account: string, gas: string, gasPrice: string | number): any => {
    return contract.methods.collectRewards().send(
        buildSendingParams(
            chainId,
            {
                from: account,
                gas,
                maxFeePerGas: gasPrice,
            },
            gasPrice
        )
    );
};
export const estimateCollectRewardsGas = (contract: Contract, account: string): any => {
    return contract.methods.collectRewards().estimateGas({ from: account });
};

export const collectReward = (
    chainId: ChainId,
    contract: Contract,
    account: string,
    tokenId: number,
    gas: string,
    gasPrice: string | number
): any => {
    return contract.methods.collectReward(tokenId).send(
        buildSendingParams(
            chainId,
            {
                from: account,
                gas,
                maxFeePerGas: gasPrice,
            },
            gasPrice
        )
    );
};

export const estimateCollectRewardGas = (contract: Contract, account: string, tokenId: number): any => {
    return contract.methods.collectReward(tokenId).estimateGas({ from: account });
};
