import {
    VStack,
    HStack,
    Text,
    Flex,
    Image,
    AccordionItem,
    Accordion,
    AccordionPanel,
    Icon,
    useColorMode,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { FiExternalLink } from 'react-icons/fi';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector, viewTrans } from '../../../utils/funcs';
import { FeeRate } from '../../Trade/components/FeeRate';
import { TokenIcons } from '../../Trade/components/TokenIcons';
import { Pagination } from './Pagination';
import { formatNumber } from '../../../utils/tokenMath';
import moment from 'moment';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { TransListSetting } from '../OrderHistory';
import { Loading } from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { FarmTypeEnum, ResponseFarmHistory } from '../../../net/iZUMi-endpoints/src/restful/izumiFarmStat';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { tokenAddr2Token } from '../../../config/tokens';
import { miningPoolConfigList as dynamicMiningPoolConfigList } from '../../../config/farm/iZiSwap/dynamicRange/miningPoolMetaConst';
import { miningPoolConfigList as fixRangeMiningPoolConfigList } from '../../../config/farm/iZiSwap/fixRange/miningPoolMetaConst';
import { miningPoolConfigList as oneSideMiningPoolConfigList } from '../../../config/farm/iZiSwap/oneSide/miningPoolMetaConst';
import { miningPoolConfigList as uniDynamicMiningPoolConfigList } from '../../../config/farm/UniswapV3/dynamicRange/miningPoolMetaConst';
import { miningPoolConfigList as uniFixRangeMiningPoolConfigList } from '../../../config/farm/UniswapV3/fixRange/miningPoolMetaConst';
import { miningPoolConfigList as uniOneSideMiningPoolConfigList } from '../../../config/farm/UniswapV3/oneSide/miningPoolMetaConst';

import _ from 'lodash';
import useCustomTheme from '../../../hooks/useCustomTheme';

export interface HistoryListProps {
    data: ResponseFarmHistory[];
    netWorkloading: boolean;
    totalPage: number;
    setting: TransListSetting;
    setCurrentPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
}
const FarmList: React.FC<HistoryListProps> = (pros) => {
    const { data, netWorkloading, totalPage, setting, setCurrentPage, setPageSize } = pros;
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const isXl = useBreakpointValue({ base: false, sm: true, xl: false, '2xl': false });
    const { THEME_CARD } = useCustomTheme();
    const { chainId } = useWeb3WithDefault();
    const { loading, tokenList } = useTokenListFormatted();

    const getToken = (address: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.address === address),
        };
        if (_.isEmpty(tempToken)) {
            const findToken = tokenAddr2Token(address, chainId);
            if (findToken.address) {
                return findToken;
            }
        }
        return tempToken;
    };

    const getFee = (address: string) => {
        const concatArr = [
            dynamicMiningPoolConfigList[chainId],
            fixRangeMiningPoolConfigList[chainId],
            oneSideMiningPoolConfigList[chainId],
            uniDynamicMiningPoolConfigList[chainId],
            uniFixRangeMiningPoolConfigList[chainId],
            uniOneSideMiningPoolConfigList[chainId],
        ].reduce((acc: any, val) => (acc ? acc.concat(val) : acc), []);
        return concatArr?.find((item: any) => item && item.miningContract === address)?.feeTier || 0;
    };

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const tabs = [
        [t('Pool/fees'), '250px'],
        [t('Type'), isXl ? '70px' : '100px'],
        [t('Transaction'), '315px'],
        [t('NFTId'), isXl ? '60px' : '60px'],
        [t('Order Date'), isXl ? '150px' : { base: '100%', sm: '150px' }],
        ['', '96px'],
    ];
    return isMobile ? (
        <Card
            variant="deep2"
            w={{ base: '100%', xxl: '1100px', xlp1: '1150px' }}
            minW={{ base: '100%', sm: '810px' }}
            alignItems="left"
            p={{ base: '10px', sm: '30px 20px 30px 20px', xl: '30px 40px 30px 40px' }}
            mt={{ base: '10px', sm: '23px' }}
            mb={{ base: '80px', sm: '0px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
        >
            <VStack spacing="11px" alignItems="left">
                {(netWorkloading || loading) && (
                    <Flex alignItems="center" justifyContent="center" pt="40px">
                        <Loading />
                    </Flex>
                )}
                {!netWorkloading &&
                    !loading &&
                    data &&
                    data.map((item: ResponseFarmHistory, i: number) => {
                        const tokenA = getToken(item.tokenx_addr);
                        const tokenB = getToken(item.tokeny_addr);
                        const collectRewardToken = item.rewardToken ? getToken(item.rewardToken) : undefined;

                        return (
                            <Accordion key={i} allowToggle={true}>
                                <AccordionItem border="0">
                                    <Flex direction="column">
                                        <Stack direction={{ base: 'column', sm: 'row' }}>
                                            <HStack w={isXl ? '200px' : '250px'} mt="2px !important" cursor="pointer" px="32px">
                                                <TokenIcons
                                                    tokenA={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenA.name,
                                                            symbol: tokenA.symbol,
                                                            icon: tokenA.icon,
                                                            address: tokenA.addTime,
                                                            decimal: tokenA.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenB.name,
                                                            symbol: tokenB.symbol,
                                                            icon: tokenB.icon,
                                                            address: tokenB.addTime,
                                                            decimal: tokenB.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    initialToggle={false}
                                                    // chainId={item.chainId}
                                                    // showChainIcon={true}
                                                    type={isXl ? 'small' : ''}
                                                />
                                                <FeeRate
                                                    tokenA={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenA.name,
                                                            symbol: tokenA.symbol,
                                                            icon: tokenA.icon,
                                                            address: tokenA.address,
                                                            decimal: tokenA.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenB.name,
                                                            symbol: tokenB.symbol,
                                                            icon: tokenB.icon,
                                                            address: tokenB.addTime,
                                                            decimal: tokenB.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    feeTier={getFee(item.contract_addr).toFixed(2) as any}
                                                    initialToggle={false}
                                                    direction="row"
                                                    variant="purple"
                                                    type={isXl ? 'small' : ''}
                                                    ml={{ base: '0px !important', xl: '8px !important' }}
                                                />
                                            </HStack>
                                            <Stack
                                                bg={colorTheme(
                                                    'linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)',
                                                    'inherit'
                                                )}
                                                px="32px"
                                                py="10px"
                                            >
                                                <HStack w={'100%'}>
                                                    <Text w="80px" key={i} className={i_text_copy} mr="10px">
                                                        {tabs[1][0]}
                                                    </Text>
                                                    <Flex
                                                        h="24px"
                                                        py="6px"
                                                        px="10px"
                                                        bg={colorTheme('secondary.50', '#2E2650')}
                                                        borderRadius="3px"
                                                    >
                                                        <Text className={i_text_copy} fontSize="12px">
                                                            {item.type === FarmTypeEnum.Deposit && 'Add'}
                                                            {item.type === FarmTypeEnum.Withdraw && 'Dec'}
                                                            {item.type === FarmTypeEnum.CollectReward && 'Collect'}
                                                        </Text>
                                                    </Flex>
                                                </HStack>

                                                <HStack w={'100%'} pt={{ base: '10px', sm: '0px' }} whiteSpace="nowrap">
                                                    <Text w="80px" key={i} className={i_text_copy} mr="10px">
                                                        {tabs[2][0]}
                                                    </Text>
                                                    {item.type === FarmTypeEnum.CollectReward ? (
                                                        <Flex>
                                                            {collectRewardToken && (
                                                                <HStack>
                                                                    <Image boxSize="15px" mr="6px" src={collectRewardToken.icon}></Image>

                                                                    <Text className={i_text_copy} fontSize="13px">
                                                                        {formatNumber(item.amount, 2, 5, true)}
                                                                    </Text>
                                                                    <Text className={i_text_copy} fontSize="13px">
                                                                        {collectRewardToken && collectRewardToken.symbol}
                                                                    </Text>
                                                                </HStack>
                                                            )}
                                                        </Flex>
                                                    ) : (
                                                        <Stack>
                                                            <HStack>
                                                                <Image boxSize="15px" src={tokenA.icon}></Image>
                                                                <Text className={i_text_copy} fontSize="13px">
                                                                    {formatNumber(item.amountX, 2, 5, true)}
                                                                    {' ' + tokenA.symbol}
                                                                </Text>
                                                            </HStack>

                                                            <HStack>
                                                                <Image boxSize="15px" src={tokenB.icon}></Image>
                                                                <Text className={i_text_copy} fontSize="13px">
                                                                    {formatNumber(item.amountY, 2, 5, true)}
                                                                    {' ' + tokenB.symbol}
                                                                </Text>
                                                            </HStack>
                                                        </Stack>
                                                    )}
                                                </HStack>
                                                <HStack w={'100%'} pt={{ base: '10px', sm: '0px' }}>
                                                    <Text w="80px" key={i} className={i_text_copy} mr="10px">
                                                        {tabs[3][0]}
                                                    </Text>
                                                    <Text className={i_text_copy}>{item.token_id}</Text>
                                                </HStack>
                                                <HStack w={'100%'} pt={{ base: '10px', sm: '0px' }}>
                                                    <Text w="80px" key={i} className={i_text_copy} mr="10px">
                                                        {tabs[4][0]}
                                                    </Text>

                                                    <Text className={i_text_copy}>
                                                        {moment(Number(item.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                                    </Text>
                                                </HStack>
                                                <HStack w={tabs[5][1]}>
                                                    {item.txHash && (
                                                        <HStack
                                                            cursor="pointer"
                                                            pt={{ base: '10px', sm: '0px' }}
                                                            onClick={() => {
                                                                viewTrans(chainId, item.txHash);
                                                            }}
                                                        >
                                                            <Text
                                                                color={colorTheme('#0166FF', '#B08EFF')}
                                                                className={i_text_copy}
                                                                fontSize="13px"
                                                                w="90px"
                                                            >
                                                                {item.txHash && truncateWalletAddress(item.txHash, 5, 2)}
                                                            </Text>
                                                            <Icon
                                                                as={FiExternalLink}
                                                                boxSize="16px"
                                                                onClick={() => {}}
                                                                color={colorTheme('#0166FF', '#B08EFF')}
                                                            />
                                                        </HStack>
                                                    )}
                                                </HStack>
                                            </Stack>
                                        </Stack>
                                        <AccordionPanel px="0px"></AccordionPanel>
                                    </Flex>
                                </AccordionItem>
                            </Accordion>
                        );
                    })}

                <Pagination
                    totalPage={totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                    alignItems="center"
                />
            </VStack>
        </Card>
    ) : (
        <Card
            variant="deep2"
            w={{ base: '100%', xxl: '1100px', xlp1: '1150px' }}
            minW={{ base: '100%', sm: '810px' }}
            alignItems="left"
            p={{ base: '10px', sm: '30px 20px 30px 20px', xl: '30px 40px 30px 40px' }}
            mt={{ base: '10px', sm: '23px' }}
            mb={{ base: '80px', sm: '0px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
            bg={THEME_CARD[chainId]}
        >
            <VStack spacing="11px" alignItems="left">
                {!isMobile && (
                    <HStack mb="10px">
                        {tabs.map((e: any, i: any) => {
                            return (
                                <Text key={i} w={e[1]} className={i_text_copy} color={colorTheme('#9B8FAE', 'tertiary.100')}>
                                    {e[0]}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                {(netWorkloading || loading) && (
                    <Flex alignItems="center" justifyContent="center" pt="40px">
                        <Loading />
                    </Flex>
                )}
                {!netWorkloading &&
                    !loading &&
                    data &&
                    data.map((item: ResponseFarmHistory, i: number) => {
                        const tokenA = getToken(item.tokenx_addr);
                        const tokenB = getToken(item.tokeny_addr);
                        const collectRewardToken = item.rewardToken ? getToken(item.rewardToken) : undefined;

                        return (
                            <Accordion key={i} allowToggle={true}>
                                <AccordionItem border="0">
                                    <Flex direction="column">
                                        <Stack direction={{ base: 'column', sm: 'row' }}>
                                            <HStack w={isXl ? '200px' : '250px'} mt="2px !important" cursor="pointer">
                                                <TokenIcons
                                                    tokenA={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenA.name,
                                                            symbol: tokenA.symbol,
                                                            icon: tokenA.icon,
                                                            address: tokenA.addTime,
                                                            decimal: tokenA.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenB.name,
                                                            symbol: tokenB.symbol,
                                                            icon: tokenB.icon,
                                                            address: tokenB.addTime,
                                                            decimal: tokenB.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    initialToggle={false}
                                                    // chainId={item.chainId}
                                                    // showChainIcon={true}
                                                    type={isXl ? 'small' : ''}
                                                />
                                                <FeeRate
                                                    tokenA={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenA.name,
                                                            symbol: tokenA.symbol,
                                                            icon: tokenA.icon,
                                                            address: tokenA.address,
                                                            decimal: tokenA.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    tokenB={
                                                        {
                                                            chainId: chainId,
                                                            name: tokenB.name,
                                                            symbol: tokenB.symbol,
                                                            icon: tokenB.icon,
                                                            address: tokenB.addTime,
                                                            decimal: tokenB.decimal,
                                                            custom: false,
                                                        } as unknown as TokenInfoFormatted
                                                    }
                                                    feeTier={getFee(item.contract_addr).toFixed(2) as any}
                                                    initialToggle={false}
                                                    direction="row"
                                                    variant="purple"
                                                    type={isXl ? 'small' : ''}
                                                    ml={{ base: '0px !important', xl: '8px !important' }}
                                                />
                                            </HStack>
                                            <HStack w={tabs[1][1]} pt={{ base: '10px', sm: '0px' }}>
                                                <Flex
                                                    h="24px"
                                                    py="6px"
                                                    px="10px"
                                                    bg={colorTheme('secondary.50', '#2E2650')}
                                                    borderRadius="3px"
                                                >
                                                    <Text className={i_text_copy} fontSize="12px">
                                                        {item.type === FarmTypeEnum.Deposit && 'Add'}
                                                        {item.type === FarmTypeEnum.Withdraw && 'Dec'}
                                                        {item.type === FarmTypeEnum.CollectReward && 'Collect'}
                                                    </Text>
                                                </Flex>
                                            </HStack>

                                            <HStack w={tabs[2][1]} pt={{ base: '10px', sm: '0px' }} whiteSpace="nowrap">
                                                {item.type === FarmTypeEnum.CollectReward ? (
                                                    <Flex>
                                                        {collectRewardToken && (
                                                            <HStack>
                                                                <Image boxSize="15px" mr="6px" src={collectRewardToken.icon}></Image>

                                                                <Text className={i_text_copy} fontSize="13px">
                                                                    {formatNumber(item.amount, 2, 5, true)}
                                                                </Text>
                                                                <Text className={i_text_copy} fontSize="13px">
                                                                    {collectRewardToken && collectRewardToken.symbol}
                                                                </Text>
                                                            </HStack>
                                                        )}
                                                    </Flex>
                                                ) : (
                                                    <Flex>
                                                        <HStack>
                                                            <Image boxSize="15px" src={tokenA.icon}></Image>
                                                            <Text className={i_text_copy} fontSize="13px">
                                                                {formatNumber(item.amountX, 2, 5, true)}
                                                                {' ' + tokenA.symbol}
                                                            </Text>
                                                        </HStack>
                                                        <Text className={i_text_copy} fontSize="13px" mx="10px">
                                                            +
                                                        </Text>
                                                        <HStack>
                                                            <Image boxSize="15px" src={tokenB.icon}></Image>
                                                            <Text className={i_text_copy} fontSize="13px">
                                                                {formatNumber(item.amountY, 2, 5, true)}
                                                                {' ' + tokenB.symbol}
                                                            </Text>
                                                        </HStack>
                                                    </Flex>
                                                )}
                                            </HStack>
                                            <HStack w={tabs[3][1]} pt={{ base: '10px', sm: '0px' }}>
                                                <Text className={i_text_copy}>{item.token_id}</Text>
                                            </HStack>
                                            <HStack w={tabs[4][1]} pt={{ base: '10px', sm: '0px' }}>
                                                {isMobile && (
                                                    <Text
                                                        key={i}
                                                        className={i_text_copy}
                                                        color={colorTheme('#9B8FAE', 'tertiary.100')}
                                                        mr="10px"
                                                    >
                                                        {tabs[2][0]}
                                                    </Text>
                                                )}
                                                <Text className={i_text_copy}>
                                                    {moment(Number(item.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                                </Text>
                                            </HStack>
                                            <HStack w={tabs[5][1]}>
                                                {item.txHash && (
                                                    <HStack
                                                        cursor="pointer"
                                                        pt={{ base: '10px', sm: '0px' }}
                                                        onClick={() => {
                                                            viewTrans(chainId, item.txHash);
                                                        }}
                                                    >
                                                        <Text
                                                            color={colorTheme('#0166FF', '#B08EFF')}
                                                            className={i_text_copy}
                                                            fontSize="13px"
                                                            w="72px"
                                                        >
                                                            {item.txHash && truncateWalletAddress(item.txHash, 5, 2)}
                                                        </Text>
                                                        <Icon
                                                            as={FiExternalLink}
                                                            boxSize="16px"
                                                            onClick={() => {}}
                                                            color={colorTheme('#0166FF', '#B08EFF')}
                                                        />
                                                    </HStack>
                                                )}
                                            </HStack>
                                        </Stack>
                                        <AccordionPanel px="0px"></AccordionPanel>
                                    </Flex>
                                </AccordionItem>
                            </Accordion>
                        );
                    })}

                <Pagination
                    totalPage={totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Card>
    );
};
export default FarmList;
