import { BoxProps, HStack, Text, Image, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { i_text_copy_bold } from '../../style';
import { getColorThemeSelector } from '../../utils/funcs';

type TradeHintProps = {
    isShow: boolean;
    content?: string;
    onclose: any;
} & BoxProps;
export const TradeHint: React.FC<TradeHintProps> = (props) => {
    const { isShow, content, onclose, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();

    return isShow ? (
        <HStack
            w="100%"
            bg={colorTheme(
                'linear-gradient(90deg, #37BE93 1.26%, #AEDCB8 101.26%)',
                'linear-gradient(90deg, #299071 1.26%, #144B43 101.26%);'
            )}
            borderRadius="5px"
            px={{ base: '10px', sm: '17px' }}
            py="6px"
            {...rest}
        >
            <Image boxSize="12px" src="/assets/warning/info.svg" fallbackSrc="/assets/warning/info.svg"></Image>
            <Text
                className={i_text_copy_bold}
                fontFamily={{ base: 'Montserrat-SemiBold', sm: 'Montserrat-Bold' }}
                lineHeight="14px"
                letterSpacing="0.02em"
                color={'#FFFFFF'}
            >
                {content && t(content)}
            </Text>
            <Image
                boxSize="12px"
                src="/assets/warning/close.svg"
                fallbackSrc="/assets/warning/close.svg"
                ml="auto !important"
                cursor="pointer"
                onClick={() => {
                    onclose();
                }}
            ></Image>
        </HStack>
    ) : (
        <></>
    );
};
