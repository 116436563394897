import React, { useEffect } from 'react';
import { Stack, HStack, Heading } from '@chakra-ui/react';
import PageLayout from '../../components/layout/PageLayout';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../components/PageHeader/PageHeader';
import NetworkSelect from '../../components/Select/NetworkSelect/NetworkSelect';
import useIsMobile from '../../hooks/useIsMobile';
import ChainNotValidBlockWrapper from '../components/ChainNotValidBlock';
import { PortalBlock } from './PortalBlock';

const Portal: React.FC = () => {
    useEffect(() => {
        document.title = 'Portal | iZiSwap on X1';
    }, []);
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack pt="7px" w="100%" pb="30px">
                            <PortalBlock />
                        </Stack>
                    }
                    app="all"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="20px">
                            <Heading level="3" fontSize={'24px'}>
                                {t('Portal')}
                            </Heading>
                        </HStack>
                    }
                    subtitle={t('Discover more exciting content from iZUMi.')}
                    element={
                        <HStack spacing="10px">
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%" pb="30px">
                            <PortalBlock />
                        </Stack>
                    }
                    app="all"
                />
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};

export default Portal;
