import { Button, Menu, MenuButton, MenuList, Image, Box, Flex, useDisclosure, ChakraProps, BoxProps, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { links } from '../../../config/links';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { a_display, i_text_copy } from '../../../iZUMi-UI-toolkit/src/style';
import { getColorThemeSelector } from '../../../utils/funcs';
type DropDownButtonProps = {
    page: any;
    variant?: string;
    rightIcon?: any;
    buttonProps?: ChakraProps;
    menuListProps?: ChakraProps;
    index?: number;
    setBtHoverId?: any;
} & BoxProps;
export const DropDownButton: React.FC<DropDownButtonProps> = (props) => {
    const { page, rightIcon, buttonProps, menuListProps, index, setBtHoverId, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { THEME } = useCustomTheme();
    const { chainId } = useWeb3WithDefault();
    const history = useHistory();
    const [hoverId, setHoverId] = useState(-1);
    const location = useLocation();
    const currentPath = location.pathname;
    const selected = currentPath.toLowerCase().includes(page.name.toLowerCase());
    return page.children.length === 0 ? (
        <CustomButton
            h={{ base: '59px', xl: '40px' }}
            text={t(page.name)}
            bg="inherit"
            className={i_text_copy}
            fontSize="13px !important"
            color={selected ? THEME[chainId] : colorTheme('#52466A', '#DDDAE1')}
            fontWeight="600"
            px="14px"
            borderRadius="7px"
            justifyContent={{ base: 'left', xl: 'center' }}
            _hover={{ color: THEME[chainId], bg: 'none !important' }}
            _active={{ color: THEME[chainId], bg: 'none !important' }}
            onClick={() => {
                if (page.address && page.address.startsWith('http')) {
                    if (page.address === links.analyticLink) {
                        window.open(page.address + '/Dashboard?chainId=' + chainId);
                    } else {
                        window.open(page.address);
                    }
                } else {
                    history.push(page.address);
                }
            }}
        ></CustomButton>
    ) : (
        <Menu isOpen={isOpen} {...rest}>
            <MenuButton
                as={Button}
                rightIcon={rightIcon}
                h="100%"
                bg="inherit"
                className={a_display}
                fontSize="18px !important"
                onMouseEnter={onOpen}
                onMouseLeave={() => {
                    setBtHoverId(-1);
                    onClose();
                }}
                onMouseOver={() => {
                    setBtHoverId(index);
                }}
                _hover={{ background: '#7D68E6' }}
                _active={{ background: '#7D68E6' }}
                pr={{ base: '10px', lg: '20px', xl: '44px' }}
                color={selected ? colorTheme(THEME[chainId], '#7F4AFE') : '#9388AD'}
                {...buttonProps}
            >
                {t(page.name)}
            </MenuButton>
            <MenuList
                minW={'0px'}
                bg="rgba(20, 2, 51);"
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                borderWidth="0px"
                transform="translate(0px, -8px) !important"
                {...menuListProps}
            >
                {page.children.map((child: any, index: number) => {
                    const tempImg = colorTheme('/assets/header/' + child.icon, '/assets/header/dark' + child.icon);
                    const hoverImg = colorTheme('/assets/header/Hover' + child.icon, '/assets/header/Hoverdark' + child.icon);
                    const img = hoverId === index ? hoverImg : tempImg;
                    return child.address?.startsWith('http') ? (
                        <Link key={child.name + child.address} target="_blank" to={{ pathname: child.address }}>
                            <Box
                                display="block"
                                fontFamily="Montserrat-Medium"
                                fontSize="13px"
                                pt="13px"
                                mr="34px"
                                pl="27px"
                                pb="11px"
                                target="_blank"
                                color="tertiary.50"
                                onMouseOver={() => {
                                    setHoverId(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverId(-1);
                                }}
                                {...menuListProps}
                            >
                                <Flex>
                                    <Image boxSize={'20px'} mr="14px" src={img} fallbackSrc={img}></Image>

                                    {t(child.name)}
                                </Flex>
                                {index !== page.children.length - 1 && (
                                    <Image
                                        w="100%"
                                        src={colorTheme('/assets/header/purpleLine.svg', '/assets/header/grayLine.svg')}
                                        pt="13px"
                                    ></Image>
                                )}
                            </Box>
                        </Link>
                    ) : (
                        <Link to={child.address as string} key={child.name + child.address}>
                            <Box
                                maxW="172px"
                                display="block"
                                fontSize="13px"
                                fontFamily="Montserrat-Medium"
                                pt="13px"
                                mr="34px"
                                pl="27px"
                                pb="11px"
                                target="_blank"
                                color="tertiary.50"
                                whiteSpace="nowrap"
                                onMouseOver={() => {
                                    setHoverId(index);
                                }}
                                onMouseLeave={() => {
                                    setHoverId(-1);
                                }}
                                {...menuListProps}
                            >
                                <Flex fontFamily={'Montserrat-Medium'} fontSize="13px" lineHeight="20px">
                                    <Image boxSize={'20px'} mr="14px" src={img} fallbackSrc={img}></Image>
                                    {t(child.name)}
                                </Flex>
                                {index !== page.children.length - 1 && (
                                    <Image
                                        w="100%"
                                        src={colorTheme('/assets/header/purpleLine.svg', '/assets/header/grayLine.svg')}
                                        pt="13px"
                                    ></Image>
                                )}
                            </Box>
                        </Link>
                    );
                })}
            </MenuList>
        </Menu>
    );
};
