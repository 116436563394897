import produce from 'immer';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { createModel } from '@rematch/core';
import { TransactionReceipt } from 'ethereum-abi-types-generator';

import { SetFormTokenParams } from '../utils/types';
import { RootModel } from '../../index';
import { amount2Decimal, decimal2Amount } from '../../../../utils/tokenMath';
import { IZUMI_SWAP_CONFIG } from '../../../../config/bizConfig';
import { QUOTER_TYPE } from '../../../../config/trade/tradeContracts';

import { ChainId, TokenSymbol } from '../../../../types/mod';
import { isSameToken } from '../../../../utils/funcs';
import { SwapTag } from '../aggregator/config';
import { Path, PreQueryResult, SwapDirection } from '../aggregator/utils';
import { doPathQuery } from '../aggregator/controllers';
import { Contract } from 'web3-eth-contract';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { BasePathQueryPlugin } from '../aggregator/BaseDexPlugin';
import { buildSendingParams } from '../../../../utils/contractHelpers';
import { getChain, getTxLink } from '../../../../config/chains';
import { ToastLink } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { getWrappedGasTokenIfExists } from '../../../../config/tokens';
import { isContract } from '../../../../utils/isContract';
import { sendTransaction, SendTransactionParams, TransactionTypes } from '../../wallet/sendTransaction';

export interface SwapForm {
    /// X -> Y
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    fee: FeeTier;
    amount: string;
    amountDecimal: number;
    amountDesire: string;
    amountDesireDecimal: number;
    finalTick: number;


    // tradeResponse
    swapPath: Path;

    noSufficientLiquidity: boolean;
    initPriceDecimal: number;
    priceImpact: number;
    feePayedDecimal: number;

    highPt: number;

    desireMode: boolean;

    estimatedSwapOutAmount: string;
    estimatedSwapOutAmountDecimal: number;

    slippagePercent: number;
    maxDelay: number;
    quoterType: QUOTER_TYPE;
    exclusiveLiquidity: boolean;

    swapTag?: SwapTag
    pathQueryPlugin?: BasePathQueryPlugin

    tokenToPay?: TokenInfoFormatted
    spenderAddress?: string
    depositSpenderAddress?: string
}


export interface EstimateResult {
    amount: string,
    swapPath: Path,
    chainId: ChainId,
    noSufficientLiquidity: boolean,
    feePayedDecimal: number,
    initPriceDecimal: number,
    priceImpact: number,
    swapTag: SwapTag,
    pathQueryPlugin: BasePathQueryPlugin,
    tokenToPay?: TokenInfoFormatted,
    spenderAddress?: string,
    depositSpenderAddress?: string,
}

export interface SwapParams {
    account: string
    swapForm: SwapForm
    chainId: ChainId
    gasPrice: number
    onGoingCallback?: (toastLink?: any) => void
    sourceTag?: string;
    web3: Web3;
    isAaAccount?: boolean
}

export interface TradeSwapState {
    swapForm: SwapForm;
    isSearchingX: boolean;
    isSearchingY: boolean;
}

export interface SwapEstimateResult {
    payedAmountDecimal: number;
    acquireAmountDecimal: number;

    // estimateAmountDecimal may be acquireAmountDecimal or payedAmountDecimal
    // according to non-desire mode or desire mode of Quoter
    estimateAmountDecimal: number;
    noSufficientLiquidity: boolean;

    finalTick: number;
}

export interface CalSwapParams {
    swapTag: SwapTag[]
    preQueryResult: PreQueryResult[]
    multicall: Contract
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    chainId: ChainId;
    web3: Web3;
    amountDecimal: number;
    exclusiveLiquidity: boolean;
    sourceTag?: string;
}

export interface CalParams {
    multicall: Contract
    chainId: ChainId
    web3: Web3
    swapTag: SwapTag[]
    preQueryResult: PreQueryResult[]
    exclusiveLiquidity: boolean
}

export const tradeSwap = createModel<RootModel>()({
    state: {
        swapForm: {
            slippagePercent: IZUMI_SWAP_CONFIG.SWAP_DEFAULT_SLIPPAGE_PERCENT,
            maxDelay: IZUMI_SWAP_CONFIG.SWAP_DEFAULT_MAXIMUM_DELAY,
            quoterType: QUOTER_TYPE.limit,
            exclusiveLiquidity: false,
            swapPath: undefined as unknown as Path,
            noSufficientLiquidity: false,
            tokenX: {},
            tokenY: {},
            tokenToPay: undefined,
            spenderAddress: undefined,
            depositSpenderAddress: undefined,
            amountDecimal: 0
        } as unknown as SwapForm,
        isSearchingX: false,
        isSearchingY: false,
    } as TradeSwapState,
    reducers: {
        clearSwapForm: (state: TradeSwapState) => produce(state, draft=>{
            draft.swapForm = {
                slippagePercent: IZUMI_SWAP_CONFIG.SWAP_DEFAULT_SLIPPAGE_PERCENT,
                maxDelay: IZUMI_SWAP_CONFIG.SWAP_DEFAULT_MAXIMUM_DELAY,
                quoterType: QUOTER_TYPE.limit,
                exclusiveLiquidity: false,
                swapPath: undefined as unknown as Path,
                noSufficientLiquidity: false,
                tokenX: {},
                tokenY: {},
                amountDecimal: 0,
            } as unknown as SwapForm;
        }),
        saveTradeSwap: (state: TradeSwapState, payload: TradeSwapState) => {
            return { ...state, ...payload };
        },

        setIsSearchingX: (state: TradeSwapState, payload: boolean) => produce(state, (s) => {
            s.isSearchingX = payload;
        }),

        setIsSearchingY: (state: TradeSwapState, payload: boolean) => produce(state, (s) => {
            s.isSearchingY = payload;
        }),

        setSwapFormToken: (state: TradeSwapState, mintTokenParams: SetFormTokenParams) => produce(state, ({ swapForm }) => {
            const { isUpper, tokenInfo } = mintTokenParams;
            if (!isUpper && (!isSameToken(tokenInfo, swapForm.tokenX))) {
                swapForm.tokenX = tokenInfo;
                if(!swapForm.desireMode) {
                    swapForm.amount = decimal2Amount(new BigNumber(swapForm.amountDecimal), tokenInfo)?.toFixed(0) ?? '0';
                }
                if (isSameToken(tokenInfo, swapForm.tokenY)) {
                    swapForm.tokenY = {} as TokenInfoFormatted;
                }
            } else if (isUpper && (!isSameToken(tokenInfo, swapForm.tokenY))) {
                swapForm.tokenY = tokenInfo;
                if (swapForm.desireMode) {
                    swapForm.amountDesire = decimal2Amount(new BigNumber(swapForm.amountDesireDecimal), tokenInfo)?.toFixed(0) ?? '0';
                }
                if (isSameToken(tokenInfo, swapForm.tokenX)) {
                    swapForm.tokenX = {} as TokenInfoFormatted;
                }
            }
        }),
        setSwapFormEstimateResult: (state: TradeSwapState, params: EstimateResult) => produce(state, ({ swapForm }) => {
            const { amount, swapPath, noSufficientLiquidity, initPriceDecimal, priceImpact, feePayedDecimal, swapTag, pathQueryPlugin, tokenToPay, spenderAddress, depositSpenderAddress} = params;
            let amountDecimal = 0;
            if (swapForm.desireMode) {
                amountDecimal = Number(amount2Decimal(new BigNumber(amount), swapForm.tokenX));
            } else {
                amountDecimal = Number(amount2Decimal(new BigNumber(amount), swapForm.tokenY));
            }
            if (swapForm.desireMode) {
                swapForm.amount = amount;
                swapForm.amountDecimal = amountDecimal;
            } else {
                swapForm.amountDesire = amount;
                swapForm.amountDesireDecimal = amountDecimal;
            }
            swapForm.swapPath = swapPath;
            swapForm.noSufficientLiquidity = noSufficientLiquidity;
            swapForm.initPriceDecimal = initPriceDecimal;
            swapForm.priceImpact = priceImpact;
            swapForm.feePayedDecimal = feePayedDecimal;

            swapForm.swapTag = swapTag;
            swapForm.pathQueryPlugin = pathQueryPlugin;
            swapForm.tokenToPay = tokenToPay;
            swapForm.spenderAddress = spenderAddress;
            swapForm.depositSpenderAddress = depositSpenderAddress;
        }),
        setSwapFormAmountIn: (state: TradeSwapState, params: { amountDecimal: number, chainId: ChainId }) => produce(state, ({ swapForm }) => {
            const { amountDecimal } = params;
            swapForm.amountDecimal = amountDecimal;
            swapForm.amount = decimal2Amount(new BigNumber(amountDecimal), swapForm.tokenX)?.toFixed(0) ?? '0';
            swapForm.desireMode = false;
        }),
        setSwapFormAmountOut: (state: TradeSwapState, params: { amountDecimal: number, chainId: ChainId }) => produce(state, ({ swapForm }) => {
            const { amountDecimal } = params;
            swapForm.amountDesireDecimal = amountDecimal;
            swapForm.amountDesire = decimal2Amount(new BigNumber(amountDecimal), swapForm.tokenY)?.toFixed(0) ?? '0';
            swapForm.desireMode = true;
        }),
        setSwapFormSlippagePercent: (state: TradeSwapState, percent: number) => produce(state, ({ swapForm }) => {
            swapForm.slippagePercent = percent;
        }),
        setSwapFormMaxDelay: (state: TradeSwapState, maxDelay: number) => produce(state, ({ swapForm }) => {
            swapForm.maxDelay = maxDelay;
        }),
        setSwapFormQuoterType: (state: TradeSwapState, quoterType: QUOTER_TYPE) => produce(state, ({ swapForm }) => {
            swapForm.quoterType = quoterType;
        }),
        setSwapFormExclusiveLiquidity: (state: TradeSwapState, exclusive: boolean) => produce(state, ({ swapForm }) => {
            swapForm.exclusiveLiquidity = exclusive;
        }),
        toggleTokenOrder: (state: TradeSwapState) => produce(state, (s) => {
            [s.swapForm.tokenX, s.swapForm.tokenY] = [s.swapForm.tokenY, s.swapForm.tokenX];
            
            s.swapForm.swapPath = undefined as unknown as Path;
            if (s.swapForm.desireMode) {
                s.swapForm.desireMode = false;
                s.swapForm.amount = s.swapForm.amountDesire;
                s.swapForm.amountDecimal = s.swapForm.amountDesireDecimal;
                s.swapForm.amountDesire = '0';
                s.swapForm.amountDesireDecimal = 0;
                s.swapForm.initPriceDecimal = 0;
                if (Number(s.swapForm.amountDecimal)) {
                    s.isSearchingY = true;
                }
            } else {
                s.swapForm.desireMode = true;
                s.swapForm.amountDesire = s.swapForm.amount;
                s.swapForm.amountDesireDecimal = s.swapForm.amountDecimal;
                s.swapForm.amount = '0';
                s.swapForm.amountDecimal = 0;
                s.swapForm.initPriceDecimal = 0;
                if (Number(s.swapForm.amountDesireDecimal)) {
                    s.isSearchingX = true;
                }
            }

        })
    },
    effects: (dispatch) => ({
        async updateSwapFromAmount({ amountDecimal, desireMode, chainId }: { amountDecimal: number, desireMode: boolean, chainId: ChainId }): Promise<void> {
            if (desireMode) {
                dispatch.tradeSwap.setSwapFormAmountOut({ amountDecimal, chainId });
            } else {
                dispatch.tradeSwap.setSwapFormAmountIn({ amountDecimal, chainId });
            }
        },

        async calSwapAmount(params: CalSwapParams, rootState) {
            const { tokenX, tokenY, chainId, web3, amountDecimal, swapTag: originSwapTag, preQueryResult: originPreQueryResult, multicall } = params;
            if (!chainId || !web3 || !tokenX.symbol || !tokenY.symbol || !Number(amountDecimal) || !originSwapTag || !originPreQueryResult || !multicall) {
                return new Promise((_, reject) => reject('Check swap fail'));
            }
            if (originSwapTag.length === 0 || originPreQueryResult.length === 0) {
                return;
            }

            let swapTag = originSwapTag;
            let preQueryResult = originPreQueryResult;

            if (params.exclusiveLiquidity) {
                if (originSwapTag.find((e)=>e === SwapTag.iZiSwap)) {
                    const idx = originSwapTag.findIndex((e)=> e===SwapTag.iZiSwap);
                    swapTag = [originSwapTag[idx]];
                    preQueryResult = [originPreQueryResult[idx]];
                } else {
                    return;
                }
            }

            const amountSwapAmount = decimal2Amount(
                new BigNumber(amountDecimal),
                tokenX
            ) ?? new BigNumber(0);

            try {
                dispatch.tradeSwap.setIsSearchingY(true);
                const config :{[swapTag: string]:any} = {};
                config[SwapTag.iZiSwap] = rootState.tradeSwap.swapForm.quoterType;
                const e = await doPathQuery(swapTag, preQueryResult, config, chainId, web3, multicall, tokenX, tokenY, SwapDirection.ExactIn, amountSwapAmount.toFixed(0));
                if (e) {
                    const config = rootState.tradeSwap.swapForm.swapTag === SwapTag.iZiSwap ? params.sourceTag : undefined;
                    const tokenSpenderInfo = e.swapPathQueryPlugin?.getTokenSpenderInfo(e.path, SwapDirection.ExactIn, config) ?? {};
                    const factor = IZUMI_SWAP_CONFIG.SWAP_PRICE_FEE_SWITCH ? (1 - (e.feeRate??0)) : 1;
                    dispatch.tradeSwap.setSwapFormEstimateResult({
                        amount: e.amount,
                        swapPath: e.path,
                        chainId,
                        noSufficientLiquidity: false,
                        initPriceDecimal: 1 / (e.initDecimalPriceEndByStart as unknown as number) * factor,
                        priceImpact: e.priceImpact as unknown as number,
                        feePayedDecimal: e.feesDecimal as unknown as number,
                        swapTag: e.swapTag,
                        pathQueryPlugin: e.swapPathQueryPlugin,
                        ...tokenSpenderInfo
                    } as EstimateResult);
                } else {
                    dispatch.tradeSwap.setSwapFormEstimateResult({
                        amount: '0',
                        swapPath: undefined as unknown as Path,
                        chainId,
                        noSufficientLiquidity: true,
                        initPriceDecimal: 0,
                        priceImpact: 0,
                        feePayedDecimal: 0,
                    } as EstimateResult);
                }
            }
            finally {
                dispatch.tradeSwap.setIsSearchingY(false);
            }
        },

        async calSwapDesire(params: CalSwapParams, rootState) {
            const { tokenX, tokenY, chainId, web3, amountDecimal, swapTag: originSwapTag, preQueryResult: originPreQueryResult, multicall } = params;
            if (!chainId || !web3 || !tokenX.symbol || !tokenY.symbol || !Number(amountDecimal) || !originSwapTag || !originPreQueryResult || !multicall) {
                return new Promise((_, reject) => reject('Check swap fail'));
            }
            if (originSwapTag.length === 0 || originPreQueryResult.length === 0) {
                return;
            }
            
            let swapTag = originSwapTag;
            let preQueryResult = originPreQueryResult;

            if (params.exclusiveLiquidity) {
                if (originSwapTag.find((e)=>e === SwapTag.iZiSwap)) {
                    const idx = originSwapTag.findIndex((e)=> e===SwapTag.iZiSwap);
                    swapTag = [originSwapTag[idx]];
                    preQueryResult = [originPreQueryResult[idx]];
                } else {
                    return;
                }
            }

            const amountSwapDesire = decimal2Amount(
                new BigNumber(amountDecimal),
                tokenY
            ) ?? new BigNumber(0);

            try {
                dispatch.tradeSwap.setIsSearchingX(true);
                const config :{[swapTag: string]:any} = {};
                config[SwapTag.iZiSwap] = rootState.tradeSwap.swapForm.quoterType;
                const e = await doPathQuery(swapTag, preQueryResult, config, chainId, web3, multicall, tokenX, tokenY, SwapDirection.ExactOut, amountSwapDesire.toFixed(0));
                const factor = IZUMI_SWAP_CONFIG.SWAP_PRICE_FEE_SWITCH ? (1 - (e.feeRate??0)) : 1;
                if (e) {
                    const config = rootState.tradeSwap.swapForm.swapTag === SwapTag.iZiSwap ? params.sourceTag : undefined;
                    const tokenSpenderInfo = e.swapPathQueryPlugin?.getTokenSpenderInfo(e.path, SwapDirection.ExactIn, config) ?? {};
                    dispatch.tradeSwap.setSwapFormEstimateResult({
                        amount: e.amount,
                        swapPath: e.path,
                        chainId,
                        noSufficientLiquidity: false,
                        initPriceDecimal: 1 / (e.initDecimalPriceEndByStart as unknown as number) * factor,
                        priceImpact: e.priceImpact as unknown as number,
                        feePayedDecimal: e.feesDecimal as unknown as number,
                        swapTag: e.swapTag,
                        pathQueryPlugin: e.swapPathQueryPlugin,
                        ...tokenSpenderInfo
                    } as EstimateResult);
                } else {
                    dispatch.tradeSwap.setSwapFormEstimateResult({
                        amount: '0',
                        swapPath: undefined as unknown as Path,
                        chainId,
                        noSufficientLiquidity: true,
                        initPriceDecimal: 0,
                        priceImpact: 0,
                        feePayedDecimal: 0,
                    } as EstimateResult);
                }
            }
            finally {
                dispatch.tradeSwap.setIsSearchingX(false);
            }
        },

        async swap(params: SwapParams, rootState): Promise<TransactionReceipt> {

            if (!params || !params.swapForm || !params.account || !params.chainId || !params.web3) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check swap fail'));
            }
            const pathQueryPlugin = params.swapForm.pathQueryPlugin;
            if (!pathQueryPlugin) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check swap fail'));
            }            
            const { swapForm, account, chainId, onGoingCallback } = params;

            const amountIn = swapForm.amount;
            const amountOut = swapForm.amountDesire;
            const path = swapForm.swapPath;

            if (path.tokenChain[0].symbol !== swapForm.tokenX.symbol) {
                return new Promise((_, reject) => reject('Check swap path fail'));
            }
            if (path.tokenChain[path.tokenChain.length - 1].symbol !== swapForm.tokenY.symbol) {
                return new Promise((_, reject) => reject('Check swap path fail'));
            }

            const direction = swapForm.desireMode ? SwapDirection.ExactOut : SwapDirection.ExactIn;

            const config = rootState.tradeSwap.swapForm.swapTag === SwapTag.iZiSwap ? params.sourceTag : undefined;
            const {calling, options} = pathQueryPlugin.getSwapTransaction(path, direction, amountIn, amountOut, account, swapForm.maxDelay ?? 0, swapForm.slippagePercent, config);
            const targetAddress = calling._parent?._address;
            if (!targetAddress) {
                return new Promise((_, reject) => reject('no target address'));
            } else {
                const canSend = await isContract(params.web3, targetAddress);
                if (!canSend) {                
                    return new Promise((_, reject) => reject('not contract address'));
                }
            }
            options.from = params.account;

            const sendTransactionParams: SendTransactionParams = {
                chainId,
                account,
                gasPrice: params.gasPrice,
                value: options.value ? options.value : "0",
            }
            return sendTransaction(TransactionTypes.swap, calling, sendTransactionParams, params.isAaAccount, targetAddress, onGoingCallback)
        },

        async cal(params: CalParams,  rootState) {
            const swapForm = rootState.tradeSwap.swapForm;
            const exclusiveLiquidity = params.exclusiveLiquidity;
            swapForm.desireMode
            ? dispatch.tradeSwap.calSwapDesire({
                  amountDecimal: swapForm.amountDesireDecimal,
                  tokenX: swapForm.tokenX,
                  tokenY: swapForm.tokenY,
                  chainId: params.chainId,
                  web3: params.web3,
                  multicall: params.multicall,
                  swapTag: params.swapTag,
                  preQueryResult: params.preQueryResult,
                  exclusiveLiquidity
              })
            : dispatch.tradeSwap.calSwapAmount({
                  amountDecimal: swapForm.amountDecimal,
                  tokenX: swapForm.tokenX,
                  tokenY: swapForm.tokenY,
                  chainId: params.chainId,
                  web3: params.web3,
                  multicall: params.multicall,
                  swapTag: params.swapTag,
                  preQueryResult: params.preQueryResult,
                  exclusiveLiquidity
              });
        },

    })
});
