import React, { useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageLayout from '../../../components/layout/PageLayout';
import { NFTEntry } from './components/NFTEntry';
import { useLocation } from 'react-router';
//import { CgNpm } from 'react-icons/cg';
import { NftIdParams } from '../../../state/models/veiZi/types';
import ValidBlockWrapper from '../../components/ChainNotValidBlock';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import BackButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';

export const VeNFTManage: React.FC = () => {
    useEffect(() => {
        document.title = 'veiZi | iZiSwap on X1';
    }, []);
    const { t } = useTranslation();
    const location = useLocation();

    const stateParam = location.state as NftIdParams;

    const nftId = stateParam.nftId;
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            header={<PageHeader headerTitle={t('Manage veiZi NFT')} subtitle="" variant="secondary" />}
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pb="60px">
                            <BackButton mt="10px" />
                            <NFTEntry nftId={nftId} mt="0px !important" />
                        </Stack>
                    }
                    app="veiZi"
                />
            }
            px="10px"
            type="mobile"
        />
    ) : (
        <PageLayout
            header={<PageHeader headerTitle={t('Manage veiZi NFT')} subtitle="" variant="secondary" />}
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pb="60px">
                            <NFTEntry nftId={nftId} />
                        </Stack>
                    }
                    app="veiZi"
                />
            }
            internalWidth={{ base: '100%', sm: '1100px', xlp1: '1150px' }}
            px="10px"
        />
    );
};

export default VeNFTManage;
