export enum TradeState {
    create = 'create',
    submitted = 'submitted',
    success = 'success',
}
let tradeState = TradeState.create;
export const useTradeLoading = () => {
    const setInitTradeState = () => {
        tradeState = TradeState.create;
    };
    const setTradeStateIfNotSubmitted = () => {
        if (tradeState === TradeState.create) {
            tradeState = TradeState.submitted;
        }
    };
    const setTradeStateIfNotSuccess = () => {
        // if (tradeState !== TradeState.create) {
        tradeState = TradeState.success;
        // }
    };
    return { tradeState, setInitTradeState, setTradeStateIfNotSubmitted, setTradeStateIfNotSuccess };
};
