import BigNumber from 'bignumber.js';
import { useCallback } from 'react';

import { MiningCallbacks } from './common/callbacks';
import { isGasToken } from '../../../../config/tokens';
import { buildSendingParams } from '../../../../utils/contractHelpers';
import { PoolEntryState } from '../../farm/UniswapV3/dynamicRange/types';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { ChainId, FarmDynamicRangeContractVersion } from '../../../../types/mod';
import { useMiningDynamicRangeContractWithVersion } from '../../../../hooks/useContracts';

export interface DynamicRangeDepositParams {
    amount0Desired: string;
    amount1Desired: string;
    numIZI: string;
}
export interface DynamicRangeEntity {
    deposit: (params: DynamicRangeDepositParams, callbacks: MiningCallbacks, gasPrice: number) => void;
}
export interface MintCallParams {
    chainId: ChainId;
    account: any;
    poolEntry: PoolEntryState;
    miningContract: any;
    gas?: string;
    gasPrice?: number;
    depositParams: DynamicRangeDepositParams;
}

const _getMintCallV2 = (params: MintCallParams): any => {
    const { account, poolEntry, miningContract, gas, gasPrice, depositParams, chainId } = params;
    let sendValue = '0';
    if (isGasToken(poolEntry.meta.tokenA, chainId)) {
        sendValue = depositParams.amount0Desired;
    }
    if (isGasToken(poolEntry.meta.tokenB, chainId)) {
        sendValue = depositParams.amount1Desired;
    }
    const options = {
        from: account,
        value: sendValue,
        gas: gas,
        maxFeePerGas: gasPrice,
    };

    return [miningContract?.methods.deposit(
        depositParams.amount0Desired,
        depositParams.amount1Desired,
        depositParams.numIZI,
        poolEntry.data.oracleTick
    ), options];
};


const _getMintCallVeiZi = (params: MintCallParams): any => {
    const { account, poolEntry, miningContract, gas, gasPrice, depositParams, chainId } = params;
    let sendValue = '0';
    if (isGasToken(poolEntry.meta.tokenA, chainId)) {
        sendValue = depositParams.amount0Desired;
    }
    if (isGasToken(poolEntry.meta.tokenB, chainId)) {
        sendValue = depositParams.amount1Desired;
    }
    const options = {
        from: account,
        value: sendValue,
        gas: gas,
        maxFeePerGas: gasPrice,
    };

    return [miningContract?.methods.deposit(
        depositParams.amount0Desired,
        depositParams.amount1Desired,
        poolEntry.data.oracleTick
    ), options];
};

const useDynamicRangeEntity = (poolEntry: PoolEntryState): DynamicRangeEntity => {
    const { chainId, account } = useWeb3WithDefault();

    const { contract } = useMiningDynamicRangeContractWithVersion(poolEntry.meta.miningContract, poolEntry.meta.contractVersion);

    const version = poolEntry.meta.contractVersion;

    const deposit = useCallback(
        (params: DynamicRangeDepositParams, callbacks: MiningCallbacks, gasPrice: number) => {

            let getMintCall = _getMintCallV2;

            if (version === FarmDynamicRangeContractVersion.VEIZI) {
                getMintCall = _getMintCallVeiZi;
            }
            const [mintCall, options] = getMintCall(
                {
                    chainId, account, poolEntry, miningContract: contract, depositParams: params
                }
            );

            mintCall.estimateGas(buildSendingParams(chainId, options, gasPrice))
                .then((gas: number) => {
                    const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);

                    const [sendMintCall, sendOptions] = getMintCall(
                        {
                            chainId, account, poolEntry, miningContract: contract, depositParams: params, gas: gasLimit, gasPrice
                        }
                    );
                    sendMintCall.send(buildSendingParams(chainId, sendOptions, gasPrice))
                        .on('transactionHash', (e: any) => callbacks.onTransactionHash?.(e))
                        .then((e: any) => callbacks.then?.(e))
                        .catch((e: any) => callbacks.catch?.(e));
                })
                .catch((e: any) => callbacks.catch?.(e));
        },
        [chainId, version, account, poolEntry, contract]
    );
    return {
        deposit
    };
};

export default useDynamicRangeEntity;