import { useColorMode } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { THEME_CONFIG } from '../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { ChainId } from '../../types/mod';
import { getColorThemeSelector } from '../../utils/funcs';
type IziLogoProps = {
    style?: React.CSSProperties;
    onClick?: any;
};
const IziLogo: React.FC<IziLogoProps> = (props) => {
    const { style, onClick } = props;
    const { chainId } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [upperColor, setUpperColor] = useState('#C0EB00');
    const [lowerColor, setLowerColor] = useState(colorTheme('#000000', '#ECECEC'));

    useEffect(() => {
        if (!THEME_CONFIG.enable) {
            setUpperColor('#7F4AFE');
            setLowerColor('#0166FF');
            return;
        }
        if (chainId === ChainId.Linea) {
            setUpperColor('#BEE2F1');
            setLowerColor('#33A9C9');
        } else if (chainId === ChainId.Scroll) {
            setUpperColor('#FFD8AB');
            setLowerColor('#DF7031');
        } else if (chainId === ChainId.Mantle) {
            setUpperColor('#B0D7CB');
            setLowerColor('#529B96');
        } else if (chainId === ChainId.ZkSyncEra) {
            setUpperColor('#8C8DFC');
            setLowerColor('#4046C3');
        } else if (chainId === ChainId.X1Test) {
            setUpperColor('#C0EB00');
            setLowerColor(colorTheme('#000000', '#ECECEC'));
        } else {
            setUpperColor('#C0EB00');
            setLowerColor(colorTheme('#000000', '#ECECEC'));
        }
    }, [chainId, THEME_CONFIG.enable, colorTheme]);

    return (
        <div onClick={onClick}>
            <svg
                version="1.1"
                id="图层_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 84.9 89"
                // style={{ enableBackground: 'new 0 0 84.9 89' }}
                xmlSpace="preserve"
                style={{ width: '38px', height: ' 38px', ...style }}
            >
                <style type="text/css">
                    {`
            .st0{fill:#1D0928;}
            .st1{fill:${upperColor};}
            .st2{fill:${lowerColor};}
          `}
                </style>
                <g>
                    <path
                        className="st1"
                        d="M0.5,40.2L28.4,0l14.1,20.4L28.2,40.7H0.8c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2
            C0.4,40.3,0.5,40.3,0.5,40.2z"
                    />
                    <path
                        className="st1"
                        d="M84.4,40.2L56.5,0l-14,20.4l14.2,20.4h27.4c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
            C84.5,40.3,84.4,40.3,84.4,40.2z"
                    />
                    <path
                        className="st2"
                        d="M84.9,46c0.1,5.6-1,11.2-3.1,16.4c-2.1,5.2-5.2,10-9.2,14s-8.7,7.2-13.8,9.3S48.1,89,42.4,89
            c-5.6,0-11.2-1.1-16.4-3.3s-9.9-5.3-13.8-9.3c-3.9-4-7.1-8.7-9.2-14C1,57.2-0.1,51.6,0,46h27c0,2.1,0.3,4.1,1.1,6
            c0.8,1.9,1.9,3.7,3.3,5.1c1.4,1.5,3.2,2.6,5.1,3.4c1.9,0.8,3.9,1.2,6,1.2c2.1,0,4.1-0.4,6-1.2c1.9-0.8,3.6-2,5.1-3.4
            c1.4-1.5,2.6-3.2,3.3-5.1c0.8-1.9,1.1-4,1.1-6H84.9z"
                    />
                </g>
            </svg>
        </div>
    );
};

export default IziLogo;
