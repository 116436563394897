import { BoxProps, HStack, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { AdaptationMode } from '../../../components/layout/PageLayout';
import useCustomTheme from '../../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { getColorThemeSelector } from '../../../utils/funcs';

type Props = {
    dex: 'iZi' | 'uni';
    type?: AdaptationMode;
} & BoxProps;

const TypeSelect: React.FC<Props> = (props) => {
    const { type } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { THEME, THEME_BUTTON_FONT } = useCustomTheme();
    const { dex, ...rest } = props;
    const history = useHistory();
    const location = useLocation();
    const { chainId } = useWeb3WithDefault();

    return type === 'mobile' ? (
        <HStack w="342px" alignItems="start" justifyContent="start" spacing="15px" {...rest}>
            <CustomButton
                bg={location.pathname === `/farm/${dex}/dynamic` ? THEME[chainId] : 'inherit'}
                _selected={{}}
                border="none"
                color={location.pathname === `/farm/${dex}/dynamic` ? THEME_BUTTON_FONT[chainId] : '#9E96AF'}
                fontFamily={location.pathname !== `/farm/${dex}/dynamic` ? 'Montserrat' : 'Montserrat-Bold'}
                onClick={() => {
                    location.pathname !== `/farm/${dex}/dynamic` && history.push(`/farm/${dex}/dynamic`);
                }}
                borderRadius="3px"
                text={t('Dynamic')}
                h="25px"
                w="80px"
                fontSize="12px"
                letterSpacing="0.02em"
            />
            <CustomButton
                bg={location.pathname === `/farm/${dex}/fix` ? THEME[chainId] : 'inherit'}
                border="none"
                _selected={{}}
                color={location.pathname === `/farm/${dex}/fix` ? THEME_BUTTON_FONT[chainId] : '#9E96AF'}
                fontFamily={location.pathname !== `/farm/${dex}/fix` ? 'Montserrat' : 'Montserrat-Bold'}
                onClick={() => {
                    location.pathname !== `/farm/${dex}/fix` && history.push(`/farm/${dex}/fix`);
                }}
                borderRadius="3px"
                text={t('Fix Range')}
                h="25px"
                w="80px"
                fontSize="12px"
            />

            <CustomButton
                bg={location.pathname === `/farm/${dex}/one-side` ? THEME[chainId] : 'inherit'}
                border="none"
                _selected={{}}
                color={location.pathname === `/farm/${dex}/one-side` ? THEME_BUTTON_FONT[chainId] : '#9E96AF'}
                fontFamily={location.pathname !== `/farm/${dex}/one-side` ? 'Montserrat' : 'Montserrat-Bold'}
                onClick={() => {
                    location.pathname !== `/farm/${dex}/one-side` && history.push(`/farm/${dex}/one-side`);
                }}
                borderRadius="3px"
                text={t('One Side')}
                h="25px"
                w="80px"
                fontSize="12px"
            />
        </HStack>
    ) : (
        <HStack
            w="100%"
            alignItems="start"
            //justifyContent={{ base: 'space-between' }}
            justifyContent="end"
            spacing="20px"
            {...rest}
        >
            <CustomButton
                variant={location.pathname === `/farm/${dex}/fix` ? 'purple' : 'outlinePurple'}
                bg={location.pathname === `/farm/${dex}/fix` ? THEME[chainId] : 'unset'}
                color={location.pathname === `/farm/${dex}/fix` ? THEME_BUTTON_FONT[chainId] : colorTheme('tertiary.600', '#EBE6F0')}
                _hover={{ borderColor: THEME[chainId] }}
                onClick={() => {
                    location.pathname !== `/farm/${dex}/fix` && history.push(`/farm/${dex}/fix`);
                }}
                borderRadius="3px"
                text={t('Fix Range')}
                h="30px"
                w="130px"
                px="10px"
                fontSize="12px !important"
            />

            <CustomButton
                variant={location.pathname === `/farm/${dex}/one-side` ? 'purple' : 'outlinePurple'}
                bg={location.pathname === `/farm/${dex}/one-side` ? THEME[chainId] : 'unset'}
                color={location.pathname === `/farm/${dex}/one-side` ? THEME_BUTTON_FONT[chainId] : colorTheme('tertiary.600', '#EBE6F0')}
                _hover={{ borderColor: THEME[chainId] }}
                onClick={() => {
                    location.pathname !== `/farm/${dex}/one-side` && history.push(`/farm/${dex}/one-side`);
                }}
                borderRadius="3px"
                text={t('One Side')}
                h="30px"
                w="130px"
                px="10px"
                fontSize="12px !important"
            />

            <CustomButton
                variant={location.pathname === `/farm/${dex}/dynamic` ? 'purple' : 'outlinePurple'}
                bg={location.pathname === `/farm/${dex}/dynamic` ? THEME[chainId] : 'unset'}
                color={location.pathname === `/farm/${dex}/dynamic` ? THEME_BUTTON_FONT[chainId] : colorTheme('tertiary.600', '#EBE6F0')}
                _hover={{ borderColor: THEME[chainId] }}
                onClick={() => {
                    location.pathname !== `/farm/${dex}/dynamic` && history.push(`/farm/${dex}/dynamic`);
                }}
                borderRadius="3px"
                text={t('Dynamic')}
                h="30px"
                w="130px"
                px="10px"
                fontSize="12px !important"
            />
        </HStack>
    );
};

export default TypeSelect;
