import { HStack, Stack, VStack, IconButton, Icon, useColorMode, Tooltip, BoxProps } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { formatNumber } from '../../../../../utils/tokenMath';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { RootDispatch, RootState } from '../../../../../state/store';
import { findPoolEntryByPoolKey } from '../../../../../state/models/farm/iZiSwap/fixRange/funcs';
import React, { useEffect, useMemo, useState } from 'react';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { CustomNumberInput } from '../../../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { AiOutlineSync, AiOutlineInfoCircle, AiOutlineConsoleSql } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import { isNumeric } from '../../../../../utils/valid';
import { InitLiquidityParams, PoolEntryState } from '../../../../../state/models/farm/iZiSwap/fixRange/types';
import { getColorThemeSelector, identity, parseJsonRpcError } from '../../../../../utils/funcs';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { i_text_copy_bold, i_text_copy } from '../../../../../style';
import { parallelCollect } from '../../../../../net/contractCall/parallel';
import CurrentPriceBlock from '../../../../components/CurrentPriceBlock';
import { TokenIconSymbol } from '../../../../components/TokenIconSymbol';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import BigNumber from 'bignumber.js';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import ApproveTokenButton from '../../../../components/ApproveTokenButton';
import { TokenIcons } from '../../../../Trade/components/TokenIcons';
import { FeeRate } from '../../../../Trade/components/FeeRate';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import { point2PriceDecimal, point2PriceUndecimal } from '../../../../../state/models/farm/iZiSwap/price';
import { getFieldValidateInfo } from '../../../../../state/models/trade/liquidity/validationHelper';
import { LiquidityManagerContract } from '../../../../../types/abis/iZiSwap/LiquidityManager';
import { useLiquidityManagerContract } from '../../../../../utils/contractFactory';
import { LIQUIDITY_MANAGER_ADDRESS } from '../../../../../config/trade/tradeContracts';
import { useTranslation } from 'react-i18next';
import { validateMintForm } from '../../../../../state/models/farm/iZiSwap/fixRange/addLiquidity/validationHelper';
import { getChain, getTxLink } from '../../../../../config/chains';
import { TradeHint } from '../../../../../components/WarningInfo/TradeHint';
import useCustomTheme from '../../../../../hooks/useCustomTheme';

const IconWithSymbol = TokenIconSymbol;

type AddLiquidityModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
    entry: PoolEntryState;
} & BoxProps;

const AddLiquidityModal: React.FC<AddLiquidityModalProps> = (props) => {
    const { isOpen, onClose, entry, ...rest } = props;
    const { t } = useTranslation();
    const { THEME, THEME_CARD, THEME_SELECT, THEME_BUTTON_FONT } = useCustomTheme();
    console.log('add liquidity modal');

    const {
        farmFixRangeiZi: { poolEntryList },
        farmFixRangeiZiLiquidity: { mintForm },
    } = useSelector((state: RootState) => state);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const { account, chainId, web3 } = useWeb3WithDefault();
    const liquidityManagerContract = useLiquidityManagerContract(chainId, web3);

    const toast = useCustomToast();

    const liquidityManagerAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];

    const token0Entity = useTokenEntity(mintForm.token0, liquidityManagerAddress, undefined, true);
    const token1Entity = useTokenEntity(mintForm.token1, liquidityManagerAddress, undefined, true);

    const [estimateUSD, setEstimateUSD] = useState(0);
    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        if (entry.meta.remark) {
            setShowHint(true);
        } else {
            setShowHint(false);
        }
    }, [entry.meta.remark]);
    useEffect(() => {
        if (!mintForm.token0 || !mintForm.token1 || !mintForm.token0.symbol || !mintForm.token1.symbol) {
            return;
        }
        parallelCollect(
            dispatch.token.fetchTokenPriceIfMissing(mintForm.token0),
            dispatch.token.fetchTokenPriceIfMissing(mintForm.token1)
        ).then((priceResult: number[]) => {
            let estimateValue = 0;
            estimateValue += (isNumeric(mintForm.amount0DecimalDesired) ? mintForm.amount0DecimalDesired : 0) * priceResult[0];
            estimateValue += (isNumeric(mintForm.amount1DecimalDesired) ? mintForm.amount1DecimalDesired : 0) * priceResult[1];
            setEstimateUSD(estimateValue);
        });
    }, [mintForm.amount0DecimalDesired, mintForm.amount1DecimalDesired, mintForm.token0, mintForm.token1, dispatch]);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { gasPrice } = useGasPrice();

    const { width } = useWindowDimensions();
    const changeLineWidth = 960;

    console.log('liquidity pool key: ', mintForm.liquidityPoolKey);
    console.log('pool entry list: ', poolEntryList);

    if (!mintForm.liquidityPoolKey || poolEntryList.length === 0) {
        console.log('null null null');
        return null;
    }

    const poolEntry = findPoolEntryByPoolKey(poolEntryList, mintForm.liquidityPoolKey);

    // Trick: price are computed from poolEntry, which is alway in a certain order
    // if the token order in poolEntry is not the same as in mintForm, the price
    // should be toggled.
    // console.log(poolEntry);

    let priceDecimal = point2PriceDecimal(poolEntry.meta.tokenA, poolEntry.meta.tokenB, poolEntry.data.currentPoint);

    let price = Number(point2PriceUndecimal(poolEntry.meta.tokenA, poolEntry.meta.tokenB, poolEntry.data.currentPoint));
    let currentPoint0By1 = poolEntry.data.currentPoint;

    price = mintForm.token0.address === poolEntry.meta.tokenA.address ? price : 1 / price;
    priceDecimal = mintForm.token0.address === poolEntry.meta.tokenA.address ? priceDecimal : 1 / priceDecimal;
    currentPoint0By1 = mintForm.token0.address === poolEntry.meta.tokenA.address ? currentPoint0By1 : -currentPoint0By1;

    const needToken0 = currentPoint0By1 < mintForm.tickUpper;
    const needToken1 = currentPoint0By1 > mintForm.tickLower;

    const validateResult = validateMintForm(mintForm, [token0Entity.tokenBalance(), token1Entity.tokenBalance()]);

    const isChangeLine = changeLineWidth > width ? true : false;

    const amount0Desired = useMemo(() => mintForm?.amount0Desired?.toFixed() ?? '0', [mintForm]);
    const amount1Desired = useMemo(() => mintForm?.amount1Desired?.toFixed() ?? '0', [mintForm]);
    console.log('mint form amount0: ', mintForm.amount0DecimalDesired);
    console.log('mint form amount1: ', mintForm.amount1DecimalDesired);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '73%', lg: '744px' }}
            h="unset"
            title={t('Add liquidity')}
            top={{ base: '55%', xl: '51%' }}
            minH="750px"
            bg={THEME_CARD[chainId]}
            modalBg={THEME_CARD[chainId]}
            {...rest}
            overflowX="hidden"
        >
            <VStack w="100%" spacing="10px" alignItems="start">
                <Card variant="deep" w="100%" h={isChangeLine ? 'unset' : '108px'} mt="-10px" bg={THEME_SELECT[chainId]}>
                    <Stack
                        direction={isChangeLine ? 'column' : 'row'}
                        w="100%"
                        spacing="10px"
                        p={isChangeLine ? '14px 20px 14px 25px' : '14px 20px 14px 40px'}
                        justifyContent="space-between"
                    >
                        <HStack>
                            <TokenIcons tokenA={mintForm.token0} tokenB={mintForm.token1} initialToggle={false} />
                            <FeeRate
                                tokenA={mintForm.token0}
                                tokenB={mintForm.token1}
                                feeTier={entry.meta.feeTier as FeeTier}
                                initialToggle={false}
                            />
                        </HStack>
                        <CurrentPriceBlock
                            tokenA={mintForm.token0}
                            tokenB={mintForm.token1}
                            price={priceDecimal}
                            handleToggle={() => dispatch.farmFixRangeiZiLiquidity.toggleTokenOrder()}
                            w={isChangeLine ? '100%' : '241px'}
                            mt={isChangeLine ? '40px !important' : 'unset'}
                            bg={THEME_CARD[chainId]}
                        />
                    </Stack>
                </Card>
                <Stack direction="row" h="35px" w="150px" alignItems="center">
                    <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                        {t('Volume')}
                    </Text>
                    <Tooltip label={'The tokens will be added into the iZiSwap pool to earn fees.'}>
                        <IconButton
                            size="sm"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </Stack>

                <Stack
                    direction={isChangeLine ? 'column' : 'row'}
                    bg={colorTheme('#FDFDFD', THEME_SELECT[chainId])}
                    pt="16px"
                    w="100%"
                    boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined')}
                    borderRadius="6px"
                    px={isChangeLine ? '20px' : '30px'}
                    pb="16px"
                >
                    <VStack
                        bg={colorTheme('#fff', THEME_CARD[chainId])}
                        boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                        borderRadius="6px"
                        border={colorTheme('undefined', '1px solid #8664CE')}
                        pb={isChangeLine ? '16px' : '10px'}
                        w={isChangeLine ? '100%' : '270px'}
                    >
                        <Stack
                            direction={isChangeLine ? 'column' : 'row'}
                            h={isChangeLine ? 'unset' : '31px'}
                            px="17px"
                            justifyContent="space-between"
                            w="100%"
                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            alignItems="center"
                        >
                            <IconWithSymbol token={mintForm.token0} mt={isChangeLine ? '10px' : 'unset'} />

                            <Text
                                color="tertiary.300"
                                variant="caption"
                                w={isChangeLine ? '100%' : 'unset'}
                                pb={isChangeLine ? '10px' : 'unset'}
                                textAlign={isChangeLine ? 'center' : 'unset'}
                            >
                                {formatNumber(token0Entity.tokenBalance())}
                            </Text>
                        </Stack>
                        <VStack w={isChangeLine ? '100%' : 'unset'}>
                            {needToken0 ? (
                                <>
                                    <CustomNumberInput
                                        inputValue={mintForm.amount0DecimalDesired && Number(mintForm.amount0DecimalDesired).toFixed(4)}
                                        onBlur={(value) => {
                                            isNumeric(value) &&
                                                dispatch.farmFixRangeiZiLiquidity.setMintFormAmountDesired({
                                                    isDesired0: true,
                                                    desiredAmountDecimal: value,
                                                    currentTick: poolEntry.data.currentPoint,
                                                });
                                        }}
                                        errorInfo={getFieldValidateInfo(validateResult, 'amount0Desired')}
                                    />
                                    <HStack w={isChangeLine ? '100%' : 'unset'} px={isChangeLine ? '10px' : 'unset'}>
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="tertiary"
                                            text="Clear"
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeiZiLiquidity.setMintFormAmountDesired({
                                                    isDesired0: true,
                                                    desiredAmountDecimal: 0,
                                                    currentTick: poolEntry.data.currentPoint,
                                                })
                                            }
                                        />
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="primary2"
                                            text="All"
                                            ml={isChangeLine ? '10px' : 'unset'}
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeiZiLiquidity.setMintFormAmountDesired({
                                                    isDesired0: true,
                                                    desiredAmountDecimal: token0Entity.tokenBalance(),
                                                    currentTick: poolEntry.data.currentPoint,
                                                })
                                            }
                                        />
                                    </HStack>
                                </>
                            ) : (
                                <Text color="tertiary.300" variant="caption">
                                    {t('No Need')}
                                </Text>
                            )}
                        </VStack>
                    </VStack>

                    <IconButton
                        size="sm"
                        transform="rotate(90deg)"
                        aria-label=""
                        variant="ghost"
                        alignSelf="center"
                        width="15px"
                        height="15px"
                        icon={<Icon as={MdAdd} boxSize="20px" />}
                    />
                    <VStack
                        bg={colorTheme('#fff', THEME_CARD[chainId])}
                        boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'undefined')}
                        borderRadius="6px"
                        border={colorTheme('undefined', '1px solid #8664CE')}
                        pb="10px"
                        w={isChangeLine ? '100%' : '270px'}
                    >
                        <Stack
                            direction={isChangeLine ? 'column' : 'row'}
                            h={isChangeLine ? 'unset' : '31px'}
                            px="17px"
                            justifyContent="space-between"
                            w="100%"
                            borderBottom={colorTheme('1px solid', '0.5px solid')}
                            borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            alignItems="center"
                        >
                            <IconWithSymbol token={mintForm.token1} mt={isChangeLine ? '10px' : 'unset'} />

                            <Text
                                color="tertiary.300"
                                variant="caption"
                                w={isChangeLine ? '100%' : 'unset'}
                                pb={isChangeLine ? '10px' : 'unset'}
                                textAlign={isChangeLine ? 'center' : 'unset'}
                            >
                                {formatNumber(token1Entity.tokenBalance())}
                            </Text>
                        </Stack>
                        <VStack w={isChangeLine ? '100%' : 'unset'}>
                            {needToken1 ? (
                                <>
                                    <CustomNumberInput
                                        inputValue={mintForm.amount1DecimalDesired && Number(mintForm.amount1DecimalDesired).toFixed(4)}
                                        onBlur={(value) => {
                                            isNumeric(value) &&
                                                dispatch.farmFixRangeiZiLiquidity.setMintFormAmountDesired({
                                                    isDesired0: false,
                                                    desiredAmountDecimal: value,
                                                    currentTick: poolEntry.data.currentPoint,
                                                });
                                        }}
                                        errorInfo={getFieldValidateInfo(validateResult, 'amount1Desired')}
                                    />
                                    <HStack w={isChangeLine ? '100%' : 'unset'} px={isChangeLine ? '10px' : 'unset'}>
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="tertiary"
                                            text="Clear"
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeiZiLiquidity.setMintFormAmountDesired({
                                                    isDesired0: false,
                                                    desiredAmountDecimal: 0,
                                                    currentTick: poolEntry.data.currentPoint,
                                                })
                                            }
                                        />
                                        <CustomButton
                                            w={isChangeLine ? '50%' : '88px'}
                                            h="24px"
                                            variant="primary2"
                                            text="All"
                                            ml={isChangeLine ? '10px' : 'unset'}
                                            fontClass={i_text_copy_bold}
                                            onClick={() =>
                                                dispatch.farmFixRangeiZiLiquidity.setMintFormAmountDesired({
                                                    isDesired0: false,
                                                    desiredAmountDecimal: token1Entity.tokenBalance(),
                                                    currentTick: poolEntry.data.currentPoint,
                                                })
                                            }
                                        />
                                    </HStack>
                                </>
                            ) : (
                                <Text color="tertiary.300" variant="caption">
                                    {t('No Need')}
                                </Text>
                            )}
                        </VStack>
                    </VStack>
                </Stack>
                <Text variant="caption" color="tertiary.300" transform="scale(0.83)" alignSelf="center" textAlign="center">
                    {t('Estimate Value')} : &nbsp; &nbsp; ${formatNumber(estimateUSD)} USD
                </Text>
                <Stack direction="row" h="35px" w="150px" alignItems="center">
                    <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                        {t('Price Range')}
                    </Text>
                    <IconButton
                        size="sm"
                        aria-label=""
                        variant="ghost"
                        isRound={true}
                        onClick={() => dispatch.farmFixRangeiZiLiquidity.toggleTokenOrder()}
                        icon={<Icon as={AiOutlineSync} />}
                    />
                </Stack>

                <Stack
                    direction={isChangeLine ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    bg={colorTheme('#fff', THEME_SELECT[chainId])}
                    boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', 'undefined')}
                    borderRadius="6px"
                    borderColor="tertiary.100"
                    w="100%"
                    py="16px"
                    px="20px"
                >
                    <VStack w="340px" justifyContent="space-between" spacing="15px" pt="15px !important">
                        <HStack>
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.200')}>
                                1
                            </Text>
                            <IconWithSymbol token={mintForm.token0} />
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.200')}>
                                = &nbsp; ?
                            </Text>

                            <IconWithSymbol token={mintForm.token1} />
                        </HStack>
                        <HStack>
                            <CustomButton
                                w="88px"
                                h="24px"
                                text={t('Default')}
                                variant="tertiary"
                                fontClass={i_text_copy_bold}
                                onClick={() => dispatch.farmFixRangeiZiLiquidity.setMintFormDefault(priceDecimal)}
                            />
                        </HStack>
                    </VStack>

                    <VStack w="200px" alignItems="center">
                        <Card variant="deep">
                            <CustomNumberInput
                                onDec={() =>
                                    dispatch.farmFixRangeiZiLiquidity.addMintFormTick({
                                        stepPositive: false,
                                        isUpper: false,
                                        currentTick: poolEntry.data.currentPoint,
                                    })
                                }
                                onInc={() =>
                                    dispatch.farmFixRangeiZiLiquidity.addMintFormTick({
                                        stepPositive: true,
                                        isUpper: false,
                                        currentTick: poolEntry.data.currentPoint,
                                    })
                                }
                                inputValue={mintForm.tickLowerPriceDecimal?.toPrecision(8)}
                                onBlur={(value) => {
                                    isNumeric(value) &&
                                        dispatch.farmFixRangeiZiLiquidity.setMintFormTickPrice({
                                            tickPriceDecimal: value,
                                            tickPrice: 0,
                                            isUpper: false,
                                            currentTick: poolEntry.data.currentPoint,
                                        });
                                }}
                                errorInfo={getFieldValidateInfo(validateResult, 'tickLower')}
                            />
                        </Card>
                        <Text color={colorTheme('tertiary.600', 'tertiary.300')} variant="caption-bold">
                            {t('Min Price')}
                        </Text>
                    </VStack>

                    <VStack w="200px" alignItems="center">
                        <Card variant="deep">
                            <CustomNumberInput
                                onDec={() =>
                                    dispatch.farmFixRangeiZiLiquidity.addMintFormTick({
                                        stepPositive: false,
                                        isUpper: true,
                                        currentTick: poolEntry.data.currentPoint,
                                    })
                                }
                                onInc={() =>
                                    dispatch.farmFixRangeiZiLiquidity.addMintFormTick({
                                        stepPositive: true,
                                        isUpper: true,
                                        currentTick: poolEntry.data.currentPoint,
                                    })
                                }
                                inputValue={mintForm.tickUpperPriceDecimal?.toPrecision(8)}
                                onBlur={(value) => {
                                    isNumeric(value) &&
                                        dispatch.farmFixRangeiZiLiquidity.setMintFormTickPrice({
                                            tickPriceDecimal: value,
                                            tickPrice: 0,
                                            isUpper: true,
                                            currentTick: poolEntry.data.currentPoint,
                                        });
                                }}
                                errorInfo={getFieldValidateInfo(validateResult, 'tickUpper')}
                            />
                        </Card>
                        <Text color={colorTheme('tertiary.600', 'tertiary.300')} variant="caption-bold">
                            {t(' Max Price')}
                        </Text>
                    </VStack>
                </Stack>

                {showHint && (
                    <TradeHint
                        isShow={showHint}
                        content={entry.meta.remark}
                        onclose={() => {
                            setShowHint(false);
                        }}
                        mt="17px !important"
                    ></TradeHint>
                )}
                <Stack w="100%" direction={{ base: 'column', sm: 'row' }} mt={showHint ? '17px !important' : '40px !important'}>
                    <CustomButton
                        hidden={
                            ![
                                amount0Desired === '0' || token0Entity.isEnoughApproved(amount0Desired),
                                amount1Desired === '0' || token1Entity.isEnoughApproved(amount1Desired),
                            ].every(identity)
                        }
                        disabled={validateResult !== undefined}
                        variant="purple"
                        text={t('Add Liquidity')}
                        className={i_text_copy}
                        fontSize="14px"
                        w={{ base: '100%', sm: '300px' }}
                        h="51px"
                        mx="auto"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        onClick={() =>
                            dispatch.farmFixRangeiZiLiquidity
                                .estimateMintGasLimit({
                                    account: account!,
                                    mintForm,
                                    liquidityManagerContract: liquidityManagerContract as unknown as LiquidityManagerContract,
                                    chainId,
                                    gasPrice,
                                })
                                .then((gas: number) => {
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                                    dispatch.farmFixRangeiZiLiquidity
                                        .mintLiquidityFromUniswap({
                                            account: account!,
                                            mintForm,
                                            liquidityManagerContract: liquidityManagerContract as unknown as LiquidityManagerContract,
                                            chainId,
                                            gas: gasLimit,
                                            gasPrice,
                                        })
                                        .on('transactionHash', (e: any) => {
                                            if (chain) {
                                                toastLink.title = 'View on ' + chain.name;
                                                toastLink.link = getTxLink(e, chain);
                                            }
                                            toast('info', 'Mint iZiSwap NFT: ' + e, undefined, toastLink);
                                        })
                                        .then((e: any) => {
                                            console.log(e);
                                            // refresh if success
                                            toast('success', 'Mint iZiSwap NFT successfully', undefined, toastLink);
                                            dispatch.farmFixRangeiZi.initLiquidity({
                                                chainId: chainId,
                                                web3,
                                                liquidityManagerContract,
                                                account,
                                            } as InitLiquidityParams);
                                        })
                                        .catch((e: any) => {
                                            toast('error', e.message);
                                        });
                                })
                                .catch((e: any) => {
                                    toast('error', parseJsonRpcError(e));
                                })
                        }
                    />

                    <ApproveTokenButton tokenEntity={token0Entity} approveAmount={amount0Desired} w="200px" mx="auto !important" />

                    <ApproveTokenButton tokenEntity={token1Entity} approveAmount={amount1Desired} w="200px" mx="auto !important" />
                </Stack>
            </VStack>
        </Modal>
    );
};

export default AddLiquidityModal;
