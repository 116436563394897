import { CyberApp } from '@cyberlab/cyber-app-sdk';

const loginWithCyber = async (): Promise<CyberApp | void> => {
    const cyberApp = new CyberApp({ name: 'izumi', icon: 'icon.png', appId: '5617fdf8-4fde-4127-9e58-4bf15e9eeb29' });

    const cyberAccount = await cyberApp.start();
    if (cyberAccount) {
        console.log('Connected to CyberWallet', cyberAccount, 'app: ', cyberApp);
        return cyberApp;
    } else {
        console.log('Failed to connect to CyberWallet');
    }
};

export { loginWithCyber };
