import { tokenSymbol2token } from '../../../../../config/tokens';
import { ChainId, TokenSymbol } from '../../../../../types/mod';

export const SupportFeeTiers = {
    [ChainId.BSC]: [0.01, 0.05, 0.3, 1], // ${fee}%
    [ChainId.BSCTestnet]: [0.01, 0.05, 0.3, 1],
    [ChainId.Aurora]: [0.01, 0.05, 0.3, 1],
    [ChainId.AuroraTestnet]: [0.01, 0.05, 0.3, 1],
    [ChainId.ETC]: [0.01, 0.05, 0.3, 1],
    [ChainId.Arbitrum]: [0.01, 0.05, 0.3, 1],
    [ChainId.Matic]: [0.01, 0.05, 0.3, 1],
    [ChainId.Cronos]: [0.01, 0.05, 0.3, 1],
    [ChainId.Rinkeby]: [0.01, 0.05, 0.3, 1],
    [ChainId.Optimism]: [0.01, 0.05, 0.3, 1],
    [ChainId.EthereumMainnet]: [0.01, 0.05, 0.3, 1],
} as Record<ChainId, FeeTier[]>;

export const SUPPORTED_001_POOL = {
    [ChainId.BSC]: [
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
            tokenB: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSC),
        },
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
            tokenB: tokenSymbol2token(TokenSymbol.BUSD, ChainId.BSC),
        },
    ],
    [ChainId.BSCTestnet]: [
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSCTestnet),
            tokenB: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSCTestnet),
        },
        {
            tokenA: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSCTestnet),
            tokenB: tokenSymbol2token(TokenSymbol.BUSD, ChainId.BSCTestnet),
        },
    ],
    [ChainId.Aurora]: [],
    [ChainId.AuroraTestnet]: [],
    [ChainId.ETC]: [],
} as any;


export const MULTIHOP_MID_TOKEN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.BSCTestnet]: [TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT],
    [ChainId.ETC]: [TokenSymbol.ETC, TokenSymbol.IUSD],
} as Record<ChainId, TokenSymbol[]>;


export const STABLE_COIN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.BSCTestnet]: [TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.USDT, TokenSymbol.USDC],
    [ChainId.ETC]: [TokenSymbol.IUSD],
} as Record<ChainId, TokenSymbol[]>;

export const isSwapRouter02 = (chainId: ChainId):boolean => {
    return chainId === ChainId.Optimism || chainId === ChainId.BSC;
};