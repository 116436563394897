import React, { useState } from 'react';
import { Box, Image, Text, Flex, useColorMode } from '@chakra-ui/react';
import { i_h3 } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { CustomTag } from '../../../components/Tag';
import '../HomePiece3/HomePiece3.css';
import '../HomePiece6/HomePiece6.css';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'ahooks';
import { i_h5 } from '../../../../iZUMi-UI-toolkit/src/style';
const LiquidityPartners: React.FC = () => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const l: Record<number, string> = {};

    const shortShowLength = 20;
    const allShowLength = 30;
    const [showLength, setShowLength] = useState(shortShowLength);
    const [isShowAll, setIsShowAll] = useState(false);

    useInterval(() => {
        if (isShowAll) {
            if (showLength + 4 < allShowLength) {
                setShowLength(showLength + 4);
            } else {
                setShowLength(allShowLength);
            }
        } else {
            if (showLength > shortShowLength) {
                setShowLength(showLength - 4);
            } else {
                setShowLength(shortShowLength);
            }
        }
    }, 20);

    const singleItem = (item: any, index: number) => {
        return (
            <Flex
                //flexBasis="25%"
                w={{ base: '25%' }}
                flexShrink={0}
                h={{ base: '100px', sm: '140px' }}
                justify="center"
                alignItems="center"
                key={index}
                position="relative"
                className={!isShowAll && index > shortShowLength - 5 ? 'opa' : ''}
            >
                <Image
                    w={{ base: '80%', lg: 'unset' }}
                    src={process.env.PUBLIC_URL + '/assets/home/homePiece6/liquidityPartners/' + index + colorTheme('', 'Dark') + '.png'}
                    onClick={
                        index in l
                            ? () => {
                                  window.open(l[index]);
                              }
                            : () => {}
                    }
                    cursor={index in l ? 'pointer' : 'unset'}
                    className={!isShowAll && index > shortShowLength - 5 ? 'opa' : ''}
                />
                {index in l && (
                    <CustomTag
                        variant="purple"
                        position="absolute"
                        text="Buy iZi"
                        right="5"
                        top="5"
                        px="5px"
                        onClick={
                            l[index]
                                ? () => {
                                      window.open(l[index]);
                                  }
                                : () => {}
                        }
                        cursor={l[index] ? 'pointer' : 'unset'}
                    ></CustomTag>
                )}
            </Flex>
        );
    };

    return (
        <Box
            className={isShowAll ? '' : 'overflowAnchor'}
            w="100%"
            pt="10px"
            h={!isShowAll ? { base: '650px', sm: '870px' } : { base: '960px', sm: '1280px' }}
            transitionProperty="height"
            transitionDuration="1s"
            transitionTimingFunction="ease"
        >
            <Flex justifyContent="space-between" alignItems="center" h="26px" mx="auto" w={{ base: '90%', lg: '620px' }}>
                <Box w={{ base: '20%', lg: '150px' }} h="1px" backgroundColor="#BEB6C6"></Box>
                <Text
                    mt="8px"
                    mb="12px"
                    bgGradient={colorTheme('linear(98.73deg, #1F0C4E -2.37%, #4F1FB4 89.44%)', 'undefined')}
                    bgClip={colorTheme('text', 'undefined')}
                    className={i_h3}
                    color={colorTheme('undefined', '#EBE6F0')}
                    textAlign="center"
                >
                    {t('Liquidity Partners')}
                </Text>
                <Box w={{ base: '20%', lg: '150px' }} h="1px" backgroundColor="#BEB6C6"></Box>
            </Flex>

            <Flex w={{ base: '90%', lg: '750px' }} flexWrap="wrap" mx="auto" mt="50px">
                {Array.from({ length: showLength }).map((item, index) => singleItem(item, index))}
                <Flex
                    w="100%"
                    h="100px"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    mt="-40px"
                    onClick={() => {
                        if (showLength === shortShowLength) {
                            setIsShowAll(true);
                        } else {
                            setIsShowAll(false);
                        }
                    }}
                >
                    <Text className={i_h5} fontFamily="Montserrat" fontWeight="600" color={colorTheme('tertiary.900', 'tertiary.200')}>
                        {isShowAll ? 'HIDE MOST PARTNERS' : 'CLICK TO SEE MORE'}
                    </Text>
                    <Image
                        w="10px"
                        h="14px"
                        src={
                            isShowAll
                                ? process.env.PUBLIC_URL + '/assets/home/homePiece6/arrowUp.svg'
                                : '/assets/home/homePiece6/arrowDown.svg'
                        }
                        ml="13px"
                    ></Image>
                </Flex>
            </Flex>
        </Box>
    );
};

export default LiquidityPartners;
