import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, FarmFixRangeiZiContractVersion, TokenSymbol } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    swapPoolAddress: string;
    initialToggle?: boolean;

    veiZiBoost?: boolean;
    iZiBoost?: boolean;
    contractVersion?: FarmFixRangeiZiContractVersion;

    useTimestamp?: boolean;

    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;
    isEnded?: boolean;
    additionalKey?: string;
    remark?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [],
              [ChainId.Matic]: [],
              [ChainId.Mantle]: [
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.Mantle),
                      miningContract: '0xBF2b951Ae6af066A03Dbfa52b1329704D923980c',
                      swapPoolAddress: '0xC865dd3421a6DD706688955fe727C802A98c1df9',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                      useTimestamp: true,
                      remark: 'Please deposit at least 100 USDT and 100 USDC with the default range',
                  } as MiningPoolMetaConfig,
              ],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.slUSDT, ChainId.BSC),
                      miningContract: '0xa2337F50091dbfC8e802F811eaD575162FA104e6',
                      swapPoolAddress: '0x1f9cfbbf25dc7b47e9ef05963fc7b57107af3136',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.slUSDC, ChainId.BSC),
                      miningContract: '0xc41806270E9f45752287C249e450F8cb3aD5F440',
                      swapPoolAddress: '0x3Ff2C3916E038dE6F2Cf0890916076456A8ccbD7',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: false,
                  } as MiningPoolMetaConfig,
              ],
              [ChainId.BSC]: [
                  {
                      ...sortedToken(TokenSymbol.ankrBNB, TokenSymbol.BNB, ChainId.BSC),
                      miningContract: '0xF6A40dE06aD9f1ca0A83d73eF389CbCEf5ACc8D4',
                      swapPoolAddress: '0x0b393f7819ea3e47b43790c9c142a5e4fb62a24b',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: true,
                  } as MiningPoolMetaConfig,
                  {
                      ...sortedToken(TokenSymbol.BUSD, TokenSymbol.USDT, ChainId.BSC),
                      miningContract: '0x50e024bd504f066fd7f343d6d048f4bd97b1064e',
                      swapPoolAddress: '0x3b82208353012d154A3C00aaa331686DC09301B0',
                      veiZiBoost: false,
                      contractVersion: FarmFixRangeiZiContractVersion.V1,
                      feeTier: 0.04,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      isEnded: true,
                  } as MiningPoolMetaConfig,
                  //   {
                  //       ...sortedToken(TokenSymbol.IUSD, TokenSymbol.USDT, ChainId.BSC),
                  //       miningContract: '0xAdA24c4d35252001DD0947570E85DE01650160a1',
                  //       swapPoolAddress: '0x6BCf92CFbDB740fc99c804c3f78B7F2911F10F8C',
                  //       veiZiBoost: false,
                  //       contractVersion: FarmFixRangeiZiContractVersion.V1,
                  //       feeTier: 0.04,
                  //       twoRewards: false,
                  //       iZiBoost: true,
                  //       noFee: false,
                  //       feeCharged: 1,
                  //       isEnded: true,
                  //   } as MiningPoolMetaConfig,
              ],
          }
        : {};
