import { EthereumProvider } from '@walletconnect/ethereum-provider';
import chains from '../config/chains';
import { setCurrentConnector } from '../hooks/useWeb3WithDefault';
import { ConnectorNames } from './connectors';

declare type ArrayOneOrMore<T> = {
    0: T;
} & Array<T>;

const projectId = '4029de8664d00dd15ccf8df3d2b7285c';
const WALLETCONNECTPROVIDER = {} as { chainId: number; value: any };
const ISCONNECTDISCARD = { value: true } as { value: Boolean };

export const getWalletConnectProvider = () => {
    if (WALLETCONNECTPROVIDER.value !== undefined) {
        return WALLETCONNECTPROVIDER.value;
    }
};

export const cleanWalletConnectCache = () => {
    if (WALLETCONNECTPROVIDER.value !== undefined) {
        WALLETCONNECTPROVIDER.value = undefined;
    }
    ISCONNECTDISCARD.value = true;

    const keys = Object.keys(localStorage);
    for (const key of keys) {
        // clean walletconnect cache
        if (key.startsWith('wc@2')) {
            localStorage.removeItem(key);
        }
    }
};

export const loginWalletConnect = async (chainId: number) => {
    try {
        if (WALLETCONNECTPROVIDER.value == undefined || chainId != WALLETCONNECTPROVIDER.chainId) {
            cleanWalletConnectCache();
            const provider = await EthereumProvider.init({
                projectId, // REQUIRED your projectId
                chains: [chainId], // REQUIRED chain ids
                rpcMap: chains.all.reduce((acc, chain) => Object.assign(acc, { [chain.id]: chain.rpcUrl }), {}),
                showQrModal: true,
                optionalChains: chains.all.map((chain) => chain.id) as ArrayOneOrMore<number>,
            });
            WALLETCONNECTPROVIDER.value = provider;
            WALLETCONNECTPROVIDER.chainId = chainId;
        }

        if (WALLETCONNECTPROVIDER.value.accounts.length == 0 || ISCONNECTDISCARD.value) {
            await WALLETCONNECTPROVIDER.value.connect();
            ISCONNECTDISCARD.value = false;
        } else {
            await WALLETCONNECTPROVIDER.value.enable();
        }

        WALLETCONNECTPROVIDER.value.on('session_delete', (event: any) => {
            console.info('event:  ', event);
            WALLETCONNECTPROVIDER.value.disconnect();
            // cleanWalletConnectCache();
            ISCONNECTDISCARD.value = true;
            setCurrentConnector(ConnectorNames.Injected);
        });
        return true;
    } catch (e) {
        console.info('error:  ', e);
        // cleanWalletConnectCache();
        return false;
    }
};

export const logoutWalletConnect = async () => {
    await WALLETCONNECTPROVIDER.value.disconnect();
    cleanWalletConnectCache();
    setCurrentConnector(ConnectorNames.Injected);
};
