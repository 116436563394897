import { HStack, Image, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { RootDispatch, RootState } from '../../../../state/store';
import { i_h5, i_text_copy } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';

const LiquidityWarning: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const { liquidity } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const [showWarning, setShowWarning] = useState(true);

    return liquidity.showWarning && showWarning ? (
        <Stack
            w="100%"
            h={{ base: 'unset', xxl: '64px' }}
            direction={{ base: 'column', xl: 'row' }}
            bg={colorTheme('#FFE7E7', '#582835')}
            px={{ base: '18px', xl: '22px' }}
            py="15px"
            justifyContent="space-between"
            borderRadius="7px"
            mt="7px"
        >
            <HStack spacing="16px">
                <Image
                    w="26px"
                    h="23px"
                    src={colorTheme('/assets/liquidity/warning.svg', '/assets/liquidity/darkWarning.svg')}
                    fallbackSrc="/assets/liquidity/warning.svg"
                ></Image>
                <Stack className={i_text_copy} color={colorTheme('#FF2626', '#FFA8A8')} spacing="2px">
                    <Text>{t('Your added LP positions will appear as NFTs, and the assets are bound to the NFTs.')}</Text>
                    <Text>{t('Please do NOT list them in any NFT marketplace to avoid accidental loss of your fund.')}</Text>
                </Stack>
            </HStack>
            <HStack spacing="13px" ml="auto !important">
                <HStack
                    _hover={{ opacity: '0.75' }}
                    cursor="pointer"
                    onClick={() => {
                        setShowWarning(false);
                    }}
                >
                    <Image
                        w="10px"
                        h="10px"
                        src={colorTheme('/assets/liquidity/close.svg', '/assets/liquidity/darkClose.svg')}
                        fallbackSrc="/assets/liquidity/close.svg"
                    ></Image>
                    <Text className={i_text_copy} color={colorTheme('#C63C3C', '#FF8888')}>
                        {t('CLOSE')}
                    </Text>
                </HStack>
                <CustomButton
                    w={{ base: '100px', sm: '120px' }}
                    h="31px"
                    className={i_h5}
                    bg="rgba(255, 47, 47, 0.51)"
                    color="#FFFFFF"
                    text={t('never show')}
                    borderRadius="100px"
                    _hover={{ opacity: '0.75' }}
                    onClick={() => {
                        dispatch.liquidity.setShowWarning(false);
                    }}
                ></CustomButton>
            </HStack>
        </Stack>
    ) : (
        <></>
    );
};
export default LiquidityWarning;
