import { useEffect, useState } from 'react';
import { LIQUIDITY_MANAGER_ADDRESS_LEGACY } from '../config/trade/tradeContracts';
import { getLegacyLiquidityManagerContract } from '../utils/contractFactory';
import { useWeb3WithDefault } from './useWeb3WithDefault';

export const useHasLegacyLiquidity = () => {
    const [hasLegacyLiquidity, setHasLegacyLiquidity] = useState(false);
    const { web3, chainId, account } = useWeb3WithDefault();

    useEffect(() => {
        const legacyLiquidityManagerAddress = LIQUIDITY_MANAGER_ADDRESS_LEGACY[chainId];
        if (!legacyLiquidityManagerAddress) {
            setHasLegacyLiquidity(false);
            return;
        }

        const legacyLiquidityManager = getLegacyLiquidityManagerContract(chainId, web3);
        if (!legacyLiquidityManager) {
            setHasLegacyLiquidity(false);
            return;
        }

        if (!account) {
            setHasLegacyLiquidity(false);
            return;
        }
        legacyLiquidityManager.methods
            .balanceOf(account)
            .call()
            .then((res: any) => {
                if (res > 0) {
                    setHasLegacyLiquidity(true);
                } else {
                    setHasLegacyLiquidity(false);
                }
            });
    }, [web3, chainId, account]);

    return { hasLegacyLiquidity };
};
