import { Circle, HStack, Text, Image, useColorMode, Stack } from '@chakra-ui/react';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomTheme from '../../../hooks/useCustomTheme';
import useIsMobile from '../../../hooks/useIsMobile';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import {
    ResponseIziSwapSummaryNormalRecord,
    getIziSwapGenericSummaryRecord,
    SummaryRecordTypeEnum,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { i_text_copy } from '../../../style';
import { calRatio, getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';

const Summary: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { THEME_CARD } = useCustomTheme();
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const [latestPoolInfo, setLatestPoolInfo] = useState<ResponseIziSwapSummaryNormalRecord>({} as ResponseIziSwapSummaryNormalRecord);
    type NewType = ResponseIziSwapSummaryNormalRecord;

    const [lastDayPoolInfo, setLastDayPoolInfo] = useState<NewType>({} as ResponseIziSwapSummaryNormalRecord);

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_OF_CHAIN_LATEST,
        }).then((r) => {
            const latest = r.data.is_success ? r.data.data : [];
            setLatestPoolInfo(latest[0]);
        });

        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_OF_CHAIN_DAY_ARCHIVE,
            page_size: 60,
            order_by: '-time',
        }).then((r) => {
            const poolHistory = r.data.is_success ? r.data.data : [];
            setLastDayPoolInfo(poolHistory[0]);
        });
    }, [chainId]);

    const overviewInfo = useMemo(() => {
        return {
            tvlLastedDay: latestPoolInfo?.tvl ?? 0,
            volLastedDay: latestPoolInfo?.volDay ?? 0,
            feesLastedDay: latestPoolInfo?.feesDay ?? 0,
            tvlDayOnDayRatio: calRatio(latestPoolInfo?.tvl ?? 0, lastDayPoolInfo?.tvl ?? 0),
            volDayOnDayRatio: calRatio(latestPoolInfo?.volDay ?? 0, lastDayPoolInfo?.volDay ?? 0),
            feesDayOnDayRatio: calRatio(latestPoolInfo?.feesDay ?? 0, lastDayPoolInfo?.feesDay ?? 0),
        };
    }, [latestPoolInfo, lastDayPoolInfo]);
    return (
        <Stack
            w="100%"
            h={{ base: 'unset', sm: '50px' }}
            direction={{ base: 'column', sm: 'row' }}
            spacing={{ base: '15px', sm: '84px' }}
            bg={colorTheme('linear-gradient(269.91deg, #F5F6FF -1.35%, #FFFFFF 105.14%)', THEME_CARD[chainId])}
            border={colorTheme('1px solid #C7C8FE', '1px solid #4A5188')}
            borderRadius="10px"
            position="relative"
            py={{ base: '20px', sm: '0px' }}
            px={{ base: '21px', sm: '0px' }}
        >
            <HStack h="100%" spacing="15px" pl={{ base: '0px', sm: '62px' }} zIndex="1">
                <Circle w="10px" h="10px" bg={colorTheme('#4046C3', '#8A8FFF')}></Circle>
                <Text className={i_text_copy} fontSize="14px !important" color={colorTheme('#4046C3', '#8A8FFF')}>
                    {t('Liquidity')}
                </Text>
                <Text
                    className={i_text_copy}
                    fontSize={{ base: '16px !important', sm: '20px !important' }}
                    color={colorTheme('#132462', '#EAEEFF')}
                    ml={{ base: 'auto !important', sm: '15px !important' }}
                >
                    $ {formatNumber(overviewInfo.tvlLastedDay)}
                </Text>
            </HStack>
            <HStack h="100%" spacing="15px" pl={{ base: '0px', sm: '62px' }} zIndex="1">
                <Circle w="10px" h="10px" bg={colorTheme('#4046C3', '#8A8FFF')}></Circle>
                <Text className={i_text_copy} fontSize="14px !important" color={colorTheme('#4046C3', '#8A8FFF')}>
                    {t('Volume (24H)')}
                </Text>
                <Text
                    className={i_text_copy}
                    fontSize={{ base: '16px !important', sm: '20px !important' }}
                    color={colorTheme('#132462', '#EAEEFF')}
                    ml={{ base: 'auto !important', sm: '15px !important' }}
                >
                    $ {formatNumber(overviewInfo.volLastedDay)}
                </Text>
            </HStack>

            {!isMobile && (
                <Image
                    w={{ base: 'unset', sm: '155px' }}
                    h={{ base: 'unset', sm: '100%' }}
                    position={{ base: 'absolute', sm: 'static' }}
                    right="0px"
                    bottom="0px"
                    src="/assets/pools/summary/coin.svg"
                    fallbackSrc="/assets/pools/summary/coin.svg"
                ></Image>
            )}
        </Stack>
    );
};
export default Summary;
