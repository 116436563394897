import React, { useMemo, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Center, Heading, HStack } from '@chakra-ui/react';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import HistoryListEntry from './HistoryListEntry';
import { LimitOrder } from '../../../../../state/models/trade/limitOrder/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { ListSortByEnum } from '../../../../../types/mod';
import moment from 'moment';
import { TokenIcons } from '../../../components/TokenIcons';
import { FeeRate } from '../../../components/FeeRate';
import HistoryGroupedListEntry from './HistoryGroupedListEntry';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import useCustomTheme from '../../../../../hooks/useCustomTheme';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';

export const HistoryList: React.FC<{ historyOrderList: LimitOrder[] }> = ({ historyOrderList }) => {
    const {
        tradeLimitOrder: { control },
    } = useSelector((state: RootState) => state);
    const [toggle] = useState(false);
    const { THEME_CARD } = useCustomTheme();
    const { chainId } = useWeb3WithDefault();

    const historyListFiltered: LimitOrder[] = useMemo(() => {
        let resultList = [...historyOrderList];
        switch (control.sortBy) {
            case ListSortByEnum.TimeAsc:
                resultList.sort((a, b) => moment(a.createTime).unix() - moment(b.createTime).unix());
                break;
            case ListSortByEnum.TimeDesc:
                resultList.sort((b, a) => moment(a.createTime).unix() - moment(b.createTime).unix());
                break;
            default:
                break;
        }
        if (control.searchKey) {
            resultList = resultList.filter((pool) => {
                return pool.tokenX.symbol.includes(control.searchKey) || pool.tokenY.symbol.includes(control.searchKey);
            });
        }

        return resultList;
    }, [historyOrderList, control.sortBy, control.searchKey]);

    const groupedHistoryList = useMemo(() => {
        return historyListFiltered.reduce((acc: any, e: LimitOrder) => {
            const key = e.tokenX.symbol + e.tokenY.symbol + String(e.feeTier);
            const r = acc.find((e: any) => e.key === key);
            r
                ? r.data.push(e)
                : acc.push({
                      key,
                      data: [e],
                      tokenX: e.tokenX,
                      tokenY: e.tokenY,
                      feeTier: e.feeTier,
                  });
            return acc;
        }, []);
    }, [historyListFiltered]);

    return (
        <Accordion allowMultiple={true} allowToggle={true}>
            {historyListFiltered.length && groupedHistoryList.length > 0 ? (
                control.showByPair ? (
                    groupedHistoryList.map(
                        (
                            entry: {
                                key: React.Key | null | undefined;
                                tokenX: TokenInfoFormatted;
                                tokenY: TokenInfoFormatted;
                                feeTier: FeeTier;
                                data: LimitOrder[];
                            },
                            i: React.Key | null | undefined
                        ) => (
                            <Card key={i} bg={THEME_CARD[chainId]} mt="0px" mb="15px">
                                <AccordionItem border="0">
                                    <AccordionButton h="81px" p="0">
                                        <HStack pl="40px" pr="30px" spacing="20px" minW={{ base: '100%', sm: '660px' }} w="100%">
                                            <HStack spacing="28px" flexShrink={0} w="100%">
                                                <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                                                <FeeRate
                                                    tokenA={entry.tokenX}
                                                    tokenB={entry.tokenY}
                                                    feeTier={entry.feeTier}
                                                    initialToggle={toggle}
                                                />
                                                <AccordionIcon ml="auto !important" />
                                            </HStack>
                                        </HStack>
                                    </AccordionButton>
                                    <AccordionPanel py={3}>
                                        <HistoryGroupedListEntry key={i} entrys={entry.data} mb="15px"></HistoryGroupedListEntry>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Card>
                        )
                    )
                ) : (
                    historyListFiltered.map((historyListEntry, i) => <HistoryListEntry key={i} entry={historyListEntry} mb="15px" />)
                )
            ) : (
                <Center>
                    <Heading size="lg" color="tertiary.100">
                        No history limit order yet.
                    </Heading>
                </Center>
            )}
        </Accordion>
    );
};
