import { ChainId } from '../../types/mod';
import chains from '../chains';

export const MULTICALL_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSC]: '0xA1189a420662105bef5Be444B8b1E0a7D8279672',
        [ChainId.Aurora]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.Arbitrum]: '0x844A47ad42187F255e5523D4d3Be33f6e94786f8',
        [ChainId.Cronos]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.Matic]: '0x48dA26C7645e98f6764E8E1f4A87112a2BD10F19',
        [ChainId.Icplaza]: '0x5B9A9E3C344Ae569F0Bb8CE45dd0E1C7919be7d8',
        [ChainId.ConfluxESpace]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.Meter]: '0x98d10345627450233Bac4C52079Bd7d1F32b576F',
        [ChainId.Telos]: '0x1DADF066518E2b7064D85cED45625BFeC52ca07d',
        [ChainId.BSCTestnet]: '0x5712A9aeB4538104471dD85659Bd621Cdd7e07D8',
        [ChainId.OpBNBTestnet]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08',
        [ChainId.AuroraTestnet]: '0xc616D0e903337d8712aFf35e2ccEbD7966EA922D',
        [ChainId.Rinkeby]: '0x546a355d5527e82a0633B363c9f9458c7b959c68',
        [ChainId.MantleTest]: '0x3c823cF552fbEc203aAF9B4406BE696900841c9E', // 2023-03-08
        [ChainId.Mantle]: '0x1DADF066518E2b7064D85cED45625BFeC52ca07d',
        [ChainId.ZkSyncAlphaTest]: '0x14164daA623ec8f3646d7f29Da759FAbB20b501C',
        [ChainId.ZkSyncEra]: '0x18d6b2F2A5F88380D42AdD6588F4484Cfb27EE07',
        [ChainId.ScrollTestL2]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
        [ChainId.OntologyTestnet]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
        [ChainId.Ontology]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
        [ChainId.EthereumMainnet]: '0x79f5f187be352543de92885b4f09d8cca756b21c',
        [ChainId.Ultron]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
        [ChainId.LineaTest]: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
        [ChainId.Linea]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.OpsideTestRollux]: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
        [ChainId.ETC]: '0x263B272A99127ad57cff73AA9c04C515007bFb6f',
        [ChainId.Loot]: '0x1DADF066518E2b7064D85cED45625BFeC52ca07d',
        [ChainId.Base]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.MantaTest]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
        [ChainId.StagingFastActiveBellatrix]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
        [ChainId.Optimism]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.KromaSepoliaTest]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
        [ChainId.OpBNB]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.Kroma]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.Manta]: '0x7a524c7e82874226F0b51aade60A1BE4D430Cf0F',
        [ChainId.GasZeroGoerliL2]: '0x551197e6350936976DfFB66B2c3bb15DDB723250',
        [ChainId.ZetaTest]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
        [ChainId.Scroll]: '0x93E94ef7D2d735fF21C302c765d8A77C1955A311',
        [ChainId.X1Test]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});
