import { createModel } from "@rematch/core";
import BigNumber from "bignumber.js";
import Web3 from "web3";
import { RootModel } from "../../..";
import { TokenInfoFormatted } from "../../../../../hooks/useTokenListFormatted";
import { LiquidityManagerContract, PoolMetasResponse } from "../../../../../types/abis/iZiSwap/LiquidityManager";
import { ChainId } from "../../../../../types/mod";
import { LiquidityDetail, MyLiquidityControl } from "../types";

import { Contract } from 'web3-eth-contract';
import { buildSendingParams, decodeMethodResult } from "../../../../../utils/contractHelpers";
import { toContractFeeNumber, toFeeNumber } from "../../../../../utils/funcs";
import { amount2Decimal, getSwapTokenAddress } from "../../../../../utils/tokenMath";
import { parallelCollect } from "../../../../../net/contractCall/parallel";
import { getPoolContractByAddressConfirm } from "../../../../../utils/contractFactory";
import { StateResponse } from "../../../../../types/abis/iZiSwap/Pool";

import { point2PriceDecimal, point2PriceUndecimal, priceUndecimal2PriceDecimal } from "../../utils/priceMath";
import { TAP_PROXY_ADDRESS } from "../../tap/config";
import { getLiquidityAmountForWithdrawTokenXY } from "../maths/liquidityMath";
import { getPositionPoolKey } from "../../../common/positionPoolHelper";
import { produce } from "immer";
import { A_LONG_FUTURE_TIME } from "../../../../../config/bizConfig";
import { TransactionReceipt } from "ethereum-abi-types-generator";
import { ToastLink } from "../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast";
import { sendTransaction, SendTransactionParams, TransactionTypes } from "../../../wallet/sendTransaction";

export interface iZiSwapLiquidityListState {
    liquidityList: LiquidityDetail[]

    isApprovedForBox: boolean,
    isApprovedForTapProxy: boolean,
    control: MyLiquidityControl
}

export interface FetchLiquidityParams {
    chainId: ChainId;
    web3?: Web3;
    liquidityManagerContract?: LiquidityManagerContract;
    account: string;
    tokenList: TokenInfoFormatted[];
}

export interface SetNFTApprovedForParams {
    chainId: ChainId
    liquidityManagerContract?: LiquidityManagerContract
    account?: string
    gasPrice: number
    operatorAddress: string
    onGoingCallback?: (toastLink?: ToastLink) => void
    isAaAccount?: boolean
}

export interface GetApprovedForParams {
    chainId: ChainId
    liquidityManagerContract?: LiquidityManagerContract
    account?: string
}

export const iZiSwapLiquidityList = createModel<RootModel>()({
    state: {
        liquidityList: [],
        isApprovedForBox: false,
        isApprovedForTapProxy: false,
        control: {
            showByPair: false,
            sortBy: undefined,
        } as MyLiquidityControl,
    } as iZiSwapLiquidityListState,
    reducers: {

        setControl: (state: iZiSwapLiquidityListState, control: MyLiquidityControl) => produce(state, draft => {
            draft.control = { ...control };
        }),
        setLiquidityList: (state: iZiSwapLiquidityListState, params: { liquidityList: LiquidityDetail[], isApprovedForBox: boolean, isApprovedForTapProxy: boolean }) => produce(state, draft => {
            draft.liquidityList = params.liquidityList;
            draft.isApprovedForBox = params.isApprovedForBox;
            draft.isApprovedForTapProxy = params.isApprovedForTapProxy;
        }),
        setIsApprovedForBox: (state: iZiSwapLiquidityListState, isApprovedForBox: boolean) => {
            return { ...state, isApprovedForBox }
        },
        setIsApprovedForTapProxy: (state: iZiSwapLiquidityListState, isApprovedForTapProxy: boolean) => {
            return { ...state, isApprovedForTapProxy }
        },

    },
    effects: (dispatch) => ({
        async fetchLiquidities(fetchLiquidityParams: FetchLiquidityParams): Promise<void> {
            const { chainId, web3, liquidityManagerContract, account, tokenList } = fetchLiquidityParams;
            if (!chainId || !web3 || !account || !liquidityManagerContract) { return; }
            const startTime = new Date();
            if (tokenList.length === 0) {
                return;
            }

            // console.log(tokenList);

            // 1. get total nft by account
            const tokenTotal = await liquidityManagerContract.methods.balanceOf(account).call().then((balance: string) => Number(balance));
            if (tokenTotal <= 0) { return; }

            // const ownerOf107 = await liquidityManagerContract.methods.ownerOf('102').call()
            // console.log('======================= ownerOf107: ', ownerOf107)

            // 2. get tokenId list by total nft
            const tokenIdMulticallData = [];
            for (let i = 0; i < tokenTotal; i++) {
                tokenIdMulticallData.push(liquidityManagerContract.methods.tokenOfOwnerByIndex(account, i.toString()).encodeABI());
            }
            const tokenIdListResult: string[] = await liquidityManagerContract.methods.multicall(tokenIdMulticallData).call();
            const tokenIdList: BigNumber[] = tokenIdListResult.map((tokId: string) => new BigNumber(tokId));

            // 3. get all liquidities data by tokenId list
            const liquidityMulticallData = tokenIdList.map(tokId => liquidityManagerContract.methods.liquidities(tokId.toString()).encodeABI());
            const refreshLiquidityMulticallData = tokenIdList.map(tokId => liquidityManagerContract.methods.decLiquidity(tokId.toString(), '0', '0', '0', String(A_LONG_FUTURE_TIME)).encodeABI());

            const liquidityResult: string[] = await liquidityManagerContract.methods.multicall([...refreshLiquidityMulticallData, ...liquidityMulticallData]).call({ from: account });
            const liquidities: LiquidityDetail[] = liquidityResult.slice(refreshLiquidityMulticallData.length, liquidityResult.length).map((l, i) => {
                const liquidity: LiquidityDetail = decodeMethodResult(liquidityManagerContract as unknown as Contract, 'liquidities', l);
                liquidity.tokenId = tokenIdList[i].toString();
                return liquidity;
            });

            // 4. get liquidity meta data by poolId
            const metaMulticallData = liquidities.map(({ poolId }) => liquidityManagerContract.methods.poolMetas(poolId).encodeABI());
            const metaResult: string[] = await liquidityManagerContract.methods.multicall(metaMulticallData).call();

            for (let i = 0; i < metaResult.length; i++) {
                const m = metaResult[i];
                const poolMeta: PoolMetasResponse = decodeMethodResult(liquidityManagerContract as unknown as Contract, 'poolMetas', m);
                liquidities[i] = { ...liquidities[i], fee: toFeeNumber(Number(poolMeta.fee)) };
                liquidities[i].tokenX = { ...tokenList.find((e) => (e.address.toUpperCase() === poolMeta.tokenX.toUpperCase() || e.wrapTokenAddress?.toUpperCase() === poolMeta.tokenX.toUpperCase())) } as unknown as any;
                liquidities[i].tokenY = { ...tokenList.find((e) => (e.address.toUpperCase() === poolMeta.tokenY.toUpperCase() || e.wrapTokenAddress?.toUpperCase() === poolMeta.tokenY.toUpperCase())) } as unknown as any;
                if (!liquidities[i].tokenX.symbol) {
                    liquidities[i].tokenX = await dispatch.customTokens.fetchAndAddToken({ tokenAddr: poolMeta.tokenX, chainId, web3 });
                }
                if (!liquidities[i].tokenY.symbol) {
                    liquidities[i].tokenY = await dispatch.customTokens.fetchAndAddToken({ tokenAddr: poolMeta.tokenY, chainId, web3 });
                }
            }

            // TODO set main data first, price later, same farm
            // 5. get pool address
            const poolAddressMulticallData = liquidities.map((l) => liquidityManagerContract.methods.pool(getSwapTokenAddress(l.tokenX), getSwapTokenAddress(l.tokenY), toContractFeeNumber(l.fee)).encodeABI());
            const poolAddressResult: string[] = await liquidityManagerContract.methods.multicall(poolAddressMulticallData).call();
            const poolAddressList = poolAddressResult.map(r => decodeMethodResult(liquidityManagerContract as unknown as Contract, 'pool', r));

            // 6. get current price from pool
            const stateResultList = await parallelCollect(...poolAddressList.map(poolAddr => getPoolContractByAddressConfirm(web3, poolAddr).methods.state().call()));
            stateResultList.forEach((value: any, i) => {
                const r: StateResponse = value;
                liquidities[i].currentPt = Number(r.currentPoint);
                const priceUndecimal = point2PriceUndecimal(liquidities[i].tokenX, liquidities[i].tokenY, liquidities[i].currentPt)
                liquidities[i].currentPrice = Number(priceUndecimal)
                liquidities[i].currentPriceDecimal = priceUndecimal2PriceDecimal(
                    liquidities[i].tokenX, liquidities[i].tokenY, priceUndecimal
                )
                liquidities[i].currentLiquidity = r.liquidity;
                liquidities[i].currentLiquidityX = r.liquidityX;
            });

            // 7. pure function calculate data
            for (const liquidity of liquidities) {

                const [tokenPriceX, tokenPriceY] = await parallelCollect(
                    dispatch.token.fetchTokenPriceIfMissing(liquidity.tokenX),
                    dispatch.token.fetchTokenPriceIfMissing(liquidity.tokenY)
                );
                const leftPoint = Number(liquidity.leftPt)
                const rightPoint = Number(liquidity.rightPt)

                const priceLowerDecimal = point2PriceDecimal(liquidity.tokenX, liquidity.tokenY, leftPoint)
                const priceUpperDecimal = point2PriceDecimal(liquidity.tokenX, liquidity.tokenY, rightPoint)
                const priceLower = Number(point2PriceUndecimal(liquidity.tokenX, liquidity.tokenY, leftPoint))
                const priceUpper = Number(point2PriceUndecimal(liquidity.tokenX, liquidity.tokenY, rightPoint))

                liquidity.minPrice = Math.min(priceLower, priceUpper);
                liquidity.maxPrice = Math.max(priceLower, priceUpper);
                liquidity.minPriceDecimal = Math.min(priceLowerDecimal, priceUpperDecimal);
                liquidity.maxPriceDecimal = Math.max(priceLowerDecimal, priceUpperDecimal);

                // const {amountXDecimal, amountYDecimal, amountX, amountY} = getLiquidityValue(liquidity);
                const { amountX, amountY } = getLiquidityAmountForWithdrawTokenXY(
                    new BigNumber(liquidity.liquidity),
                    new BigNumber(liquidity.currentLiquidity),
                    new BigNumber(liquidity.currentLiquidityX),
                    leftPoint,
                    rightPoint,
                    liquidity.currentPt
                )
                const amountXDecimal = amount2Decimal(amountX, liquidity.tokenX) as number
                const amountYDecimal = amount2Decimal(amountY, liquidity.tokenY) as number
                liquidity.tokenXLiquidityDecimal = amountXDecimal;
                liquidity.tokenYLiquidityDecimal = amountYDecimal;
                liquidity.tokenXLiquidity = amountX;
                liquidity.tokenYLiquidity = amountY;
                liquidity.liquidityValue = tokenPriceX * liquidity.tokenXLiquidityDecimal + tokenPriceY * liquidity.tokenYLiquidityDecimal;
                liquidity.liquidityPoolKey = getPositionPoolKey(liquidity.tokenX.address, liquidity.tokenY.address, liquidity.fee, '');

                liquidity.remainTokenX = amount2Decimal(new BigNumber(liquidity.remainTokenX), liquidity.tokenX)?.toString() ?? '0';
                liquidity.remainTokenY = amount2Decimal(new BigNumber(liquidity.remainTokenY), liquidity.tokenY)?.toString() ?? '0';
            }
            const isApprovedForBox = false

            const tapProxyAddress = TAP_PROXY_ADDRESS[chainId]
            const isApprovedForTapProxy = tapProxyAddress ? await liquidityManagerContract.methods.isApprovedForAll(account, tapProxyAddress).call() : true;

            dispatch.iZiSwapLiquidityList.setLiquidityList(
                {
                    liquidityList: liquidities.filter(liq => liq.tokenX.symbol && liq.tokenY.symbol),
                    isApprovedForBox,
                    isApprovedForTapProxy
                }
            );
            console.log(`fetchLiquidities end, ${(new Date()).getTime() - startTime.getTime()} ms`);
        },


        async getApprovedForBox(fetchLiquidityParams: GetApprovedForParams): Promise<void> { },

        async getApprovedForTapProxy(fetchLiquidityParams: GetApprovedForParams): Promise<void> {
            const { chainId, liquidityManagerContract, account } = fetchLiquidityParams;
            if (!chainId || !account || !liquidityManagerContract) { return; }
            const tapProxyAddress = TAP_PROXY_ADDRESS[chainId]
            const isApprovedForTapProxy = await liquidityManagerContract.methods.isApprovedForAll(account, tapProxyAddress).call()
            dispatch.iZiSwapLiquidityList.setIsApprovedForTapProxy(isApprovedForTapProxy)
        },
        async setApprovedFor(params: SetNFTApprovedForParams): Promise<TransactionReceipt> {
            if (!params || !params.account || !params.liquidityManagerContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check CreatePoolParams fail'));
            }
            const operatorAddress = params.operatorAddress
            const { account, gasPrice, liquidityManagerContract, onGoingCallback } = params

            const targetAddress = (liquidityManagerContract as any)._address
            const sendTransactionParams: SendTransactionParams = {
                chainId: params.chainId,
                account,
                gasPrice,
                value: '0',
            }

            const calling = liquidityManagerContract.methods.setApprovalForAll(operatorAddress, true)
            return sendTransaction(TransactionTypes.addLiquidity, calling, sendTransactionParams, params.isAaAccount, targetAddress, onGoingCallback)
        },
        cleanPosition(): void {
            dispatch.iZiSwapLiquidityList.setLiquidityList({ liquidityList: [], isApprovedForBox: false, isApprovedForTapProxy: false });
            console.info('swap cleanPosition end');
        },
    }),


});
