import { useEffect, useMemo, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { BoxProps, useColorMode, VStack, Text, Center, HStack, Image, Stack, Circle, Icon, IconButton, Tooltip } from '@chakra-ui/react';

import { i_text_copy } from '../../../../style';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { amount2Decimal, formatNumber } from '../../../../utils/tokenMath';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { RootDispatch, RootState } from '../../../../state/store';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { TapMode, TapModeStatus, TapResultForMint } from '../../../../state/models/trade/tap/types';
import useTokenEntity, { TokenEntity } from '../../../../state/models/hooks/useTokenEntity';
import { LIQUIDITY_MANAGER_ADDRESS, QUOTER_TYPE } from '../../../../config/trade/tradeContracts';
import { TAP_PROXY_ADDRESS } from '../../../../state/models/trade/tap/config';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useLiquidityManagerContract } from '../../../../utils/contractFactory';
import { SwapTag } from '../../../../state/models/trade/aggregator/config';
import { QueryConfig } from '../../../../state/models/trade/tap/types';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { Loading, LoadingEnum } from '../../../components/Loading';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { GetApprovedForParams, SetNFTApprovedForParams } from '../../../../state/models/trade/liquidity/models/liquidityListModel';
import { TapCalculateParams } from '../../../../state/models/trade/liquidity/models/mintModel';
import { getChain, getTxLink } from '../../../../config/chains';
import useCustomTheme from '../../../../hooks/useCustomTheme';

type TapConfirmIncModalProps = {
    currentPoint: number;
    isOpen: boolean | any;
    onClose: () => void;
    onSuccess?: () => void;
} & BoxProps;

export const TapConfirmIncModal: React.FC<TapConfirmIncModalProps> = (props) => {
    const { isOpen, onClose, currentPoint, onSuccess, ...rest } = props;
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { THEME, THEME_BUTTON_FONT } = useCustomTheme();
    const { account, chainId, web3, isAaAccount } = useWeb3WithDefault();

    let token0Entity = undefined as unknown as TokenEntity;
    let token1Entity = undefined as unknown as TokenEntity;

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const { iZiSwapMintOrIncForm, swapPreQueryModel, iZiSwapLiquidityList } = useSelector((state: RootState) => state);

    const pathQueryPluginConfig: { [swapTag: string]: any } = { [SwapTag.iZiSwap]: QUOTER_TYPE.limit };

    const needApproveForTapProxy =
        iZiSwapMintOrIncForm.tapResultForMint?.tapMode !== TapMode.NoTap && !iZiSwapLiquidityList.isApprovedForTapProxy;

    useEffect(() => {
        const queryConfig = {
            swapTags: swapPreQueryModel.swapTag,
            preQueryResult: swapPreQueryModel.preQueryResult,
            pathQueryPluginConfig,
        } as QueryConfig;
        if (iZiSwapMintOrIncForm.tapModeStatus === TapModeStatus.Modified) {
            dispatch.iZiSwapMintOrIncForm.tapCalculate({
                currentPoint,
                queryConfig,
                web3,
                chainId,
            } as TapCalculateParams);
        }
    }, [iZiSwapMintOrIncForm, currentPoint, swapPreQueryModel]);

    const liquidityManagerContract = useLiquidityManagerContract(chainId, web3);

    const tapResultForMint = iZiSwapMintOrIncForm.tapResultForMint;

    const liquidityManagerAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];
    const tapProxyAddress = TAP_PROXY_ADDRESS[chainId];

    if (tapResultForMint?.tapMode === TapMode.NoTap) {
        token0Entity = useTokenEntity(iZiSwapMintOrIncForm.token0, liquidityManagerAddress, undefined, true);
        token1Entity = useTokenEntity(iZiSwapMintOrIncForm.token1, liquidityManagerAddress, undefined, true);
    } else {
        token0Entity = useTokenEntity(iZiSwapMintOrIncForm.token0, tapProxyAddress, undefined, true);
        token1Entity = useTokenEntity(iZiSwapMintOrIncForm.token1, tapProxyAddress, undefined, true);
    }

    const { amount0Desired, amount1Desired } = useMemo(() => {
        const amount0Desired = tapResultForMint?.originToken0Amount ?? '0';
        const amount1Desired = tapResultForMint?.originToken1Amount ?? '0';
        return {
            amount0Desired,
            amount1Desired,
        };
    }, [tapResultForMint]);

    const token0NeedApprove = !token0Entity.isEnoughApproved(amount0Desired);
    const token1NeedApprove = !token1Entity.isEnoughApproved(amount1Desired);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const tapModeStatus = iZiSwapMintOrIncForm.tapModeStatus;

    const originInputToken0DecimalAmount = iZiSwapMintOrIncForm.amount0DecimalDesired;
    const originInputToken1DecimalAmount = iZiSwapMintOrIncForm.amount1DecimalDesired;

    const mintToken0DecimalAmount = amount2Decimal(new BigNumber(tapResultForMint?.amount0 ?? '0'), iZiSwapMintOrIncForm.token0);

    const mintToken1DecimalAmount = amount2Decimal(new BigNumber(tapResultForMint?.amount1 ?? '0'), iZiSwapMintOrIncForm.token1);

    const toast = useCustomToast();

    const { gasPrice } = useGasPrice();

    const tokenBlock = (token: TokenInfoFormatted) => {
        return (
            <HStack>
                <Image boxSize="18px" borderRadius="5px" src={token?.icon} />
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('#735A6F', '#9B8FAE')}>
                    {token?.symbol}
                </Text>
            </HStack>
        );
    };

    let token0SwapAmount = 0;
    let token1SwapAmount = 0;
    if (tapModeStatus === TapModeStatus.Complete) {
        if (tapResultForMint?.tapMode === TapMode.Tap0To1) {
            token0SwapAmount = amount2Decimal(new BigNumber(tapResultForMint.swapAmountIn), iZiSwapMintOrIncForm.token0) ?? 0;
            token1SwapAmount = -(amount2Decimal(new BigNumber(tapResultForMint.swapAmountOut), iZiSwapMintOrIncForm.token1) ?? 0);
        } else if (tapResultForMint?.tapMode === TapMode.Tap1To0) {
            token0SwapAmount = -(amount2Decimal(new BigNumber(tapResultForMint.swapAmountOut), iZiSwapMintOrIncForm.token0) ?? 0);
            token1SwapAmount = amount2Decimal(new BigNumber(tapResultForMint.swapAmountIn), iZiSwapMintOrIncForm.token1) ?? 0;
        }
    }

    const isComplete = tapModeStatus === TapModeStatus.Complete;

    console.log('needApproveForTapProxy: ', needApproveForTapProxy);

    return isMobile ? (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w="348px"
            title={'Tap And Mint'}
            borderRadius="6px"
            titleProps={{ px: '11px', pb: '10px', pt: '24px' }}
            contentProps={{ px: '11px' }}
            pb="20px"
            {...rest}
        >
            <Stack w="100%">
                <VStack w="100%" spacing="10px">
                    <Stack
                        w="100%"
                        px="11px"
                        pt="22px"
                        pb="13px"
                        justifyContent="center"
                        spacing="15px"
                        bg={colorTheme('#F5F6F8', '#3A2F53')}
                        borderRadius="6px"
                    >
                        <Stack>
                            <HStack>
                                <Circle w="8px" h="8px" bg="#FA4D6C"></Circle>

                                <Text className={i_text_copy} w="130px" fontSize="14px" color={colorTheme('#3D344B', '#958EA6')}>
                                    Original liquidity
                                </Text>
                            </HStack>
                            <HStack borderRadius="6px" py="6px">
                                <Stack
                                    w="49%"
                                    bg={colorTheme('#FFFFFF', '#24193B')}
                                    alignItems="end"
                                    pr="28px"
                                    py="9px"
                                    borderRadius="6px"
                                    mr="-28px !important"
                                >
                                    <Text className={i_text_copy} fontWeight="600" fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                        {originInputToken0DecimalAmount}
                                    </Text>
                                    {tokenBlock(iZiSwapMintOrIncForm.token0)}
                                </Stack>
                                <Image
                                    boxSize="46px"
                                    src={colorTheme(
                                        process.env.PUBLIC_URL + '/assets/addLiquidity/add.svg',
                                        '/assets/addLiquidity/darkAdd.svg'
                                    )}
                                    border={colorTheme('2px solid #F5F6F8', '2px solid #3A2F53')}
                                    bg={colorTheme('#F5F6F8', '#3A2F53')}
                                    borderRadius="50%"
                                    zIndex="1"
                                ></Image>
                                <Stack
                                    w="49%"
                                    bg={colorTheme('#FFFFFF', '#24193B')}
                                    alignItems="end"
                                    pr="9px"
                                    py="9px"
                                    borderRadius="6px"
                                    ml="-22px !important"
                                >
                                    <Text className={i_text_copy} fontWeight="600" fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                        {originInputToken1DecimalAmount}
                                    </Text>
                                    {tokenBlock(iZiSwapMintOrIncForm.token1)}
                                </Stack>
                            </HStack>
                        </Stack>
                        {isComplete ? (
                            <Stack>
                                <HStack>
                                    <Circle w="8px" h="8px" bg="#FA4D6C"></Circle>
                                    <Text className={i_text_copy} w="130px" fontSize="16px" color={colorTheme('#3D344B', '#958EA6')}>
                                        Swap
                                    </Text>
                                </HStack>
                                <HStack borderRadius="6px" py="6px">
                                    <Stack
                                        w="49%"
                                        bg={colorTheme('#FFFFFF', '#24193B')}
                                        alignItems="end"
                                        pr="28px"
                                        py="9px"
                                        borderRadius="6px"
                                        mr="-28px !important"
                                    >
                                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                            {formatNumber(token0SwapAmount > 0 ? token0SwapAmount : token1SwapAmount, 2, 2, true)}
                                        </Text>
                                        {tokenBlock(token0SwapAmount > 0 ? iZiSwapMintOrIncForm.token0 : iZiSwapMintOrIncForm.token1)}
                                    </Stack>
                                    <Image
                                        boxSize="46px"
                                        src={colorTheme(
                                            process.env.PUBLIC_URL + '/assets/addLiquidity/ArrowRight.svg',
                                            '/assets/addLiquidity/darkArrowRight.svg'
                                        )}
                                        bg={colorTheme('#F5F6F8', '#3A2F53')}
                                        borderRadius="50%"
                                        zIndex="1"
                                    ></Image>
                                    <Stack
                                        w="49%"
                                        bg={colorTheme('#FFFFFF', '#24193B')}
                                        alignItems="end"
                                        pr="9px"
                                        py="9px"
                                        borderRadius="6px"
                                        ml="-22px !important"
                                    >
                                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                            {formatNumber(token0SwapAmount > 0 ? -token1SwapAmount : -token0SwapAmount, 2, 2, true)}
                                        </Text>
                                        {tokenBlock(token0SwapAmount > 0 ? iZiSwapMintOrIncForm.token1 : iZiSwapMintOrIncForm.token0)}
                                    </Stack>
                                </HStack>
                                <VStack color="#857999" spacing="4px">
                                    <Text className={i_text_copy}>Average Price</Text>
                                    <Text className={i_text_copy}>
                                        1 {token0SwapAmount > 0 ? iZiSwapMintOrIncForm.token0.symbol : iZiSwapMintOrIncForm.token1.symbol} /{' '}
                                        {token0SwapAmount > 0 ? -token1SwapAmount / token0SwapAmount : -token0SwapAmount / token1SwapAmount}{' '}
                                        {token0SwapAmount > 0 ? iZiSwapMintOrIncForm.token1.symbol : iZiSwapMintOrIncForm.token0.symbol}
                                    </Text>
                                </VStack>
                            </Stack>
                        ) : (
                            <></>
                        )}
                    </Stack>
                    {isComplete ? (
                        <Stack w="100%">
                            <VStack w="100%" py="3px">
                                <Image w="23px" h="22px" src="/assets/addLiquidity/ArrowDown.svg"></Image>
                            </VStack>

                            <Stack
                                w="100%"
                                bg={colorTheme('#F2EDFE', '#372751')}
                                border="1.2px solid #7F4AFE"
                                borderRadius="6px"
                                px="11px"
                                py="17px"
                            >
                                <HStack>
                                    <Circle w="8px" h="8px" bg="#7F4AFE"></Circle>
                                    <Text className={i_text_copy} w="130px" fontSize="14px" color={colorTheme('#7F4AFE', '#A179FF')}>
                                        Final liquidity
                                    </Text>
                                </HStack>
                                <HStack w="100%" borderRadius="6px" py="6px">
                                    <Stack
                                        w="49%"
                                        bg={colorTheme('#FFFFFF', '#24193B')}
                                        alignItems="end"
                                        pr="28px"
                                        py="9px"
                                        borderRadius="6px"
                                        mr="-28px !important"
                                    >
                                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                            {formatNumber(mintToken0DecimalAmount ?? 0, 2, 2, true)}
                                        </Text>
                                        {tokenBlock(iZiSwapMintOrIncForm.token0)}
                                    </Stack>
                                    <Image
                                        boxSize="46px"
                                        src={colorTheme(
                                            process.env.PUBLIC_URL + '/assets/addLiquidity/purpleAdd.svg',
                                            '/assets/addLiquidity/darkAdd.svg'
                                        )}
                                        bg={colorTheme('#F2EDFE', '#372751')}
                                        borderRadius="50%"
                                        zIndex="1"
                                    ></Image>
                                    <Stack
                                        w="49%"
                                        bg={colorTheme('#FFFFFF', '#24193B')}
                                        alignItems="end"
                                        pr="9px"
                                        py="9px"
                                        borderRadius="6px"
                                        ml="-22px !important"
                                    >
                                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                            {formatNumber(mintToken1DecimalAmount ?? 0, 2, 2, true)}
                                        </Text>
                                        {tokenBlock(iZiSwapMintOrIncForm.token1)}
                                    </Stack>
                                </HStack>
                            </Stack>
                        </Stack>
                    ) : (
                        <HStack>
                            <Loading variant={LoadingEnum.purple} text="Calculating..." py="30px" />
                        </HStack>
                    )}
                </VStack>
            </Stack>

            <Center w="100%" mt="6px !important" mb="42px !important">
                <Stack w="100%">
                    <CustomButton
                        hidden={!token0NeedApprove || tapModeStatus !== TapModeStatus.Complete}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve') + ' ' + iZiSwapMintOrIncForm.token0.symbol}
                        w="100%"
                        h="40px"
                        fontSize="14px"
                        onClick={() =>
                            token0Entity
                                .handleApprove(undefined, isAaAccount)
                                .then(() => token0Entity.handleApproveSuccess())
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                })
                        }
                    />

                    <CustomButton
                        hidden={!token1NeedApprove || tapModeStatus !== TapModeStatus.Complete}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve') + ' ' + iZiSwapMintOrIncForm.token1.symbol}
                        w="100%"
                        h="40px"
                        fontSize="14px"
                        onClick={() =>
                            token1Entity
                                .handleApprove(undefined, isAaAccount)
                                .then(() => token1Entity.handleApproveSuccess())
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                })
                        }
                    />

                    <CustomButton
                        hidden={!needApproveForTapProxy}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve Tap Proxy')}
                        w="100%"
                        h="40px"
                        fontSize="14px"
                        onClick={() => {
                            dispatch.iZiSwapLiquidityList
                                .setApprovedFor({
                                    chainId,
                                    account,
                                    liquidityManagerContract,
                                    gasPrice,
                                    operatorAddress: TAP_PROXY_ADDRESS[chainId],
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                    isAaAccount,
                                } as SetNFTApprovedForParams)
                                .then((e: any) => {
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    const transactionHash = e.transactionHash ? e.transactionHash : e;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    toast('success', 'Approve tap proxy successfully', undefined, toastLink);

                                    dispatch.iZiSwapLiquidityList.getApprovedForTapProxy({
                                        chainId,
                                        account,
                                        liquidityManagerContract,
                                    } as GetApprovedForParams);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={
                            token0NeedApprove || token1NeedApprove || tapModeStatus !== TapModeStatus.Complete || needApproveForTapProxy
                        }
                        disabled={iZiSwapMintOrIncForm.tapModeStatus !== TapModeStatus.Complete}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Create')}
                        w="100%"
                        h="40px"
                        fontSize="14px"
                        onClick={() => {
                            dispatch.iZiSwapMintOrIncForm
                                .addLiquidityTap({
                                    tapResult: tapResultForMint as TapResultForMint,
                                    account: account as string,
                                    web3,
                                    chainId,
                                    gasPrice,
                                    liquidityManagerContract,
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                    isAaAccount,
                                })
                                .then((e: any) => {
                                    if (amount0Desired !== '0') {
                                        token0Entity.handleApproveSuccess().catch((e) => {
                                            console.log('refresh approve error: ', e);
                                        });
                                    }
                                    if (amount1Desired !== '0') {
                                        token1Entity.handleApproveSuccess().catch((e) => {
                                            console.log('refresh approve error: ', e);
                                        });
                                    }
                                    console.log(e);
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    const transactionHash = e.transactionHash ? e.transactionHash : e;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    toast('success', 'add liquidity successfully', undefined, toastLink);
                                })
                                .catch((e: any) => {
                                    console.log(e.message);
                                    toast('info', e.message);
                                });
                        }}
                    />
                </Stack>
            </Center>
        </Modal>
    ) : (
        <Modal isOpen={isOpen} onClose={onClose} w="768px" title={'Tap And Mint'} {...rest}>
            <Stack w="100%" mt="-22px !important">
                <VStack w="100%" spacing="10px">
                    <Stack
                        w="100%"
                        py="22px"
                        alignItems="center"
                        justifyContent="center"
                        spacing="15px"
                        bg={colorTheme('#F5F6F8', '#3A2F53')}
                        borderRadius="6px"
                    >
                        <Stack spacing="15px">
                            <HStack>
                                <HStack>
                                    <Circle w="8px" h="8px" bg="#FA4D6C"></Circle>

                                    <Text className={i_text_copy} w="130px" fontSize="14px" color={colorTheme('#3D344B', '#958EA6')}>
                                        Original liquidity
                                    </Text>
                                </HStack>
                                <HStack bg={colorTheme('#FFFFFF', '#24193B')} borderRadius="6px" px="20px" py="6px">
                                    <Text className={i_text_copy} fontWeight="600" fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                        {originInputToken0DecimalAmount}
                                    </Text>
                                    {tokenBlock(iZiSwapMintOrIncForm.token0)}
                                    <Text color={colorTheme('#B7B1BE', '#958EA6')} fontWeight="600" px="15px">
                                        +
                                    </Text>
                                    <Text className={i_text_copy} fontWeight="600" fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                        {originInputToken1DecimalAmount}
                                    </Text>
                                    {tokenBlock(iZiSwapMintOrIncForm.token1)}
                                </HStack>
                            </HStack>
                            {isComplete ? (
                                <HStack>
                                    <HStack>
                                        <Circle w="8px" h="8px" bg="#FA4D6C"></Circle>
                                        <Text className={i_text_copy} w="130px" fontSize="16px" color={colorTheme('#3D344B', '#958EA6')}>
                                            Swap
                                        </Text>
                                    </HStack>
                                    <HStack bg={colorTheme('#FFFFFF', '#24193B')} borderRadius="6px" px="20px" py="6px">
                                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                            {formatNumber(token0SwapAmount > 0 ? token0SwapAmount : token1SwapAmount, 2, 2, true)}
                                        </Text>
                                        {tokenBlock(token0SwapAmount > 0 ? iZiSwapMintOrIncForm.token0 : iZiSwapMintOrIncForm.token1)}
                                        <VStack h="27px" spacing="0px" px="15px">
                                            <Text className={i_text_copy} color={colorTheme('#B7B1BE', '#958EA6')}>
                                                Swap
                                            </Text>
                                            <Image w="42px" src="/assets/addLiquidity/SwapTo.svg" opacity={colorTheme(1, 0.7)}></Image>
                                        </VStack>
                                        <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                            {formatNumber(token0SwapAmount > 0 ? -token1SwapAmount : -token0SwapAmount, 2, 2, true)}
                                        </Text>
                                        {tokenBlock(token0SwapAmount > 0 ? iZiSwapMintOrIncForm.token1 : iZiSwapMintOrIncForm.token0)}
                                    </HStack>
                                    <Tooltip
                                        label={
                                            <Stack>
                                                <Text>Average Price</Text>
                                                <Text>
                                                    1{' '}
                                                    {token0SwapAmount > 0
                                                        ? iZiSwapMintOrIncForm.token0.symbol
                                                        : iZiSwapMintOrIncForm.token1.symbol}{' '}
                                                    ={' '}
                                                    {token0SwapAmount > 0
                                                        ? -token1SwapAmount / token0SwapAmount
                                                        : -token0SwapAmount / token1SwapAmount}{' '}
                                                    {token0SwapAmount > 0
                                                        ? iZiSwapMintOrIncForm.token1.symbol
                                                        : iZiSwapMintOrIncForm.token0.symbol}
                                                </Text>
                                            </Stack>
                                        }
                                    >
                                        <IconButton
                                            size="sm"
                                            aria-label=""
                                            variant="ghost"
                                            isRound={true}
                                            icon={<Icon as={AiOutlineInfoCircle} boxSize="20px" opacity="0.5" />}
                                        />
                                    </Tooltip>
                                </HStack>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Stack>

                    {/* <HStack>
                            <Text className={i_text_copy} fontWeight="bold" fontSize="16px" color={colorTheme('#FA4D6C', '#C54158')}>
                                necessary input token0, {incLiquidityForm.token0.symbol} :{' '}
                                {tapModeStatus === TapModeStatus.Complete ? originToken0DecimalAmount : ' wait...'}
                            </Text>
                            <Text className={i_text_copy} fontWeight="bold" fontSize="16px" color={colorTheme('#FA4D6C', '#C54158')}>
                                necessay input token1, {incLiquidityForm.token1.symbol} :{' '}
                                {tapModeStatus === TapModeStatus.Complete ? originToken1DecimalAmount : ' wait...'}
                            </Text>
                        </HStack> */}

                    {isComplete ? (
                        <Stack w="100%">
                            <VStack w="100%" py="3px">
                                <Image w="23px" h="22px" src="/assets/addLiquidity/ArrowDown.svg"></Image>
                            </VStack>

                            <HStack
                                w="100%"
                                justifyContent="center"
                                bg={colorTheme('#F2EDFE', '#372751')}
                                border="1.2px solid #7F4AFE"
                                borderRadius="6px"
                                py="17px"
                            >
                                <Circle w="8px" h="8px" bg="#7F4AFE"></Circle>
                                <Text className={i_text_copy} w="130px" fontSize="14px" color={colorTheme('#7F4AFE', '#A179FF')}>
                                    Final liquidity
                                </Text>

                                <HStack bg={colorTheme('#FFFFFF', '#24193B')} borderRadius="6px" px="20px" py="6px">
                                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                        {formatNumber(mintToken0DecimalAmount ?? 0, 2, 2, true)}
                                    </Text>
                                    {tokenBlock(iZiSwapMintOrIncForm.token0)}
                                    <Text color="#B7B1BE" fontWeight="600" px="15px">
                                        +
                                    </Text>
                                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('#312C2D', '#E8E2ED')}>
                                        {formatNumber(mintToken1DecimalAmount ?? 0, 2, 2, true)}
                                    </Text>
                                    {tokenBlock(iZiSwapMintOrIncForm.token1)}
                                </HStack>
                            </HStack>
                        </Stack>
                    ) : (
                        <HStack>
                            <Loading variant={LoadingEnum.purple} text="Calculating..." py="30px" />
                        </HStack>
                    )}
                </VStack>
            </Stack>

            <Center w="100%" mt="20px !important">
                <HStack spacing="20px">
                    <CustomButton
                        hidden={!token0NeedApprove || tapModeStatus !== TapModeStatus.Complete}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve') + ' ' + iZiSwapMintOrIncForm.token0.symbol}
                        w="269px"
                        h="52px"
                        fontSize="14px"
                        onClick={() =>
                            token0Entity
                                .handleApprove(undefined, isAaAccount)
                                .then(() => token0Entity.handleApproveSuccess())
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                })
                        }
                    />

                    <CustomButton
                        hidden={!token1NeedApprove || tapModeStatus !== TapModeStatus.Complete}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve') + ' ' + iZiSwapMintOrIncForm.token1.symbol}
                        w="269px"
                        h="52px"
                        fontSize="14px"
                        onClick={() =>
                            token1Entity
                                .handleApprove(undefined, isAaAccount)
                                .then(() => token1Entity.handleApproveSuccess())
                                .catch((e: any) => {
                                    console.info('error   :', e.message);
                                })
                        }
                    />

                    <CustomButton
                        hidden={!needApproveForTapProxy}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Approve Tap Proxy')}
                        w="269px"
                        h="52px"
                        fontSize="14px"
                        onClick={() => {
                            dispatch.iZiSwapLiquidityList
                                .setApprovedFor({
                                    chainId,
                                    account,
                                    liquidityManagerContract,
                                    gasPrice,
                                    operatorAddress: TAP_PROXY_ADDRESS[chainId],
                                    onGoingCallback: (toastLink: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                    isAaAccount,
                                } as SetNFTApprovedForParams)
                                .then((e: any) => {
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    const transactionHash = e.transactionHash ? e.transactionHash : e;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    toast('success', 'Approve tap proxy successfully', undefined, toastLink);

                                    dispatch.iZiSwapLiquidityList.getApprovedForTapProxy({
                                        chainId,
                                        account,
                                        liquidityManagerContract,
                                    } as GetApprovedForParams);
                                });
                        }}
                    />
                    <CustomButton
                        hidden={
                            token0NeedApprove || token1NeedApprove || tapModeStatus !== TapModeStatus.Complete || needApproveForTapProxy
                        }
                        disabled={iZiSwapMintOrIncForm.tapModeStatus !== TapModeStatus.Complete}
                        variant="purple"
                        bg={THEME[chainId]}
                        color={THEME_BUTTON_FONT[chainId]}
                        _hover={{ opacity: 0.75 }}
                        _focus={{ opacity: 0.75 }}
                        text={t('Create')}
                        w="269px"
                        h="52px"
                        fontSize="14px"
                        onClick={() => {
                            dispatch.iZiSwapMintOrIncForm
                                .addLiquidityTap({
                                    tapResult: tapResultForMint as TapResultForMint,
                                    account: account as string,
                                    web3,
                                    chainId,
                                    gasPrice,
                                    liquidityManagerContract,
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                    isAaAccount,
                                })
                                .then((e: any) => {
                                    console.log(e);
                                    if (amount0Desired !== '0') {
                                        token0Entity.handleApproveSuccess().catch((e) => {
                                            console.log('refresh approve error: ', e);
                                        });
                                    }
                                    if (amount1Desired !== '0') {
                                        token1Entity.handleApproveSuccess().catch((e) => {
                                            console.log('refresh approve error: ', e);
                                        });
                                    }
                                    const chain = getChain(chainId);
                                    const toastLink = {} as ToastLink;
                                    const transactionHash = e.transactionHash ? e.transactionHash : e;
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(transactionHash, chain);
                                    }
                                    toast('success', 'add liquidity successfully', undefined, toastLink);
                                })
                                .catch((e: any) => {
                                    console.log(e.message);
                                    toast('info', e.message);
                                });
                        }}
                    />
                </HStack>
            </Center>
        </Modal>
    );
};
