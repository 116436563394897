import { ChainId, TokenSymbol } from "../../../../../types/mod";

export const PANCAKE_FACTORY_ADDRESS = {
    [ChainId.BSCTestnet]: '0xb7926c0430afb07aa7defde6da862ae0bde767bc',
    [ChainId.BSC]: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
} as Record<ChainId, string>

export const PANCAKE_SWAP_ROUTER_ADDRESS = {
    // [ChainId.BSCTestnet]: '0x9ac64cc6e4415144c455bd8e4837fea55603e5c3',
    [ChainId.BSC]: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
} as Record<ChainId, string>

export const PANCAKE_FEE_RATE = {
    [ChainId.BSCTestnet]: 0.002,
    [ChainId.BSC]: 0.0025
} as Record<ChainId, number>


export const PANCAKE_MULTIHOP_MID_TOKEN_SYMBOL = {
    [ChainId.BSC]: [TokenSymbol.BUSD, TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.BSCTestnet]: [TokenSymbol.BNB, TokenSymbol.USDT, TokenSymbol.USDC, TokenSymbol.IUSD],
    [ChainId.Aurora]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.IUSD],
    [ChainId.AuroraTestnet]: [TokenSymbol.ETH, TokenSymbol.USDC, TokenSymbol.USDT, TokenSymbol.IUSD],
    [ChainId.ETC]: [TokenSymbol.ETC, TokenSymbol.IUSD],
} as Record<ChainId, TokenSymbol[]>


export const PANCAKE_TOKEN_BLACK_LIST = [TokenSymbol.COCA]