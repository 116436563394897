import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    Switch,
    Image,
    InputLeftElement,
    useColorMode,
    InputRightElement,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    useBreakpointValue,
} from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getChain } from '../../../../config/chains';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { getColorThemeSelector } from '../../../../iZUMi-UI-toolkit/src/utils/funcs';
import { RootDispatch, RootState } from '../../../../state/store';
import { i_text_copy, i_text_copy_bold } from '../../../../style';
import { Filter } from '../../../Farm/Gallery/components/Filter';

const MyLiquidityControl: React.FC = () => {
    const { t } = useTranslation();
    const { iZiSwapLiquidityList } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { chainId } = useWeb3WithDefault();
    const chain = getChain(chainId);
    const isMobile = useIsMobile();
    const history = useHistory();
    const isXXl = useBreakpointValue({ base: false, xxl: true, '2xl': true });

    const onShowByPair = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.showByPair = e.target.checked;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const onSearchKeyChange = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.searchKey = e.target.value;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const onSortByChange = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.sortBy = e;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const onUseWrappedGasToken = useCallback(
        (e: any) => {
            const control = { ...iZiSwapLiquidityList.control };
            control.useWrappedGasToken = e.target.checked;
            dispatch.iZiSwapLiquidityList.setControl(control);
        },
        [iZiSwapLiquidityList, dispatch]
    );

    const SortByBlock = useMemo(() => {
        return (
            <FormControl display="flex" alignItems="center" w="unset">
                <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" pt="6px">
                    {t('Sort by')}
                </FormLabel>
                <Menu>
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                        className={i_text_copy_bold}
                        fontSize="14px"
                        w={{ base: '140px', sm: '150px' }}
                    >
                        {iZiSwapLiquidityList.control.sortBy || t('NFTId')}
                    </MenuButton>
                    <MenuList className={i_text_copy}>
                        <MenuItem onClick={() => onSortByChange('NFTId')}>{t('NFTId')} </MenuItem>
                        <MenuItem onClick={() => onSortByChange('Liquidity')}>{t('Liquidity')} </MenuItem>
                    </MenuList>
                </Menu>
            </FormControl>
        );
    }, [iZiSwapLiquidityList.control.sortBy, onSortByChange, t]);

    const UseBlock = () => (
        <FormControl
            display="flex"
            alignItems="end"
            w={{ base: '207px', sm: 'unset' }}
            mr={{ base: 'auto', xxl: '20px !important' }}
            ml={{ base: 'auto !important', sm: 'auto !important' }}
            flexDirection={{ base: 'column', sm: 'row' }}
        >
            {chain?.wrappedTokenSymbol && (
                <Flex>
                    <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" ml={{ base: 'unset', lg: '20px' }} pt="6px">
                        {t('Use')} {chain.wrappedTokenSymbol}
                    </FormLabel>
                    <Switch
                        isChecked={iZiSwapLiquidityList.control.useWrappedGasToken}
                        onChange={(e) => onUseWrappedGasToken(e)}
                        size="lg"
                    ></Switch>
                </Flex>
            )}
        </FormControl>
    );

    const ZipBlock = () => (
        <Flex
            w={{ base: '100%', xxl: 'unset' }}
            mt={{ base: '10px', sm: '0px' }}
            ml={{ base: '0px', lg: 'auto', xxl: '20px' }}
            alignItems="end"
        >
            <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" ml="auto !important" pt="6px">
                {t('Zip')}
            </FormLabel>
            <Switch isChecked={iZiSwapLiquidityList.control.showByPair} onChange={(e) => onShowByPair(e)} size="lg"></Switch>
        </Flex>
    );

    return isMobile ? (
        <HStack w="100%" alignItems="start" pt="16px" pb="8px">
            <InputGroup w="274px">
                <InputLeftElement boxSize="20px" mt="4px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    h="30px"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={iZiSwapLiquidityList.control.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>

            <HStack h="100%" spacing="12px" ml="auto !important">
                <Filter
                    h="100%"
                    alignItems="center"
                    filterTitle="Liquidity Filter"
                    filterElement={[
                        <Stack key={0} w="100%" alignItems="end">
                            {SortByBlock}
                        </Stack>,
                        <UseBlock key={1}></UseBlock>,
                        <ZipBlock key={2}></ZipBlock>,
                    ]}
                ></Filter>
                <Image
                    boxSize="20px"
                    src="/assets/liquidity/addLiquidity.svg"
                    onClick={() => {
                        history.push('/trade/add-liquidity');
                    }}
                ></Image>
            </HStack>
        </HStack>
    ) : (
        <HStack w="100%" alignItems="start" pt={{ base: '12px', sm: '33px' }} pb={{ base: '20px', sm: '32px' }}>
            <InputGroup w={{ base: '275px', sm: '310px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e) => onSearchKeyChange(e)}
                    value={iZiSwapLiquidityList.control.searchKey || ''}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
            {!isXXl && (
                <Filter
                    h="100%"
                    ml="auto !important"
                    alignItems="center"
                    filterTitle="Liquidity Filter"
                    filterElement={[<Stack key={0}>{SortByBlock}</Stack>, <UseBlock key={1}></UseBlock>, <ZipBlock key={2}></ZipBlock>]}
                ></Filter>
            )}

            {isXXl && (
                <HStack ml="auto !important" spacing="0px">
                    {SortByBlock}
                    <UseBlock></UseBlock>
                    <ZipBlock></ZipBlock>
                </HStack>
            )}
        </HStack>
    );
};

export default MyLiquidityControl;
