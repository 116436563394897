import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/bridge/';

export const BRIDGE = {
    [ChainId.ZkSyncEra]: [
        {
            titleImgSrc: baseURL + 'era.svg',
            title: 'zkSync Era Bridge',
            content: "zkSync Era's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.zksync.io/',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=zkSync%20Era&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'cbridge.png',
            title: 'cBridge',
            content:
                'cBridge is powered by Celer network, which is a decentralized and non-custodial asset bridge that supports 40+ blockchains.',
            link: 'https://cbridge.celer.network/1/324/USDC',
        },
        {
            titleImgSrc: baseURL + 'squid.svg',
            title: 'Squid',
            content:
                'Squid allows any token to be swapped between blockchains, and unlocks access to apps across chains in a single click.',
            link: 'https://app.squidrouter.com/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?chainIn=ZkSync%20Era&chainOut=Linea&tokenIn=0x16A9494e257703797D747540f01683952547EE5b&tokenOut=ETH',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Rubic is a cross-chain technology aggregator + tool for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=ZK_SYNC&toChain=BSC&from=iZi&to=iZi',
        },
        {
            titleImgSrc: baseURL + 'xy.png',
            title: 'XY Finance',
            content:
                'XY Finance is a cross-chain dex & bridge aggregator that operates on 20 EVM chains including Linea, zkSync Era, Base, Mantle, Arbitrum, Optimism, Polygon & more.',
            link: 'https://app.xy.finance/?utm_source=partner&utm_medium=iZUMi&utm_campaign=link',
        },
        {
            titleImgSrc: baseURL + 'swft.png',
            title: 'SWFT',
            content:
                'SWFT is a one stop cross-chain bridge and swap solution, that allows users to trade, invest, and transact in cryptocurrencies.',
            link: 'https://defi.swft.pro/#/',
        },
    ],
    [ChainId.Linea]: [
        {
            titleImgSrc: baseURL + 'linea.png',
            title: 'Linea Bridge',
            content: "Linea's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.linea.build/',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Linea&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'squid.svg',
            title: 'Squid',
            content:
                'Squid allows any token to be swapped between blockchains, and unlocks access to apps across chains in a single click.',
            link: 'https://app.squidrouter.com/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?chainIn=Linea&chainOut=ZkSync%20Era&tokenIn=ETH&tokenOut=0x16A9494e257703797D747540f01683952547EE5b',
        },
        {
            titleImgSrc: baseURL + 'xy.png',
            title: 'XY Finance',
            content:
                'XY Finance is a cross-chain dex & bridge aggregator that operates on 20 EVM chains including Linea, zkSync Era, Base, Mantle, Arbitrum, Optimism, Polygon & more.',
            link: 'https://app.xy.finance/?utm_source=partner&utm_medium=iZUMi&utm_campaign=link',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Rubic is a cross-chain technology aggregator + tool for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=LINEA&toChain=ZK_SYNC&from=iZi&to=iZi',
        },
        {
            titleImgSrc: baseURL + 'hop.png',
            title: 'Hop',
            content:
                'Hop is a scalable rollup-to-rollup general token bridge. It allows users to send tokens from one rollup or sidechain to another almost immediately.',
            link: 'https://app.hop.exchange/#/send?token=ETH&destNetwork=linea&sourceNetwork=ethereum',
        },
    ],
    [ChainId.Mantle]: [
        {
            titleImgSrc: baseURL + 'mantle.png',
            title: 'Mantle Bridge',
            content: "Mantle's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.mantle.xyz/',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Mantle&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'squid.svg',
            title: 'Squid',
            content:
                'Squid allows any token to be swapped between blockchains, and unlocks access to apps across chains in a single click.',
            link: 'https://app.squidrouter.com/',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?amountIn=600&chainIn=Mantle&chainOut=ZkSync%20Era&tokenIn=MNT&tokenOut=0x16A9494e257703797D747540f01683952547EE5b',
        },
        {
            titleImgSrc: baseURL + 'xy.png',
            title: 'XY Finance',
            content:
                'XY Finance is a cross-chain dex & bridge aggregator that operates on 20 EVM chains including Linea, zkSync Era, Base, Mantle, Arbitrum, Optimism, Polygon & more.',
            link: 'https://app.xy.finance/?utm_source=partner&utm_medium=iZUMi&utm_campaign=link',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Cross-chain technology aggregator + Tools to enable it for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=MANTLE&toChain=ZK_SYNC&from=MNT&to=iZi',
        },
    ],
    [ChainId.Base]: [
        {
            titleImgSrc: baseURL + 'base.png',
            title: 'Base Bridge',
            content: "Base's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.base.org/deposit',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Base&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'squid.svg',
            title: 'Squid',
            content:
                'Squid allows any token to be swapped between blockchains, and unlocks access to apps across chains in a single click.',
            link: 'https://app.squidrouter.com/',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
        {
            titleImgSrc: baseURL + 'cbridge.png',
            title: 'cBridge',
            content:
                'cBridge is powered by Celer network, which is a decentralized and non-custodial asset bridge that supports 40+ blockchains.',
            link: 'https://cbridge.celer.network/8453/1/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?amountIn=600&chainIn=Base&chainOut=ZkSync%20Era&tokenIn=ETH&tokenOut=0x16A9494e257703797D747540f01683952547EE5b',
        },
        {
            titleImgSrc: baseURL + 'xy.png',
            title: 'XY Finance',
            content:
                'XY Finance is a cross-chain dex & bridge aggregator that operates on 20 EVM chains including Linea, zkSync Era, Base, Mantle, Arbitrum, Optimism, Polygon & more.',
            link: 'https://app.xy.finance/?utm_source=partner&utm_medium=iZUMi&utm_campaign=link',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Rubic is a cross-chain technology aggregator + tool for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=BASE&toChain=ZK_SYNC&from=ETH&to=iZi',
        },
    ],
    [ChainId.Manta]: [
        {
            titleImgSrc: baseURL + 'manta.png',
            title: 'Manta',
            content: "Manta's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://pacific-bridge.manta.network/',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Manta&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
    ],
    [ChainId.Scroll]: [
        {
            titleImgSrc: baseURL + 'scroll.png',
            title: 'Scroll',
            content: "Scroll's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://scroll.io/bridge',
        },
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Scroll&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'squid.svg',
            title: 'Squid',
            content:
                'Squid allows any token to be swapped between blockchains, and unlocks access to apps across chains in a single click.',
            link: 'https://app.squidrouter.com/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?amountIn=600&chainIn=Ethereum&chainOut=Scroll&chainTo=Scroll&tokenIn=ETH&tokenOut=ETH',
        },
        {
            titleImgSrc: baseURL + 'xy.png',
            title: 'XY Finance',
            content:
                'XY Finance is a cross-chain dex & bridge aggregator that operates on 20 EVM chains including Linea, zkSync Era, Base, Mantle, Arbitrum, Optimism, Polygon & more.',
            link: 'https://app.xy.finance/?utm_source=partner&utm_medium=iZUMi&utm_campaign=link',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
    ],
    [ChainId.Kroma]: [
        {
            titleImgSrc: baseURL + 'kroma.png',
            title: 'Kroma',
            content: "Kroma's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://kroma.network/bridge',
        },
        {
            titleImgSrc: baseURL + 'owlto.png',
            title: 'Owlto',
            content:
                'Owlto Finance is a decentralized cross-rollup bridge that focuses on L2, providing "safe, fast, cheap, and easy-to-use" services.',
            link: 'https://owlto.finance/bridge?channel=6564',
        },
    ],
    [ChainId.BSC]: [
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=BNB%20Chain&token=ETH',
        },
        {
            titleImgSrc: baseURL + 'xy.png',
            title: 'XY Finance',
            content:
                'XY Finance is a cross-chain dex & bridge aggregator that operates on 19 EVM chains including Linea, zkSync Era, Base, Mantle, Arbitrum, Optimism, Polygon & more.',
            link: 'https://app.xy.finance/?utm_source=partner&utm_medium=iZUMi&utm_campaign=link',
        },
        {
            titleImgSrc: baseURL + 'squid.svg',
            title: 'Squid',
            content:
                'Squid allows any token to be swapped between blockchains, and unlocks access to apps across chains in a single click.',
            link: 'https://app.squidrouter.com/',
        },
    ],
    [ChainId.Arbitrum]: [
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Arbitrum&token=ETH',
        },
    ],
    [ChainId.Optimism]: [
        {
            titleImgSrc: baseURL + 'orbiter.png',
            title: 'Orbiter',
            content:
                'Orbiter Finance is a decentralized cross-rollup bridge for transferring the Ethereum native assets, which is the infrastructure of Layer 2, it offers low cost and almost instant transfers.',
            link: 'https://dealer.orbiter.finance/?dealerId=0x3836986159b358d6FBc03c512E3e1dDE37ec6a5c&source=Ethereum&dest=Optimism&token=ETH',
        },
    ],
} as any;
