import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmOneSideContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';
import { tokenSymbol2token } from '../../../tokens';

interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    initialToggle?: boolean;

    miningContract?: any;

    tokenUni: TokenInfoFormatted;
    tokenLock: TokenInfoFormatted;

    twoRewards?: boolean;
    lockBoost?: boolean;
    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    allowStakeNFT?: boolean;
    contractVersion?: FarmOneSideContractVersion;
    isEnded?: boolean;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [
                  {
                      ...sortedToken(TokenSymbol.IZI, TokenSymbol.ETH, ChainId.EthereumMainnet),
                      tokenUni: tokenSymbol2token(TokenSymbol.ETH, ChainId.EthereumMainnet),
                      tokenLock: tokenSymbol2token(TokenSymbol.IZI, ChainId.EthereumMainnet),
                      miningContract: '0xbE138aD5D41FDc392AE0B61b09421987C1966CC3',
                      feeTier: 0.3,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: true,
                      allowStakeNFT: false,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.YIN, TokenSymbol.ETH, ChainId.EthereumMainnet),
                      tokenUni: tokenSymbol2token(TokenSymbol.ETH, ChainId.EthereumMainnet),
                      tokenLock: tokenSymbol2token(TokenSymbol.YIN, ChainId.EthereumMainnet),
                      miningContract: '0x57AFF370686043B5d21fDd76aE4b513468B9fb3C',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: true,
                      allowStakeNFT: false,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.DEVT, TokenSymbol.USDC, ChainId.EthereumMainnet),
                      tokenUni: tokenSymbol2token(TokenSymbol.USDC, ChainId.EthereumMainnet),
                      tokenLock: tokenSymbol2token(TokenSymbol.DEVT, ChainId.EthereumMainnet),
                      miningContract: '0x99CC0A41F8006385f42aed747e2d3642a226d06E',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      feeCharged: 0.4,
                      allowStakeNFT: false,
                      isEnded: true,
                  },
              ],
              [ChainId.Matic]: [
                  {
                      ...sortedToken(TokenSymbol.ACY, TokenSymbol.USDC, ChainId.Matic),
                      tokenUni: tokenSymbol2token(TokenSymbol.USDC, ChainId.Matic),
                      tokenLock: tokenSymbol2token(TokenSymbol.ACY, ChainId.Matic),
                      miningContract: '0x28d7BFf13c5A1227aEe2E892F8d22d8A1a84A0D4',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      feeCharged: 0.4,
                      allowStakeNFT: false,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.YIN, TokenSymbol.WETH, ChainId.Matic),
                      tokenUni: tokenSymbol2token(TokenSymbol.WETH, ChainId.Matic),
                      tokenLock: tokenSymbol2token(TokenSymbol.YIN, ChainId.Matic),
                      miningContract: '0x150848c11040f6e52d4802bffaffbd57e6264737',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      feeCharged: 0.4,
                      allowStakeNFT: false,
                      isEnded: true,
                  },
              ],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.APEX, TokenSymbol.ETH, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.ETH, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.APEX, ChainId.Arbitrum),
                      miningContract: '0x1c0a560EF9f6Ff3f5c2BCCe98dC92f2649a507EF',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      feeCharged: 0.4,
                      allowStakeNFT: false,
                      isEnded: true,
                  },
              ],
          }
        : {};
