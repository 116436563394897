import { useColorMode } from '@chakra-ui/react';
import chains from '../config/chains';
import { ChainId } from '../types/mod';
import { getColorThemeSelector } from '../utils/funcs';
export const THEME_CONFIG = { enable: true };

export type CustomThemeType = {
    THEME: Record<number, string>;
    THEME_CARD: Record<number, string>;
    THEME_SELECT: Record<number, string>;
    THEME_BG: Record<number, string>;
    THEME_HEADER: Record<number, string>;
    THEME_HOME_FONT: Record<number, string>;
    THEME_BUTTON_FONT: Record<number, string>;
};
export const useCustomTheme = (): CustomThemeType => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const THEME = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = '#5FB76C';
        const theme = {
            [ChainId.Linea]: '#32A9C9',
            [ChainId.Scroll]: '#FF7121',
            [ChainId.Mantle]: '#529B96',
            [ChainId.X1Test]: colorTheme('#B4E348', '#C0EB00'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});
    const THEME_CARD = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = colorTheme('#FFFFFF', '#191919');
        const theme = {
            [ChainId.Linea]: colorTheme('#fff', '#1E2631'),
            [ChainId.Scroll]: colorTheme('#fff', '#2C261E'),
            [ChainId.Mantle]: colorTheme('#fff', '#222525'),
            [ChainId.X1Test]: colorTheme('#FFFFFF', '#191919'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});
    const THEME_SELECT = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = colorTheme('#F8F8FB', '#2D2E2A !important');
        const theme = {
            [ChainId.Linea]: colorTheme('#F8F8FB', '#0A1321 !important'),
            [ChainId.Scroll]: colorTheme('#F8F8FB', '#221D18 !important'),
            [ChainId.Mantle]: colorTheme('#F8F8FB', '#010303 !important'),
            [ChainId.X1Test]: colorTheme('#F8F8FB', '#2D2E2A !important'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});
    const THEME_BG = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = colorTheme('#F8F8FB', '#000 !important');
        const theme = {
            [ChainId.Linea]: colorTheme('#F8F8FB', '#16100A !important'),
            [ChainId.Scroll]: colorTheme('#F8F8FB', '#16100A !important'),
            [ChainId.Mantle]: colorTheme('#F8F8FB', '#16100A !important'),
            [ChainId.X1Test]: colorTheme('#F8F8FB', '#000 !important'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});
    const THEME_HEADER = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = colorTheme('#fff', '#191919 !important');
        const theme = {
            [ChainId.Linea]: colorTheme('#fff', '#0B071E !important'),
            [ChainId.Scroll]: colorTheme('#fff', '#2C261E !important'),
            [ChainId.Mantle]: colorTheme('#fff', '#182528 !important'),
            [ChainId.X1Test]: colorTheme('#fff', '#191919 !important'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});
    const THEME_HOME_FONT = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = colorTheme('#6A6A6A', '#909090');
        const theme = {
            [ChainId.Linea]: colorTheme('#5F6F7B', '#9DCBE4'),
            [ChainId.Scroll]: colorTheme('#846D57', '#DCC5B0'),
            [ChainId.Mantle]: colorTheme('#798280', '#E6F1FC'),
            [ChainId.X1Test]: colorTheme('#6A6A6A', '#909090'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});
    const THEME_BUTTON_FONT = chains.all.reduce<Record<number, string>>((memo, chain) => {
        const defalut_theme = colorTheme('#ffffff', '#ffffff');
        const theme = {
            [ChainId.X1Test]: colorTheme('#000', '#000'),
        } as any;
        memo[chain.id] = THEME_CONFIG.enable ? theme[chain.id] ?? defalut_theme : defalut_theme;
        return memo;
    }, {});

    return {
        THEME: THEME,
        THEME_CARD: THEME_CARD,
        THEME_SELECT: THEME_SELECT,
        THEME_BG: THEME_BG,
        THEME_HEADER: THEME_HEADER,
        THEME_HOME_FONT: THEME_HOME_FONT,
        THEME_BUTTON_FONT: THEME_BUTTON_FONT,
    } as CustomThemeType;
};

export default useCustomTheme;
