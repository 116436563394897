import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { BoxProps, useColorMode, Divider, HStack, VStack, Text, Image, Box, Center, Stack } from '@chakra-ui/react';
import { TokenSymbol } from '../../../../../types/mod';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h5, i_h2 } from '../../../../../style';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import tokenContracts from '../../../../../config/tokens';
import { PoolEntryState, PositionEntry } from '../../../../../state/models/farm/UniswapV3/dynamicRange/types';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../state/store';
import { formatNumber } from '../../../../../utils/tokenMath';
import { getTokenOwned } from '../../../../../net/contractCall/uniV3Interface/getTokenOwned';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';

import { usePositionManagerContract } from '../../../../../net/contractCall/positionManager';
import { useEffect, useState, useMemo } from 'react';
import { PositionsResponse } from '../../../../../types/abis/UniswapV3/UniswapPositionManager';
import { RootState } from '../../../../../state/store';
import { useSelector } from 'react-redux';
import { ModalHead } from '../../../components/ModalHead';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';
import { getSafeTokenPrice } from '../../../../../state/models/token/funcs';

type PositionDetailModalProps = {
    isOpen: boolean;
    onClose: () => void;
    entry: PoolEntryState;
    index: number;
} & BoxProps;

export const PositionDetailModal: React.FC<PositionDetailModalProps> = (props) => {
    const { isOpen, onClose, entry, index, ...rest } = props;

    const { chainId, web3 } = useWeb3WithDefault();
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { token } = useSelector((state: RootState) => state);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));

    const positionManagerContract = usePositionManagerContract(chainId, web3);
    const [tokenAGainDecimal, setTokenAGainDecimal] = useState(0);
    const [tokenBGainDecimal, setTokenBGainDecimal] = useState(0);

    const position = useMemo(() => {
        const p = [...(entry.positionList ?? []), ...(entry.stakedPositionList ?? [])][index];
        if (p === undefined) {
            return {} as PositionEntry;
        }
        return p;
    }, [entry, index]);

    useEffect(() => {
        getTokenOwned(positionManagerContract, Number(position.nftId)).then((r: PositionsResponse) => {
            setTokenAGainDecimal((parseFloat(r.tokensOwed0) / 10 ** entry.meta.tokenA.decimal) * (1 - (entry.meta.feeCharged ?? 0))); // after charge fee
            setTokenBGainDecimal((parseFloat(r.tokensOwed1) / 10 ** entry.meta.tokenB.decimal) * (1 - (entry.meta.feeCharged ?? 0)));
        });
    }, [position, positionManagerContract, entry, setTokenAGainDecimal, setTokenBGainDecimal]);

    const tokenAGainValue = tokenAGainDecimal * getSafeTokenPrice(token, entry.meta.tokenA.symbol);
    const tokenBGainValue = tokenBGainDecimal * getSafeTokenPrice(token, entry.meta.tokenB.symbol);

    const tokenIconBlock = (token: TokenInfoFormatted, amount: any, value: any, key?: number) => {
        let amountDisplay = amount;
        let valueDisplay = value;
        if (amountDisplay !== 0) {
            amountDisplay = formatNumber(amountDisplay, 2, 4, true);
        }
        if (valueDisplay !== 0) {
            valueDisplay = formatNumber(valueDisplay, 2, 4, true);
        }

        return (
            <Stack w="100%" direction={{ base: 'column', sm: 'row' }} justifyContent="space-between" key={key}>
                <HStack>
                    <Image borderRadius="4px" w="18px" h="18px" src={token.icon} />
                    <Text className={i_text_copy_bold} fontSize="16px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {token.symbol}
                    </Text>
                </HStack>
                <VStack alignItems="end" spacing="0px">
                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                        {amountDisplay}
                    </Text>
                    <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.300', 'tertiary.300')}>
                        ~ {valueDisplay} USD
                    </Text>
                </VStack>
            </Stack>
        );
    };

    const tokenBoostedBlock = (token: any) => (
        <HStack>
            <Image borderRadius="7px" w="14px" h="14px" src={process.env.PUBLIC_URL + tokenContracts[token as TokenSymbol].icon} />
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.400')}>
                {token} boosted
            </Text>
        </HStack>
    );
    // const uniswapNftAddr = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId];

    // const viewNft = (nftId: string) =>
    //     window.open(getNftUrl(chainId, uniswapNftAddr, nftId));

    let earnedDecimal = position.earnedDecimal;
    if (!earnedDecimal) {
        earnedDecimal = [];
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '73%', lg: '744px' }} h="750px" title={t('Liquidity Mining Status')} {...rest}>
            <ModalHead entry={entry} index={index} dispatch={dispatch.farmDynamicRange} />

            <Stack w="100%" direction={{ base: 'column', sm: 'row' }} spacing="16px" h={{ base: 'unset', sm: '384px' }} mt="30px">
                <Card variant="deep" w={{ base: '100%', sm: '328px' }} h={{ base: 'unset', sm: '384px' }}>
                    <VStack w="100%" p="25px 46px 14px 46px" spacing="30px" alignItems="center">
                        <Text className={i_h5} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.50')}>
                            {t('Your Liquidity')}
                        </Text>
                    </VStack>

                    <VStack
                        w="100%"
                        p="30px 46px 14px 46px"
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start" spacing="20px">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                {t('Token in pools')}
                            </Text>
                            {tokenIconBlock(entry.meta.tokenB, position.amountBInUniswapDecimal, position.worthBInUniswap)}
                            {tokenIconBlock(entry.meta.tokenA, position.amountAInUniswapDecimal, position.worthAInUniswap)}
                            <Box h="0px"></Box>
                        </VStack>
                    </VStack>

                    {(entry.meta.iZiBoost ?? false) && (
                        <VStack
                            w="100%"
                            p="25px 46px 14px 46px"
                            spacing="30px"
                            alignItems="center"
                            bg={colorTheme('linear-gradient(180deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%)', 'undefined')}
                            position="relative"
                        >
                            <Box
                                position="absolute"
                                w="36px"
                                h="36px"
                                borderRadius="18px"
                                transform="translateX(-50%) translateY(-50%)"
                                top="0"
                                left="50%"
                                bg={colorTheme('#F8F8F8', 'tertiary.600')}
                            >
                                <Center>
                                    <Text color={colorTheme('tertiary.400', 'tertiary.200')} fontSize="36px" mt="-12px !important">
                                        +
                                    </Text>
                                </Center>
                            </Box>

                            <VStack w="100%" alignItems="center" alignSelf="center" spacing="5px" mt="0px !important">
                                {tokenBoostedBlock(TokenSymbol.IZI)}
                                <Text className={i_h2} fontSize="24px" color={colorTheme('tertiary.700', 'tertiary.50')} fontWeight="500">
                                    {formatNumber(position.niZiDecimal, 2, 4)}
                                </Text>
                                <Text
                                    className={i_text_copy}
                                    fontSize="12px"
                                    color={colorTheme('tertiary.400', 'tertiary.400')}
                                    mb="-5px !important"
                                >
                                    {t('Estimate Value')}
                                </Text>

                                <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.400', 'tertiary.400')} mb="4px">
                                    {formatNumber(position.iZiWorth, 2, 4)} USD
                                </Text>
                            </VStack>
                        </VStack>
                    )}
                </Card>

                <Card variant="deep" w={{ base: '100%', sm: '328px' }} h={{ base: 'unset', sm: '384px' }}>
                    <VStack w="100%" p="25px 46px 14px 46px" spacing="30px" alignItems="center">
                        <Text className={i_h5} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.50')}>
                            {t('Your Reward')}
                        </Text>
                    </VStack>

                    {(entry.meta.feeCharged ?? 0) < 1 && (
                        <>
                            <VStack
                                w="100%"
                                p="20px 46px 14px 46px"
                                spacing="30px"
                                alignItems="center"
                                bg={colorTheme('undefined', 'tertiary.800')}
                            >
                                <VStack w="100%" alignItems="start" spacing="20px">
                                    <Text
                                        className={i_text_copy}
                                        fontSize="12px"
                                        color={colorTheme('tertiary.500', 'tertiary.200')}
                                        mb="4px"
                                    >
                                        {t('Fee Gains')}
                                    </Text>

                                    {tokenIconBlock(entry.meta.tokenA, tokenAGainDecimal, tokenAGainValue)}
                                    {tokenIconBlock(entry.meta.tokenB, tokenBGainDecimal, tokenBGainValue)}
                                </VStack>
                            </VStack>

                            {colorTheme(<Divider mt="15px !important" />, <></>)}
                        </>
                    )}

                    <VStack
                        w="100%"
                        p="30px 46px 14px 46px"
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start" spacing="20px">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                {t('Farming Incentives')}
                            </Text>
                            {earnedDecimal.map((amount, i) => {
                                return tokenIconBlock(entry.data.rewardTokens[i], amount, position.earnedWorth[i], i);
                            })}
                        </VStack>
                    </VStack>
                </Card>
            </Stack>
        </Modal>
    );
};
