import { ChainId } from '../../types/mod';
import chains from '../chains';

export const LIQUIDITY_MANAGER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0xBF55ef05412f1528DbD96ED9E7181f87d8C9F453', // 2023.07.18
        [ChainId.Aurora]: '0x61A41182CD6e94f2A026aE3c0D1b73B1AA579aEa', // 2023.07.18
        [ChainId.Arbitrum]: '0xAD1F11FBB288Cd13819cCB9397E59FAAB4Cdc16F', // 2023.07.18
        [ChainId.Matic]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', // 2023.07.18
        [ChainId.ETC]: '0xd7de110Bd452AAB96608ac3750c3730A17993DE0', // 2023.07.24
        [ChainId.ZkSyncEra]: '0x483FDE31bcE3DCc168E23a870831b50Ce2cCd1F1', //2023.07.17
        [ChainId.ConfluxESpace]: '0x6a7CDD0CC87ec02ed85c196e57BaBe1bc0Acd6f2', // 2023.07.18
        [ChainId.Meter]: '0x579ffe4A5c8CB2C969aE4E65039B7dBb6951d164', // 2023.07.18
        [ChainId.Telos]: '0x4a41EbEa62E7aB70413356D30DF73cA803aaE41c', // 2023.07.18
        [ChainId.EthereumMainnet]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.04.21
        [ChainId.Ultron]: '0xAC9788cfea201950dB91d7db6F28C448CF3A4B29', // 2023.07.18
        [ChainId.Linea]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', // 2023.07.17
        [ChainId.Mantle]: '0x611575eE1fbd4F7915D0eABCC518eD396fF78F0c', //2023.07.17
        [ChainId.Loot]: '0x1502d025BfA624469892289D45C0352997251728', // 2023.07.31
        [ChainId.Base]: '0x110dE362cc436D7f54210f96b8C7652C2617887D', // 2023.07.31
        [ChainId.Optimism]: '0x110dE362cc436D7f54210f96b8C7652C2617887D', // 2023.08.14
        [ChainId.OpBNB]: '0x110dE362cc436D7f54210f96b8C7652C2617887D', // 2023.08.24
        [ChainId.Kroma]: '0x110dE362cc436D7f54210f96b8C7652C2617887D', // 2023.09.06
        [ChainId.Manta]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.09.11
        [ChainId.Scroll]: '0x1502d025BfA624469892289D45C0352997251728', // 2023.10.13
        [ChainId.Ontology]: '0x344ADD21b136B09051fb061881eC7971c92cE7f7', // 2023.10.19

        //testnet
        [ChainId.BSCTestnet]: '0xDE02C26c46AC441951951C97c8462cD85b3A124c', // 2022.09.26
        [ChainId.OpBNBTestnet]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.07.11
        [ChainId.AuroraTestnet]: '0xEa0f268a066dF3329283b0AF7560e19B89c35511',
        [ChainId.ZkSyncAlphaTest]: '0x25727b360604E1e6B440c3B25aF368F54fc580B6', //2023.4.10
        [ChainId.MantleTest]: '0x879cd319b8aa506F4130acf766fA8E3654eD249B', //2023.06.27
        // [ChainId.OntologyTestnet]: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF', // 2023.04.25
        [ChainId.OntologyTestnet]: '0x5708c30c6eCEa3f432aC860Cf6CFdF631f576810', // 2023.10.19
        [ChainId.LineaTest]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0xa341a31CCdD570cAEab465c96D64c880db609021', // 2023.06.20
        [ChainId.MantaTest]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.07.31
        [ChainId.StagingFastActiveBellatrix]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.08.08
        [ChainId.KromaSepoliaTest]: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF', // 2023.08.23
        [ChainId.ScrollTestL2]: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF', // 2023.08.25
        [ChainId.GasZeroGoerliL2]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.09.20
        [ChainId.ZetaTest]: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF', // 2023.08.17
        [ChainId.X1Test]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.11.16
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const LIMIT_ORDER_MANAGER_ADDRESSES: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0x72fAfc28bFf27BB7a5cf70585CA1A5185AD2f201', // 2023.07.18
        [ChainId.Aurora]: '0x05dCaF89dFA5A09d832aC4e7FE01f75e82643F60', // 2023.07.18
        [ChainId.Arbitrum]: '0x156d8a0bE25FA232bb637Fc76255bCd00dEae9E9', // 2023.07.18
        [ChainId.Matic]: '0x25C030116Feb2E7BbA054b9de0915E5F51b03e31', // 2023.07.18
        [ChainId.ETC]: '0xAC9788cfea201950dB91d7db6F28C448CF3A4B29', // 2023.07.24
        [ChainId.ZkSyncEra]: '0xe36caa16D78eF3233848a542D437C1c540Ca8149', //2023.07.17
        [ChainId.ConfluxESpace]: '0x4a41EbEa62E7aB70413356D30DF73cA803aaE41c',
        [ChainId.Meter]: '0x35Dcc4e1ae93DA1316b603f0A9Fe3aD1b85C38C6', // 2023.07.18
        [ChainId.Telos]: '0x45e5F26451CDB01B0fA1f8582E0aAD9A6F27C218', // 2023.07.18
        [ChainId.EthereumMainnet]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.04.21
        [ChainId.Ultron]: '0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', // 2023.07.18
        [ChainId.Linea]: '0x25C030116Feb2E7BbA054b9de0915E5F51b03e31', // 2023.07.17
        [ChainId.Mantle]: '0xcA7e21764CD8f7c1Ec40e651E25Da68AeD096037', //2023.07.17
        [ChainId.Loot]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.07.31
        [ChainId.Base]: '0x1502d025BfA624469892289D45C0352997251728', // 2023.07.31
        [ChainId.Optimism]: '0x1502d025BfA624469892289D45C0352997251728', // 2023.08.14
        [ChainId.OpBNB]: '0x1502d025BfA624469892289D45C0352997251728', // 2023.08.24
        [ChainId.Kroma]: '0x1502d025BfA624469892289D45C0352997251728', // 2023.09.06
        [ChainId.Manta]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.09.11
        [ChainId.Scroll]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.10.13
        [ChainId.Ontology]: '0x79D175eF5fBe31b5D84B3ee359fcbBB466153E39', // 2023.10.19

        //testnet
        [ChainId.BSCTestnet]: '0x5C97187B2CEb1494078b70623c1E59edA79983A3', // 2022.09.26
        [ChainId.OpBNBTestnet]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.07.11
        [ChainId.AuroraTestnet]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6',
        [ChainId.ZkSyncAlphaTest]: '0x430972C4AF4703F7ce7B95C03735ae1504dD0Dd6', //2023.4.10
        [ChainId.MantleTest]: '0x4aAD4b81f886b12A29B68f9C8A7949d7d81Fe887', //2023.06.27
        // [ChainId.OntologyTestnet]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.04.25
        [ChainId.OntologyTestnet]: '0x3aB38EF845E86E949253Dd3a6FdA37Cc7d4Cd892', // 2023.10.19
        [ChainId.LineaTest]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0x35BF764d0440CBB1F7b546e6AF664177DEBF8304', // 2023.06.20
        [ChainId.MantaTest]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.07.31
        [ChainId.StagingFastActiveBellatrix]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.08.08
        [ChainId.KromaSepoliaTest]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.08.23
        [ChainId.ScrollTestL2]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.08.25
        [ChainId.GasZeroGoerliL2]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.09.20
        [ChainId.ZetaTest]: '0xC6C7c2edF70A3245ad6051E93809162B9758ce08', // 2023.08.17
        [ChainId.X1Test]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.11.16
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0x25fbB4FFB88366162329Fd6A92407726C92e6573', // 2023.07.18
        [ChainId.Arbitrum]: '0xE78e7447223aaED59301b44513D1d3A892ECF212', // 2023.07.18
        [ChainId.ETC]: '0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', // 2023.07.24
        [ChainId.ZkSyncEra]: '0x83109541318bbd104c9c85328696107F5767DFFB', //2023.07.17
        [ChainId.Meter]: '0x41826d56cdC83e8B006dA7F447248a2C3231aCf8', // 2023.07.18
        [ChainId.Ultron]: '0x9Bf8399c9f5b777cbA2052F83E213ff59e51612B', // 2023.07.18
        [ChainId.Linea]: '0x1D377311b342633A970e71a787C50F83858BFC1B', // 2023.07.17
        [ChainId.Mantle]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', //2023.07.17
        [ChainId.Loot]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.07.31
        [ChainId.Base]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.07.31
        [ChainId.Optimism]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.08.14
        [ChainId.OpBNB]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.08.24
        [ChainId.Kroma]: '0x19b683A2F45012318d9B2aE1280d68d3eC54D663', // 2023.09.06
        [ChainId.Manta]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.09.11
        [ChainId.Scroll]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.10.13
        [ChainId.Ontology]: '0xd7de110Bd452AAB96608ac3750c3730A17993DE0', // 2023.10.19

        //testnet
        [ChainId.BSCTestnet]: '0x14C91A5b3b130e2342cCe549c0f4072Fc7604B6B',
        [ChainId.OpBNBTestnet]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.07.11
        [ChainId.MantleTest]: '0x9b6C5f8D5e99ae928151F1e2Ed74C81B94167f11', //2023.06.27
        [ChainId.ZkSyncAlphaTest]: '0x51082D700FB9cD0E4AC9F7D98AE5Ec2e9B97879C', // 2023.4.10
        // [ChainId.OntologyTestnet]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.04.25
        [ChainId.OntologyTestnet]: '0x6953DEe956eEe39973F1e1090368Ba434CCa0d94', // 2023.10.19
        [ChainId.LineaTest]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0x60FE1bE62fa2082b0897eA87DF8D2CfD45185D30', // 2023.06.20
        [ChainId.MantaTest]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.07.31
        [ChainId.StagingFastActiveBellatrix]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.08.08
        [ChainId.KromaSepoliaTest]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.08.23
        [ChainId.ScrollTestL2]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.08.25
        [ChainId.GasZeroGoerliL2]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.09.20
        [ChainId.ZetaTest]: '0x1eE5eDC5Fe498a2dD82862746D674DB2a5e7fef6', // 2023.08.17
        [ChainId.X1Test]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.11.16
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const SWAP_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0xedf2021f41AbCfE2dEA4427E1B61f4d0AA5aA4b8', // 2023.07.18
        [ChainId.Aurora]: '0xEB9668316Cb87Bd107b05C52455ed31577eA82Cc', // 2023.07.18
        [ChainId.Arbitrum]: '0x01fDea353849cA29F778B2663BcaCA1D191bED0e', // 2023.07.18
        [ChainId.Matic]: '0x032b241De86a8660f1Ae0691a4760B426EA246d7', // 2023.07.18
        [ChainId.Cronos]: '0x04830cfCED9772b8ACbAF76Cfc7A630Ad82c9148', //2022.09.23
        [ChainId.ETC]: '0x9Bf8399c9f5b777cbA2052F83E213ff59e51612B', // 2023.07.24
        [ChainId.ZkSyncEra]: '0x943ac2310D9BC703d6AB5e5e76876e212100f894', //2023.07.17
        [ChainId.ConfluxESpace]: '0x611575eE1fbd4F7915D0eABCC518eD396fF78F0c', // 2023.07.18
        [ChainId.Meter]: '0x90bd53037B504fB83687Ea153F9657D3BD989976', // 2023.07.18
        [ChainId.Telos]: '0xcA7e21764CD8f7c1Ec40e651E25Da68AeD096037', // 2023.07.18
        [ChainId.EthereumMainnet]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', //2023.04.21
        [ChainId.Ultron]: '0x12a76434182c8cAF7856CE1410cD8abfC5e2639F', // 2023.07.18
        [ChainId.Linea]: '0x032b241De86a8660f1Ae0691a4760B426EA246d7', // 2023.07.17
        [ChainId.Mantle]: '0x25C030116Feb2E7BbA054b9de0915E5F51b03e31', //2023.07.17
        [ChainId.Loot]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.07.31
        [ChainId.Base]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.07.31
        [ChainId.Optimism]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.08.14
        [ChainId.OpBNB]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.08.24
        [ChainId.Kroma]: '0x02F55D53DcE23B4AA962CC68b0f685f26143Bdb2', // 2023.09.06
        [ChainId.Manta]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.09.11
        [ChainId.Scroll]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.10.13
        [ChainId.Ontology]: '0xAC9788cfea201950dB91d7db6F28C448CF3A4B29', // 2023.10.19

        //testnet
        [ChainId.BSCTestnet]: '0x4bD007912911f3Ee4b4555352b556B08601cE7Ce', // 2022.09.26
        [ChainId.OpBNBTestnet]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.07.11
        [ChainId.AuroraTestnet]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49',
        [ChainId.ZkSyncAlphaTest]: '0x3040EE148D09e5B92956a64CDC78b49f48C0cDdc', //2023.4.10
        [ChainId.MantleTest]: '0xae3272690D0db0199535EAec1C880283d4baD0cC', //2023.06.27
        // [ChainId.OntologyTestnet]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', //2023.04.25
        [ChainId.OntologyTestnet]: '0xcFf0318C36147deaB07Cf751a00b21F83d13412d', // 2023.10.19
        [ChainId.LineaTest]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0x2A19e0CF8c73280CdDdFd5877AA64A9690AE6d47', //2023.07.04
        [ChainId.MantaTest]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.07.31
        [ChainId.StagingFastActiveBellatrix]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.08.08
        [ChainId.KromaSepoliaTest]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.08.23
        [ChainId.ScrollTestL2]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.08.25
        [ChainId.GasZeroGoerliL2]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.09.20
        [ChainId.ZetaTest]: '0x77132b63429718Db2B6ad8D942eE13A198f6Ab49', // 2023.08.17
        [ChainId.X1Test]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.11.16
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const QUOTER_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0x0e79C263EeBc37977038F26fb86Dfa84636cFE84', // 2023.07.18
        [ChainId.Aurora]: '0xF2bd6078D51576B5c8d7e80AF3C4E2292e916985', // 2023.07.18
        [ChainId.Arbitrum]: '0x96539F87cA176c9f6180d65Bc4c10fca264aE4A5', // 2023.07.18
        [ChainId.Matic]: '0xe6805638db944eA605e774e72c6F0D15Fb6a1347', // 2023.07.18
        [ChainId.Cronos]: '0x2C6Df0fDbCE9D2Ded2B52A117126F2Dc991f770f', //2022.09.23
        [ChainId.ETC]: '0x12a76434182c8cAF7856CE1410cD8abfC5e2639F', // 2023.07.24
        [ChainId.ZkSyncEra]: '0x30C089574551516e5F1169C32C6D429C92bf3CD7', // 2023.07.17
        [ChainId.ConfluxESpace]: '0xcA7e21764CD8f7c1Ec40e651E25Da68AeD096037', // 2023.07.18
        [ChainId.Meter]: '0x72F51b617A9c48EbaDF997586C5C6472c08775A3', // 2023.07.18
        [ChainId.Telos]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', // 2023.07.18
        [ChainId.EthereumMainnet]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.04.21
        [ChainId.Ultron]: '0xBd3bd95529e0784aD973FD14928eEDF3678cfad8', // 2023.07.18
        [ChainId.Linea]: '0xe6805638db944eA605e774e72c6F0D15Fb6a1347', // 2023.07.17
        [ChainId.Mantle]: '0x032b241De86a8660f1Ae0691a4760B426EA246d7', //2023.07.17
        [ChainId.Loot]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.07.31
        [ChainId.Base]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.07.31
        [ChainId.Optimism]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.08.14
        [ChainId.OpBNB]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.08.24
        [ChainId.Kroma]: '0x2db0AFD0045F3518c77eC6591a542e326Befd3D7', // 2023.09.06
        [ChainId.Manta]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2023.09.11
        [ChainId.Scroll]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.10.13
        [ChainId.Ontology]: '0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', // 2023.10.19

        //testnet
        [ChainId.BSCTestnet]: '0x34bfB7478Eb5d13968CACeD116023D215A7505Dc', // 2022.11.24
        [ChainId.OpBNBTestnet]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.07.11
        [ChainId.AuroraTestnet]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3',
        [ChainId.ZkSyncAlphaTest]: '0xE93D1d35a63f7C6b51ef46a27434375761a7Db28', //2023.4.10
        [ChainId.MantleTest]: '0x6Cfc083bEFCFa41Dc36e33549F25429725B11A61', //2023.06.27
        // [ChainId.OntologyTestnet]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.04.25
        [ChainId.OntologyTestnet]: '0x848a0C239e1C42785f71e16B1d5c48D503fd0797', // 2023.10.19
        [ChainId.LineaTest]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0xcC2DE5D1A8154f7fa0e80a6732a38422D8fBEB10', // 2023.06.20
        [ChainId.MantaTest]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.07.31
        [ChainId.StagingFastActiveBellatrix]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.08.08
        [ChainId.KromaSepoliaTest]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.08.23
        [ChainId.ScrollTestL2]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.08.25
        [ChainId.GasZeroGoerliL2]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.09.20
        [ChainId.ZetaTest]: '0xa9754f0D9055d14EB0D2d196E4C51d8B2Ee6f4d3', // 2023.08.17
        [ChainId.X1Test]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.11.16
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});

export const QUOTER_ADDRESS_LIMIT: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0xDCe9a4ACC59E69ECcC0cdA2E82fe601fdB726542', // 2023.07.18
        [ChainId.Aurora]: '0x5f6AF64f2FE0AE00D20932B6698aaB89AFb847eB', // 2023.07.18
        [ChainId.Arbitrum]: '0x64b005eD986ed5D6aeD7125F49e61083c46b8e02', // 2023.07.18
        [ChainId.Matic]: '0xe4A0b241D8345d86FB140D40c87C5fbDd685B9dd', // 2023.07.18
        [ChainId.Cronos]: '0x88867BF3bB3321d8c7Da71a8eAb70680037068E4', // 2022.09.23
        [ChainId.ZkSyncEra]: '0x1a447a8Ec35B8120549C6567fC6FCC0768b318C2', //2023.07.17
        [ChainId.ETC]: '0xBd3bd95529e0784aD973FD14928eEDF3678cfad8', // 2023.07.24
        [ChainId.Meter]: '0x85a3871CA57637860b5d35E983341c92aE07Ed5C', // 2023.07.18
        [ChainId.Telos]: '0x25C030116Feb2E7BbA054b9de0915E5F51b03e31', // 2023.07.18
        [ChainId.EthereumMainnet]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2023.04.21
        [ChainId.Ultron]: '0xCFD8A067e1fa03474e79Be646c5f6b6A27847399', // 2023.07.18
        [ChainId.Linea]: '0xe4A0b241D8345d86FB140D40c87C5fbDd685B9dd', // 2023.07.17
        [ChainId.Mantle]: '0xe6805638db944eA605e774e72c6F0D15Fb6a1347', //2023.07.17
        [ChainId.Loot]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2023.07.31
        [ChainId.Base]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.07.31
        [ChainId.Optimism]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.08.14
        [ChainId.OpBNB]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.08.24
        [ChainId.Kroma]: '0x3EF68D3f7664b2805D4E88381b64868a56f88bC4', // 2023.09.06
        [ChainId.Manta]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406', // 2023.09.11
        [ChainId.ConfluxESpace]: '0x1CB60033F61e4fc171c963f0d2d3F63Ece24319c', // 2023.07.18
        [ChainId.Scroll]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2023.10.13
        [ChainId.Ontology]: '0x9Bf8399c9f5b777cbA2052F83E213ff59e51612B', // 2023.10.19

        //testnet
        [ChainId.BSCTestnet]: '0x4bCACcF9A0FC3246449AC8A42A8918F2349Ed543', // 2022.09.26
        [ChainId.OpBNBTestnet]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09', // 2023.07.11
        [ChainId.AuroraTestnet]: null,
        [ChainId.ZkSyncAlphaTest]: '0xA8101060508f3A7fB9a98425a7fb765DB14ae224', //2023.4.10
        [ChainId.MantleTest]: '0xd06D1eeDbD9c64971DC946F466c56f020CDE6540', //2023.06.27
        // [ChainId.OntologyTestnet]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.04.25
        [ChainId.OntologyTestnet]: '0xaDd9336AB4a795a66E4E1820f5d347c4294dd76a', // 2023.10.19
        [ChainId.LineaTest]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0x0e7091e9036c2E7b3E6978Dd868d458a731Cd998', // 2023.06.20
        [ChainId.MantaTest]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.07.31
        [ChainId.StagingFastActiveBellatrix]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.08.08
        [ChainId.KromaSepoliaTest]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.08.23
        [ChainId.ScrollTestL2]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.08.25
        [ChainId.GasZeroGoerliL2]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09', // 2023.09.20
        [ChainId.ZetaTest]: '0xF6FFe4f3FdC8BBb7F70FFD48e61f17D1e343dDfD', // 2023.08.17
        [ChainId.X1Test]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.11.16
    } as any;
    memo[chain.id] = contracts[chain.id] ?? null;
    return memo;
}, {});

export const BOX_ADDRESS: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        //mainnet
        [ChainId.BSC]: '0x7cbF7b96a7069bA384E10Ccb4F556DEBaB9c89F0', // 2023.07.18
        [ChainId.Aurora]: '0x759424DD2d409b4d6B39A83199177d07dc257ad7', // 2023.07.18
        [ChainId.Arbitrum]: '0x50E024bd504f066fd7f343d6D048F4BD97B1064E', // 2023.07.18
        [ChainId.Cronos]: '0xB8dDAfE7385A962a4515821248368823E93fa61F', //2022.09.23
        [ChainId.ZkSyncEra]: '0xb498C54F8F73969841C2cBce6FD550b82eBbcB63', //2023.07.17
        [ChainId.EthereumMainnet]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406', // 2023.04.21
        [ChainId.ConfluxESpace]: '0x25C030116Feb2E7BbA054b9de0915E5F51b03e31', // 2023.07.18
        [ChainId.Linea]: '0x19647d5f84308EE780cC5E3Da6E4aEe8e12B0ED4', // 2023.07.17
        [ChainId.Mantle]: '0x3a2932a74e511C9Dc4CaD60e06eE6D3690Ce2492', //2023.07.17
        //testnet
        [ChainId.BSCTestnet]: '0x8fbA4265785c56110B1277d6B23dD43B3797b3A8', // 2022.10.26
        [ChainId.OpBNBTestnet]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a', // 2023.07.11
        [ChainId.AuroraTestnet]: null,
        [ChainId.ZkSyncAlphaTest]: '0x4130b96c97406EdfCe493088A008D80a3F48F2Ae', //2023.4.10
        [ChainId.MantleTest]: '0x5617675423Ac3C73081a3e331F5f135A948606D7', //2023.06.27
        [ChainId.OntologyTestnet]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247', // 2023.04.25
        [ChainId.LineaTest]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09', // 2023.05.26
        [ChainId.OpsideTestRollux]: '0xec51f6b8082e7Aa0AFCB1e31d3d1162345bd5AdA', // 2023.06.20
    } as any;
    memo[chain.id] = contracts[chain.id] ?? null;
    return memo;
}, {});

export enum QUOTER_TYPE {
    limit,
    full,
}

export const SWAP_ADDRESS_WITH_SOURCE = chains.all.reduce<Record<number, any>>((memo, chain) => {
    const contracts = {
        [ChainId.ZkSyncEra]: {
            // K61R3U7BM4: '0x7Fb9b1492e4D69Ee8840c00c1baBc0E988DeeE9D',
            // '6xT2HD3hit': '0xeDd6fd68e3283D9855791bFb9B16C4D7E7591D07',
            // '3EGeGLgkWg': '0x2ddb0F3B573e366642d0c6f66EB360B22f9F9E07',
            // '18y5vp2zJh': '0xEbF9775748B7e25c3BEE5A673bb8785F324cF0D8',
        },
        [ChainId.ZkSyncAlphaTest]: {
            test_token: '0xdFA454b8BdA6B0b315852d178F00DE83D7739b2e',
        },
    } as any;
    memo[chain.id] = contracts[chain.id] ?? {};
    return memo;
}, {});

export const LIQUIDITY_MANAGER_ADDRESS_LEGACY: TokenId2ContractAddress = chains.all.reduce<Record<number, string>>((memo, chain) => {
    const contracts = {
        [ChainId.BSC]: '0x93C22Fbeff4448F2fb6e432579b0638838Ff9581', // 2022.05.10
        [ChainId.Aurora]: '0xE78e7447223aaED59301b44513D1d3A892ECF212', //2022.09.23
        [ChainId.Arbitrum]: '0x611575eE1fbd4F7915D0eABCC518eD396fF78F0c', // 2022.09.23
        [ChainId.Matic]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF', // 2022.09.28
        [ChainId.ZkSyncEra]: '0x936c9A1B8f88BFDbd5066ad08e5d773BC82EB15F', //2023.03.27
        [ChainId.Ontology]: '0x110dE362cc436D7f54210f96b8C7652C2617887D', // 2023.04.25
        [ChainId.Ultron]: '0xcA7e21764CD8f7c1Ec40e651E25Da68AeD096037', // 2023.05.18
    } as any;
    memo[chain.id] = contracts[chain.id] ?? '';
    return memo;
}, {});
