import {
    HStack,
    Stack,
    Text,
    Image,
    useColorMode,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Center,
    useInterval,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IZUMI_SWAP_CONFIG } from '../../../../../config/bizConfig';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import {
    getIziSwapOrderRecord,
    iZiSwapOrderTypeEnum,
    RequestIziSwapOrderRecord,
} from '../../../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiOrders';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';
import { getToken } from '../../../../../state/models/pro/pro';
import { getBaseQuoteOrder } from '../../../../../state/models/pro/proOrderFormState/funcs';
import { RootState } from '../../../../../state/store';
import { noto_h3, noto_t3_bold } from '../../../../../style';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { Loading, LoadingEnum } from '../../../../components/Loading';
type PastOrdersProps = {
    themeColor: any;
};
const PastOrders: React.FC<PastOrdersProps> = (props) => {
    const { themeColor } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { t } = useTranslation();

    const tabs = [
        { title: 'Pairs', width: { base: '128px', xxl: '126px' } },
        { title: 'Direction', width: { base: '84px', xxl: '113px' } },
        { title: 'Type', width: { base: '96px', xxl: '120px' } },
        { title: 'Amount', width: { base: '126px', xxl: '152px' } },
        { title: 'Price', width: { base: '115px', xxl: '152px' } },
        { title: 'Filled', width: { base: '116px', xxl: '130px' } },
        { title: 'Time', width: { base: '182px', xxl: '207px' } },
        { title: 'State', width: '86px' },
    ];
    const { pro, proControlState } = useSelector((state: RootState) => state);

    const { chainId, account } = useWeb3WithDefault();
    const [showList, setShowList] = useState([] as any[]);
    const { tokenList } = useTokenListFormatted();
    const [loading, setLoading] = useState(false);

    const tokenXIsGasToken = isGasOrWrappedGasToken(pro.tokenX, chainId);
    const tokenYIsGasToken = isGasOrWrappedGasToken(pro.tokenY, chainId);
    const gasToken = useGasToken();

    const refreshPastOrder = useCallback(() => {
        if (tokenList.length === 0) {
            return;
        }
        getIziSwapOrderRecord({ account_addr: account, order_by: '-time', page_size: 100 } as RequestIziSwapOrderRecord)
            .then((r) => {
                setLoading(false);
                const data = r.data.data;
                const filterData = data.filter((r) => {
                    const tokenA = getToken(r.token_in_symbol, chainId, tokenList) as TokenInfoFormatted;
                    const tokenB = getToken(r.token_out_symbol, chainId, tokenList) as TokenInfoFormatted;

                    const { quoteToken, baseToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                    return (
                        r.chain_id === chainId &&
                        baseToken.symbol === pro.tokenX.symbol &&
                        quoteToken.symbol === pro.tokenY.symbol &&
                        r.fee === pro.currentFee
                    );
                });
                const showList = filterData.map((item) => {
                    const tokenA = getToken(item.token_in_symbol, chainId, tokenList) as TokenInfoFormatted;
                    const tokenB = getToken(item.token_out_symbol, chainId, tokenList) as TokenInfoFormatted;

                    const { tokenAIsQuoteToken } = getBaseQuoteOrder(tokenA, tokenB, chainId);
                    const pairA = proControlState.useGasToken && tokenXIsGasToken ? gasToken.symbol : pro.tokenX.symbol;
                    const pairB = proControlState.useGasToken && tokenYIsGasToken ? gasToken.symbol : pro.tokenY.symbol;
                    const pair = pairA + '/' + pairB;
                    const direction = item.token_in_symbol === pro.tokenX.symbol ? 'Sell' : 'Buy';
                    const type = item.type === iZiSwapOrderTypeEnum.MARKET ? 'Market' : 'Limit';
                    const state = item.type === iZiSwapOrderTypeEnum.LIMIT_CANCEL ? 'Canceled' : 'Filled';
                    return {
                        Pairs: pair + '/' + toFeeNumber(item.fee) + '%',
                        Direction: direction,
                        Type: type,
                        Amount: tokenAIsQuoteToken
                            ? formatNumber(Number(item.amount_in) * Number(item.price), 2, 2, true)
                            : formatNumber(Number(item.amount_in), 2, 2, true),
                        Price: tokenAIsQuoteToken
                            ? formatNumber(1 / Number(item.price), 2, 2, true)
                            : formatNumber(Number(item.price), 2, 2, true),
                        Filled:
                            formatNumber(
                                ((Number(item.amount_in) - Number(item.amount_in_remain)) / Number(item.amount_in)) * 100,
                                2,
                                2,
                                true
                            ) + '%',
                        Time: moment(item.record_time).format('YYYY-MM-DD HH:mm:ss'),
                        State: state,
                    };
                });

                setShowList(showList);
            })
            .catch((e) => {
                console.info(e);
                setLoading(false);
            });
    }, [account, pro.pool, chainId, tokenList]);

    useEffect(() => {
        if (tokenList.length === 0) {
            return;
        }
        setLoading(true);
        refreshPastOrder();
    }, [account, pro.pool, chainId, tokenList]);

    useInterval(() => {
        refreshPastOrder();
    }, IZUMI_SWAP_CONFIG.AUTO_REFRESH_LIMIT_ORDER_DATA_INTERVAL);

    return isMobile ? (
        <Stack>
            {loading ? (
                <Loading
                    w="100%"
                    h="100%"
                    variant={LoadingEnum.purple}
                    justifyContent="center"
                    imgProps={{ boxSize: '16px' }}
                    textProps={{ fontSize: '14px' }}
                ></Loading>
            ) : showList.length === 0 ? (
                <Center w="100%" h="180px">
                    <Text className={noto_h3} color="#83789B">
                        {t("Oh, there's nothing here !")}
                    </Text>
                </Center>
            ) : (
                <Accordion allowMultiple={true} allowToggle={true}>
                    {showList.map((item: any, index: number) => {
                        return (
                            <AccordionItem key={index} mb="3px">
                                <AccordionButton
                                    px="10px"
                                    mx="0px"
                                    _expanded={{
                                        bg: colorTheme('#EEF0F9', '#1C1831'),
                                    }}
                                    _hover={{}}
                                    border={colorTheme('1px solid #EEEEEE', '1px solid #251C4D')}
                                >
                                    <HStack spacing="0px">
                                        <Stack w="110px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                {t('Pairs')}
                                            </Text>
                                            <Text className={noto_t3_bold} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {item.Pairs}
                                            </Text>
                                        </Stack>
                                        <Stack w="75px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                {t('Price')}
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {item.Price}
                                            </Text>
                                        </Stack>
                                        <Stack w="63px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                {t('Filled')}
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {item.Filled}
                                            </Text>
                                        </Stack>
                                        <Stack w="70px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                {t('Amount')}
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#FFFFFF')}>
                                                {item.Amount}
                                            </Text>
                                        </Stack>
                                    </HStack>
                                    <AccordionIcon />
                                </AccordionButton>

                                <AccordionPanel pb={4} bg={colorTheme('#EEF0F9', '#1C1831')} px="10px" pt="0px">
                                    <HStack w="100%" spacing="0px">
                                        <HStack w="110px">
                                            <HStack
                                                w="80px"
                                                bg={item.State === 'Filled' ? '#87AF73' : colorTheme('#EEEEEE', '#3A2F53')}
                                                borderRadius="3px"
                                                color={item.State === 'Filled' ? '#FFFFFF' : colorTheme('#B3B3B3', '#6A598C')}
                                                justifyContent="center"
                                                py="2px"
                                                mt="15px"
                                            >
                                                {item.State === 'Filled' && (
                                                    <Image boxSize="14px" src="/assets/pro/orders/check.svg"></Image>
                                                )}
                                                <Text className={noto_h3}>{item.State} </Text>
                                            </HStack>
                                        </HStack>
                                        <Stack w="75px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                {t('Direction')}
                                            </Text>
                                            <Text
                                                className={noto_h3}
                                                textAlign="left"
                                                color={item.Direction === 'Buy' ? '#12931F' : '#CB1414'}
                                            >
                                                {item.Direction}
                                            </Text>
                                        </Stack>
                                        <Stack w="63px">
                                            <Text className={noto_h3} textAlign="left" color="#6A598C">
                                                {t('Type')}
                                            </Text>
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#EEEEEE')}>
                                                {item.Type}
                                            </Text>
                                        </Stack>
                                        <Stack w="70px">
                                            <Text className={noto_h3} textAlign="left" color={colorTheme('#6A598C', '#C6B8CF')}>
                                                {item.Time}
                                            </Text>
                                        </Stack>
                                    </HStack>
                                </AccordionPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            )}
        </Stack>
    ) : (
        <Stack h="100%" overflow="auto">
            <HStack spacing="0px" position="sticky" top="0px" zIndex="1" bg={themeColor} pt="8px">
                {tabs.map((item, index) => {
                    return (
                        <Text className={noto_h3} key={index} w={item.width} color="#6A598C">
                            {t(item.title)}
                        </Text>
                    );
                })}
            </HStack>
            <Stack h="100%">
                {loading ? (
                    <Loading
                        w="100%"
                        h="100%"
                        variant={LoadingEnum.purple}
                        justifyContent="center"
                        imgProps={{ boxSize: '16px' }}
                        textProps={{ fontSize: '14px' }}
                    ></Loading>
                ) : showList.length === 0 ? (
                    <Center w="100%" h="100%">
                        <Text className={noto_h3} color="#83789B">
                            {t("Oh, there's nothing here !")}
                        </Text>
                    </Center>
                ) : (
                    showList.map((item: any, index: number) => {
                        return (
                            <HStack key={index} spacing="0px">
                                {Object.keys(item).map((key: any, i) => {
                                    return key === 'Time' ? (
                                        <Text className={noto_h3} key={i} w={tabs[i].width} color={colorTheme('#6A598C', '#C9C3D7')}>
                                            {item[key]}
                                        </Text>
                                    ) : key === 'State' ? (
                                        <HStack
                                            w={tabs[i].width}
                                            key={i}
                                            bg={item[key] === 'Filled' ? '#87AF73' : colorTheme('#EEEEEE', '#3A2F53')}
                                            borderRadius="3px"
                                            color={item[key] === 'Filled' ? '#FFFFFF' : colorTheme('#B3B3B3', '#6A598C')}
                                            justifyContent="center"
                                            py="2px"
                                        >
                                            {item[key] === 'Filled' && <Image boxSize="14px" src="/assets/pro/orders/check.svg"></Image>}
                                            <Text className={noto_h3}>{item[key]}</Text>
                                        </HStack>
                                    ) : key === 'Direction' ? (
                                        <Text
                                            className={noto_h3}
                                            key={i}
                                            w={tabs[i].width}
                                            color={item[key] === 'Buy' ? '#12931F' : '#CB1414'}
                                        >
                                            {item[key]}
                                        </Text>
                                    ) : (
                                        <Text className={noto_h3} key={i} w={tabs[i].width} color={colorTheme('#332D43', '#F8EBEB')}>
                                            {item[key]}
                                        </Text>
                                    );
                                })}
                            </HStack>
                        );
                    })
                )}
            </Stack>
        </Stack>
    );
};

export default PastOrders;
