import { ChainId } from '../../types/mod';

export const SupportFeeTiers = {
    // mainnet
    [ChainId.BSC]: [0.04, 0.2, 1], // ${fee}%
    [ChainId.EthereumMainnet]: [0.01, 0.04, 0.2, 1], // ${fee}%
    [ChainId.Aurora]: [0.04, 0.2, 1],
    [ChainId.ETC]: [0.04, 0.2, 1],
    [ChainId.Arbitrum]: [0.04, 0.2, 1],
    [ChainId.ZkSyncEra]: [0.04, 0.2, 1],
    [ChainId.Matic]: [0.04, 0.2, 1],
    [ChainId.Cronos]: [0.04, 0.2, 1],
    [ChainId.Ontology]: [0.04, 0.2, 1],
    [ChainId.Ultron]: [0.04, 0.2, 1],
    [ChainId.Meter]: [0.04, 0.2, 1],
    [ChainId.Telos]: [0.04, 0.2, 1],
    [ChainId.Icplaza]: [0.04, 0.2, 1],
    [ChainId.ConfluxESpace]: [0.04, 0.2, 1],
    [ChainId.Mantle]: [0.05, 0.3, 1],
    [ChainId.Linea]: [0.05, 0.3, 1],
    [ChainId.Loot]: [0.04, 0.2, 1],
    [ChainId.Optimism]: [0.04, 0.2, 1],
    [ChainId.Base]: [0.05, 0.3, 1],
    [ChainId.OpBNB]: [0.05, 0.3, 1],
    [ChainId.Kroma]: [0.05, 0.3, 1],
    [ChainId.Manta]: [0.05, 0.3, 1],
    [ChainId.Scroll]: [0.05, 0.3, 1],

    // testnet
    [ChainId.AuroraTestnet]: [0.04, 0.2, 1],
    [ChainId.BSCTestnet]: [0.04, 0.2, 1],
    [ChainId.MantleTest]: [0.04, 0.2, 1],
    [ChainId.ZkSyncAlphaTest]: [0.04, 0.2, 1],
    [ChainId.OntologyTestnet]: [0.04, 0.2, 1],
    [ChainId.ScrollTestL2]: [0.04, 0.2, 1],
    [ChainId.LineaTest]: [0.04, 0.2, 1],
    [ChainId.OpsideTestRollux]: [0.04, 0.2, 1],
    [ChainId.OpBNBTestnet]: [0.04, 0.2, 1],
    [ChainId.MantaTest]: [0.04, 0.2, 1],
    [ChainId.StagingFastActiveBellatrix]: [0.04, 0.2, 1],
    [ChainId.KromaSepoliaTest]: [0.04, 0.2, 1],
    [ChainId.GasZeroGoerliL2]: [0.04, 0.2, 1],
    [ChainId.ZetaTest]: [0.04, 0.2, 1],
    [ChainId.X1Test]: [0.04, 0.2, 1],
} as Record<ChainId, FeeTier[]>;
