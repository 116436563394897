import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import { useMiningFixRangeContract } from '../../../../hooks/useContracts';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { FarmFixRangeContractVersion } from '../../../../types/mod';
import { buildSendingParams } from '../../../../utils/contractHelpers';
import { PoolEntryState } from '../../farm/UniswapV3/fixRange/types';
import { MiningCallbacks } from './common/callbacks';

export interface FixRangeStakeParams {
    nftId: string;
    niZi?: string;
    // todo, we may need add fields for ve in the future
    // ve?: any;
}

export interface FixRangeEntity {
    stake: (params: FixRangeStakeParams, callbacks: MiningCallbacks, gasPrice: number) => void;
}

export interface StakeCallParams {
    account: any,
    miningContract: any,
    gas?: string,
    gasPrice?: number,
    stakeParams: FixRangeStakeParams,
}

const _getStakeCallV2 = (params: StakeCallParams): any => {
    const { account, miningContract, gas, gasPrice, stakeParams } = params;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    
    return [miningContract?.methods.deposit(
        stakeParams.nftId, 
        stakeParams.niZi, 
    ), options];
};

const _getStakeCallVeiZi = (params: StakeCallParams): any => {
    const { account, miningContract, gas, gasPrice, stakeParams } = params;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    
    return [miningContract?.methods.deposit(
        stakeParams.nftId, 
    ), options];
};

const useFixRangeEntity = (poolEntry: PoolEntryState): FixRangeEntity => {

    const { chainId, account } = useWeb3WithDefault();
    const { contract } = useMiningFixRangeContract(poolEntry.meta.miningContract, poolEntry.meta.contractVersion);
    const version = poolEntry.meta.contractVersion;

    const stake = useCallback(
        (params: FixRangeStakeParams, callbacks: MiningCallbacks, gasPrice: number) => {
            let getStakeCall = _getStakeCallV2;
            if (version === FarmFixRangeContractVersion.VEIZI) {
                getStakeCall = _getStakeCallVeiZi;
            }
            const [stakeCall, options] = getStakeCall(
                {
                    account, miningContract: contract, stakeParams: params
                }
            );
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                console.log('gaslimit in fixrange entity: ', gasLimit);
                const [sendStakeCall, sendOptions] = getStakeCall(
                    {
                        account, miningContract: contract, stakeParams: params, gas: gasLimit, gasPrice
                    }
                );
                sendStakeCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [chainId, version, account, contract]
    );
    return {
        stake
    };
};

export default useFixRangeEntity;