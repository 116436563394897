import React, { useCallback } from 'react';
import CustomButton from '../../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { withdraw, estimateWithdrawGas } from '../../../../../../net/contractCall/farm/fixRange/withdraw';
import { usePoolEntry } from './PoolListEntry';
import { useMiningFixRangeContract } from '../../../../../../hooks/useContracts';
import { BoxProps } from '@chakra-ui/react';
import { i_text_copy_bold } from '../../../../../../style';
import BigNumber from 'bignumber.js';
import { useGasPrice } from '../../../../../../hooks/useGasPrice';
import { ChainId } from '../../../../../../types/mod';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../../../config/chains';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';

export type UnstakeButtonProps = {
    tokenId?: string;
    onSuccess?(): void;
} & BoxProps;

export const UnstakeButton: React.FC<UnstakeButtonProps> = (props) => {
    const { tokenId, onSuccess, ...rest } = props;
    const { t } = useTranslation();
    const { account, chainId } = useWeb3WithDefault();
    const entry = usePoolEntry();
    const toast = useCustomToast();
    const { gasPrice } = useGasPrice();

    const { contract: miningContract } = useMiningFixRangeContract(entry.meta.miningContract, entry.meta.contractVersion);
    const onUnstake = useCallback(() => {
        if (miningContract && tokenId && account) {
            estimateWithdrawGas(miningContract, account, Number(tokenId)).then((gas: number) => {
                const chain = getChain(chainId);
                const toastLink = {} as ToastLink;
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                withdraw(chainId as ChainId, miningContract, account, Number(tokenId), gasLimit, gasPrice)
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Unstaking NFT...', undefined, toastLink);
                    })
                    .then(() => {
                        console.log('unstake success');
                        toast('success', 'Unstaking successfully.', undefined, toastLink);
                        onSuccess?.();
                    });
            });
        }
    }, [miningContract, tokenId, account, chainId, gasPrice, toast, onSuccess]);

    return <CustomButton onClick={onUnstake} variant="orange" text={t('Unstake')} fontClass={i_text_copy_bold} {...rest}></CustomButton>;
};

export default UnstakeButton;
