import React, { useEffect, useMemo } from 'react';
import { Accordion, Center, Heading, useColorMode } from '@chakra-ui/react';
import PoolListEntry from './PoolListEntry';
import { usePositionManagerContract } from '../../../../../net/contractCall/positionManager';
import { useFactoryContract } from '../../../../../hooks/uniswapV3/useFactoryContract';
import { useSelector } from 'react-redux';
import {
    InitPoolListDataParams,
    InitPoolListMetaParams,
    InitPositionParams,
    RefreshPoolListDataAndPositionParams,
} from '../../../../../state/models/farm/UniswapV3/dynamicRange/types';
import { FARM_CONFIG } from '../../../../../config/bizConfig';
import { RootDispatch, RootState } from '../../../../../state/store';
import { miningPoolConfigList } from '../../../../../config/farm/UniswapV3/dynamicRange/miningPoolMetaConst';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import useInterval from 'ahooks/lib/useInterval';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { AdaptationMode } from '../../../../../components/layout/PageLayout';

export interface FarmRefreshHandle {
    refreshPosition?(): void;
    refreshPoolListDataAndPosition?(): void;
    type?: AdaptationMode;
}

export const PoolList: React.FC = () => {
    const { chainId, web3, account } = useWeb3WithDefault();
    const { t } = useTranslation();
    const { farmDynamicRange: farm } = useSelector((state: RootState) => state);

    const positionManagerContract = usePositionManagerContract(chainId, web3);
    const positionFactoryContract = useFactoryContract(chainId, web3);
    const pools = miningPoolConfigList[chainId];
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const poolListFiltered = useMemo(() => {
        const control = farm.farmControl;
        let ff = [...farm.poolEntryList];

        if (account && control.stakedOnly) {
            ff = ff.filter((pool) => pool.stakedPositionList && pool.stakedPositionList.length > 0);
        }

        if (control.type === 'live') {
            ff = ff.filter((pool) => !pool.data.isEnded && !pool.meta.isEnded);
        } else {
            ff = ff.filter((pool) => pool.meta.isEnded || pool.data.isEnded);
        }

        if (control.sortBy === 'APR') {
            ff = ff.sort((a, b) => {
                const av = a.data.apr.length > 1 ? a.data.apr[1] : a.data.apr[0];
                const bv = b.data.apr.length > 1 ? b.data.apr[1] : b.data.apr[0];
                return bv - av;
            });
        } else if (control.sortBy === 'Liquidity') {
            ff = ff.sort((a, b) => {
                return (b.data.vLiquidity as unknown as number) - (a.data.vLiquidity as unknown as number);
            });
        }

        if (control.searchKey) {
            ff = ff.filter((pool) => {
                return pool.meta.tokenA.symbol.includes(control.searchKey) || pool.meta.tokenB.symbol.includes(control.searchKey);
            });
        }

        return ff;
    }, [farm, account]);

    const refreshPosition = () => {
        console.log('enter refresh position');
        dispatch.farmDynamicRange
            .initPosition({
                chainId,
                web3,
                positionManagerContract,
                account,
            } as InitPositionParams)
            .catch((e) => console.log('initPosition', e));
    };

    const refreshPoolListDataAndPosition = () =>
        dispatch.farmDynamicRange
            .refreshPoolListDataAndPosition({
                chainId,
                web3,
                positionFactoryContract,
                positionManagerContract,
                account,
            } as RefreshPoolListDataAndPositionParams)
            .catch((e) => console.log('initPoolListDataAndPosition', e));

    useEffect(() => {
        if (farm.currentFarmChainId === chainId) {
            return;
        }
        console.info('trigger initPoolList', chainId, account);
        dispatch.farmDynamicRange
            .initPoolList({
                chainId: chainId,
                web3,
                metaList: pools,
                positionFactoryContract,
            } as InitPoolListMetaParams & InitPoolListDataParams)
            .catch((e) => console.log('initPoolList', e));
    }, [chainId, web3, account, positionFactoryContract]);

    useEffect(() => {
        if (farm.poolEntryList.find((p) => p.positionList?.length || p.stakedPositionList?.length)) {
            return;
        }
        console.info('initPosition', chainId, account);
        refreshPosition();
    }, [chainId, account, web3, positionManagerContract]);

    useEffect(() => {
        console.info('trigger cleanPosition', account);
        dispatch.farmDynamicRange.cleanPositionIfExist(account).then(() => {
            if (!account) {
                return;
            }
            console.log('account change, trigger refreshFarmData', chainId, account);
            refreshPoolListDataAndPosition();
        });
    }, [chainId, web3, account, positionFactoryContract]);

    useInterval(() => {
        if (farm.poolEntryList.length === 0) {
            return;
        }
        console.log('trigger refreshFarmData', chainId, account);
        refreshPoolListDataAndPosition();
    }, FARM_CONFIG.AUTO_REFRESH_FARM_DATA_INTERVAL);

    return (
        <Accordion allowMultiple={true} allowToggle={true} mb="60px !important">
            {poolListFiltered.length > 0 ? (
                poolListFiltered.map((poolEntry, i) => (
                    <PoolListEntry
                        key={i}
                        entry={poolEntry}
                        mb="15px"
                        refreshPoolListDataAndPosition={refreshPoolListDataAndPosition}
                        refreshPosition={refreshPosition}
                        type={isMobile ? 'mobile' : 'pc'}
                    />
                ))
            ) : (
                <Center>
                    <Heading
                        size={isMobile ? 'sm' : 'lg'}
                        w={isMobile ? '75%' : 'unset'}
                        color={colorTheme('tertiary.100', 'tertiary.600')}
                        mt="50px !important"
                        textAlign={isMobile ? 'center' : 'unset'}
                    >
                        {t(
                            'No ' +
                                (farm.farmControl.type === 'live' ? 'live' : 'ended') +
                                ' dynamic range mining pools in current network yet.'
                        )}{' '}
                    </Heading>
                </Center>
            )}
        </Accordion>
    );
};
