import { ChainId } from '../../../../types/mod';

export const TAP_PROXY_ADDRESS = {
    //mainnet
    [ChainId.BSC]: '0xDa6dBe8951523391C9F2D1E6cb91FCc5a7B7Fbe2',
    [ChainId.Aurora]: '0xb922af73b899a4F0B9761b0c4407F1250FdD05be',
    [ChainId.Arbitrum]: '0xC4987497DEddd529e689809Ad213cc7645170c22',
    [ChainId.ETC]: '0xCFD8A067e1fa03474e79Be646c5f6b6A27847399',
    [ChainId.Scroll]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406',
    [ChainId.ZkSyncEra]: '0xaC9E38E8Ea126aF1Fa1822717C75b971BD69E8aa',
    [ChainId.ConfluxESpace]: '0x032b241De86a8660f1Ae0691a4760B426EA246d7',
    [ChainId.Meter]: '0xDE70867658B498f69A05a44C13743651C516B7eC',
    [ChainId.Telos]: '0x1D377311b342633A970e71a787C50F83858BFC1B',
    [ChainId.Ultron]: '0xAD1F11FBB288Cd13819cCB9397E59FAAB4Cdc16F',
    [ChainId.Linea]: '0x3a2932a74e511C9Dc4CaD60e06eE6D3690Ce2492',
    [ChainId.Mantle]: '0xe4A0b241D8345d86FB140D40c87C5fbDd685B9dd',
    [ChainId.Loot]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406',
    [ChainId.Base]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF',
    [ChainId.Optimism]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF',
    [ChainId.OpBNB]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF',
    [ChainId.Kroma]: '0x33531bDBFE34fa6Fd5963D0423f7699775AacaaF',
    [ChainId.Manta]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
    [ChainId.Ontology]: '0x12a76434182c8cAF7856CE1410cD8abfC5e2639F',

    //testnet
    [ChainId.BSCTestnet]: '0x2e6195eD946064aC6aAD70847788891F29AB62C9',
    [ChainId.OpBNBTestnet]: '0x6AECfe44225A50895e9EC7ca46377B9397D1Bb5b',
    [ChainId.MantleTest]: '0x0953C6771cD8eE5421dE0CE1F11BEC559EBCF9d6',
    [ChainId.ScrollTestL2]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247',
    [ChainId.ZkSyncAlphaTest]: '0x30Dd9d18CfC94AD34dD75defb71b189831d46688',
    // [ChainId.OntologyTestnet]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
    [ChainId.OntologyTestnet]: '0xfc88C03eea05aCd91e394d418B73978D6AF10E94',
    [ChainId.LineaTest]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
    [ChainId.OpsideTestRollux]: '0x7a29F4b9b6d924e8C974c84d789b923A4dBC8e46',
    [ChainId.MantaTest]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
    [ChainId.StagingFastActiveBellatrix]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
    [ChainId.KromaSepoliaTest]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247',
    [ChainId.GasZeroGoerliL2]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
    [ChainId.ZetaTest]: '0x95c5F14106ab4d1dc0cFC9326225287c18c2d247',
    [ChainId.X1Test]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
} as Record<ChainId, string>;