import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmOneSideContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';
import { tokenSymbol2token } from '../../../tokens';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    initialToggle?: boolean;

    miningContract?: any;
    swapPoolAddress: string;

    tokenUni: TokenInfoFormatted;
    tokenLock: TokenInfoFormatted;

    useTimestamp?: boolean;

    twoRewards?: boolean;
    lockBoost?: boolean;
    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    noFee?: boolean;
    feeCharged?: number;
    allowStakeNFT?: boolean;
    contractVersion?: FarmOneSideContractVersion;
    isEnded?: boolean;
    remark?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [],
              [ChainId.Matic]: [],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.slUSDC, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.slUSDC, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.Arbitrum),
                      miningContract: '0xbe53AC7D965D94B3786Bd7A9B0d4858E09cc3506',
                      swapPoolAddress: '0x40Ca43f1C90919a53bC0E5482E2347ED64cdfe6B',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.slUSDT, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.slUSDT, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.Arbitrum),
                      miningContract: '0x31406859b696D122d4B30f1f617226886793BD7c',
                      swapPoolAddress: '0x5B2292B257beB805F9ef3983B584E98641353E48',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.IUSD, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.IUSD, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.Arbitrum),
                      miningContract: '0x02F91e7f9F0B2a0B62Ef0a2a5986870d99451C62',
                      swapPoolAddress: '0xD01Cb43b6CC8860dE7e8e7eF3F4992DC56aAe256',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
              ],
              [ChainId.BSC]: [],
          }
        : {};
