import { Image, useColorMode, Text, BoxProps, VStack } from '@chakra-ui/react';
import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AIRDROP_CHAIN } from '../../../config/bizConfig';
import tokens, { tokenSymbol2token } from '../../../config/tokens';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useTokenListFormatted, TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { Modal } from '../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { useCustomToast, ToastType } from '../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { getSkaleAirdrop } from '../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { RootState, RootDispatch } from '../../../state/store';
import { i_h4, i_text_copy } from '../../../style';
import { TokenSymbol, ChainId } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';

type Props = BoxProps;
export const AirdropLoadingModal: React.FC<Props> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId, account, web3 } = useWeb3WithDefault();
    const { ...rest } = props;
    const { account: accountModel } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { tokenList } = useTokenListFormatted();
    const toast = useCustomToast();
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [isAirdropSuccess, setIsAirdropSuccess] = useState(false);

    const getToken = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        };
        if (tokens[symbol as TokenSymbol]) {
            return tokenSymbol2token(symbol, chainId);
        }
        return tempToken;
    };
    const balance = useMemo(() => {
        if (AIRDROP_CHAIN[chainId] && account) {
            return accountModel.tokenBalance[AIRDROP_CHAIN[chainId].token]?.[0];
        } else {
            return 0;
        }
    }, [accountModel.tokenBalance, chainId, account, isAirdropSuccess]);

    const checkTransactionStatus: any = async (transactionHash: string) => {
        try {
            const receipt = await web3.eth.getTransactionReceipt(transactionHash);
            if (!receipt) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
                return checkTransactionStatus(transactionHash);
            } else if (receipt.status) {
                return 'success';
            } else {
                return 'failed';
            }
        } catch (error) {
            toast(ToastType.error, error);
        }
    };

    useEffect(() => {
        if (AIRDROP_CHAIN[chainId] && account) {
            if (balance < AIRDROP_CHAIN[chainId].amount && chainId === ChainId.StagingFastActiveBellatrix) {
                setShowLoadingModal(true);
                if (isConfirm) {
                    const token = getToken(AIRDROP_CHAIN[chainId].token) as TokenInfoFormatted;
                    getSkaleAirdrop(account)
                        .then((res: any) => {
                            const transactionHash = res.data.distribute.hash;
                            checkTransactionStatus(transactionHash)
                                .then((result: any) => {
                                    if (result === 'success') {
                                        dispatch.account
                                            .fetchTokenBalanceIfMissing({
                                                account: account as unknown as any,
                                                web3,
                                                token: token,
                                                chainId,
                                                skipCache: true,
                                            })
                                            .then(() => {
                                                setIsAirdropSuccess(true);
                                            });
                                    }
                                })
                                .catch((error: any) => {
                                    console.info(error);
                                });
                        })
                        .catch((e) => {
                            setShowLoadingModal(false);
                            toast(ToastType.error, e.message);
                        });
                }
            }
        }
    }, [account, chainId, balance, isConfirm]);

    return (
        <Modal
            isOpen={showLoadingModal}
            onClose={() => {
                setShowLoadingModal(false);
                setIsAirdropSuccess(false);
            }}
            w={{ base: '341px', sm: '540px' }}
            minH={{ base: '383px', sm: '478px' }}
            title=""
            isShowClose={false}
            {...rest}
        >
            <VStack h={{ base: '300px', sm: '350px' }} spacing="10px" pt={{ base: '30px', sm: '50px' }}>
                {isAirdropSuccess ? (
                    <Image
                        w="60px"
                        h="60px"
                        src={colorTheme('/assets/loading/confirm.svg', '/assets/loading/confirm.svg')}
                        fallbackSrc={colorTheme('/assets/loading/confirm.svg', '/assets/loading/confirm.svg')}
                    ></Image>
                ) : isConfirm ? (
                    <Image
                        w="60px"
                        h="60px"
                        src={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}
                        fallbackSrc={colorTheme('/assets/loading/loading.gif', '/assets/loading/darkLoading.gif')}
                    ></Image>
                ) : (
                    <Image
                        w="60px"
                        h="60px"
                        src={colorTheme('/assets/loading/question.svg', '/assets/loading/question.svg')}
                        fallbackSrc={colorTheme('/assets/loading/question.svg', '/assets/loading/question.svg')}
                    ></Image>
                )}

                <Text
                    className={i_h4}
                    mt="38px !important"
                    color={colorTheme('#2E0069', '#E8D6FF')}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                >
                    {'Airdrop  ' + AIRDROP_CHAIN[chainId].amount + ' ' + AIRDROP_CHAIN[chainId].token + ' into your wallet'}
                </Text>

                <Text className={i_text_copy} color={colorTheme('#5D2DAC', '#C29CFF')}>
                    Airdrop tokens into your wallet.
                </Text>

                <CustomButton
                    w="210px"
                    h="51px"
                    mt="65px !important"
                    borderRadius="3px"
                    variant={'purple'}
                    className={i_text_copy}
                    text={isAirdropSuccess ? 'Close' : 'Comfirm'}
                    onClick={() => {
                        if (isAirdropSuccess) {
                            setShowLoadingModal(false);
                            setIsAirdropSuccess(false);
                        } else {
                            setIsConfirm(true);
                        }
                    }}
                ></CustomButton>
            </VStack>
        </Modal>
    );
};
